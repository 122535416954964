import React, { useEffect } from 'react';
import "./style.css";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import localurl from '../../../src/assets/images/big-lightgallry/012.jpg';

const CustomDocViewer = ({ ls_url }) => {
  const docs = [
    { uri: ls_url }, // Document URL
  ];

  const config = {
    header: {
      disableHeader: true, // Hide the header
      disableFileName: true, // Hide the file name
      retainURLParams: false // Do not retain URL parameters
    },
  };

  const headers = {
    mode: 'no-cors',
  };

  const removeViewerComponent = () => {
    const footerTaskbar = document.querySelectorAll('.footer-taskbar-class'); // Change to the actual class/id of the footer taskbar
    footerTaskbar.forEach(el => {
      el.style.display = 'none';
    });
  };

  useEffect(() => {
    // Function to disable right-click
    const disableRightClick = (document) => {
      const handleContextMenu = (e) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextMenu);
    };

    // Apply right-click disable to the main document
    disableRightClick(document);

    // Get all iframes
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      iframe.onload = () => {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        disableRightClick(iframeDocument);
      };
    });

    // Call removeViewerComponent after a slight delay
    setTimeout(removeViewerComponent, 2000);

    // Cleanup function to remove the event listener
    return () => {
      iframes.forEach((iframe) => {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.removeEventListener('contextmenu', disableRightClick);
      });
      document.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);

  useEffect(() => {
    removeViewerComponent();
  }, [document.querySelectorAll('iframe')]);

  return (
    <div className='docviewr-prnt'>
      <DocViewer className="my-doc-viewer-style noSelect"
        prefetchMethod="GET"
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={config}
        requestHeaders={headers}
        theme={{
          primary: "#333",
          secondary: "#222",
          tertiary: "#555",
          textPrimary: "#FFF",
          textSecondary: "#EEE",
          textTertiary: "#CCC",
          disableThemeScrollbar: false,
        }}
        style={{ height: '650px' }}
      />
    </div>
  );
};

export default CustomDocViewer;
