import { Button, Cascader, Col, Divider, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import './style.css'
import { SearchOutlined } from '@ant-design/icons';
import { getRequestApiByToken } from '../../../../api';
import Typography from 'antd/lib/typography/Typography';
const { Search } = Input;

const AdvanceSerchForm = ({advanceForm, la_sectorData=[],la_practices=[],la_country=[],la_clientName=[],handleMultiSearch=()=>{}, lb_loading,lb_isAdvanceSearch=false, la_documentCategory, la_documentSubCategory, setDocumentSubCategory}) => {
    
    const getDocumentSubCategoryData = async (item) => {
        setDocumentSubCategory(item?.subcategory || [])
    }
    //   useEffect(() => {
    //     // const selectedOption = la_documentCategory.find(option => option.id === ls_documentCatId);
    //     getDocumentSubCategoryData(selectedOption)
    //   }, [ la_documentCategory])

    //   useEffect(() => {
    //     getDocumentCategoryData()
    //     getDocumentSubCategoryData()
    //  }, [])
    
  return (
    <>
        <Card>
            <CardBody>
            <Form 
              className="theme-form" 
              labelCol={{ span: 24 }}
              onFinish={handleMultiSearch}
              form={advanceForm}
            >
                <Row gutter={[6]}>
                    {/* <Col xs={24} sm={24} md={21} lg={21}>
                        <Form.Item name='search'>
                            <Input placeholder="Search text" suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)',}} />} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={1} lg={1}></Col>
                    <Col xs={24} sm={24} md={2} lg={2}>
                    <div className="col-xl-3 xl-100 col-sm-12 "  >
                        <Button
                                loading={lb_loading}
                                icon={<i className="fa fa-search" style={{ color: 'white' }} />}
                                className="btn btn-primary button-style"
                                type="button"
                                htmlType='submit'
                                style={{ marginInlineStart: 3,width:'100%' }}
                                //   onClick={handleMultiSearch}
                        />
                    </div> 
                    
                    </Col> */}
                    <div className={`col-xl-12 xl-100 col-sm-12 mb-2`} >
                    <Typography.Text>Search project document (Project name / Mandate no / Document name)</Typography.Text>
                    </div>
                     {/* <div className={`col-xl-${lb_isAdvanceSearch? 12 : 11} xl-100 col-sm-12`}
                     style={{backgroundColor:'red'}}
                     > */}
                    <Col xs={24} sm={lb_isAdvanceSearch ? 24 : 22} md={lb_isAdvanceSearch ? 24 : 22} lg={lb_isAdvanceSearch ? 24 : 22}>
                        <Form.Item name='search' >
                            <Input allowClear placeholder="Enter Project name / Mandate no / Document name" suffix={lb_isAdvanceSearch && <SearchOutlined style={{ color: 'rgba(0,0,0,.45)',}} />} />
                        </Form.Item>
                        </Col>
                    {/* </div> */}
                    {/* </Col> */}
                {!lb_isAdvanceSearch && 
                    <div className="col-xl-1 xl-100 col-sm-12 searchbutton"  
                    //  style={{marginTop:-37}}
                    >
                            <Button
                                loading={lb_loading}
                                icon={<i className="fa fa-search" style={{ color: 'white' }} />}
                                className="btn btn-primary button-style"
                                type="button"
                                htmlType='submit'
                                style={{ marginInlineStart: 3,width:'98%' }}
                            ></Button>
                    </div>
                     }
                </Row>
                 {lb_isAdvanceSearch && 
                <Row gutter={[6]}>
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Form.Item label="Country" name="country_id">
                            <Select placeholder="Select Country"
                                // onChange={(value, event) => {setCategoryId(value); setCategoryIdName(event?.children)}}
                                mode='multiple'
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                           >
                               {la_country?.map(country => (
                                    <Select.Option key={country.value} value={country.value}>
                                        {country.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Form.Item
                            label="Sectors"
                            name="sector_id"
                        >
                            <Select
                                placeholder="Select Sectors"
                                mode="multiple"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {la_sectorData.map(sector => (
                                    <Select.Option key={sector.value} value={sector.value}>
                                        {sector.label}
                                    </Select.Option>
                                ))}
                            </Select>
                                
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Form.Item
                            label="Practices"
                            name="parctices"
                        >
                            <Select placeholder="Select Practices"
                                // options={la_practices}
                                mode='multiple'
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                             {la_practices?.map(practices => (
                                    <Select.Option key={practices.value} value={practices.value}>
                                        {practices.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Form.Item
                            label="Client"
                            name="donor"
                        >
                            <Select placeholder="Select Client"
                                // options={la_clientName}
                                mode='multiple'
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                             {la_clientName?.map(clientName => (
                                    <Select.Option key={clientName.value} value={clientName.value}>
                                        {clientName.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={20} md={20} lg={20}> */}
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Form.Item
                              label="Document Category"
                              name="category_id"
                            //   rules={[{ required: true,  message: "Please input Document Category!"}]}
                          >
                              <Select placeholder="Select Document Category"
                                onChange={(value, event) => {getDocumentSubCategoryData(event)}}
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {la_documentCategory?.map(rowValue => (
                                    <Select.Option key={rowValue.id} value={rowValue.id} subcategory={rowValue?.children || []}>
                                        {rowValue.category_name}
                                    </Select.Option>
                                ))}
                              </Select>
                            
                          </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                    <Form.Item label="Document Sub Category" name="child_cat_id">
                            <Select placeholder="Select Document Category"
                              allowClear showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {la_documentSubCategory?.map(rowValue => (
                                  <Select.Option key={rowValue.id} value={rowValue.id}>
                                      {rowValue.category_name}
                                  </Select.Option>
                              ))}
                            </Select>
                          
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Form.Item
                            label="Tag"
                            name="key_words"
                            style={{marginInlineEnd:3}}
                        >
                            <Select
                              mode="tags"
                              style={{ width: '100%' }}
                              placeholder="Tags Mode"
                            //   onChange={handleTagSelect}
                              options={[]}
                            />
                        </Form.Item>
                    </Col>
                    {/* </Col> */}

                    <div className="col-xl-3 xl-100 col-sm-12 serchdoc-btn"  >
                        {/* <Form.Item> */}
                            <Button
                                loading={lb_loading}
                                icon={<i className="fa fa-search" style={{ color: 'white' }} />}
                                className="btn btn-primary button-style"
                                type="button"
                                htmlType='submit'
                                style={{ marginInlineStart: 3 }}
                                //   onClick={handleMultiSearch}
                            >Search</Button>
                        {/* </Form.Item> */}
                    </div>
                </Row>
                 }
            </Form>
            </CardBody>
        </Card>
    </>
  )
}

export default AdvanceSerchForm