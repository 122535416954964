import { Button, message } from 'antd/lib';
import React, { useState } from 'react'
import { LoadingOutlined, DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import { postRequestApiByToken } from '../../../../api';

const DocumentRequest = ({ lo_documentData, handleGetdata = () => {} }) => {
    const [lb_reqLoading, setReqLoading] = useState(false);

    const handleOnClickRequestForLimit = (row) => {
        Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleFilled />,
            content: 'You want to request for increase limit?',
            onOk() {
                return new Promise((resolve, reject) => {
                    requestForDocLimit(row, resolve, reject)
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    };

    const requestForDocLimit = async (rowValue, resolve, reject) => {
        setReqLoading(true)
        const res = await postRequestApiByToken('createLimitRequest', {});
        console.log('request---',res);
        setReqLoading(false)
        if (res?.status == "Success") {
            message.success('Request sent successfully!');
            resolve();
            handleGetdata();
        } else if (res?.status == "Error") {
            message.error(res?.message);
            reject();
            handleGetdata();
        } else {
            message.error('Something wrong! Request not sent!');
            reject();
            handleGetdata();
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-12 xl-100" >
                    <div className="card" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="text-center pgress-space">
                            <Button loading={lb_reqLoading} onClick={() => handleOnClickRequestForLimit(lo_documentData)} className="btn btn-pill btn-outline-light-2x btn-air-light txt-dark" type="default" htmlType='button'>Request for Download / View Limit</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentRequest