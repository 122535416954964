import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postRequestApiWithDataType } from '../../../api';
import { message } from 'antd/lib';
import { Loader } from 'react-feather';

const MicrosoftLoginPage = () => {
  const ls_userId = useSelector(content => content.commonReducer.user_id);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // Function to initiate the login process
  const login = () => {
    // instance.loginRedirect({
    //     scopes: ["user.read"], // Add other scopes as needed
    // });
    instance.loginRedirect({
      scopes: ["user.read"],
    }).catch((error) => console.log(error));
  };

  // Function to initiate the logout process
  const logout = () => {
    instance.logout();
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setUserDEtails = (userType, userId, authToken, userDetails) => {
    dispatch({ type: 'SET_USER_TOKEN', payload: authToken });
    dispatch({ type: 'SET_USER_ID', payload: userId });
    dispatch({ type: 'SET_USER_TYPE', payload: userType });
    dispatch({ type: 'SET_USER_DETAILS', payload: { ...userDetails } });
    dispatch({ type: 'SET_LOGIN_TYPE', payload: 'msal' });
  }

  const loginAuth = async (lo_data) => {
    console.log('---lo_data', lo_data);
    if (!lo_data?.username || !lo_data?.idToken) {
      message.error('Invalid Email Id!');
      return;
    }

    const lo_postData = { ...lo_data }
    const lo_response = await postRequestApiWithDataType('json', 'auth-login', lo_postData)
    if (lo_response?.success) {
      const usrTyp = (lo_response?.user?.user_type_id == 1) ? 'admin' : 'user';
      setUserDEtails(usrTyp, lo_response?.user?.id, lo_response?.token?.token, lo_response?.user);
      message.success("Logged in successfully!");
      if (usrTyp == 'user') {
        navigate(`${process.env.PUBLIC_URL}/document/search-document`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      }

    } else {
      // instance.logout({ postLogoutRedirectUri: `${process.env.PUBLIC_URL}/login`});
      message.error(lo_response?.message);
      instance.logout();
      navigate(`${process.env.PUBLIC_URL}/login`);
    }
  }

  useEffect(() => {
    console.log('accounts--------------------', accounts, isAuthenticated);
    if (isAuthenticated && accounts && accounts[0]) loginAuth(accounts[0]);
  }, [accounts, isAuthenticated])

  useEffect(() => {
    login()
  }, [])


  return (
    <>
      <div><Loader /></div>
      {/* <button onClick={login} style={{ background: "#2f2f2ff0", height: 50, display: "flex", alignItems: "center" }} type="button" className="btn social-btn btn-fb mb-2 text-center"><img style={{ width: 35, marginRight: 8 }} src={'https://purepng.com/public/uploads/large/purepng.com-microsoft-logo-iconlogobrand-logoiconslogos-251519939091wmudn.png'} className="align-self-center pull-left  blur-up lazyloaded" />{'Sign in with Microsoft'}</button> */}

      {/* <div>
                {isAuthenticated ? (
                    <div>
                        <h3>Welcome, {accounts[0].name}</h3>
                        <button onClick={logout}>Sign out</button>
                    </div>
                ) : (
                    <button onClick={login}>Sign in with Microsoft</button>
                )}
            </div> */}
    </>

  );
};

export default MicrosoftLoginPage;