import { Button, Form, Input, Table } from 'antd';
import { Badge, Spin, Tag, message } from 'antd/lib';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react'
import { getRequestApiByToken } from '../../../../../api';

const UserWithAccessDetails = ({ userId }) => {

  const [form] = Form.useForm()
  const [la_sectorData, setSectorData] = useState([])
  const [lb_spining, setSpining] = useState(false);

  const getSectorData = async () => {
    const res = await getRequestApiByToken('all_sector');
    console.log('--res',res);
    setSectorData(res?.sector_master || [])
  }

  const user_columns = [
    {
      title: 'Sector',
      dataIndex: 'sector_name',
      key: 'sector_name',
      render: (text, record, inx) => <>
        <span>{record.sector_name}</span>
        <div >
          <Form.Item noStyle name={['sectors', inx, 'id']} >
            <Input type="hidden" value={record.id} />
          </Form.Item>
        </div>
      </>,
      width: 300,
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      align: 'center',
      render: (_, record, inx) =>
        <Form.Item noStyle name={['sectors', inx, 'view']} valuePropName="checked">
          <Checkbox disabled />
        </Form.Item>
      ,
      width: 100, // Set a specific width for the "View" column
    },
    {
      title: 'Download',
      dataIndex: 'download',
      key: 'download',
      align: 'center',
      render: (_, record, inx) =>
        <Form.Item noStyle name={['sectors', inx, 'download']} valuePropName="checked">
          <Checkbox disabled />
        </Form.Item>
      ,
      width: 100, // Set a specific width for the "Download" column
    },
    {
      title: 'Upload',
      dataIndex: 'upload',
      key: 'upload',
      align: 'center',
      render: (_, record, inx) =>
        <Form.Item noStyle name={['sectors', inx, 'upload']} valuePropName="checked">
          <Checkbox disabled />
        </Form.Item>,
      width: 100, // Set a specific width for the "Upload" column
    },
    {
      title: 'Approve',
      dataIndex: 'approve',
      key: 'approve',
      align: 'center',
      render: (_, record, inx) =>
        <Form.Item noStyle name={['sectors', inx, 'approve']} valuePropName="checked">
          <Checkbox disabled />
        </Form.Item>,
      width: 100, // Set a specific width for the "Approve" column
    },
    {
      title: 'Review required',
      dataIndex: 'approve',
      key: 'require_approval',
      align: 'center',
      render: (_, record, inx) => <Form.Item noStyle name={['sectors', inx, 'require_approval']} valuePropName="checked">
        <Checkbox disabled />
      </Form.Item>,
      width: 100, // Set a specific width for the "Approve" column
    },
  ];

  const getuserinfo = async () => {
    setSpining(true)
    let res = {};
    if (userId) res = await getRequestApiByToken(`usersectoraccess/${userId}`);
    console.log('----res---', res);
    const lo_record = res?.User ? res.User : {};

    const lo_sectorObj = {};
    for (let k = 0; k < lo_record?.sectoraccess?.length; k++) {
      const rowVal = lo_record?.sectoraccess[k];
      if (rowVal?.pivot?.sector_id) lo_sectorObj[rowVal.pivot.sector_id] = {
        "id": rowVal.pivot?.sector_id,
        "view": rowVal.pivot?.view ? 1 : 0,
        "download": rowVal.pivot?.download ? 1 : 0,
        "upload": rowVal.pivot?.upload ? 1 : 0,
        "approve": rowVal.pivot?.approve ? 1 : 0,
        "require_approval": rowVal.pivot?.require_approval ? 1 : 0
      }
    }

    const sectorsdata = la_sectorData?.map((rowValue) => {
      let obj = {
        "id": rowValue?.id,
        "view": rowValue?.view ? 1 : 0,
        "download": rowValue?.download ? 1 : 0,
        "upload": rowValue?.upload ? 1 : 0,
        "approve": rowValue?.approve ? 1 : 0,
        "require_approval": rowValue?.require_approval ? 1 : 0
      }

      if (lo_sectorObj[rowValue?.id]) {
        obj = { ...obj, ...lo_sectorObj[rowValue?.id] }
      }
      return obj;
    })

    const lo_updateDate = { "sectors": sectorsdata };
    form.resetFields()
    form.setFieldsValue(lo_updateDate)
    setSpining(false)
  };

  useEffect(() => {
    getSectorData()
  }, [])

  useEffect(() => {
    getuserinfo()
  }, [userId, la_sectorData])



  return (
    <div>
      <Spin spinning={lb_spining}>
        <Form form={form} >
          <Table
            columns={user_columns}
            dataSource={la_sectorData}
            pagination={false}
            size="small"
            scroll={{ y: 400 }} // Set the vertical scroll height
          />
        </Form>
      </Spin>
    </div>
  )
}

export default UserWithAccessDetails