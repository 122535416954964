import { Button, Form, Input, Table } from 'antd';
import { message } from 'antd/lib';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import { getRequestApiByToken, postRequestApiByTokenWithDataType } from '../../../api';
import { SearchOutlined } from '@ant-design/icons';
import Typography from 'antd/lib/typography/Typography';

const UserWithAccess = ({ userId, handleSetDataAction }) => {
  const [form] = Form.useForm();
  const [la_sectorData, setSectorData] = useState([]);
  const [la_sectorFormData, setSectorFormData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [lb_spining, setSpining] = useState(false);
  const [lb_loading, setLoading] = useState(false);
  const [headerCheckboxes, setHeaderCheckboxes] = useState({
    view: false,
    download: false,
    upload: false,
    approve: false,
    require_approval: false,
  });

  const la_sectors = Form.useWatch('sectors', form);

  useEffect(() => {
    console.log('---la_sectors----', la_sectors);
  }, [la_sectors]);

  useEffect(() => {
    if (filteredInfo.sector_name) {
      const filtered = la_sectorData.filter(sector =>
        sector.sector_name.toLowerCase().includes(filteredInfo.sector_name.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(la_sectorData);
    }
  }, [filteredInfo, la_sectorData]);

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const getSectorData = async () => {
    const res = await getRequestApiByToken('sector_master');
    setSectorData(res?.sector_master || []);
    setFilteredData(res?.sector_master || []);
  };

  const handleSelectAll = (columnKey, checked) => {
    const updatedValues = form.getFieldsValue(true);
    updatedValues.sectors = updatedValues.sectors.map(sector => ({
      ...sector,
      [columnKey]: checked,
    }));
    form.setFieldsValue(updatedValues);
    setHeaderCheckboxes(prevState => ({ ...prevState, [columnKey]: checked }));
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setFilteredInfo({ sector_name: value });
  };

  const handleRowCheckboxChange = (columnKey, index, checked) => {
    const formValues = form.getFieldsValue(true);
    const allChecked = formValues.sectors.every(sector => sector[columnKey]);
    if (!checked && headerCheckboxes[columnKey]) {
      setHeaderCheckboxes(prevState => ({ ...prevState, [columnKey]: false }));
    } else if (checked && allChecked) {
      setHeaderCheckboxes(prevState => ({ ...prevState, [columnKey]: true }));
    }
  };

  const user_columns = [
    {
      title: 'Sector',
      dataIndex: 'sector_name',
      key: 'sector_name',
      render: (text, record, inx) => (
        <>
          <span>{record.sector_name}</span>
          <div>
            <Form.Item noStyle name={['sectors', inx, 'id']}>
              <Input type="hidden" value={record.id} />
            </Form.Item>
          </div>
        </>
      ),
      width: 300,
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search sector"
            onChange={handleSearch}
            style={{ marginBottom: 8, display: 'flex' }}
            allowClear
            prefix={
              <SearchOutlined
                style={{
                  color: 'rgba(0,0,0,.25)',
                }}
              />
            }
          />
        </div>
      ),
      onFilter: (value, record) => record.sector_name.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: (
        <div style={{display:"flex"}}>
          <Typography.Text>View</Typography.Text>
          <Checkbox
            onChange={(e) => handleSelectAll('view', e.target.checked)}
            checked={headerCheckboxes.view}
            style={{ marginLeft: 8 }}
          />
        </div>
      ),
      dataIndex: 'view',
      key: 'view',
      align: 'center',
      render: (_, record, inx) => (
        <Form.Item noStyle name={['sectors', inx, 'view']} valuePropName="checked">
          <Checkbox onChange={(e) => handleRowCheckboxChange('view', inx, e.target.checked)} />
        </Form.Item>
      ),
      width: 100,
    },
    {
      title: (
        <div style={{display:"flex"}}>
          Download
          <Checkbox
            onChange={(e) => handleSelectAll('download', e.target.checked)}
            checked={headerCheckboxes.download}
            style={{ marginLeft: 8 }}
          />
        </div>
      ),
      dataIndex: 'download',
      key: 'download',
      align: 'center',
      render: (_, record, inx) => (
        <Form.Item noStyle name={['sectors', inx, 'download']} valuePropName="checked">
          <Checkbox onChange={(e) => handleRowCheckboxChange('download', inx, e.target.checked)} />
        </Form.Item>
      ),
      width: 100,
    },
    {
      title: (
        <div style={{display:"flex"}}>
          Upload
          <Checkbox
            onChange={(e) => handleSelectAll('upload', e.target.checked)}
            checked={headerCheckboxes.upload}
            style={{ marginLeft: 8 }}
          />
        </div>
      ),
      dataIndex: 'upload',
      key: 'upload',
      align: 'center',
      render: (_, record, inx) => (
        <Form.Item noStyle name={['sectors', inx, 'upload']} valuePropName="checked">
          <Checkbox onChange={(e) => handleRowCheckboxChange('upload', inx, e.target.checked)} />
        </Form.Item>
      ),
      width: 100,
    },
    {
      title: (
        <div style={{display:"flex"}}>
          Approve
          <Checkbox
            onChange={(e) => handleSelectAll('approve', e.target.checked)}
            checked={headerCheckboxes.approve}
            style={{ marginLeft: 8 }}
          />
        </div>
      ),
      dataIndex: 'approve',
      key: 'approve',
      align: 'center',
      render: (_, record, inx) => (
        <Form.Item noStyle name={['sectors', inx, 'approve']} valuePropName="checked">
          <Checkbox onChange={(e) => handleRowCheckboxChange('approve', inx, e.target.checked)} />
        </Form.Item>
      ),
      width: 100,
    },
    {
      title: (
        <div style={{display:"flex"}}>
          <Typography.Text>Review required</Typography.Text>
          <Checkbox
            onChange={(e) => handleSelectAll('require_approval', e.target.checked)}
            checked={headerCheckboxes.require_approval}
            style={{ marginLeft: 8 }}
          />
        </div>
      ),
      dataIndex: 'require_approval',
      key: 'require_approval',
      align: 'center',
      render: (_, record, inx) => (
        <Form.Item noStyle name={['sectors', inx, 'require_approval']} valuePropName="checked">
          <Checkbox onChange={(e) => handleRowCheckboxChange('require_approval', inx, e.target.checked)} />
        </Form.Item>
      ),
      width: 100,
    },
  ];

  const handleSubmitForm = async (formValues) => {
    if (!userId) {
      message.error('Please Choose Any user!');
      return;
    }

    const sectors = formValues?.sectors?.map((rowValue, indx) => {
      return {
        id: la_sectorFormData[indx]?.id,
        view: rowValue?.view ? 1 : 0,
        download: rowValue?.download ? 1 : 0,
        upload: rowValue?.upload ? 1 : 0,
        approve: rowValue?.approve ? 1 : 0,
        require_approval: rowValue?.require_approval ? 1 : 0,
      };
    });

    let lo_formData = { sectors: sectors, user_id: userId };

    setLoading(true);
    const res = await postRequestApiByTokenWithDataType('json', 'usersectoraccess', lo_formData);
    if (res?.status == 'Success') {
      message.success('Successfully Updated!');
      handleSetDataAction('get_user_access_list');
    } else {
      message.error(res?.message);
    }
    setLoading(false);
  };

  const getuserinfo = async () => {
    setSpining(true);
    let res = {};
    if (userId) res = await getRequestApiByToken(`usersectoraccess/${userId}`);
    console.log('----res---', res);
    const lo_record = res?.User ? res.User : {};

    const lo_sectorObj = {};
    for (let k = 0; k < lo_record?.sectoraccess?.length; k++) {
      const rowVal = lo_record?.sectoraccess[k];
      if (rowVal?.pivot?.sector_id)
        lo_sectorObj[rowVal.pivot.sector_id] = {
          id: rowVal.pivot?.sector_id,
          view: rowVal.pivot?.view ? 1 : 0,
          download: rowVal.pivot?.download ? 1 : 0,
          upload: rowVal.pivot?.upload ? 1 : 0,
          approve: rowVal.pivot?.approve ? 1 : 0,
          require_approval: rowVal.pivot?.require_approval ? 1 : 0,
        };
    }

    const sectorsdata = la_sectorData?.map((rowValue) => {
      let obj = {
        id: rowValue?.id,
        view: rowValue?.view ? 1 : 0,
        download: rowValue?.download ? 1 : 0,
        upload: rowValue?.upload ? 1 : 0,
        approve: rowValue?.approve ? 1 : 0,
        require_approval: rowValue?.require_approval ? 1 : 0,
      };

      if (lo_sectorObj[rowValue?.id]) {
        obj = { ...obj, ...lo_sectorObj[rowValue?.id] };
      }
      return obj;
    });

    const lo_updateDate = { sectors: sectorsdata };
    setSectorFormData(sectorsdata);
    form.resetFields();
    form.setFieldsValue(lo_updateDate);
    setSpining(false);
  };

  useEffect(() => {
    getSectorData();
  }, []);

  useEffect(() => {
    getuserinfo();
  }, [userId, la_sectorData]);

  useEffect(() => {
    const formValues = form.getFieldsValue(true);
    const sectors = formValues.sectors || [];
    const newHeaderCheckboxes = {
      view: sectors.every(sector => sector.view),
      download: sectors.every(sector => sector.download),
      upload: sectors.every(sector => sector.upload),
      approve: sectors.every(sector => sector.approve),
      require_approval: sectors.every(sector => sector.require_approval),
    };
    setHeaderCheckboxes(newHeaderCheckboxes);
  }, [form.getFieldsValue(true)]);

  return (
    <div>
      <Form form={form} onFinish={handleSubmitForm}>
        <Table
          columns={user_columns}
          dataSource={filteredData}
          onChange={handleChange}
          pagination={false}
          size="small"
          scroll={{ y: 400 }} // Set the vertical scroll height
        />

        <Button
          loading={lb_loading}
          className="btn btn-primary button-style"
          type="button"
          htmlType="submit"
          style={{ float: 'right', maxWidth: 100 }}
        >
          Save
        </Button>
      </Form>
    </div>
  );
};

export default UserWithAccess;
