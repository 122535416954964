import React, { useEffect, useState } from 'react'
import { getRequestApiByToken } from '../../../api';
import DocumentListing from '../DocumentListing';
import Breadcrumb from '../../../components/common/breadcrumb';

const ShareDocumentByMe = () => {
  const [la_documentTypes, setDocumentTypes] = useState([])
  const [lb_loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changePage, setChangePage] = useState(false);
  const [la_documentList, setDocumentList] = useState([])

  const getSearchData = async () => {
    setLoading(true);
    const res = await getRequestApiByToken(`getmysharedocument`);
    console.log('response====>>>', res?.data?.data);
    setDocumentList(res?.data?.data)
    setLoading(false)
  }

  useEffect(() => {
    getSearchData()
  }, [])

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Breadcrumb parent="Shared By Me" title="" />
      <div className="col-xl-12">
        <DocumentListing
          {...{
            lb_showDocTypeTag: false,
            la_documentTypes,
            lb_loading,
            handlePaginationChange,
            totalCount,
            la_documentList,
            getSearchData,
            setLoading,
            ls_headerText: 'Shared By Me',
            la_actionAceess: ['view']
          }} />
      </div>
    </>
  )
}

export default ShareDocumentByMe