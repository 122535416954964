import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import UserDashboard from './userDashboard';
import AdminDashboard from './adminDashboard';
import { useNavigate } from 'react-router-dom';
import { setApproverDocumentCount } from '../../utility';

const Dashboard = () => {
    const ls_userType = useSelector(content => content.commonReducer.user_type);

    useEffect(() => {
        setApproverDocumentCount();
        console.log('navigate >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    }, [])

    return (
        <>
            {ls_userType == 'user' ? <UserDashboard /> : (ls_userType == 'admin' ? <AdminDashboard /> : '')}
        </>
    )
}

export default Dashboard