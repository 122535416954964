
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container } from "reactstrap";
import DataTable from 'react-data-table-component'
import AddDocumentForm from './addDocumentForm';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Tooltip, message ,Input} from 'antd';
import { FileIconsObj } from '../../../constant/globalConfig';
import { checkIsnumericValue, getCascaderIdsFormArray, getCountryCode, getFileExtension, getIdsFormArray } from '../../../utility';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

const { Search } = Input;

const AddOtherDocument = () => {
  const ls_userId = useSelector(content => content.commonReducer.user_id);
  const ls_userType = useSelector(content => content.commonReducer.user_type);
  const [la_documentList, setDocumentList] = useState([])
  const [loading, setLoading] = useState(false);
  const [lb_buttonLoader, setButtonLoader] = useState(false)
  const [ls_updateId, setUpdateId] = useState('')
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lb_spining, setSpining] = useState(false);
  const [la_columnsList, setColumnsLis] = useState([]);
  const [filterText, setFilterText] = useState('');

  const navigate = useNavigate();
  const [form] =Form.useForm()
  const location = useLocation();
  // const ls_documentId = location?.state?.documentId || null;
  useEffect(() => {
    setUpdateId(location?.state?.updateId || null)
  }, [location])

  const getDocumentList = async () => {
    setLoading(true)
      const res = await getRequestApiByToken(`otherdocument?search=${filterText}&orderBy=desc&page=${currentPage}`)
      setDocumentList(res?.Documents?.data)
      setTotalCount(res?.Documents?.total);
    setLoading(false)
  }
  
 useEffect(() => {
    getDocumentList()
 }, [currentPage,filterText])



 const returnCountryIcon = (cntryNm) => {
  const cntryCode = getCountryCode(cntryNm);
  if (cntryCode) {
    return <Tooltip title={cntryNm}>
            <i className={`flag-icon flag-icon-${cntryCode.toLowerCase()}`} style={{ width: 45, fontSize: 16, padding: 5}}></i>
          </Tooltip>;
  } else {
    return cntryNm;
  }
 }
 
 const columns = [
  {
    name: "Country",
    selector: (row) => <div>
      <span>
        {row?.project?.country[0]?.country_name ? returnCountryIcon(row.project.country[0].country_name) : ''}
      </span>
    </div>,
    // row?.project?.country[0]?.country_name,
    sortable: true,
    center: true,
    width: '100px', 
  },
  {
    selector: (row) => row?.title,
    name: "Document Name",
    sortable: true,
    width: '310px', 
    // center: true,
  },
  {
    selector: (row) => row?.project?.sector?.map((i,inx)=>i?.sector_name),
    name: "Sector",
    sortable: true,
    center: true,
    // width: '265px', 
  },
  {
    selector: (row) => moment(row?.document_created_date).format("DD MMM YYYY"),
    name: "Date",
    sortable: true,
    center: true,
    // width: '110px', 
  },
  {
    selector: (row) =>row.owners.name,
    name: "Uploaded By",
  //   sortable: true,
    center: true,
    // width: '200px', 
  },
  {
    selector: (row) => {
      const extnsn = getFileExtension(row?.document_file || '');
      return <div>
     {(row?.document_file && extnsn) ? FileIconsObj[extnsn] : row?.document_file}

      {/* <span style={{cursor:"pointer"}}>
      <i 
      className="fa fa-pencil" 
      style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}
      onClick={() => { setUpdateId(row.id); window.scrollTo({ top: 0, behavior: "smooth" }) ;}}
      ></i>
    </span> */}
      </div>
    } ,
    name: "Type",
  //   sortable: true,
    center: true,
    // width: '60px', 
  },
  {
    selector: (row) => <div>
     <span style={{cursor:"pointer"}}>
      <i 
      className="fa fa-pencil" 
      style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}
      onClick={() => { setUpdateId(row.id); window.scrollTo({ top: 0, behavior: "smooth" }) ;}}
      ></i>
    </span>
    {/* <span>
      <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    </span> */}
  </div>,
    name: "Action",
  //   sortable: true,
    center: true,
    // width: '100px',
  },
];

useEffect(() => {
  const columnList = [...columns];
  // if (ls_userType == 'user') {
  //    columnList.pop();
  // }
  setColumnsLis(columnList);
}, [ls_userType])

const resetFormData = async ()=>{
  form.resetFields()
  setUpdateId('')
}

const [lo_updatRecord, setUpdateRecord] = useState({})
const setFormData = async ()=>{
  setSpining(true)
  const lo_updateDate = {}
  if (ls_updateId) {
      const lo_response = await getRequestApiByToken(`otherdocument/${ls_updateId}`);
      const lo_record = lo_response?.Documents ? lo_response?.Documents : {};
      console.log('--!!!!!!!!!lo_record,',lo_record);
      console.log('lo_record',lo_record);
      lo_updateDate['title'] = lo_record.title;
      lo_updateDate['project_id'] = lo_record?.project?.id;

      // lo_updateDate['parent_cat_id'] = lo_record?.parent_cat_id && parseInt(lo_record?.parent_cat_id);
      // if(lo_record?.child_cat_id && lo_record.child_cat_id != 'undefined') lo_updateDate['child_cat_id'] = parseInt(lo_record.child_cat_id);

      if (lo_record?.parent_cat_id && checkIsnumericValue(lo_record?.parent_cat_id)) {
        lo_updateDate['parent_cat_id'] = parseInt(lo_record.parent_cat_id);
      }
      if (lo_record?.child_cat_id && checkIsnumericValue(lo_record?.child_cat_id)) {
        lo_updateDate['child_cat_id'] = parseInt(lo_record.child_cat_id);
      }

      lo_updateDate['sector_id'] = getIdsFormArray(lo_record?.sectors);
      lo_updateDate['parctices'] = getIdsFormArray(lo_record?.practices);
      lo_updateDate['group_id'] = getIdsFormArray(lo_record?.groups);
      // lo_updateDate['Country'] = getCascaderIdsFormArray(lo_record?.country);
      lo_updateDate['key_words'] = lo_record?.key_words?.split(",") || [];
      // getCascaderIdsFormArray(lo_record?.groups);
      // lo_updateDate['document_file'] = lo_record.document_file;
      if(lo_record?.document_file) lo_updateDate['document_file'] = [{ uid: '1', name: lo_record.document_file }];
      if(lo_record?.document_created_date) lo_updateDate['document_created_date'] = dayjs(lo_record.document_created_date);
      lo_updateDate['author'] = lo_record?.author;
      lo_updateDate['version'] = lo_record?.version;
      lo_updateDate['description'] = lo_record?.description;
      setUpdateRecord(lo_record);
  }else{
    setUpdateRecord({})
  }
  form.resetFields()
  form.setFieldsValue(lo_updateDate)
  setSpining(false)
}
useEffect(() => {
  console.log('ls_updateId:', ls_updateId);
  setFormData();
}, [ls_updateId]);


 const handleSubmitForm = async (formValues) => {
  console.log('formValues....>>>>>',formValues);
  let lo_formData = {
      ...formValues,
      // document_created_date: new Date(formValues.document_created_date).toISOString().slice(0, 10),
      document_created_date: dayjs(formValues.document_created_date).format('YYYY-MM-DD'),
      owner:ls_userId,
      status: lo_updatRecord?.status || 0
  }
  const la_files = [];
  formValues?.document_file?.forEach((file) => {
      // console.log('2-----------------------file.originFileObj',file);
      // lo_formData['fileuploadname'] = file.originFileObj;
      if(file?.originFileObj) {
          la_files.push({fieldKey:'document_file', value: file.originFileObj})
      } else {
          la_files.push({fieldKey:'document_file', value: file.name})
      }
  });

  setButtonLoader(true)
  console.log('---lo_formData',lo_formData);
   if (ls_updateId) {
     const res = await postRequestApiByToken('otherdocument/' + ls_updateId, lo_formData, la_files)
     console.log('res in update api calll------>>>>', res);
     if (res?.status == 'Success') {
       // setFormData()
       getDocumentList()
       form.resetFields()
       form.setFieldsValue({})
       setUpdateId('')
       navigate('/other-documents/search-document')
       message.success('Successfully Updated!');

     } else {
       message.error(res?.message);
     }
   } else {
     // lo_formData['description'] = '';
     lo_formData['status'] = 0;
     lo_formData['owner'] = ls_userId;
     const res = await postRequestApiByToken('otherdocument', lo_formData, la_files)
     if (res?.status == "Success") {
       getDocumentList()
       form.resetFields()
       form.setFieldsValue({})
       setUpdateId('')
       message.success(res?.message);
     } else {
       message.error(res?.message)
     }
   }
    setButtonLoader(false)
  }
  const handleSearch = debounce((value) => {
    setFilterText(value);
 }, 300);
    return (
        <>
        <Breadcrumb parent= {ls_updateId ? "Edit Other Document" :"Add Other Document"} title="Manage Other Document"  />
        <Container fluid={true}>

          <AddDocumentForm
            handleSubmitForm={handleSubmitForm}
            lb_buttonLoader={lb_buttonLoader}
            ls_updateId={ls_updateId}
            form={form}
            resetFormData={resetFormData}
            lb_spining={lb_spining}
            lo_updatRecord={lo_updatRecord}
          />

          {/* <div className="col-xl-12 xl-100">
          <div className="card">
          <div className="card-header card-header-border">
                            <div className="row" style={{alignItems:'center'}}>
                              <div className="col-sm-8">
                                  <h5>{"Last Uploaded"}</h5>
                              </div>
                              <div className="col-sm-4">
                                  <div>
                                  <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
                                  </div>
                              </div>
                            </div>
                        </div>
             <div className="card-body">
                <div className="table-responsive">
                    <DataTable
                      columns={la_columnsList}
                      data={la_documentList}
                      striped={true}
                      center={true}
                      persistTableHead
                      pagination
                      paginationServer
                      paginationTotalRows={totalCount} // Assuming 10 rows per page
                      paginationPerPage={10} // Number of rows per page
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                      paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                      onChangePage={setCurrentPage} 
                      progressPending={loading}
                      progressComponent={<div><LoadingOutlined/></div>}
                      customStyles={{
                      headCells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px',
                          },
                      },
                      rows: { style: { minHeight: '40px'}},
                      cells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px', // Adjust cell padding as needed
                          },
                      },
                      }}
                   />
                </div>
             </div>
          </div>
          </div> */}
      </Container>
        </>
    )
  }
export default AddOtherDocument 
