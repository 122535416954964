import React, { useEffect, useState } from 'react';
import { BookOpen, Download, DownloadCloud, Edit2, Eye, File, FilePlus, FileText, MoreHorizontal, Search, Send, Share2, Tag, Trash2, Users } from 'react-feather';
import CountCrad from './CountCrad';
import { getRequestApiByToken } from '../../../api';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';

const BascicCounter = () => {
    const [lo_counterDetails, setCounterDetails] = useState({});

    const getAndSetBascicCounter = async () => {
        let res = await getRequestApiByToken(`dashboard_counter`);
        setCounterDetails(res?.data || {});
    }
    useEffect(() => {
        getAndSetBascicCounter()
    }, [])

    return (
        <>
            <div className="col-xl-3 xl-50 col-sm-6">
                <Link to={`${process.env.PUBLIC_URL}/management/user`}>
                <CountCrad
                    icon={<Users />}
                    title={'USERS'}
                    count={`${lo_counterDetails?.active_user || 0} / ${lo_counterDetails?.total_user || 0}`}
                />
                </Link>
            </div>
            <div className="col-xl-3 xl-50 col-sm-6">
                <Link to={`${process.env.PUBLIC_URL}/projects/approver-project`}>
                    <Badge.Ribbon text={`${lo_counterDetails?.inactive_documents || 0}`} color="#f13737e6">
                        <CountCrad
                            icon={<File />}
                            title={'PROJECT DOCUMENTS'}
                            count={`${lo_counterDetails?.total_documents || 0}`}
                        />
                    </Badge.Ribbon>
                </Link>
            </div>
            <div className="col-xl-3 xl-50 col-sm-6">
                <Link to={`${process.env.PUBLIC_URL}/other-documents/search-document`}>
                    <CountCrad
                        icon={<FileText />}
                        title={'OTHER DOCUMENTS'}
                        count={`${lo_counterDetails?.other_total_documents || 0}`}
                    />
                </Link>
            </div>
            <div className="col-xl-3 xl-50 col-sm-6">
                <Link to={`${process.env.PUBLIC_URL}/projects/project-list`}>
                    <CountCrad
                        icon={<BookOpen />}
                        title={'PROJECTS'}
                        count={`${lo_counterDetails?.active_project || 0} / ${lo_counterDetails?.total_projects || 0}`}
                    />
                </Link>
            </div>
        </>
    )
}

export default BascicCounter