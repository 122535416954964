import React, { useEffect, useState } from 'react'
import Typography from 'antd/lib/typography/Typography'

const FileSizeView = ({ docFileUrl }) => {
    const [fileSize, setFileSize] = useState('--');

    const getFileSize = async (fileUrl) => {
        console.log('--fileUrl--', fileUrl);
        try {
            const response = await fetch(fileUrl);
            console.log(':::::response------:::::',response);
            if (response?.ok) {
                const blob = await response.blob();
                const sizeInBytes = blob.size;
                let size, unit;
                if (sizeInBytes >= 1024 * 1024) {
                    size = (sizeInBytes / (1024 * 1024)).toFixed(2);
                    unit = 'MB';
                } else {
                    size = (sizeInBytes / 1024).toFixed(2);
                    unit = 'KB';
                }
                const ls_size = (size || unit) ? `${size} ${unit}` : '';
                console.log(':::::contentLength:::::',ls_size);
                setFileSize(ls_size);
            } else {
                setFileSize('--');
            }
        } catch (error) {
            console.log('---error--',error);
            setFileSize(`--`);
        }
    };

    useEffect(() => {
        if (docFileUrl) getFileSize(docFileUrl);
    }, [docFileUrl])


    return (<Typography.Text code>File Size: <span style={{color:"#dd870eba" , fontWeight:600}}>{fileSize}</span></Typography.Text>)
}

export default FileSizeView