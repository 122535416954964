import React, { Fragment, useEffect, useState } from 'react'
import './style.css';
import Breadcrumb from '../../../components/common/breadcrumb'
import CountUp from 'react-countup'
import { Link2, Download , DownloadCloud, Eye, Send, HelpCircle, Bookmark, File} from 'react-feather'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Progress } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import ScrollBar from 'react-perfect-scrollbar';
import { message, Spin, Tag, Timeline, Typography } from 'antd';
import { Descriptions } from 'antd';
import { Button, Space } from 'antd';
import { LikeOutlined, DislikeOutlined, ShareAltOutlined, DownloadOutlined, LoadingOutlined, ClockCircleOutlined, CheckCircleOutlined, StarOutlined } from '@ant-design/icons';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { FileIconsObj, FilePath } from '../../../constant/globalConfig';
import moment from 'moment';
import { getFileExtension } from '../../../utility';
import ViewProjectDetails from '../../projects/viewProjectDetails';
import RelatedDocumentList from '../relatedDocumentList';
import CustomViewer from '../../../components/CustomViewer';
import CustomDocViewer from '../../../components/CustomDocViewer';
import DocCommunication from './DocCommunication';
import DocActivities from './DocActivities';
import DocActions from './DocActions';
import DocumentBasicCounter from './DocumentBasicCounter.js';
import DocumentRequest from './DocumentRequest/index.js';
import { useSelector } from 'react-redux';
import DocDescription from './DocDescription/index.js';
import DocDownload from './DocDownload/index.js';
import FileSizeView from '../FileSizeView/index.js';

const DocumentDetails = ({route}) => {
  const ls_userType = useSelector(content => content.commonReducer.user_type);
  const navigate = useNavigate();
  const location = useLocation();
  const ls_documentId = location?.state?.documentId || null;
  const ls_viewType = location?.state?.viewType || null;
  const [lb_spining, setSpining] = useState(false)
  const [lo_documentData, setDocumentData] = useState(false)

  const [lo_counterData, setCounterData] = useState({download_count: 0, view_count: 0, linked_user: 0});

  const [lb_showProjectDetails, setShowProjectDetails] = useState(false)
  const [ls_projectId, setProjectId] = useState('')

  const getAndSetCounterData = (counterType) => {
    const lo_counter = {...lo_counterData};
    lo_counter[counterType] = lo_counterData[counterType]+1;
    console.log('---lo_counter---',lo_counter);
    setCounterData(lo_counter);
  }

  const getDocumentData = async ()=>{
    setSpining(true)
    if (ls_documentId) {
      const lo_response = await getRequestApiByToken(`document/${ls_documentId}`);
      const lo_record = lo_response?.Documents ? lo_response.Documents : {};
      console.log('doc details >>>>>>>', lo_record);
      setDocumentData(lo_record);
      setCounterData({download_count: lo_record?.download_count || 0, view_count: lo_record?.view_count || 0, linked_user: lo_record?.linked_user || 0})
    }
    setSpining(false)
  }

  useEffect(() => {
    getDocumentData();
  }, [ls_documentId]);
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll:1,
    arrows: false,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
};
const getFileDetails = (row) => {
  const extension = getFileExtension(row?.document_file || '');
  const fileElement = (row?.document_file && extension) ? FileIconsObj[extension] : row?.document_file
  return { extension, fileElement }
}

const items = [
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-file-o docdtl-icn-color"></i> &nbsp;Document Name</Typography.Text>,
    children: lo_documentData?.title,
  },
  {
    key: '2',
    label: <Typography.Text  type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Project Name</Typography.Text>,
    children: <Link onClick={() => { setShowProjectDetails(true); setProjectId(lo_documentData?.project?.id);}} style={{cursor:"pointer"}}>{lo_documentData?.project?.project_name}</Link>,
  },
  {
    key: '3',
    label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Created Date</Typography.Text>,
    children: moment(lo_documentData?.created).format('DD MMM YYYY'),
  },
  // {
  //   key: '3',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Last Downloaded</Typography.Text>,
  //   // children: '10/09/2013',
  //   children: '--',
  // },
  // {
  //   key: '4',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;Document Size</Typography.Text>,
  //   children: '8',
  // },
  {
    key: '4',
    label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;Document Type</Typography.Text>,
    children: <> {lo_documentData?.document_parent_category?.category_name}
    {/* {getFileDetails(lo_documentData)?.extension} {getFileDetails(lo_documentData)?.fileElement} */}
    </>,
    // children: <>Pdf <i className="fa fa-file-pdf-o"></i></>,
  },
  // {
  //   key: '4',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-download docdtl-icn-color"></i> &nbsp;No of Downloads</Typography.Text>,
  //   children: <>{lo_documentData?.download_count || 0}</> ,
  // },
  // {
  //   key: '4',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-eye docdtl-icn-color"></i> &nbsp;No of Views</Typography.Text>,
  //   children: <>{lo_documentData?.view_count || 0}</>,
  // },
];

 const itemStyle = {
    border: '1px solid #e8e8e8',
    padding: '8px',
    margin: '4px',
  };

  useEffect(() => {
    if (ls_viewType == 'communication') {
      const communicationSection = document.getElementById('communicationSection');
      if (communicationSection) {
        setTimeout(() => {
          window.scrollTo({
            top: communicationSection.offsetTop,
            behavior: 'smooth',
          });
        }, 1000);
      }
    }
  }, [ls_viewType]);

  useEffect(() => {
    console.log('---lo_documentData',lo_documentData);
  }, [lo_documentData])
  

  return (
    <Fragment>
      <Breadcrumb parent="Document Details" title="" />
      <Spin spinning={lb_spining}>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-6 xl-100"
            >
              <div className="row">
                <div className="col-xl-12 xl-100">
                  <div className='card' style={{ padding: 30 }}>
                    <Descriptions bordered column={1} size="small" labelStyle={{ minWidth: 180 }}>
                      {items.map((item) => (
                        <Descriptions.Item key={item.key} label={item.label}>
                          {item.children}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-xl-6 xl-100" >
              <DocumentBasicCounter lo_counterData={lo_counterData} />
              <div className="row docdetail-counter">
                <div className="col-xl-12 xl-100">
                  <DocActions lo_documentData={lo_documentData} ls_documentId={ls_documentId} getData={getDocumentData} />
                </div>
              </div>
              
              {/* {ls_userType == 'user' ? 
                <DocumentRequest lo_documentData={lo_documentData} />
              : <></>} */}

            </div>

            <div className="col-xl-12 xl-100" >
              {lo_documentData?.description ? 
                <DocDescription lo_documentData={lo_documentData} />
              : <></>}
            </div>

            <div className="col-xl-12 xl-100" >
              <div className="card card-mb-faq xs-mt-search">
                <div className="card-header faq-header docviewr-headr">
                  <span style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>
                    <File />
                    &nbsp;&nbsp;
                    <h5> {'Document Viewer'}</h5>
                  </span>

                  <div style={{display: 'flex', justifyContent:"flex-end", alignItems:"center"}}>
                    <DocDownload lo_documentData={lo_documentData} ls_documentId={ls_documentId}  getAndSetCounterData={getAndSetCounterData} />
                    <div style={{marginLeft:10}}>
                    {(lo_documentData?.full_file_url?.includes('https') || lo_documentData?.full_file_url?.includes('http')) ?
                      <FileSizeView docFileUrl={lo_documentData?.full_file_url}/> : <></>}
                    </div>
                    <span style={{marginLeft:10}}><Bookmark /></span>
                  </div>

                  {/* <span><Bookmark /></span> */}
                </div>
                <div className="card-body faq-body">
                  {/* <div>
                          {(lo_documentData?.full_file_url?.includes('https') || lo_documentData?.full_file_url?.includes('http')) ? 
                            <iframe
                            title="Document Viewer"
                            src={lo_documentData?.full_file_url ? lo_documentData.full_file_url + "#view=FitH&toolbar=0&navpanes=0&scrollbar=0" : ''}
                            width="100%"
                            height="600px"
                            style={{ border: '1px solid #ccc' }}
                            // sandbox="allow-same-origin allow-scripts allow-popups"
                            ></iframe>
                            : 
                            <div style={{textAlign:"center"}}><Typography.Text type='danger'>File path temporary unavailable!</Typography.Text></div>}
                      </div> */}
                  <div>
                    {(lo_documentData?.full_file_url?.includes('https') || lo_documentData?.full_file_url?.includes('http')) ?
                      (
                        <>

                          {getFileExtension(lo_documentData?.document_file) == 'pdf' ?
                            <CustomViewer ls_url={lo_documentData?.full_file_url ? lo_documentData.full_file_url : ''} />
                            :
                            <CustomDocViewer ls_url={lo_documentData?.full_file_url ? lo_documentData.full_file_url : ''} />
                          }
                        </>
                      )
                      :
                      <div style={{ textAlign: "center" }}><Typography.Text type='danger'>File path temporary unavailable!</Typography.Text></div>}
                  </div>
                </div>
              </div>

            </div>

            {/* communication */}
            <div className="col-xl-6 xl-100" id="communicationSection">
              <DocCommunication ls_documentId={ls_documentId} />
            </div>

            {/* Activities */}
            <div className="col-xl-6 xl-100" >
              <DocActivities ls_documentId={ls_documentId} lo_documentData={lo_documentData} />
            </div>

            <Col md="24" lg="24" xl="24" className="box-col-24" >
              <RelatedDocumentList selectedProject={lo_documentData?.project_id || ''} />
            </Col>
          </div>
        </div>
      </Spin>

      <ViewProjectDetails {...{ lb_showProjectDetails, setShowProjectDetails, ls_projectId, setProjectId }} />
    </Fragment>
  )
}

export default DocumentDetails
