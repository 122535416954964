import React, { useEffect, useState } from 'react'
import OnProgressView from '../OnProgressView'
import DataTable from 'react-data-table-component';
// import { leader_columns, tblListdata } from './commonData';
import { Button } from 'antd';
import { getRequestApiByToken } from '../../../../api';
import moment from 'moment/moment';
import { DateFormatForView } from '../../../../constant/basicConstant';
import LimitRequestApproveForm from '../../../commonActions/LimitRequestApproveForm';

const LimitEnhancement = () => {
    const [lb_listLoading, setlistLoading] = useState(false);
    const [la_dataList, setDataList] = useState([]);
    const [ls_actionFor, setActionFor] = useState(null);
    const [ls_userId, setUserId] = useState(null);

    const handleClickActionForRequest = (rowValue) => {
        setUserId(rowValue?.user_id);
        setActionFor('action_for_limit');
    }

    const getUserDetailsComponent = (rowValue) => {
        return (
            <div>
                <img className="rounded-circle" style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }}
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5nvlmwygLKlmp7aC6rEIPSgNEcTLbi1TV5P1gVU-LSwImRRp9CzMZywB1PPC9JjeFWNU&usqp=CAU'
                    alt="" />
                <span>{rowValue?.user?.name} ({rowValue?.user?.email})</span>
            </div>
        );
    }
    const columns = [
        {
            selector: (row) => <Button onClick={() => handleClickActionForRequest(row)} icon={<i className="fa fa-long-arrow-up" style={{ width: 35, fontSize: 19, padding: 11, color: 'green' }}></i>} type='text'></Button>,
            name: "",
            // center: true,
            width: '60px',
        },
        {
            name: "User Name",
            selector: (row) => <div>{getUserDetailsComponent(row)}</div>,
            sortable: true,
            // center: true,
            width: '350px',
        },
        {
            selector: (row) => <div>{moment(row?.requested_at).format(DateFormatForView)}</div>,
            name: "Req / Track Dated",
            sortable: true,
            // center: true,
        },
    ];

    const getAllLimitData = async () => {
        setlistLoading(true);
        const res = await getRequestApiByToken(`getLimitRequest`);
        setlistLoading(false)
        console.log('response====>>>', res);
        setDataList(res?.data || []);
    }

    useEffect(() => {
        getAllLimitData()
    }, [])

    const lo_frormProps = {ls_actionFor, setActionFor, ls_userId, getAllLimitData};

    return (<>
        <div className='limit-encncmnt-parent'>
            <div className="card">
                <div className="card-header server-header">
                    <h5 className="d-inline-block">{"Limit Enhancement"}</h5>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={la_dataList}
                            striped={true}
                            center={true}
                            persistTableHead
                            pagination
                            progressPending={lb_listLoading}
                            paginationPerPage={4} // Set the number of rows per page to 4
                            paginationComponentOptions={{ noRowsPerPage: true }}
                        />
                    </div>

                    {/* <OnProgressView /> */}
                </div>
            </div>
        </div>

        <LimitRequestApproveForm {...lo_frormProps}/>
    </>)
}

export default LimitEnhancement