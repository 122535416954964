import './style.css'
import { Checkbox, Col, Form, Row,Badge } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';


const JobFilter = ({la_sectorData=[],la_practices=[],la_country=[],la_clientName=[], filterForm, onFilterValuesChange}) => {
    const [isSector, setSector] = useState(true);
    const [country, setCountry] = useState(true);
    const [isClient, setClient] = useState(true);
    const [isPractices, setPractices] = useState(true);
    
    return (
        <Fragment>
            <div className="col-xl-3">
                <div className="default-according style-1 faq-accordion job-accordion" id="accordionoc">
                    <Form 
                    className="theme-form" 
                    labelCol={{ span: 24 }}
                    form={filterForm}
                    onValuesChange={onFilterValuesChange}
                    >
                        <div className="col-xl-12">
                            <div className="card mb-2">
                                <div className="card-header">
                                    <h5 className="mb-0">
                                        <button style={{marginInlineStart:20}} className="btn btn-link ps-0" onClick={() => setCountry(!country)}
                                            data-toggle="collapse" data-target="#collapseicon2" aria-expanded={country} aria-controls="collapseicon2">
                                            <i className="fa fa-globe"  style={{marginTop:1}}></i>
                                            {'Country'}
                                        </button>
                                    </h5>
                                </div>
                                <Collapse isOpen={country} className='filtercardsection'>
                                    <div className="left-filter-block" >
                                    <Form.Item name='country_id' >
                                    <Checkbox.Group style={{display:'flex', flexDirection:"column"}}>
                                     {la_country?.map((country, index) => (
                                        <div className='main-view' key={country.id}>
                                            <div className='first-content'>
                                                <p style={{marginInlineStart:10,marginTop:13}}>{country.country_name}</p>
                                            </div>
                                            <Row>
                                            <Col span={24}>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                            <Badge count={country?.documents_count} overflowCount={100000000000000}
                                            // color='lime' 
                                            color={'#2fc3e5'}
                                            style={{marginInlineEnd:5}}/>
                                                <Checkbox
                                                    value={country.id}
                                                    style={{ lineHeight: '22px'}}
                                                />
                                                </div>
                                            </Col>
                                            </Row>
                                        </div>
                                     ))}
                                     </Checkbox.Group>
                                     </Form.Item>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                         <div className="col-xl-12">
                            <div className="card mb-2">
                                <div className="card-header">
                                <h5 className="mb-0">
                                        <button style={{marginInlineStart:20}} className="btn btn-link ps-0" onClick={() => setSector(!isSector)}
                                            data-toggle="collapse" data-target="#collapseicon2" aria-expanded={isSector} aria-controls="collapseicon2">
                                            <i className="fa fa-gear" style={{marginTop:1}}></i>
                                            {'Sector'}
                                        </button>
                                    </h5>
                                </div>
                                <Collapse isOpen={isSector} className='filtercardsection'>
                                    <div className="left-filter-block">
                                      <Form.Item
                                                    // labelAlign="right"
                                                    // labelCol={{ span: 1 }}
                                                    // label="Text"
                                                    name='sector_id'
                                            >
                                        <Checkbox.Group style={{display:'flex', flexDirection:"column"}}>
                                        {la_sectorData?.map((item, index) => (
                                            <div className='main-view' key={item.id}>
                                            <div className='first-content' >
                                                <p style={{marginInlineStart:10,marginTop:13}}>{item?.sector_name}</p>
                                            </div>
                                            <Row>
                                            <Col span={24}>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                            <Badge count={item?.documents_count} overflowCount={100000000000000}
                                            // color='lime' 
                                            color={'#2fc3e5'}
                                            style={{marginInlineEnd:5}}/>
                                                <Checkbox
                                                    value={item.id}
                                                    style={{ lineHeight: '22px'}}
                                                />
                                            </div>
                                            </Col>
                                            {/* //sector_name */}
                                            </Row>
                                               {/* <input className="checkbox_animated" id="chk-ani11" type="checkbox" /> */}
                                        </div>
                                        ))}
                                        </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card mb-2">
                                <div className="card-header">
                                <h5 className="mb-0">
                                        <button style={{marginInlineStart:20}} className="btn btn-link ps-0" onClick={() => setPractices(!isPractices)}
                                            data-toggle="collapse" data-target="#collapseicon2" aria-expanded={isPractices} aria-controls="collapseicon2">
                                           <i className="fa fa-tasks" style={{fontSize:17,marginTop:3}}></i>
                                            {'Practices'}
                                        </button>
                                    </h5>
                                </div>
                                <Collapse isOpen={isPractices} className='filtercardsection'>
                                    <div className="left-filter-block">
                                      <Form.Item
                                                    // labelAlign="right"
                                                    // labelCol={{ span: 1 }}
                                                    // label="Text"
                                                    name='parctices'
                                            >
                                        <Checkbox.Group style={{display:'flex', flexDirection:"column"}}>
                                        {la_practices?.map((item, index) => (
                                            <div className='main-view' key={item.id} >
                                            <div className='first-content' >
                                                
                                                <p style={{marginInlineStart:10,marginTop:13}}>{item?.practice_name}</p>
                                            </div>
                                            <Row>
                                            <Col span={24}>
                                            <div style={{display:'flex',flexDirection:'row'}}>
                                            <Badge count={item?.documents_count} overflowCount={100000000000000}
                                            // color='lime' 
                                            color={'#2fc3e5'}
                                            style={{marginInlineEnd:5}} />
                                                <Checkbox
                                                    value={item.id}
                                                    style={{ lineHeight: '22px'}}
                                                />
                                            </div>
                                              {/* documents_count */}
                                            </Col>
                                            </Row>
                                        </div>
                                        ))}
                                        </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card mb-2">
                                <div className="card-header">
                                <h5 className="mb-0">
                                        <button style={{marginInlineStart:20}} className="btn btn-link ps-0" onClick={() => setClient(!isClient)}
                                            data-toggle="collapse" data-target="#collapseicon2" aria-expanded={isClient} aria-controls="collapseicon2">
                                           <i className="fa fa-user"></i>
                                            {'Clients'}
                                        </button>
                                    </h5>
                                </div>
                                <Collapse isOpen={isClient} className='filtercardsection'>
                                <div className="left-filter-block">
                                            <Form.Item
                                                    // labelAlign="right"
                                                    // labelCol={{ span: 1 }}
                                                    // label="Text"
                                                    name='donor'
                                            >
                                            <Checkbox.Group style={{display:'flex', flexDirection:"column"}}>
                                            {la_clientName?.map((item, index) => (
                                            <div className='main-view' key={item.id}>
                                                <div className='first-content'>
                                                    <p style={{marginInlineStart:10,marginTop:13}}>{item?.client_name}</p>
                                                </div>
                                                
                                                <Row>
                                                <Col span={8}>
                                                <div style={{display:'flex',flexDirection:'row'}}>

                                                   <Badge count={item?.projects_count} overflowCount={100000000000000}
                                                //    color='lime' 
                                                color={'#2fc3e5'}
                                                   style={{marginInlineEnd:5}}/>
                                                    <Checkbox
                                                        value={item.id}
                                                        style={{ lineHeight: '22px'}}
                                                    />
                                                </div>
                                                {/* projects_count */}
                                                </Col>
                                                </Row>
                                                {/* <input className="checkbox_animated" id="chk-ani11" type="checkbox" /> */}
                                            </div>
                                            ))}
                                        </Checkbox.Group>
                                            </Form.Item>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </Form>
                    {/* </div> */}
                </div>
            </div>
        </Fragment>
    );
};

export default JobFilter;