import React, { useState, useEffect } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import "./style.less";
import Divider from "antd/lib/divider";
import message from "antd/lib/message";
import { postRequestApiByToken } from "../../../../api";
// import { postRequestByTokenParamApi } from "../../../../api/commonRequest";

const CreateUserForm = ({ls_actionFromPage="main", getDataList, ls_actionType, lo_actionRecord, handleSetDataAction}) => {
    const ls_organisationId = localStorage.getItem('organisation_id')
    const ls_token = localStorage.getItem('token')
    const [form] = Form.useForm();
    const dateFormat = "DD/MM/YYYY";
    const [lb_loading, setLoading] = useState(false)
    const [lb_visibleModal, setVisibleModal] = useState(false)
    const [ls_updateId, setUpdateId] = useState('')
    const [la_jobRoleList, setJobRoleList] = useState([]);
    const [lo_responseMessage, setResponseMessage] = useState({})
    
    const handleShowModal = ()=>{
        setUpdateId('')
        form.resetFields();
        setVisibleModal(true)
    }
    const handleShowUpdateModal = (record, index)=>{
        setVisibleModal(true)
        const lo_updateDate = {}
        // lo_updateDate['name'] = record.name;
        // lo_updateDate['mobile'] = record.mobile.primary_number;
        // lo_updateDate['email'] = record.email;
        // lo_updateDate['organization'] = {...record.organization};
        setUpdateId(record._id)
        form.resetFields()
        form.setFieldsValue(lo_updateDate)
    }
    const handleCancel = () => {
        form.resetFields()
        setUpdateId('')
        setVisibleModal(false)
        handleSetDataAction()
    };

    const handleSubmitFormDetails = async (lo_formDetails) => {
        setLoading(true)
        const lo_formData = { ...lo_formDetails };

        //'@ipeglobal.com', '@tripleline.com', '@ipeckd.org'

        const la_rrestrictedEmail = ['@ipeglobal.com', '@tripleline.com', '@ipeckd.org'];
        let lb_emailIssue = false;
        for (let k = 0; k < la_rrestrictedEmail.length; k++) {
            const rowval = la_rrestrictedEmail[k];
            if (lo_formDetails?.email?.includes(rowval)) {
                lb_emailIssue = true;
                break;
            }
        }

        if(lb_emailIssue) {
            message.error('Sorry! You can not add email from this domain!');
            setLoading(false)
            return
        }

        if (ls_updateId) {
            // const lo_updateData = {...lo_formData};
        } else {
            const lo_response = await postRequestApiByToken('users', lo_formData)
            setLoading(false)
            if (lo_response?.status == 'Success') {
                setVisibleModal(false);
                form.resetFields();
                getDataList();
                handleSetDataAction('get_user_access_list');
                setResponseMessage({});
                message.success("User Created Successfully!");
            }else{
                if (lo_response?.message) {
                    setResponseMessage(lo_response.message)
                    const lo_mssg = lo_response?.message;
                    if (lo_mssg['name'][0]) {
                        message.error(lo_mssg['name'][0])
                    }else if (lo_mssg['email'][0]) {
                        message.error(lo_mssg['email'][0])
                    }else if (lo_mssg['password'][0]) {
                        message.error(lo_mssg['password'][0])
                    }else if (lo_mssg['password_confirmation'][0]) {
                        message.error(lo_mssg['password_confirmation'][0])
                    }
                }else{
                    message.error('Something Wrong!!')
                }
            }
        }
    }

    useEffect(() => {
        if (ls_actionType == "add_new_user") {
            handleShowModal()
        } else if (ls_actionType == "edit_checkinuser") {
            handleShowUpdateModal(lo_actionRecord)
        } else if (ls_actionType == 'remove_checkinuser') {
            // handleRemoveSpecificCheckInUserById(lo_actionRecord)
        }
    }, [ls_actionType])

    return (
        <Modal
            title={ls_updateId ? 'Update Add User' : 'Add User'}
            open={lb_visibleModal}
            onOk={() => {
                form.validateFields().then((values) => { handleSubmitFormDetails(values); }).catch((info) => {});
            }}
            onCancel={handleCancel}
            confirmLoading={lb_loading}
            // className='modal'
            width={700}
            // style={{backgroundColor:'red'}}
        >
            <Form form={form}
                className='mt8-xs'
                labelCol={{
                    span: 8,
                }}
            >
                <Row>
                    <Col span={24}>
                            <Divider plain></Divider>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Name"
                            name='name'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Email"
                            name='email'
                            rules={[{ type: "email", required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Password"
                            placeholder="Password"
                            name='password'
                            rules={[{ required: true }]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col> 
                    <Col span={24}>
                        {lo_responseMessage['password'] ? 

                        (lo_responseMessage['password'] && lo_responseMessage['password'].map((rowMsg, inx) => <p key={inx} 
                        className="comn-input-err2"
                        style={{ top : (55+(15*inx))+'px'}}
                        >
                        {rowMsg}
                        </p>))
                        : <></>}
                    </Col>

                    {/* <Col span={24}>
                        <Form.Item
                            labelAlign="right"
                            label="Confirm Password"
                            name='password_confirmation'
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {lo_responseMessage['password_confirmation'] ? 

                        (lo_responseMessage['password_confirmation'] && lo_responseMessage['password_confirmation'].map((rowMsg, inx) => <p key={inx} 
                        className="comn-input-err2"
                        style={{ top : (55+(15*inx))+'px'}}
                        >
                        {rowMsg}
                        </p>))
                        : <></>} */}
                    {/* </Col> */}
                    
                </Row>


            </Form>

        </Modal>
    )
}

export default CreateUserForm