import React, { useEffect, useState } from 'react';
import { Col, message, Modal, Row, Select } from 'antd';
import Form from "antd/lib/form";
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { Divider, Spin } from 'antd/lib';
import { debounce } from 'lodash';
import { getRequestApiByToken, postRequestApiByToken, postRequestApiByTokenWithDataType } from '../../../api';
import Typography from 'antd/lib/typography/Typography';
import RequestLimitForm from './RequestLimitForm';

const LimitRequestApproveForm = ({ ls_actionFor, setActionFor, ls_userId, getAllLimitData }) => {
  const [formRequest] = Form.useForm();
  const [lb_showmodalView, setModalView] = useState(false);
  const [lo_userDetails, setUserDetails] = useState({});
  const [lb_loading, setLoading] = useState(false);
  const [lb_formSpining, setFormSpining] = useState(false);

  const onCancelModal = () => {
    setActionFor(null);
    setModalView(false);
  }
  const getuserLimitDetails = async () => {
    setFormSpining(true)
    let res = {};
    let lo_formData = {};

    if (ls_userId) {
      res = await getRequestApiByToken(`showLimitRequest/${ls_userId}`)
      console.log('getuserLimitDetails>>>>',res);
      setUserDetails(res);
      
      const lo_record = res?.requestData?.user || {};
      const lo_limitData = lo_record?.download_limit || {};
      lo_formData = { ...lo_limitData };
    }

    formRequest.resetFields();
    formRequest.setFieldsValue(lo_formData);
    setFormSpining(false);
  };

  const submitApproveLimitRequest = async (formValues) => {
    let lo_formData = { ...formValues };
    
    setLoading(true)
    const res = await postRequestApiByTokenWithDataType('json', 'approveLimitRequest/'+ls_userId, lo_formData);
    if (res?.status == 'Success') {
        getAllLimitData();
        message.success('Successfully Submitted!');
        onCancelModal();
        setLoading(false)
    } else {
        message.error(res?.message);
    }
    setLoading(false)
  };

  const handleOnOk = async (value) => {
    console.log('------value----',value);
    if (ls_actionFor == 'action_for_limit' && ls_userId) {
      await submitApproveLimitRequest(value);
    } else {
      message.error('Data not set!');
    }
  }

  useEffect(() => {
    if (ls_actionFor == 'action_for_limit') {
      setModalView(true);
      if (ls_userId) getuserLimitDetails();
    }
  }, [ls_actionFor, ls_userId])


  return (
    <Modal
      title={"Enhance Limit Request"}
      visible={lb_showmodalView}
      onCancel={onCancelModal}
      // footer={null}
      onOk={() => {
        formRequest.validateFields().then((values) => {
          // form.resetFields();
          handleOnOk(values);
        })
          .catch((info) => {
            // console.log('Validate Failed:', info);
          });
      }
      }
      confirmLoading={lb_loading}
      width={800}
    >
      <Divider />
      <div>
        <Spin spinning={lb_formSpining}>
          <Form form={formRequest}
            className='mt8-xs'
            labelCol={{
              span: 8,
            }}
            wrapperCol={{ span: 16 }}
          >
            <Row>
              <Col span={24}>
                <div className="card-body" style={{ paddingTop: 6 }}>
                  <Row className="align-items-center">
                    <Col className='col-sm-4 col-xl-4 col-lg-4 mb-3'>
                    </Col>
                    <Col className='col-sm-4 col-xl-4 col-lg-4 mb-3' style={{ textAlign: "center" }}>
                      <Typography.Text style={{ color: "#707273" }} strong>{'View'}</Typography.Text>
                    </Col>
                    <Col className='col-sm-4 col-xl-4 col-lg-4 mb-3' style={{ textAlign: "center" }}>
                      <Typography.Text style={{ color: "#707273" }} strong>{'Download'}</Typography.Text>
                    </Col>
                  </Row>
                  <RequestLimitForm limitType={'Daily'} view_number={'daily_view'} download_number={'daily_download'} />
                  <RequestLimitForm limitType={'Weekly'} view_number={'weekly_view'} download_number={'weekly_download'} />
                  <RequestLimitForm limitType={'Monthly'} view_number={'monthly_view'} download_number={'monthly_download'} />
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

export default LimitRequestApproveForm;
