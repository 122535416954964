import React from 'react';
import { Modal } from 'antd';

const AlertModal = ({ visible=false, title='', content='', onCancel=()=>{},onOk=()=>{} ,loading=false}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
        // footer={null}
        onOk={onOk}
        confirmLoading={loading}
    >
      <p>{content}</p>
    </Modal>
  );
};

export default AlertModal;
