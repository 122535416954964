import { Form, Input,Button, Spin } from 'antd'
import React from 'react'
import { Card, CardBody, Col, Row} from 'reactstrap'
import { PlusOutlined } from '@ant-design/icons';

const AddForm = ({handleSubmitForm=()=>{},lb_buttonLoader=false,ls_updateId='',form, resetFormData, lb_spining=false}) => {

  return (
    <>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Spin spinning={lb_spining}>
                  <Form 
                    form={form}
                    className="theme-form" 
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmitForm}
                  >
                    <Row>
                      <Col sm="12">
                          <Form.Item
                              label="Department Name"
                              name="name"
                              rules={[{ required: true,  message: "Please input Department Name!"}]}
                          >
                              <Input placeholder="Department name *" enterButton />
                          </Form.Item>
                      </Col>
                      
                    </Row>
                    <Row>
                      <Col>
                        {ls_updateId ? <button onClick={() => resetFormData()} class="btn btn-outline-info btn-sm" type="button"><PlusOutlined /> Add New</button> : <></>}
                          <Button 
                            style={{float:"right"}} 
                            color="success" 
                            // className="me-3"
                            loading={lb_buttonLoader}
                            type='primary'
                            htmlType='submit'
                          >
                            {ls_updateId =='' ? 'Add' : 'Update'}
                          </Button>
                      </Col>
                    </Row> 
                  </Form>
                </Spin>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </>
  )
}

export default AddForm