import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom/dist';

const RedirectPage = () => {
    const ls_userId = useSelector(content => content.commonReducer.user_id);
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const navigate = useNavigate();
    useEffect(() => {
        if (ls_userId && ls_userType) {
            if (ls_userType == 'user') {
                navigate(`${process.env.PUBLIC_URL}/document/search-document`);
            } else if (ls_userType == 'admin') {
                navigate(`${process.env.PUBLIC_URL}/dashboard`);
            }
        }
    }, [ls_userId, ls_userType])
    
    return (
        <div></div>
    )
}

export default RedirectPage