
import React, { useEffect, useState } from 'react';
import { Container } from "reactstrap";
import DataTable from 'react-data-table-component'
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Tooltip, message ,Input} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import { getRequestApiByToken } from '../../api';
import { FileIconsObj } from '../../constant/globalConfig';
import { getCascaderIdsFormArray, getCountryCode, getFileExtension } from '../../utility';
import DocumentListing from '../document/DocumentListing';

const { Search } = Input;

const BookmaredDocument = () => {
  const ls_userId = useSelector(content => content.commonReducer.user_id);
  const ls_userType = useSelector(content => content.commonReducer.user_type);
  const [la_bookmaredDocumentList, setBookmaredDocumentList] = useState([])
  const [lb_loading, setLoading] = useState(false);
  const [lb_buttonLoader, setButtonLoader] = useState(false)
  const [ls_updateId, setUpdateId] = useState('')
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lb_spining, setSpining] = useState(false);
  const [la_columnsList, setColumnsLis] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [form] =Form.useForm()

  const getBookMaredDocumentList = async () => {
    setLoading(true)
      const res = await getRequestApiByToken(`bookmarkdocumentlist?search=${filterText}&orderBy=desc&page=${currentPage}`);
      console.log('bookmark',res);
      setBookmaredDocumentList(res?.data?.data)
      setTotalCount(res?.data?.total);
    setLoading(false)
  }
  
 useEffect(() => {
    getBookMaredDocumentList()
 }, [currentPage,filterText])

 const returnCountryIcon = (cntryNm) => {
  const cntryCode = getCountryCode(cntryNm);
  if (cntryCode) {
    return <Tooltip title={cntryNm}>
            <i className={`flag-icon flag-icon-${cntryCode.toLowerCase()}`} style={{ width: 45, fontSize: 16, padding: 5}}></i>
          </Tooltip>;
  } else {
    return cntryNm;
  }
 }
 
 const columns = [
  {
    name: "Country",
    selector: (row) => 

    <div>
      <span>
        {row?.documents[0]?.project?.country[0]?.country_name ? returnCountryIcon(row?.documents[0]?.project?.country[0]?.country_name) : ''}
      </span>
    </div>,
    // row?.project?.country[0]?.country_name,
    sortable: true,
    center: true,
    width: '100px', 
  },
  {
    selector: (row) => 
    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    {row?.documents[0]?.title}
    </div>
    ,
    name: "Document Name",
    sortable: true,
    width: '310px', 
    // center: true,
  },
  {
    selector: (row) => row?.documents[0]?.project?.sector?.map((i,inx)=>i?.sector_name),
    name: "Sector",
    sortable: true,
    center: true,
    width: '265px', 
  },
  {
    selector: (row) => row?.documents[0]?.project?.mandate_no,
    name: "Mandate Number",
    sortable: true,
    center: true,
    width:'220px'
  },
  {
    selector: (row) => moment(row?.documents[0]?.created).format("DD MMM YYYY"),
    name: "Date",
    sortable: true,
    center: true,
  },
  {
    selector: (row) => {
      const extnsn = getFileExtension((row?.documents?.length && row?.documents[0]?.document_file) || '');
      return <div>
    {((row?.documents?.length && row?.documents[0]?.document_file) && extnsn) ? FileIconsObj[extnsn] : row?.documents[0]}
      </div>} ,
    name: "Type",
    center: true,
  },
  {
    selector: (row) => <div>
      <span style={{cursor:"pointer"}}>
      <Link to={'/document/document-details'} state={{ documentId: row.document_id }}>
          <i className="fa fa-eye text-secondary" style={{ fontSize: 18 }}/>
      </Link>,
    </span>
  </div>,
    name: "Action",
  //   sortable: true,
    center: true,
    // width: '100px',
  },
];

useEffect(() => {
  const columnList = [...columns];
  // if (ls_userType == 'user') {
  //    columnList.pop();
  // }
  setColumnsLis(columnList);
}, [ls_userType])

// const resetFormData = async ()=>{
//   form.resetFields()
//   setUpdateId('')
// }

// const [lo_updatRecord, setUpdateRecord] = useState({})

// const setFormData = async ()=>{
//   setSpining(true)
//   const lo_updateDate = {}
//   if (ls_updateId) {
//       const lo_response = await getRequestApiByToken(`document/${ls_updateId}`);
//       const lo_record = lo_response?.Documents ? lo_response?.Documents : {};
//       lo_updateDate['title'] = lo_record.title;
//       lo_updateDate['project_id'] = lo_record?.project?.id;
//       lo_updateDate['parent_cat_id'] = lo_record?.parent_cat_id;
//       if(lo_record?.child_cat_id && lo_record.child_cat_id != 'undefined') lo_updateDate['child_cat_id'] = parseInt(lo_record.child_cat_id);
//       lo_updateDate['sector_id'] = getCascaderIdsFormArray(lo_record?.sectors);
//       lo_updateDate['parctices'] = getCascaderIdsFormArray(lo_record?.practices);
//       lo_updateDate['group_id'] = getCascaderIdsFormArray(lo_record?.groups);
//       lo_updateDate['Country'] = getCascaderIdsFormArray(lo_record?.country);
//       lo_updateDate['key_words'] = lo_record?.key_words?.split(",") || [];
//       // getCascaderIdsFormArray(lo_record?.groups);
//       // lo_updateDate['document_file'] = lo_record.document_file;
//       if(lo_record?.document_file) lo_updateDate['document_file'] = [{ uid: '1', name: lo_record.document_file }];
//       if(lo_record?.document_created_date) lo_updateDate['document_created_date'] = dayjs(lo_record.document_created_date);
//       lo_updateDate['author'] = lo_record?.author;
//       lo_updateDate['version'] = lo_record?.version;
//       lo_updateDate['description'] = lo_record?.description;
//       setUpdateRecord(lo_record);
//   }else{
//     setUpdateRecord({})
//   }
//   form.resetFields()
//   form.setFieldsValue(lo_updateDate)
//   setSpining(false)
// }
// useEffect(() => {
//   setFormData();
// }, [ls_updateId]);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handleSearch = debounce((value) => {
    setFilterText(value);
 }, 300);

 const lo_props = { lb_showDocTypeTag:false, ls_headerText:'Favourite Documents', la_documentTypes:[], lb_loading, handlePaginationChange, totalCount, la_documentList:la_bookmaredDocumentList, getSearchData: getBookMaredDocumentList, setLoading, lb_showSearch:true, handleSearch, la_actionAceess:['view','remove-fav'] };

    return (
        <>
        <Breadcrumb parent="Favourite Document"  />
        <Container fluid={true}>

          <div className="col-xl-12 xl-100">
            <DocumentListing {...lo_props}/>
          {/* <div className="card">
            <div className="card-header card-header-border">
                <div className="row" style={{alignItems:'center'}}>
                  <div className="col-sm-8">
                      <h5>{"Favourite Documents"}</h5>
                  </div>
                  <div className="col-sm-4">
                      <div>
                      <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
                      </div>
                  </div>
                </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                  <DataTable
                    columns={la_columnsList}
                    data={la_bookmaredDocumentList}
                    striped={true}
                    center={true}
                    persistTableHead
                    pagination
                    paginationServer
                    paginationTotalRows={totalCount} // Assuming 10 rows per page
                    paginationPerPage={10} // Number of rows per page
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                    paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                    onChangePage={setCurrentPage} 
                    progressPending={lb_loading}
                    progressComponent={<div><LoadingOutlined/></div>}
                    customStyles={{
                    headCells: {
                        style: {
                          paddingLeft: '8px', // Adjust cell padding as needed
                          paddingRight: '8px',
                        },
                    },
                    rows: { style: { minHeight: '40px'}},
                    cells: {
                        style: {
                          paddingLeft: '8px', // Adjust cell padding as needed
                          paddingRight: '8px', // Adjust cell padding as needed
                        },
                    },
                    }}
                  />
              </div>
            </div>
          </div> */}
          </div>
      </Container>
        </>
    )
  }
export default BookmaredDocument 
