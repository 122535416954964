import FormData from "form-data";
import { countryCodes } from "../constant/countries";
import moment from "moment";
import { getRequestApiByToken } from "../api";
import store from "../store";

export const objectToFormData = (obj, la_files=null) => {
    let formData = new FormData();
    
    for (const key in obj) {
        formData.append(key, obj[key]);
    }
    if (la_files) {
        for (let i = 0; i < la_files?.length; i++) {
            const rowValue = la_files[i];
            formData.append(rowValue.fieldKey, rowValue.value);
        }
    }
    return formData;
}

export const romeveUndefinedValueFromObject = (formValues) => {
    let lo_formValues = {}
    for (const key in formValues) {
        if(formValues[key]) lo_formValues[key] = formValues[key];
    }
    return lo_formValues;
}

export const getCommaSeperateIdsFormArray = (data) => {
    let ls_ids = '';
    if(data?.length) ls_ids = data?.map(item => item).join(',');
    return ls_ids;
}
export const getCommaSeperateIdsFormCascaderArray = (data) => {
    let ls_ids = '';
    if(data?.length) ls_ids = data?.map(item => item[0]).join(',');
    return ls_ids;
}

export const getIdsFormArray = (data) => {
    let la_ids = [];
    if(data?.length) la_ids = data?.map(item => item.id);
    return la_ids;
}

export const getCascaderIdsFormArray = (data) => {
    let la_ids = [];
    if(data?.length) la_ids = data?.map(item => [item?.id]);
    return la_ids;
}

export const getCountryCode = (countryName) => {
    const normalizedCountryName = countryName.toLowerCase().replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });

    // Check if the country name exists in the lookup object
    if (countryCodes.hasOwnProperty(normalizedCountryName)) {
        return countryCodes[normalizedCountryName];
    } else {
        return null;
    }
}

export const getQuerySelectorValueFormArray = (formValues) => {
    let la_values = [];
    let ls_values = '';
    for (const key in formValues) {
        // console.log('-----type----',typeof formValues[key]);
        if(formValues[key]) {
            // console.log('-----ls_values----',formValues[key]);
            if (typeof formValues[key] == 'object' && formValues[key]?.length) {
                la_values.push(`${key}=${formValues[key]}`);
            } else if (typeof formValues[key] != 'object') {
                la_values.push(`${key}=${formValues[key]}`);
            }
        }
    }
    ls_values = la_values.join("&");
    return ls_values;
}

export const getFileExtension = (fileName) => {
    if(!fileName) return '';
    const extension = fileName?.split('.')?.pop();
    if (extension) {
        return extension;
    } else {
        return ''
    }
}

// export const awspath= 'https://ipeshare.s3.ap-south-1.amazonaws.com/staging/uploads'

export const getFileIconsObj = (extnsn) => {
    const fileIcon = {
        "jpg": <i className="fa fa-file-image-o"  style={{  fontSize: 16, color: "#e4566e" }}></i>,
        "png": <i className="fa fa-file-image-o"  style={{  fontSize: 16, color: "#e4566e" }}></i>,
        "jpeg": <i className="fa fa-file-image-o"  style={{  fontSize: 16, color: "#e4566e" }}></i>,
        "pdf": <i className="fa fa-file-pdf-o"  style={{  fontSize: 16, color: "#e4566e" }}></i>,
        'ppt': <i className="fa fa-file-powerpoint-o"  style={{  fontSize: 16, color: "#f9bc33" }}></i>,
        'pptx': <i className="fa fa-file-powerpoint-o"  style={{  fontSize: 16, color: "#f9bc33" }}></i>,
        'word': <i className="fa fa-file-word-o"  style={{  fontSize: 16, color: "#6487f2" }}></i>,
        'docx': <i className="fa fa-file-word-o"  style={{  fontSize: 16, color: "#6487f2" }}></i>,
        'doc': <i className="fa fa-file-word-o"  style={{  fontSize: 16, color: "#6487f2" }}></i>,
        'xlsx': <i className="fa fa-file-excel-o"  style={{  fontSize: 16, color: "green" }}></i>,
        'xls': <i className="fa fa-file-excel-o"  style={{  fontSize: 16, color: "green" }}></i>,
        'csv': <i className="fa fa-file-excel-o"  style={{  fontSize: 16, color: "green" }}></i>,
    }

    return fileIcon[extnsn];
}

export const getIndexKeyByDate = (ld_date) => {
    const ls_objIndex = moment.utc(ld_date).format("D-MM-YYYY");
    return ls_objIndex;
}

export const handleDownloadFile = async (ls_fileUrl, ls_fileTitle) => {
    if (!ls_fileUrl || !ls_fileTitle) {
        return;
    }
    fetch(ls_fileUrl)
        .then(response => response.blob())
        .then(blob => {
            // Create a local URL for the blob
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${ls_fileTitle}.${ls_fileUrl?.split('.').pop()}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl); // Clean up the blob URL
        })
        .catch(error => console.error('Error downloading the file:', error));
};

export const formatName = (ls_name = '') => {
    if (ls_name == '')
        return ls_name;

    else {
        let la_name = ls_name.split(" ");
        if (la_name[1] == null)
            return la_name[0][0].toUpperCase();

        return la_name[0][0].toUpperCase() + ' ' + la_name[1][0].toUpperCase();
    }
}

export const stringAvatar = (name) => {
    return {
      children:
        name.split(" ")[1] == null
          ? `${name.split(" ")[0][0]}`
          : `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
};

export const setApproverDocumentCount = async () => {
    const res = await getRequestApiByToken(`approvallist?orderBy=desc`);
    console.log('-setApproverDocumentCount-', res);
    store.dispatch({ type: 'SET_APPROVE_DOCUMENTS_COUNT', payload: (res?.Documents?.total || '') });
    return res;
}

// Function to generate a random color
export const getAutoRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const getSpecificDateValueByDate = (ls_date, ls_valueType) => {
    const date = new Date(ls_date);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (ls_valueType === 'yearly') {
        // returns the format "MMM YYYY"
        return `${ls_date}`;
        // return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    } else if (ls_valueType === 'daily') {
        // returns the format "DD MMM YYYY"
        // return `${('0' + date.getDate()).slice(-2)} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        return `${('0' + date.getDate()).slice(-2)} ${monthNames[date.getMonth()]}`;
    } else if (ls_valueType === 'monthly') {
        // returns the format "DD MMM"
        return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        // return `${('0' + date.getDate()).slice(-2)} ${monthNames[date.getMonth()]}`;
    }
}

export const actionForUserLogout = async (instance) => {
    const lo_storeData = store.getState();
    const ls_loginType = lo_storeData.commonReducer.login_type;
    store.dispatch({ type: 'SET_USER_TOKEN', payload: null });
    store.dispatch({ type: 'SET_USER_ID', payload: null });
    store.dispatch({ type: 'SET_USER_TYPE', payload: null });
    store.dispatch({ type: 'SET_USER_DETAILS', payload: {} });
    store.dispatch({ type: 'SET_LOGIN_TYPE', payload: '' });
    store.dispatch({ type: 'SET_APPROVE_DOCUMENTS_COUNT', payload: 0 });
    store.dispatch({ type: 'SET_HAS_SECTOR', payload: false });

    if(instance && ls_loginType=='msal') instance?.logout();
}

export const redirectToLogoutPage = () => {
    const lo_storeData = store.getState();
    const ls_token = lo_storeData.commonReducer.token;
    if (ls_token) {
        window.location.href = `${process.env.PUBLIC_URL}/logging-out-user`;
    }
}

export const checkIsnumericValue = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
}

export const capitalizeFirstLetter = (string) => {
    let ls_removeSpecialChar = string.replace('_', ' ');
    return ls_removeSpecialChar.split(" ").map(function (w) { return w[0].toUpperCase() + w.substr(1) }).join(" ").replace('_', ' ');
}

export const setNotificationsListData = async () => {
    const res = await getRequestApiByToken(`getNotification?is_seen=0&page=${1}&orderBy=desc`);
    console.log('getAndSetNotifications response====>>>', res);
    const la_newNotifictnarr = res?.data?.data || [];
    const la_newNotificationData = la_newNotifictnarr?.slice(0, 6);
    store.dispatch({ type: 'SET_Notifications', payload: (la_newNotificationData || []) });
    return res;
}

export const setNotificationCountData = async () => {
    const res = await getRequestApiByToken(`getNotificationCount`)
    console.log('getAndSetNotificationCount response====>>>', res);
    const lo_newNotificationCount = res?.data || {"unSeenCount": 0, "seenCount": 0};
    store.dispatch({ type: 'SET_NotificationCount', payload: lo_newNotificationCount });
    return res;
}