import React, { useCallback, useEffect, useState } from 'react';
import "./style.css";
import { Button, Tooltip, message } from 'antd/lib';
import { Search } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

const DocSearchInput = () => {
    const [searchKeyword, setSearchKeyword] = useState('');
    const navigate = useNavigate();
    const [searchValue, setsearchValue] = useState('');
    const [searchOpen, setsearchOpen] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search_query');


    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setsearchOpen(false)
            setsearchValue('')
        }
    }, []);

    useEffect(() => {
        setsearchValue(searchQuery)
        setSearchKeyword(searchQuery)
    }, [searchQuery])
    

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    });

    const handleSearchKeyword = (value) => {
        setSearchKeyword(value);
    };

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            document.querySelector(".empty-menu").classList.add('is-open');
        } else {
            document.querySelector(".empty-menu").classList.remove('is-open');
        }
    }

    const addFix = () => {
        document.querySelector(".Typeahead-menu").classList.add('is-open');
        document.body.classList.add("offcanvas");
    }

    const removeFix = () => {
        setsearchValue('')
        document.querySelector(".Typeahead-menu").classList.remove('is-open');
        document.body.classList.remove("offcanvas");
    }

    const toggleBtn = () => {
        if (searchOpen) {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.add('open');
        } else {
            setsearchOpen(!searchOpen);
            document.querySelector('.searchIcon').classList.remove('open');
        }
    }

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchKeyword) {
            // navigate(`/document/search-document?search_query=${encodeURIComponent(searchKeyword)}`);
            window.location.href = `/document/search-document?search_query=${encodeURIComponent(searchKeyword)}`;
        } else {
            message.warning('Please enter search keyword!');
        }
    };

    return (
        <div>
            <form className="form-inline search-form" onSubmit={handleSearchSubmit}>
                    <div className="form-group">
                        <input
                            className="form-control-plaintext searchIcon"
                            type="text"
                            placeholder="Project name / Mandate no / Document name"
                            defaultValue={searchValue}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                        />
                        <span className="d-sm-none mobile-search" onClick={toggleBtn}>
                            <Search />
                        </span>

                        
                        <div className="Typeahead-menu empty-menu">
                            <div className="tt-dataset tt-dataset-0">
                                <div className="EmptyMessage">
                                    {"Opps!! There are no result found."}
                                </div>
                            </div>
                        </div>

                        <div className='hdr-search-btn'>
                            <Button size='large' onClick={handleSearchSubmit} htmlType='button' shape="circle" icon={<SearchOutlined />} />
                        </div>
                    </div>
                </form>
            {/* <form className="form-inline search-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        className="form-control-plaintext searchIcon"
                        type="text"
                        placeholder="Search..."
                        value={searchKeyword}
                        onChange={(e) => handleSearchKeyword(e.target.value)}
                    />
                    <span className="d-sm-none mobile-search" onClick={handleToggleBtn}>
                        <Search />
                    </span>
                </div>
            </form> */}
        </div>
    );
};

export default DocSearchInput;