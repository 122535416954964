import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component'
import { Button, Input, message, Row, Switch } from 'antd';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { getRequestApiByToken } from '../../../../api';
import { getQuerySelectorValueFormArray } from '../../../../utility';
import dayjs from 'dayjs';
const { Search } = Input;

const startLoadTheMessage = (msg) => message.loading({ content: msg || 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

const DocumentActivity = ({ lo_formData, lb_loading, setLoading, lb_isSearch }) => {
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const [la_reportList, setReportList] = useState([])
    const [la_columnsList, setColumnsLis] = useState([])
    const [filterText, setFilterText] = useState('');

    const getUserActivity = (rowValue) => {
        let componentDetails = <div className='break-tablerow'><span className='rowtable-left-txt'>{rowValue?.type || ''} by:</span> {rowValue?.data?.user?.name} ({rowValue?.data?.user?.email})</div>;
        if (rowValue?.type == 'download') {
            componentDetails = <div className='break-tablerow'><span className='rowtable-left-txt'>Downloaded by:</span> {rowValue?.data?.user?.name} ({rowValue?.data?.user?.email})</div>
        } else if (rowValue?.type == 'view') {
            componentDetails = <div className='break-tablerow'><span className='rowtable-left-txt'>Viewed by:</span> {rowValue?.data?.user?.name} ({rowValue?.data?.user?.email})</div>
        } else if (rowValue?.type == 'share') {
            componentDetails = <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: 8 }} className='break-tablerow'><span className='rowtable-left-txt'>Shared by:</span> {rowValue?.data?.share_by[0]?.name} ({rowValue?.data?.share_by[0]?.email})</div>
                <div className='break-tablerow'><span className='rowtable-left-txt'>Shared with:</span> {rowValue?.data?.share_with[0]?.name} ({rowValue?.data?.share_with[0]?.email})</div>
            </div>
        }
        return componentDetails;
    }

    const getUserActivityText = (rowValue) => {
        let userActivityText = `${rowValue?.data?.user?.name} (${rowValue?.data?.user?.email})`;
        if (rowValue?.type == 'download') {
            userActivityText = `${rowValue?.data?.user?.name} (${rowValue?.data?.user?.email})`;
        } else if (rowValue?.type == 'view') {
            userActivityText = `${rowValue?.data?.user?.name} (${rowValue?.data?.user?.email})`;
        } else if (rowValue?.type == 'share') {
            userActivityText = `${rowValue?.data?.share_by[0]?.name} (${rowValue?.data?.share_by[0]?.email})
                ${rowValue?.data?.share_with[0]?.name} (${rowValue?.data?.share_with[0]?.email})`;
        }
        return userActivityText;
    }

    const getActivityStatus = (rowValue) => {
        let componentDetails = <div>{rowValue?.type || ''}</div>;
        if (rowValue?.type == 'download') {
            componentDetails = <div className='break-tablerow'>Downloaded</div>
        } else if (rowValue?.type == 'view') {
            componentDetails = <div className='break-tablerow'>Viewed</div>
        } else if (rowValue?.type == 'share') {
            componentDetails = <div className='break-tablerow'>Shared</div>
        }
        return componentDetails;
    }

    const getActivityStatusText = (rowValue) => {
        let ls_text = rowValue?.type || '';
        if (rowValue?.type == 'download') {
            ls_text = 'Downloaded';
        } else if (rowValue?.type == 'view') {
            ls_text = 'Viewed';
        } else if (rowValue?.type == 'share') {
            ls_text = 'Shared';
        }
        return ls_text;
    }

    const getDocumentNameText = (rowValue) => {
        let ls_text = (rowValue?.data?.documents && rowValue?.data?.documents?.length && rowValue?.data?.documents[0]?.title) ? rowValue.data.documents[0].title : '';
        return ls_text;
    }
    const getProjectNameText = (rowValue) => {
        let ls_text = (rowValue?.data?.documents && rowValue?.data?.documents?.length && rowValue?.data?.documents[0]?.project?.project_name) ? rowValue?.data?.documents[0]?.project?.project_name : '';
        return ls_text;
    }

    const columns = [
        {
            selector: (row) => <>{getUserActivity(row)}</>,
            name: "User Name",
            sortable: true,
            center: true,
            width: '250px',
        },
        {
            name: "Document Name",
            selector: (row) => <div className='break-tablerow'>{getDocumentNameText(row)}</div>,
            sortable: true,
            center: true,
            width: '320px',
            // minWidth: '500px', 
        },
        {
            selector: (row) => <div className='break-tablerow'>{getProjectNameText(row)}</div>,
            name: "Project Name",
            sortable: true,
            center: true,
            width: '290px'
        },
        {
            selector: (row) => <>{getActivityStatus(row)}</>,
            name: "Type",
            sortable: true,
            center: true,
            width: '110px'
        },
        {
            selector: (row) => <div className='break-tablerow'>{moment(row?.created_at).format("DD MMM YYYY, hh:mm A")}</div>,
            name: "Date & Time",
            sortable: true,
            center: true,
            width: '200px'
        },
    ];

    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const filteredData = la_reportList.filter(item => {
        const newItems = {
            'user_name': `${item?.data?.user?.name} (${item?.data?.user?.email}) ${item?.data?.share_by ? item?.data?.share_by[0]?.name : ''} (${item?.data?.share_by ? item?.data?.share_by[0]?.email : ''}) ${item?.data?.share_with ? item?.data?.share_with[0]?.name : ''} (${item?.data?.share_with ? item?.data?.share_with[0]?.email : ''})`,
            'doc_name': `${item?.data?.documents ? item?.data?.documents[0]?.title : ''}`,
            'project_name': `${item?.data?.documents ? item?.data?.documents[0]?.project?.project_name : ''}`,
            'type': getActivityStatusText(item),
            'date_time': `${moment(item?.created_at).format("DD MMM YYYY, hh:mm A")}`
        }
        return Object.values(newItems).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const getReportList = async () => {
        setLoading(true);
        const lo_paramsValue = { ...lo_formData };
        if (lo_paramsValue?.fromdate) lo_paramsValue['fromdate'] = dayjs(new Date(lo_formData.fromdate)).format('YYYY-MM-DD');
        if (lo_paramsValue?.todate) lo_paramsValue['todate'] = dayjs(new Date(lo_formData.todate)).format('YYYY-MM-DD');

        const ls_params = getQuerySelectorValueFormArray(lo_paramsValue);
        console.log(')))))ls_params((((', ls_params);

        if (lb_isSearch || ls_params) {
            const res = await getRequestApiByToken(`document_report?${ls_params}`);
            console.log('---res--', res);
            setReportList(res || []);
        }

        setLoading(false);
    }

    const exportToExcel = () => {
        startLoadTheMessage('Exporting...');
        // for (let k = 0; k < la_reportList.length; k++) {
        //     const Exporting = la_reportList[k];
        //     console.log('--Exporting...',Exporting.data.documents);
        //     console.log('--title...',Exporting.data.documents[0].title);
        //     console.log('--project_name...',Exporting.data.documents[0].project.project_name);
        // }
        // return;
        const ws = XLSX.utils.json_to_sheet(la_reportList.map(item => ({
            'User Name': getUserActivityText(item),
            'Document Name': getDocumentNameText(item),
            'Project Name': getProjectNameText(item),
            'Type': getActivityStatusText(item),
            'Date & Time': moment(item?.created_at).format("DD MMM YYYY, hh:mm A")
        })));
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Document Activity");
        XLSX.writeFile(wb, "document_activity.xlsx");
        stopLoadingSuccessMsh('Exported!');
    };

    useEffect(() => {
        getReportList()
    }, [lo_formData, lb_isSearch])

    useEffect(() => {
        const columnList = [...columns];
        setColumnsLis(columnList);
    }, [ls_userType])


    return (
        <>
            <div>
                <div className="col-xl-12 xl-100">
                    <div className="card parent-tblsec">
                        <div className="card-header card-header-border">
                            <div className="row" style={{ alignItems: 'center' }}>
                                <div className="col-sm-7">

                                    <h5>{"Activity Details"}</h5>

                                </div>
                                <div className="col-sm-5">
                                    <div className="pull-right right-header">
                                        <div className="onhover-dropdown">
                                            <div style={{ display: "flex", alignItems: "stretch", justifyContent: "space-between" }}>
                                                <div style={{ paddingRight: 8 }}>
                                                    <Button disabled={!la_reportList?.length} onClick={exportToExcel} type="dashed" icon={<ExportOutlined />}>Export</Button>
                                                </div>
                                                <div>
                                                    <Search allowClear value={filterText} onChange={handleSearch} placeholder="Serach here..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={la_columnsList}
                                    // data={la_reportList}
                                    data={filteredData}
                                    progressPending={lb_loading}
                                    striped={true}
                                    center={true}
                                    persistTableHead
                                    pagination

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentActivity