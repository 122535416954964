import React, { useEffect, useState } from 'react'
import './style.css';
import ScrollBar from 'react-perfect-scrollbar';
import { message, Spin, Tag, Timeline, Typography } from 'antd';
import { LikeOutlined, DislikeOutlined, ShareAltOutlined, DownloadOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getRequestApiByToken } from '../../../../api';
import { getIndexKeyByDate, stringAvatar } from '../../../../utility';
import moment from 'moment';
import { ActivityStatusDefaultColor, DocActivityParentData, DocStatusData } from '../../../../constant/globalConfig';
import Avatar from 'antd/lib/avatar/avatar';

const DocActivities = (lo_props) => {
    const { ls_documentId, lo_documentData } = lo_props;
    const [lb_loading, setLoading] = useState(false);
    const [la_activityList, setActivityList] = useState([]);
    const [la_activityTimelineData, setActivityTimelineData] = useState([]);

    const getTimeLineDetails = (ls_url, ls_name, ls_status, ls_color) => {
        return (
            <div className="align-items-start tmline-col">
                <div className='d-flex' style={{ alignItems: "center" }}>
                    {false ? <img className="img-50 img-fluid m-r-20 rounded-circle" src={ls_url} alt="" /> : <Avatar {...stringAvatar(ls_name)} className="m-r-20 rounded-circle" size="large" style={{backgroundColor: '#fde3cf', color: '#f56a00'}}></Avatar>}
                    <h5 className="tmline-name f-w-700">{ls_name}</h5>
                </div>
                <h5 className='tmline-name2 f-w-600' style={{ color: ls_color }}><CheckCircleOutlined style={{ color: ls_color }} /> {ls_status}</h5>
            </div>
        )
    }

    const getActivityObject = (createdAt, ls_color1, ls_color2, ls_userName, ls_status) => {
        const lo_element = {
            label: <strong style={{ fontSize: '12px', }}>{createdAt}</strong>,
            color: ls_color2,
            children: getTimeLineDetails('https://react.pixelstrap.com/endless/static/media/5.53a215ed00aac31d7543.jpg', ls_userName, ls_status, ls_color1),
            position: 'left'
        };

        return lo_element;
    }

    const getActivityParentObject = (i, ls_accolor1, ls_accolor2, formattedDate) => {
        const lo_element = {
            dot: (<ClockCircleOutlined style={(i==0) ? { fontSize: '16px', color:ls_accolor1 } : { fontSize: '16px', marginTop: 20, color:ls_accolor1 }} />),
            children: <Tag style={(i==0) ? {} : { marginTop: 12 }} color={ls_accolor1}>{formattedDate}</Tag>,
            color: {ls_accolor2},
        };

        return lo_element;
    }

    const formatAndSetActivityTimeLineData = async () => {
        const la_activityData = [...la_activityList];
        let lo_result = {};
        let la_result = [];
        for (let i = 0; i < la_activityData.length; i++) {
            const rowValue = la_activityData[i];
            const ls_keyIndex = await getIndexKeyByDate(new Date(rowValue.created_at));

            const ls_userName = rowValue?.user?.name || '-';
            const ls_status = rowValue?.status_category || '-';
            const ls_color1 = DocStatusData[rowValue?.status_category]?.color1 || ActivityStatusDefaultColor;
            const ls_color2 = DocStatusData[rowValue?.status_category]?.color2 || ActivityStatusDefaultColor;
            const createdAt = rowValue?.created_at ? moment(new Date(rowValue.created_at)).format('hh:mm A') : '-';
            const lo_element = await getActivityObject(createdAt, ls_color1, ls_color2, ls_userName, ls_status);

            const formattedDate = rowValue?.created_at ? moment(rowValue.created_at).format('DD MMMM, YYYY') : '-';


            if (lo_result[ls_keyIndex]) {
                lo_result[ls_keyIndex]?.activity_list?.push({...lo_element});
            } else {
                const ls_accolor1 = ( DocActivityParentData[i] ? DocActivityParentData[i]?.color1 : DocActivityParentData[i%2]?.color1) || ActivityStatusDefaultColor;
                const ls_accolor2 = ( DocActivityParentData[i] ? DocActivityParentData[i]?.color2 : DocActivityParentData[i%2]?.color2) || ActivityStatusDefaultColor;
                const lo_parentElement = await getActivityParentObject(i, ls_accolor1, ls_accolor2, formattedDate);
                lo_result = { ...lo_result, [ls_keyIndex] : {'activity_list': [{...lo_parentElement} , {...lo_element}] } };
            }
        }

        for (const k in lo_result) {
            if (Object.hasOwnProperty.call(lo_result, k)) {
                const element = lo_result[k];
                if(element?.activity_list?.length) la_result?.push(...element?.activity_list);
            }
        }

        if (!la_result?.length) {
            const i = 0;
            const formattedDate = lo_documentData?.created ? moment(lo_documentData.created).format('DD MMMM, YYYY') : '-';
            const ls_accolor1 = (DocActivityParentData[i] ? DocActivityParentData[i]?.color1 : DocActivityParentData[i % 2]?.color1) || ActivityStatusDefaultColor;
            const ls_accolor2 = (DocActivityParentData[i] ? DocActivityParentData[i]?.color2 : DocActivityParentData[i % 2]?.color2) || ActivityStatusDefaultColor;
            const lo_parentElement = await getActivityParentObject(i, ls_accolor1, ls_accolor2, formattedDate);

            const ls_userName = lo_documentData?.owners?.name || '-';
            const ls_status = 'Uploaded';
            const ls_color1 = DocStatusData[ls_status]?.color1 || ActivityStatusDefaultColor;
            const ls_color2 = DocStatusData[ls_status]?.color2 || ActivityStatusDefaultColor;
            const createdAt = lo_documentData?.created ? moment(new Date(lo_documentData.created)).format('hh:mm A') : '-';
            const lo_element = await getActivityObject(createdAt, ls_color1, ls_color2, ls_userName, ls_status);

            la_result?.push(lo_parentElement, lo_element);
        }

        setActivityTimelineData(la_result);
    }

    const getActivityList = async () => {
        if (!ls_documentId) { setActivityList([]); return; }

        setLoading(true);
        const res = await getRequestApiByToken(`documentlog/${ls_documentId}`);
        setActivityList(res?.DocumentsLog || []);
        setLoading(false);
    }

    useEffect(() => {
        getActivityList();
    }, [ls_documentId])

    useEffect(() => {
        
        formatAndSetActivityTimeLineData();
    }, [JSON.stringify(la_activityList), JSON.stringify(lo_documentData)])

    useEffect(() => {
        console.log('----------------------------------------------------------------la_activityList',la_activityList);
    }, [la_activityList])
    

    return (
        <div className=''>
            <div className="card communication-card-hight">
                <div className="card-header card-header-border chat-header-default">
                    <div className="row">
                        <div className="col-sm-6">
                            <h5>{'Activities'}</h5>
                        </div>
                        <div className="col-sm-6">

                        </div>
                    </div>
                </div>
                <div className="card-body chat-box dashboard-chat tnline-mainparent doc-activty-block">
                    <Timeline mode='left' items={la_activityTimelineData} />
                </div>
            </div>
        </div>
    )
}

export default DocActivities