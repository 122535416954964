import { Form, Input,Button, Spin, Switch, Select,} from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row} from 'reactstrap'
import { PlusOutlined } from '@ant-design/icons';
import './style.css'
const AddForm = ({handleSubmitForm=()=>{},lo_updateData={},lb_buttonLoader=false,ls_updateId='',form, resetFormData, lb_spining=false,la_documentCategory=[]}) => {
  const [lb_subCategory, setSubCategory] = useState(false)
  const [la_resultArray, setResultArray] = useState([]);
  
  const onChange = (checked) => {
    setSubCategory(checked)
  };
  useEffect(() => {
    console.log('---lo_updateData---',lo_updateData);
   if (lo_updateData?.parent_id) {
    setSubCategory(true)
   } else{
    setSubCategory(false)
   }
  }, [lo_updateData])
  
  const setOptionsArray = () => {
    // const topLevelCategories = la_documentCategory.filter(category => category.parent_id === 0);
    const topLevelCategories = la_documentCategory.filter((rowValue) => {
      if (rowValue?.parent_id == 0 && rowValue?.status == 1) {
        return true;
      } else if (ls_updateId && rowValue?.id == lo_updateData?.parent_id) {
        return true;
      }
      return false;
    });
    const resultArray = topLevelCategories.map(category => ({
        label: category.category_name,
        value: category.id
    }));
    setResultArray(resultArray);
  }

  useEffect(() => {
    setOptionsArray();
  }, [la_documentCategory])
  

  return (
    <>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Spin spinning={lb_spining}>
                  <Form 
                    form={form}
                    className="theme-form" 
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmitForm}
                  >
                  <div style={{ visibility: ls_updateId ? "hidden" : "visible" }}>
                    <Row>
                      <Col sm="6" className='toggle-view'>
                        {`Category        `}
                        <Switch
                          checked={lb_subCategory}
                          size='small'
                          onChange={onChange}
                        />
                        {`   SubCategory`}
                      </Col>
                    </Row>
                  </div>
                    

                  <Row>
                    {lb_subCategory &&
                      <Col sm="6">
                        <Form.Item
                          label="Category"
                          name="parent_id"
                          rules={[{ required: true, message: "Please input Category!" }]}
                        >
                          <Select placeholder="Select Category"
                            allowClear showSearch
                            optionFilterProp="children"
                            options={la_resultArray}
                          />
                        </Form.Item>
                      </Col>
                    }
                    <Col sm={lb_subCategory ? "6" : '12'}>
                      <Form.Item
                        label={lb_subCategory ? 'SubCategory Name' : "Category Name"}
                        name="category_name"
                        rules={[{ required: true, message: "Please input Category Name!" }]}
                      >
                        <Input placeholder="Category name *" enterButton />
                      </Form.Item>
                    </Col>
                  </Row>

                    <Row>
                      <Col>
                        {ls_updateId ? <button onClick={() => resetFormData()} class="btn btn-outline-info btn-sm" type="button"><PlusOutlined /> Add New</button> : <></>}
                          <Button 
                            style={{float:"right"}} 
                            color="success" 
                            loading={lb_buttonLoader}
                            type='primary'
                            htmlType='submit'
                          >
                            {ls_updateId =='' ? 'Add' : 'Update'}
                          </Button>
                      </Col>
                    </Row> 
                  </Form>
                </Spin>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </>
  )
}

export default AddForm