import React, { useEffect, useState } from 'react';
import { Col, message, Modal, Row, Select } from 'antd';
import Form from "antd/lib/form";
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { Divider, Spin } from 'antd/lib';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { debounce } from 'lodash';

const TransferDocument = ({ visible = false, onCancel = () => { }, ls_documentId = '', getSearchData = () => { } }) => {
  const [form] = Form.useForm();
  const [la_projectList, setProjectList] = useState([])
  const [lb_loading, setLoading] = useState(false)
  const [lb_projectLoading, setProjectLoading] = useState(false);

  const getProjectList = async (filterText) => {
    setProjectLoading(true)
    if (filterText) {
      const res = await getRequestApiByToken(`project?search=${filterText}&pagination=false`);
      setProjectList(res?.Projects?.data);
    }
    setProjectLoading(false);
  }

  const transferDocumentToProject = async (ls_docId, ls_projectId) => {
    setLoading(true);
    const lo_formData = {"other_document_id": ls_docId, "project_id": ls_projectId };
    const res = await postRequestApiByToken('transfertoproject', lo_formData);
    console.log('transferDocumentToProject---res',res);

    if (res?.status == "Success") {
      const res = await postRequestApiByToken('deleteotherdocument/'+ls_docId)
      if (res?.status =="Success") {
        onCancel()
        form.resetFields();
        message.success("Document tranfered successfully!");
        getSearchData();
      }
    }
    setLoading(false);
  };

  const handleOnOk = async (value) => {
    if (ls_documentId && value?.project_id) {
      await transferDocumentToProject(ls_documentId, value.project_id);
    } else {
      message.error('Project not included!');
    }
  }

  return (
    <Modal
      title={"Transfer Document"}
      visible={visible}
      onCancel={onCancel}
      // footer={null}
      onOk={() => {
        form.validateFields().then((values) => {
          // form.resetFields();
          handleOnOk(values);
        })
          .catch((info) => {
            // console.log('Validate Failed:', info);
          });
      }
      }
      confirmLoading={lb_loading}
      width={800}
    >
      <Divider />
      <Form form={form}
        className='mt8-xs'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{ span: 16 }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Project Name"
              name="project_id"
              rules={[{ required: true, message: "Please input Project Name!" }]}
            >
              <Select
                placeholder="Select Project"
                showSearch
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                onSearch={debounce((value) => { getProjectList(value) }, 500)}
                notFoundContent={lb_projectLoading ? <Spin size="small" /> : null}
                allowClear
                style={{ width: '100%' }}
              >
                {
                  la_projectList.map((rowVal, indx) => <Select.Option key={rowVal.id} value={rowVal.id}>{rowVal.project_name}</Select.Option>)
                }
              </Select>

            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TransferDocument;
