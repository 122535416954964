import React, { Suspense, useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "../components/app";
import { routes } from "./layouts-routes";
import { Loader } from "react-feather";

import configDB from "../data/customizer/config";
import UserAndAdminLogin from "../pages/auth/userandadminlogin";
import { useSelector } from "react-redux";
import UserAuthentication from "../pages/auth/UserAuthentication";
import UserLogin from "../pages/auth/UserLogin";
import MicrosoftLoginPage from "../pages/auth/MicrosoftLogin";
import RedirectPage from "../pages/RedirectPage";
import { postRequestApiByToken } from "../api";

const MainRoutes = () => {
  const ls_userType = useSelector(content => content.commonReducer.user_type);
  const ls_userId = useSelector(content => content.commonReducer.user_id);

  const [lo_permissionCheck, setPermissionCheck] = useState({add_document:false, approve_document:false});
  const getPermissionDataRes = async () => {
    const res = await postRequestApiByToken('user_permission', {})
    console.log('---- check sector',res);
    if (res?.status == "Success") {
        setPermissionCheck({add_document: res?.add_document, approve_document: res?.approve_document});
    }
}
useEffect(() => {
    getPermissionDataRes();
}, [ls_userType])

  useEffect(() => {
    console.log('____________route ls_userType',ls_userType);
  }, [ls_userType])
  
  useEffect(() => {
    const abortController = new AbortController();
    const color = localStorage.getItem("color");
    const layout = localStorage.getItem("layout_version") || configDB.data.color.layout_version;
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <BrowserRouter basename="/">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={ls_userType ? `${process.env.PUBLIC_URL}/dashboard` : `${process.env.PUBLIC_URL}/login`} />} />
            {ls_userType && routes.map(({ path, Component, permission_key }, i) => {
              if (permission_key == 'admin') {
                if (ls_userType == 'admin') {
                  return (<Route element={<AppLayout />} key={i}><Route exact path={path} element={Component} /></Route>);
                }
              } else if (permission_key == 'add_document') {
                if (lo_permissionCheck?.add_document){
                  return (<Route element={<AppLayout />} key={i}><Route exact path={path} element={Component} /></Route>);
                }
              } else if (permission_key == 'approve_document') {
                if (lo_permissionCheck?.approve_document){
                  return (<Route element={<AppLayout />} key={i}><Route exact path={path} element={Component} /></Route>);
                }
              } else {
                return (<Route element={<AppLayout />} key={i}><Route exact path={path} element={Component} /></Route>);
              }
            })}

          {/* {!ls_userType && <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<UserAndAdminLogin/>} />}
          {!ls_userType && <Route exact path={`${process.env.PUBLIC_URL}/auth-login`} element={<UserAuthentication/>} />}
          {!ls_userType && <Route exact path={`${process.env.PUBLIC_URL}/auth_login`} element={<UserAndAdminLogin/>} />} */}
          
          {/* <Route exact path={`${process.env.PUBLIC_URL}login`} element={<UserAndAdminLogin header={'Admin'}/>} /> */}
          
            {!ls_userId ? <Route path="/" element={<UserAndAdminLogin/>}>
              <Route path={`${process.env.PUBLIC_URL}/login`} element={<UserLogin />} />
              <Route path={`${process.env.PUBLIC_URL}/auth_login`} element={<UserAuthentication />} />
              <Route path={`${process.env.PUBLIC_URL}/msa-login`} element={<MicrosoftLoginPage />} />
            </Route>
          :  
            <>
              <Route path={`${process.env.PUBLIC_URL}/login`} element={<RedirectPage />} />
              <Route path={`${process.env.PUBLIC_URL}/auth_login`} element={<RedirectPage />} />
              <Route path={`${process.env.PUBLIC_URL}/msa-login`} element={<RedirectPage />} />
            </>
          }

          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default MainRoutes;
