import { Checkbox, Form, Input, Select, Space } from 'antd'
import React from 'react'
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
import './style.css'

const DeviceIdDetails = () => {
    return (
        <div className='ipformitem-space'>
            
            <Form.Item
            noStyle
                // labelAlign="right"
                // label="Capacity"
                // name={['ip_access']}
            >
                <Form.List name={['device_id']} initialValue={[{type:'1', id:''},{type:'2', id:''}]}>
                    {(fields, { add, remove }) => (
                        <>

                            {fields.map((field, index) => (
                                <div
                                    key={field.key}
                                    className='deviceformitem-list'
                                    // align="baseline"
                                >
                                    <Form.Item
                                        style={{ marginBottom: 10 , width:'30%', marginRight:8}}
                                        name={[field.name, 'type']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <Select disabled placeholder="Select" style={{ width: '100%' }} >
                                            <Select.Option value={'1'} key={1}>Desktop</Select.Option>
                                            <Select.Option value={'2'} key={2}>Mobile</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name={[field.name, 'id']}
                                        style={{ marginBottom: 10, width:'70%', marginRight:8 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'required!',
                                            },
                                        ]}

                                    >
                                        <Input disabled></Input>
                                    </Form.Item>
                                    
                                    {/* {
                                        <>
                                            {field.name > 0 ? <MinusCircleOutlined style={{color:"red"}} onClick={() => remove(index)} /> : <PlusCircleOutlined style={{color:"blue"}} onClick={() => add()} />}
                                        </>
                                    } */}


                                </div>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </div>
    )
}

export default DeviceIdDetails