import axios from 'axios';
import { objectToFormData, redirectToLogoutPage } from '../utility';
import { Api_base_url } from '../constant/globalConfig';
import store from '../store';

export const postRequestApi = async (ls_apiEndPoint, lo_formData) => {
    let data = objectToFormData(lo_formData);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Api_base_url + ls_apiEndPoint,
        // headers: { ...formdata.getHeaders() },
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json', 
            'mode': 'no-cors',
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        console.log(response.data);
        return response.data; 
    } catch (error) {
        console.log(error?.response?.data);
        return error?.response?.data;
    }

}

export const postRequestApiByToken = async (ls_apiEndPoint, lo_formData, la_files=null) => {
    const lo_storeData = store.getState();
    const ls_token = 'Bearer '+lo_storeData.commonReducer.token;
    let data = objectToFormData(lo_formData, la_files);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Api_base_url + ls_apiEndPoint,
        // headers: { ...formdata.getHeaders() },
        headers: {
            'Authorization': ls_token,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json', 
            'mode': 'no-cors',
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        return response.data; 
    } catch (error) {
        console.log('api___error',error);
        if(error?.response?.data?.message == "Unauthenticated.") redirectToLogoutPage();
        return error?.response?.data;
    }
}

export const getRequestApi = async (ls_apiEndPoint) => {
    // const lo_storeData = store.getState();
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: Api_base_url + ls_apiEndPoint,
        headers: { 'Accept': 'application/json',  mode: 'no-cors' }
    };

    try {
        const response = await axios.request(config);
        console.log('getRequestApi response.data',response.data);
        return response.data; // return the response data
    } catch (error) {
        console.log(error);
        
    }
}
export const getRequestApiByToken = async (ls_apiEndPoint) => {
    const lo_storeData = store.getState();
    const ls_token = 'Bearer '+lo_storeData.commonReducer.token;
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: Api_base_url + ls_apiEndPoint,
        headers: { 
            'Accept': 'application/json', 
            mode: 'no-cors',
            'Authorization': ls_token
        }
    };

    try {
        const response = await axios.request(config);
        return response.data; // return the response data
    } catch (error) {
        console.log('api___error',error);
        if(error?.response?.data?.message == "Unauthenticated.") redirectToLogoutPage();
    }
}

export const postRequestApiByTokenWithDataType = async (dataType='json',ls_apiEndPoint, lo_formData) => {
    const lo_storeData = store.getState();
    const ls_token = 'Bearer '+lo_storeData.commonReducer.token;
    let data = (dataType=='json') ? JSON.stringify(lo_formData) : objectToFormData(lo_formData);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Api_base_url + ls_apiEndPoint,
        // headers: { ...formdata.getHeaders() },
        headers: {
            'Authorization': ls_token,
            'Content-Type': (dataType=='json') ? 'application/json' : 'multipart/form-data',
            // 'Accept': 'application/json', 
            'mode': 'no-cors',
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        console.log(response.data);
        return response.data; 
    } catch (error) {
        console.log('error?.response?.data',error?.response?.data);
        if(error?.response?.data?.message == "Unauthenticated.") redirectToLogoutPage();
        return error?.response?.data;
    }
}

export const postRequestApiWithDataType = async (dataType='json',ls_apiEndPoint, lo_formData) => {
    let data = (dataType=='json') ? JSON.stringify(lo_formData) : objectToFormData(lo_formData);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Api_base_url + ls_apiEndPoint,
        // headers: { ...formdata.getHeaders() },
        headers: {
            'Content-Type': (dataType=='json') ? 'application/json' : 'multipart/form-data',
            'mode': 'no-cors',
        },
        data: data
    };
    try {
        const response = await axios.request(config);
        console.log(response.data);
        return response.data; 
    } catch (error) {
        console.log(error?.response?.data);
        return error?.response?.data;
    }
}