import React, { useState, Fragment, useEffect, useLayoutEffect } from 'react';
// import logo from '../../../assets/images/logo.png';
import logo from '../../../assets/images/logo/IPShare.png';
import Language from './language';
import UserMenu from './userMenu';
import Notification from './notification';
import SearchHeader from './searchHeader';
import { Link } from 'react-router-dom';
import { AlignLeft, Maximize, Bell, MessageCircle, MoreHorizontal } from 'react-feather';
import { useSelector } from 'react-redux';
import { onMessage } from 'firebase/messaging';
import { messaging } from '../../../firebase-config';
import { getRequestApiByToken } from '../../../api';
import { Badge } from 'antd/lib';
import { setNotificationCountData, setNotificationsListData } from '../../../utility';

const Header = () => {
  const ls_userType = useSelector(content => content.commonReducer.user_type);

  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);

  // const [la_notifications, setNotifications] = useState([]);
  // const [lo_notificationCount, setNotificationCount] = useState({"unSeenCount": 0, "seenCount": 0});
  const la_notifications = useSelector(content => content.commonReducer.la_notifications);
  const lo_notificationCount = useSelector(content => content.commonReducer.lo_notificationCount);


  const getAndSetNotifications = async () => {
    await setNotificationsListData();
    // const res = await getRequestApiByToken(`getNotification?is_seen=0&Spage=${1}&orderBy=desc`);
    // console.log('getAndSetNotifications response====>>>', res);
    // const la_newNotifictnarr = res?.data?.data || [];
    // const la_newNotificationData = la_newNotifictnarr?.slice(0, 6);
    // setNotifications(la_newNotificationData || []);
  }
  const getAndSetNotificationCount = async () => {
    await setNotificationCountData()
    // const res = await getRequestApiByToken(`getNotificationCount`)
    // console.log('getAndSetNotificationCount response====>>>', res);
    // const la_newNotificationCount = res?.data || {"unSeenCount": 0, "seenCount": 0};
    // setNotificationCount(la_newNotificationCount);
  }

  useEffect(() => {
    // Listen for new notifications
    getAndSetNotifications();
    getAndSetNotificationCount();
    onMessage(messaging, (payload) => {
      console.log('Message received notify bar:', payload);
      //   const { title, body } = payload.notification;
      //   new Notification(title, { body });

      // setNotifications(prevNotifications => [...prevNotifications, payload?.notification]);
      // const lo_notifyObj = { id: payload?.data?.notification_id, notification_title: payload?.notification?.title, message: payload?.notification?.body, document_id: payload?.data?.document_id}
      // const la_newNotifications = [lo_notifyObj, ...la_notifications]
      // setNotifications([...la_newNotifications]);
      if (payload?.notification) {
        getAndSetNotifications();
        getAndSetNotificationCount();
      }
    });
  }, []);
  useEffect(() => {
    console.log('---la_notifications---',la_notifications);
  }, [la_notifications])
  

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 991) {
        setSidebar(false)
        document.querySelector(".page-main-header").classList.add('open');
        document.querySelector(".page-sidebar").classList.add('open');
      } else {
        setSidebar(true)
        document.querySelector(".page-main-header").classList.remove('open');
        document.querySelector(".page-sidebar").classList.remove('open');
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setSidebar(false)
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    } else {
      setSidebar(true)
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    }


  }, []);

  function openCloseSidebar() {
    if (sidebar) {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    } else {
      setSidebar(!sidebar);
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.add('show');
    } else {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.remove('show');
    }
  }

  //full screen function
  function goFull() {
    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      <div className="page-main-header" >
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none col-auto">
            <div className="logo-wrapper">
              <Link to={(ls_userType == 'admin') ? `${process.env.PUBLIC_URL}/dashboard` : `${process.env.PUBLIC_URL}/document/search-document`}>
                <img className="img-fluid" src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block col-auto">
            <div className="flex-grow-1 text-end switch-sm">
              <label className="switch">
                <a 
                // href="#javascript" 
                onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>
          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li>
                <SearchHeader />
              </li>
              <li>
                <a onClick={goFull} className="text-dark" href="#!">
                  <Maximize />
                </a>
              </li>
              {/* <li className="onhover-dropdown">

                <Language />
              </li> */}
              <li className="onhover-dropdown">
                <Notification la_notifications={la_notifications} lo_notificationCount={lo_notificationCount} getAndSetNotifications={getAndSetNotifications} getAndSetNotificationCount={getAndSetNotificationCount} />
                <Bell />
                <Badge count={lo_notificationCount?.unSeenCount || 0} size="small" color='red' overflowCount={99} style={{fontSize:8}}>
                </Badge>
                {/* {lo_notificationCount?.unSeenCount > 0 ? <span className="dot"></span> : <></>} */}
                <Notification la_notifications={la_notifications} lo_notificationCount={lo_notificationCount} getAndSetNotifications={getAndSetNotifications} getAndSetNotificationCount={getAndSetNotificationCount} />
              </li>
              {/* <li>
                <a href="#javascript" onClick={showRightSidebar}>
                  <MessageCircle />
                  <span className="dot"></span>
                </a>
              </li> */}
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal /></div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">{"Your search turned up 0 results. This most likely means the backend is down, yikes!"}</div>
          </script>
        </div>
      </div>
    </Fragment>
  )
};
export default Header;