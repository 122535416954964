export const FileIconsObj = {
    "jpg": <i className="fa fa-file-image-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>,
    "png": <i className="fa fa-file-image-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>,
    "jpeg": <i className="fa fa-file-image-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>,
    "pdf": <i className="fa fa-file-pdf-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>,
    'ppt': <i className="fa fa-file-powerpoint-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#f9bc33" }}></i>,
    'pptx': <i className="fa fa-file-powerpoint-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#f9bc33" }}></i>,
    'word': <i className="fa fa-file-word-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#6487f2" }}></i>,
    'docx': <i className="fa fa-file-word-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#6487f2" }}></i>,
    'doc': <i className="fa fa-file-word-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "#6487f2" }}></i>,
    'xlsx': <i className="fa fa-file-excel-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "green" }}></i>,
    'xls': <i className="fa fa-file-excel-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "green" }}></i>,
    'csv': <i className="fa fa-file-excel-o"  style={{ width: 35, fontSize: 16, padding: 11, color: "green" }}></i>,
}
export const FileIconsObjWithStyle = {
    "jpg": <i className="fa fa-file-image-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#e4566e" }}></i>,
    "png": <i className="fa fa-file-image-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#e4566e" }}></i>,
    "jpeg": <i className="fa fa-file-image-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#e4566e" }}></i>,
    "pdf": <i className="fa fa-file-pdf-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#e4566e" }}></i>,
    'ppt': <i className="fa fa-file-powerpoint-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#f9bc33" }}></i>,
    'pptx': <i className="fa fa-file-powerpoint-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#f9bc33" }}></i>,
    'word': <i className="fa fa-file-word-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#6487f2" }}></i>,
    'docx': <i className="fa fa-file-word-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#6487f2" }}></i>,
    'doc': <i className="fa fa-file-word-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "#6487f2" }}></i>,
    'xlsx': <i className="fa fa-file-excel-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "green" }}></i>,
    'xls': <i className="fa fa-file-excel-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "green" }}></i>,
    'csv': <i className="fa fa-file-excel-o"  style={{ fontSize: 18, color: "#e4566e",marginInlineEnd:15, color: "green" }}></i>,
}

export const Api_base_url='https://ipeshare.brainsparktech.net/API/Staging/public/api/';
export const FilePath='https://ipeshare.brainsparktech.net/API/Staging/public';
export const DateFormat = "DD/MM/YYYY";
export const TimeFormat = 'HH:mm';

export const AcceptFileTypes = ['application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'];

export const AwsFilePath= 'https://ipekm-live.s3.ap-south-1.amazonaws.com/uploads';

export const DocStatusData = {
    'Approved': {color1 : "#7fd825db", color2 : "green"},
    'Reviewed': {color1 : "#f37b57b8", color2 : "red"},
    'Uploaded': {color1 : "#7a6de3e0", color2 : "blue"},
    'Modified': {color1 : "#00bd97", color2 : "#03a080"},
}
export const ActivityStatusDefaultColor = "#fdc202";

export const DocActivityParentData = [
    {color1 : "#7fdf1f", color2 : "green"},
    {color1 : "blue-inverse", color2 : "blue"},
]

export const BaseUrl = process.env.PUBLIC_URL;