import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import { Input, Checkbox, Row, Col, Badge, Tag, Card, Button } from 'antd';
import Createusercard from './Createusercard';
// import 'antd/dist/antd.css';
import './style.css'
import CreateUserForm from './CreateUserForm';
import { getRequestApiByToken } from '../../../api';
import UserWithAccess from './UserWithAccess';
import UserList from './UserList';
import SyncUserList from '../../commonActions/SyncUserList';

const { Search } = Input;

const User = () =>{
  const [userId, handleSetUserId] = useState('');
  const [lb_showUserAccess, setShowUserAccess] = useState(false);
  const [la_userDetails, setUserDetails] = useState([])
  const [ls_actionType, setActionType] = useState(null);
  const [lo_actionRecord, setActionRecord] = useState({});

  const handleSetDataAction = (ls_action=null, record={}) => {
    setActionType(ls_action)
    setActionRecord(record)
  }
  const getAllUserList = async () => {
    // setLoading(true)
    // const lo_response = await getRequestApi("checkin-user-list/"+ls_organisationId);
    // setLoading(false)
    // if (lo_response?.user) {
    //     setcheckInUserList(lo_response.user)
    // }else{
    //     setcheckInUserList([])
    // }
    const res = await getRequestApiByToken('users')
    setUserDetails(res?.Users || [])

  };
  useEffect(() => {
    getAllUserList()
  }, [])

  const lo_formProps = { "getDataList": getAllUserList, ls_actionType, lo_actionRecord, handleSetDataAction };

  return (
    <>
     <Breadcrumb parent="User Management" 
      // sideComponent={<SyncUserList/>}
     />
      <div className="col-xl-12 xl-100">
        <div className="card">
          <div className="card-header card-header-border">
            <div className="col-sm-12" style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <h5>{"Create User"}</h5>
              <div style={{ display: 'flex', cursor:"pointer" }} onClick={() => { handleSetDataAction('add_new_user') }} >
                <i className="fa fa-plus-circle" style={{ fontSize: 20, marginInlineEnd: 10 }}></i>
                <strong>Add User</strong>
              </div>
            </div>
          </div>
          <div className="card-body">
            <Createusercard userId={userId} la_userDetails={la_userDetails} handleSetUserId={handleSetUserId} handleSetDataAction={handleSetDataAction} setShowUserAccess={setShowUserAccess}/>
          </div>
        </div>
      </div>
      {(userId && lb_showUserAccess) ?
      <div className="col-xl-12 xl-100">
        <div className="card">
          <div className="card-header card-header-border">
            <div className="col-sm-7">
              <h5>{"User With Access"}</h5>
            </div>
          </div>
          <div className="card-body">
            <UserWithAccess userId={userId} handleSetDataAction={handleSetDataAction}/>
          </div>
        </div>
      </div>
      : <></>}
      <div className="col-xl-12 xl-100">
        <UserList ls_actionType={ls_actionType} handleSetDataAction={handleSetDataAction}/>
      </div>
      <CreateUserForm {...lo_formProps}/>
    </>
  )
}

export default User
