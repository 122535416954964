import React, { useEffect, useState,useRef } from 'react';
import './style.css'
import { Download, Eye, File, FilePlus, } from 'react-feather';
import Breadcrumb from '../../../components/common/breadcrumb';
import {Container } from 'reactstrap';
import CountUp from 'react-countup';
import { FileIconsObj, FileIconsObjWithStyle } from '../../../constant/globalConfig';
import AdvanceSerchForm from './advanceSerchForm';
import { Badge, List, Space, Tag ,Row ,Col, Form, Popover, Typography, Descriptions, Tooltip, message, } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { getCountryCode, getFileExtension, getFileIconsObj, getQuerySelectorValueFormArray, setApproverDocumentCount } from '../../../utility';
import moment from 'moment';
import { useSelector } from 'react-redux';
import JobFilter from './jobFilter';
import DocumentListing from '../DocumentListing';
let serachFirst = true;
const SearchDocument = () => {
  const ls_userType = useSelector(content => content.commonReducer.user_type);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search_query');

  const [la_documentList, setDocumentList] = useState([])
  const [la_sectorData, setSectorData] = useState([])
  const [la_practices, setPractices] = useState([])
  const [la_country, setCountry] = useState([])
  const [la_clientName, setClientName] = useState([])
  const [lb_isAdvanceSearch, setIsAdvanceSearch] = useState(false)
  const [la_sector, setSector] = useState([])
  const [la_practice, setPractice] = useState([])
  const [la_countryData, setCountryData] = useState([])
  const [la_client, setClient] = useState([])

  const [la_documentCategory, setDocumentCategory] = useState([])
  const [la_documentSubCategory, setDocumentSubCategory] = useState([])

  const [advanceForm] =Form.useForm()
  const ls_search = Form.useWatch('search', advanceForm);
  
  const [filterForm] =Form.useForm()
  const la_country_id = Form.useWatch('country_id', filterForm);
  const la_sector_id = Form.useWatch('sector_id', filterForm);
  const la_parctices = Form.useWatch('parctices', filterForm);
  const la_donor = Form.useWatch('donor', filterForm);
  const [ls_searchText, setsSearchText] = useState('')
  const [la_keyWords, setKeyWords] = useState([]);
  const [la_selectedDocumentType, setSelectedDocumentType] = useState([])
  const [la_documentTypes, setDocumentTypes] = useState([])
  const [lb_loading, setLoading] = useState(false);

  const popoverRef = useRef();

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changePage, setChangePage] = useState(false);

  const [lo_limitCount, setLimitCount] = useState({});

  const [la_accessList, setAccessList] = useState(['view', 'delete', 'share', 'bookmark', 'edit']);
  useEffect(() => {
    if (ls_userType == 'user') {
      setAccessList(['view', 'delete', 'share', 'bookmark'])
    }
  }, [ls_userType])
  
  useEffect(() => {
    setApproverDocumentCount();
  }, [])

  const getSectorData = async () => {
    // const res = await getRequestApiByToken('sector_master')
    const res = await getRequestApiByToken('get_sectors')
    const la_options=[]
    res?.sector_master?.map((v)=>la_options.push({label: v?.sector_name,value: v?.id}))
    setSectorData(la_options)
  }
  
  const getPracticeData = async () => {
    const res = await getRequestApiByToken('practice')
    const la_options = []
    res?.practices?.map((v) => la_options.push({ label: v?.practice_name, value: v?.id }))
    setPractices(la_options)
  }
  
  const getCountryData = async () => {
    const res = await getRequestApiByToken('country_master')
    const la_options = []
    res?.country_master?.map((v) => la_options.push({ label: v?.country_name, value: v?.id }))
    setCountry(la_options)
  }

  const getDonorData = async () => {
    const res = await getRequestApiByToken('client_master')
    const la_options = []
    res?.client_master?.map((v) => la_options.push({ label: v?.client_name, value: v?.id }))
    setClientName(la_options)
  }

  
  const getDocumentCategoryData = async () => {
      const res = await getRequestApiByToken('docuemt_category');
      const la_docCatArr = res?.document_category?.filter((rowValue) => (rowValue?.parent_id == 0));
      setDocumentCategory(la_docCatArr || [])
  }
  
  useEffect(() => {
    getSectorData()
    getPracticeData()
    getCountryData()
    getDonorData()
    getDocumentCategoryData()
    // handleSingleSearch()
    // handleMultiSearch()
  }, [])

  const getSearchData = async (value = '', searchType) => {
    setLoading(true);
    // ls_search
    const lo_paramsValue = value;
    if(ls_search && !value?.search) lo_paramsValue['search'] = ls_search;
    const ls_params = getQuerySelectorValueFormArray(lo_paramsValue);
    // const res = await getRequestApiByToken(`document?orderBy=asc&pagination=false&${ls_params}`);
    const res = await getRequestApiByToken(`document?orderBy=desc&pagination=true&page=${currentPage}&${ls_params}`);

    console.log('ls_params:--', ls_params, '-----response:---', res);
    setTotalCount(res?.Documents?.total || 0)
    setLimitCount(res?.limitcount || {})
    setDocumentList(res?.Documents?.data || []);
    setLoading(false);

    if (searchType != 'filter-with-type') {
      const categoriesData = res?.categories ? Object.values(res?.categories) : [];
      setDocumentTypes(categoriesData)
    }
    

    if (searchType == 'advance') {
      const sectorsData = res?.sectors ? Object.values(res?.sectors) : [];
      const parcticesData = res?.parctices ? Object.values(res?.parctices) : [];
      const countriesData = res?.countries ? Object.values(res?.countries) : [];
      const clientsData = res?.clients ? Object.values(res?.clients) : [];
      
      setSector(sectorsData)
      setPractice(parcticesData)
      setCountryData(countriesData)
      setClient(clientsData)

      const country_ids = countriesData?.map((rowValue) => rowValue?.id);
      const sector_ids = sectorsData?.map((rowValue) => rowValue?.id);
      const parctices_ids = parcticesData?.map((rowValue) => rowValue?.id);
      const donor_ids = clientsData?.map((rowValue) => rowValue?.id);
      filterForm.setFieldsValue({ "country_id": country_ids, "sector_id": sector_ids, "parctices": parctices_ids, "donor": donor_ids });
    }
    
  }

  const handleMultiSearch = async (value) => {
    if (value) {
      // filterForm.setFieldsValue({ country_id: value.country_id, sector_id: value.sector_id, "parctices": value.parctices, donor: value.donor });
      setsSearchText(value?.search || '')
      // setKeyWords(value?.key_words || [])
      // setSelectedDocumentType(value?.category_id ? [value?.category_id] : [])
      getSearchData(value, 'advance')
    } else {
      filterForm.setFieldsValue({ country_id: [], sector_id: [], "parctices": [], donor: [] });
      setsSearchText('')
      // setKeyWords([])
      setDocumentList([])
    }
  }

  const onFilterValuesChange = (changedValues, allValues) => {
    // console.log('}}}}}}}changedValues, allValues{{{{{{-----', changedValues, allValues);
      if(currentPage > 1) setCurrentPage(1);
      else setChangePage(!changePage);
  }

  const handleDocTypeChange = (docTypeValues) => {
    console.log('---docTypeValues---',docTypeValues);
    setSelectedDocumentType(docTypeValues || [])
  }

  useEffect(() => {
    if(currentPage > 1) setCurrentPage(1); else setChangePage(!changePage);
  }, [la_selectedDocumentType])
  

  // useEffect(() => {
  //   if(currentPage > 1) setCurrentPage(1);
  //   else setChangePage(!changePage);
  // }, [ls_searchText, la_country_id, la_sector_id, la_parctices, la_donor, la_keyWords, ls_selectedDocumentType])

  useEffect(() => {
    // getSearchData({search:ls_searchText, country_id: la_country_id, sector_id:la_sector_id, parctices:la_parctices, donor:la_donor, key_words:la_keyWords, category_id:ls_selectedDocumentType})
    let la_typIds = [];
    if(la_selectedDocumentType?.length) {
      la_typIds = [...la_selectedDocumentType];
    } else {
      la_typIds = la_documentTypes?.map((rowValue) => rowValue?.id);
    }

    if (searchQuery && serachFirst) {
      advanceForm.setFieldsValue({ "search": searchQuery});
      getSearchData({search:searchQuery, country_id: la_country_id, sector_id:la_sector_id, parctices:la_parctices, donor:la_donor, category_id: la_typIds}, 'advance');
    } else {
      getSearchData({country_id: la_country_id, sector_id:la_sector_id, parctices:la_parctices, donor:la_donor, category_id: la_typIds}, serachFirst? 'advance' : ( la_selectedDocumentType?.length ? 'filter-with-type' : 'filter'));
    }

    serachFirst = false;
  }, [searchQuery, currentPage, changePage])

  useEffect(() => {
    if (!serachFirst) {
      serachFirst = true;
    }
  }, [])
  

  // Handle pagination change
  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  // useEffect(() => {
  //   console.log('search---searchQuery',searchQuery);
  //   if (searchQuery) {
  //     advanceForm.setFieldsValue({ "search": searchQuery});
  //     // handleMultiSearch({ "search": searchQuery})
  //   }
  // }, [searchQuery])
  

  const lo_listProps = { lb_showDocTypeTag:true, ls_headerText:'Document Listing', la_documentTypes, lb_loading, handlePaginationChange, totalCount, la_documentList, getSearchData, handleDocTypeChange, setLoading, la_actionAceess: la_accessList, isInput:true };
    
  return (
      <>
      <Breadcrumb parent="Search Document" title="" 
      sideComponent={
        <div style={{ display: 'flex',cursor:'pointer' }} onClick={()=>{
          if(lb_isAdvanceSearch) {setIsAdvanceSearch(false)} else {setIsAdvanceSearch(true)} }}>
          <i className={`fa fa-${lb_isAdvanceSearch ? 'minus' : 'plus'}-circle`} style={{ fontSize: 20, marginInlineEnd: 10 }}></i>
          <strong>Advance Search</strong>
        </div>} 
      />
      <Container fluid={true}>
      <AdvanceSerchForm 
        advanceForm={advanceForm}
        la_sectorData={la_sectorData}
        la_practices={la_practices}
        la_country={la_country}
        la_clientName={la_clientName}
        lb_loading={lb_loading}
        handleMultiSearch={handleMultiSearch}
        lb_isAdvanceSearch={lb_isAdvanceSearch}
        la_documentCategory={la_documentCategory} 
        la_documentSubCategory={la_documentSubCategory}
        setDocumentSubCategory={setDocumentSubCategory}
        //  handleTagSelect={handleTagSelect} handleSingleSearch={handleSingleSearch}
      />
      
      <div className="row">
        <div className="col-sm-3 col-xl-3 col-lg-6">
          <div className="card o-hidden" style={{height:110}}>
            <div className="bg-primary b-r-4 card-body">
              <div className="d-flex static-top-widget">
                <div className="align-self-center text-center">
                <FilePlus /> 
                </div>
                <div className="flex-grow-1">
                  <span className="m-0">{'TOTAL DOCUMENTS'}</span>
                  <h4 className="mb-0 counter">
                    <CountUp className="counter" end={totalCount || 0} />
                  </h4>
                  <FilePlus className="icon-bg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-xl-3 col-lg-6">
          <div className="card o-hidden" style={{height:110}}>
            <div className="bg-secondary b-r-4 card-body">
              <div className="d-flex static-top-widget">
                <div className="align-self-center text-center">
                  <File />
                </div>
                <div className="flex-grow-1"><span className="m-0">{'NEW DOCUMENTS'}</span>
                  <h4 className="mb-0 counter">
                    <CountUp className="counter" end={lo_limitCount?.new_documents || 0} />
                  </h4>
                  <File className="icon-bg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-xl-3 col-lg-6">
          <div className="card o-hidden" style={{height:110}}>
            <div className="bg-primary b-r-4 card-body">
              <div className="d-flex static-top-widget">
                <div className="align-self-center text-center">
                  <Download />
                </div>
                <div className="flex-grow-1"><span className="m-0">{'DOWNLOAD'}</span>
                  <h4 className="mb-0 counter">
                    <CountUp className="counter" end={lo_limitCount?.total_monthly_downloaded || 0} /> / <CountUp className="counter" end={lo_limitCount?.total_monthly_download_limit || 0} />
                  </h4>
                  <Download className="icon-bg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3 col-xl-3 col-lg-6">
          <div className="card o-hidden" style={{height:110}}>
            <div className="bg-primary b-r-4 card-body">
              <div className="d-flex static-top-widget">
                <div className="align-self-center text-center">
                  <Eye />
                </div>
                <div className="flex-grow-1"><span className="m-0">{'VIEW'}</span>
                  <h4 className="mb-0 counter">
                  <CountUp className="counter" end={lo_limitCount?.total_monthly_viewed || 0} /> / <CountUp className="counter" end={lo_limitCount?.total_monthly_view_limit || 0} />
                  </h4>
                  <Eye className="icon-bg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <div className="row">
        <JobFilter
          la_sectorData={la_sector}
          la_practices={la_practice}
          la_country={la_countryData}
          la_clientName={la_client}
          filterForm={filterForm}
          onFilterValuesChange={onFilterValuesChange}
        />
        <div className="col-xl-9">
          <DocumentListing {...lo_listProps} />
        </div>
      </div>
    </Container>
      </>
  );

};

export default SearchDocument;