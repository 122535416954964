import React from 'react'
import CountUp from 'react-countup';

const CountCard = ({icon = <></>,title='',count=0,Progress=0,maxCount=100 }) =>{
    const progress = (count / maxCount) * 100;

  return (
    <div className="card server-card-bg">
        <div className="card-body server-widgets">
            <div className="d-flex">
                {/* <DownloadCloud /> */}
                {icon}
                <div className="top-server">
                    <h6 className="mb-0">{title}</h6>
                </div>
            </div>
            <div className="bottom-server">
                <h5 className="mb-0">
                    <span className="second-color counter">
                        <CountUp end={count} />
                    </span>
                </h5>
            </div>
            <div className="progress">
                <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: `${progress}%`  }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
  )
}

export default CountCard