import { Cascader, Form, Input, Select, Upload,Button, Spin, message} from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row} from 'reactstrap'
import { LoadingOutlined, PlusOutlined,CloudUploadOutlined } from '@ant-design/icons';
import { AcceptDocFileTypes } from '../../../../constant/basicConstant';
const { Dragger } = Upload;

const AddSectorForm = ({handleSubmitForm=()=>{},
lb_buttonLoader=false,ls_updateId='',form, resetFormData, 
lb_spining=false}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('')

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  const beforeUpload = (file) => {
    const fileType = file.type;
    // const allowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  
    if (!AcceptDocFileTypes.includes(fileType)) {
      // const lo_formValue = form.getFieldsValue(['document_file',]);
      // console.log('getFieldsValue',lo_formValue);
      // form.setFields({
      //   document_file: {
      //     ...lo_formValue,
      //       errors: [new Error('You can only upload PDF and DOC files!')]
      //   }
      // });
        // message.error('You can only upload PDF and DOC files!');
        return false;
    }
    return true;
  };

  return (
    <>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Spin spinning={lb_spining}>
                  <Form 
                    form={form}
                    className="theme-form" 
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmitForm}
                  >
                    <Row>
                      <Col sm="6">
                          <Form.Item
                              label="Donor Agencies Name"
                              name="client_name"
                              rules={[{ required: true,  message: "Please input  Donor Agencies Name!"}]}
                          >
                              <Input placeholder="Donor Agencies name *" enterButton />
                          </Form.Item>
                      </Col>
                      <Col sm="6">
                          <Form.Item
                              label="Donor Agencies Short Name"
                              name="short_name"
                              rules={[{ required: true,  message: "Please input  Donor Agencies Short Name!"}]}
                          >
                              <Input placeholder="Donor Agencies Short name *" enterButton />
                          </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                    <Col sm='6'>
                      <Form.Item
                                label="Upload Logo"
                                name="client_logo"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                              rules={[{ required: true,  message: "Please input  Donor Agencies Logo!"}]}

                            >
                                {/* <Dragger {...{name: 'file', multiple: true,}} style={{backgroundColor:'#8fb7ee3d'}}> */}
                                <Dragger beforeUpload={() => false} name="file" multiple={false} maxCount={1}
                                    // onChange={handleFileUpload} 
                                style={{backgroundColor:'#8fb7ee3d'}}>
                                    <p className="ant-upload-drag-icon">
                                    <CloudUploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Drag and Drop your file here</p>
                                    <p className="ant-upload-hint">Or you can Browse file. </p>
                                </Dragger>
                            </Form.Item>
                      </Col>
                      <Col sm="6">
                      <Form.Item
                              label="Priority"
                              name="priority"
                              rules={[{ required: true,  message: "Please input Priority!"}]}
                          >
                              <Select placeholder="Select Priority"
                                // onChange={(value, event) => {getDocumentSubCategoryData(event)}}
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {/* {la_documentCategory?.map(rowValue => ( */}
                                  
                                    <Select.Option value={'Yes'} 
                                    // subcategory={rowValue?.children || []}
                                    >
                                        Yes
                                    </Select.Option>
                               <Select.Option value={'No'} 
                                    // subcategory={rowValue?.children || []}
                                    >
                                        No
                                    </Select.Option>
                                {/* ))} */}
                              </Select>
                            
                          </Form.Item>
                      </Col>
                     
                    </Row>
                    <Row>
                      <Col>
                        {ls_updateId ? <button onClick={() => resetFormData()} class="btn btn-outline-info btn-sm" type="button"><PlusOutlined /> Add New</button> : <></>}
                          <Button 
                            style={{float:"right"}} 
                            color="success" 
                            // className="me-3"
                            loading={lb_buttonLoader}
                            type='primary'
                            htmlType='submit'
                          >
                            {ls_updateId =='' ? 'Add' : 'Update'}
                          </Button>
                      </Col>
                    </Row> 
                  </Form>
                </Spin>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </>
  )
}

export default AddSectorForm