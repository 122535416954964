import React, { Fragment, useEffect, useState } from 'react'
import './style.css'
import Breadcrumb from '../../../components/common/breadcrumb'
import { Bookmark, DownloadCloud, Eye, Share2 } from 'react-feather'
import { Col } from 'reactstrap'
import { Chart, ArcElement, DoughnutController, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // import the plugin
import CustomChart from './customChart';
import { useSelector } from 'react-redux';
import { getRequestApiByToken } from '../../../api';
import HeaderSidebarComponent from './HeaderSidebarComponent'
import CountCard from './CountCard'
import ManageDocument from './ManageDocument'
import UserCard from './UserCard'
import DownloadAndViewCard from './DownloadAndViewCard'
import QuickAccesCard from './QuickAccesCard'
import Communication from './Communication'
import RecentActivities from './RecentActivities'

Chart.register(ArcElement, DoughnutController, Legend, Tooltip, ChartDataLabels); // register the plugin

const UserDashboard = () => {
    const ls_token = useSelector(content => content.commonReducer.token);
    const ls_userId = useSelector(content => content.commonReducer.user_id);
    const [lo_userDetails, setUserDetails] = useState({})
    const [li_view, setView] = useState(0)
    const [li_download, setDownload] = useState(0)
    const [lb_default, setDefault] = useState(true)
    const [li_bookmaredCount, setBookmaredCount] = useState(0)

    useEffect(() => {
      getuserinfo()
      setDefault(true)
      getBookMaredDocumentList()
    }, [ls_token,ls_userId])

    const getuserinfo = async () => {
       let res = await getRequestApiByToken(`userdetails/${ls_userId}`)
       setUserDetails(res?.User)
       setView(res?.User?.download_limit?.daily_view)
       setDownload(res?.User?.download_limit?.daily_download)
    }
    const getBookMaredDocumentList = async () => {
        // setLoading(true)
          const res = await getRequestApiByToken(`bookmarkdocumentlist`
        //   ?search=${filterText}&orderBy=desc&page=${currentPage}
          )
          setBookmaredCount(res?.data?.total);
        // setLoading(false)
    }

    const handleOnClick =(type='')=>{
     setDefault(false)
     if (type == 'Daily') {
        setView(lo_userDetails?.download_limit?.daily_view)
        setDownload(lo_userDetails?.download_limit?.daily_download)
     } else if(type == 'Weakly'){
        setView(lo_userDetails?.download_limit?.weekly_view)
        setDownload(lo_userDetails?.download_limit?.weekly_download)

     } else if(type == 'Monthly'){
        setView(lo_userDetails?.download_limit?.monthly_view)
        setDownload(lo_userDetails?.download_limit?.monthly_download)
     }
    }

  return (
    <Fragment>
        <Breadcrumb parent="My Dashboard" title="" sideComponent={<HeaderSidebarComponent onPress={handleOnClick} lb_default={lb_default}/>} 
        />
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-3 xl-50 col-sm-6">
                    <CountCard
                     icon={<DownloadCloud />}
                     title={'DOWNLOADS'}
                     count={li_download}
                    />
                </div>
                <div className="col-xl-3 xl-50 col-sm-6">
                    <CountCard
                     icon={<Eye />}
                     title={'VIEWS'}
                     count={li_view}
                    />
                </div>
                <div className="col-xl-3 xl-50 col-sm-6">
                    <CountCard
                     icon={<Share2 />}
                     title={'SHARED WITH ME'}
                     count={893}
                    />
                </div>
                <div className="col-xl-3 xl-50 col-sm-6">
                    <CountCard
                     icon={<Bookmark />}
                     title={'FAVOURITE'}
                     count={li_bookmaredCount}
                    />
                </div>
                <div className="col-xl-4 xl-100">
                   <ManageDocument/>
                </div>
                <div className="col-xl-4 xl-100">
                    <UserCard lo_userDetails={lo_userDetails}/>
                </div>
                <div className="col-xl-4 xl-100">
                    <DownloadAndViewCard />
                </div>
                <div className="col-xl-12 xl-100">
                    <CustomChart/>
                </div>
                <Col md="24" lg="24" xl="24" className="box-col-24" >
                    <QuickAccesCard/>
                </Col>
                <div className="col-xl-6 xl-100">
                   <Communication/>
                </div>

                <div className="col-xl-6 xl-100">
                  <RecentActivities/>
                </div>
                    
            </div>
        </div>
    </Fragment>
  )
}

export default UserDashboard