import React, { useEffect, useState } from 'react'
import "./style.css"
import DataTable from 'react-data-table-component'
import { Table, Input, Checkbox, Row, Col, Badge, Tag, Card, Button, Switch } from 'antd';
import { CardFooter, CardHeader, FormGroup, Media } from 'reactstrap';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { getRequestApiByToken, postRequestApiByToken } from '../../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import defaultUserImg from "../../../../assets/images/user/demouser.png"
import { message } from 'antd/lib';

const { Search } = Input;

const UserList = ({ls_actionType , handleSetDataAction}) => {
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const [la_userList, setUserList] = useState([])
    const [la_columnsList, setColumnsLis] = useState([])
    // const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [ls_switchLoading, setSwitchLoading] = useState(null);

    const getDeviceTypeexistingDetails = (item) => {
        const lo_deviceTypeDetails = { 'mobile':0, 'desktop':0}
        const la_deviceIds = item?.device_id || [];
        for (let i = 0; i < la_deviceIds.length; i++) {
            const rowValue = la_deviceIds[i];
            if (rowValue?.type == "Mobile") {
                lo_deviceTypeDetails['mobile'] += 1;
            } else if (rowValue?.type == "Desktop") {
                lo_deviceTypeDetails['desktop'] += 1;
            }
        }
        return lo_deviceTypeDetails;
    }
    const getDeviceTypeComponent = (item) => {
        const lo_result = getDeviceTypeexistingDetails(item);
        return (<div>
            <Row justify={"space-between"}>
                <Col span={12}>
                    {(lo_result?.mobile > 0) ? <span><i className="fa fa-mobile" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i></span> : <></>}
                </Col>
                <Col span={12}>
                    {(lo_result?.desktop > 0) ? <span><i className="fa fa-laptop" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i></span> : <></>}
                </Col>
            </Row>
        </div>)
    }
    const columns = [
        {
            name: "Name",
            selector: (row) => (<div>
                <img style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src={defaultUserImg} alt="" />
                <span>{row?.name}</span>
            </div>),
            sortable: true,
            // center: true,
        },
        {
            selector: (row) => row?.email,
            name: "Email",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => row?.info?.designation,
            name: "Designation",
            sortable: true,
            center: true,
        },
        {
            selector: (row) => row?.info?.mobile_no || '',
            name: "Mobile",
            // sortable: true,
            center: true,
            // width: '140px'
        },
        
        // {
        //     selector: (row) => row?.info?.joining_date ? moment(row?.info?.joining_date).format('DD MMM YY') : '-',
        //     name: "Joining Date",
        //     sortable: true,
        //     center: true,
        // },
        // {
        //     selector: (row) => (row?.info?.gender == 1) ? "Male" : (row?.info?.gender == 2) ? "Female" : "-",
        //     name: "Gender",
        //     sortable: true,
        //     center: true,
        // },
        // {
        //     selector: (row) => (row?.download_limit?.daily_download || 0)+" / D",
        //     name: "Download",
        //     //   sortable: true,
        //     center: true,
        // },
        // {
        //     selector: (row) => (row?.download_limit?.daily_view || 0)+" / D",
        //     name: "View",
        //     //   sortable: true,
        //     center: true,
        // },
        // {
        //     selector: (row) => (getDeviceTypeComponent(row)),
        //     name: "Device",
        //     //   sortable: true,
        //     center: true,
        // },
        
        {
            selector: (row) => <div>
                <Switch loading={ls_switchLoading == row?.id} defaultChecked={row.status} size='small' onChange={(s) => onChangeStatus(s, row)} />
            </div>,
            name: "Action",
            //   sortable: true,
            center: true,
            width: '80px'
         },
    ];

    const onChangeStatus = async (checked, row) => {
        setSwitchLoading(row?.id || null);
        let lo_formData = { status: (checked == true ? 1 : 0), user_id: row.id }
        const res = await postRequestApiByToken('user_status_update', lo_formData)
        setSwitchLoading(null);
        if (res?.status == "Success") {
            getUserList();
            message.success(`${row?.status == 1 ? 'Incativated' : 'Activated'} Successfully!`);
        }
    };

    // const data = [
    //     {
    //         name: (
    //             <div>
    //                 <img style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" />
    //                 <span>Lina Sarkar</span>
    //             </div>
    //         ),
    //         designation: 'Health, Nutrition, and Wash',
    //         date: "15 May 2022",
    //         gender: "Female",
    //         download: '5 / D',
    //         view: '50 / D',
    //         device: (
    //             <div>
    //                 <span>
    //                     <i className="fa fa-mobile" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    //                 </span>
    //             </div>
    //         ),
    //     },
    //     {
    //         name: (
    //             <div>
    //                 <img className="rounded-circle" style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src='https://react.pixelstrap.com/endless/static/media/6.d3662a71303b88382b60.jpg' alt="" />
    //                 <span>Ashak Roy</span>
    //             </div>
    //         ),
    //         designation: 'Health, Nutrition, and Wash',
    //         date: "13 May 2022",
    //         gender: "Male",
    //         download: '13 / D',
    //         view: '130 / D',
    //         device: (
    //             <div>
    //                 <span>
    //                     <i className="fa fa-mobile" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    //                 </span>
    //                 <span>
    //                     <i className="fa fa-laptop" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
    //                 </span>
    //             </div>
    //         ),
    //     },
    //     // Add more data entries below:
    //     {
    //         name: (
    //             <div>
    //                 <img className="rounded-circle" style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src='https://react.pixelstrap.com/endless/static/media/designer.d353428cfbb704348e1f.jpg' alt="" />
    //                 <span>Kalpana Mathur</span>
    //             </div>
    //         ),
    //         designation: 'Economic development',
    //         date: "13 May 2022",
    //         gender: "Female",
    //         download: '35 / D',
    //         view: '1050 / D',
    //         device: (
    //             <div>
    //                 <span>
    //                     <i className="fa fa-laptop" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
    //                 </span>
    //             </div>
    //         ),
    //     },
    //     {
    //         name: (
    //             <div>
    //                 <img className="rounded-circle" style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src='	https://react.pixelstrap.com/endless/static/media/7.e30fc62d991f11de1ef0.jpg' alt="" />
    //                 <span>Sumit kr Jana</span>
    //             </div>
    //         ),
    //         designation: 'Health, Nutrition, and Wash',
    //         date: "18 April 2022",
    //         gender: "Male",
    //         download: '7 / D',
    //         view: '30 / D',
    //         device: (
    //             <div>
    //                 <span>
    //                     <i className="fa fa-mobile" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    //                 </span>
    //                 <span>
    //                     <i className="fa fa-laptop" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
    //                 </span>
    //             </div>
    //         ),
    //     },
    //     {
    //         name: (
    //             <div>
    //                 <img style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" />
    //                 <span>Lina Sarkar</span>
    //             </div>
    //         ),
    //         designation: 'Health, Nutrition, and Wash',
    //         date: "18 April 2022",
    //         gender: "Female",
    //         download: '16 / D',
    //         view: '150 / D',
    //         device: (
    //             <div>
    //                 <span>
    //                     <i className="fa fa-mobile" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    //                 </span>

    //             </div>
    //         ),
    //     },
    //     {
    //         name: (
    //             <div>
    //                 <img className="rounded-circle" style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src='https://react.pixelstrap.com/endless/static/media/6.d3662a71303b88382b60.jpg' alt="" />
    //                 <span>Ashak Roy</span>
    //             </div>
    //         ),
    //         designation: 'Economic Development',
    //         date: "21 Apr 2022",
    //         gender: "Male",
    //         download: '50 / D',
    //         view: '1250 / D',
    //         device: (
    //             <div>
    //                 <span>
    //                     <i className="fa fa-mobile" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    //                 </span>
    //             </div>
    //         ),
    //     },
    //     {
    //         name: (
    //             <div>
    //                 <img className="rounded-circle" style={{ borderRadius: 50, height: 30, width: 30, marginInlineEnd: 7 }} src='https://react.pixelstrap.com/endless/static/media/designer.d353428cfbb704348e1f.jpg' alt="" />
    //                 <span>Kalpana Mathur</span>
    //             </div>
    //         ),
    //         designation: 'Health, Nutrition, and Wash',
    //         date: "10 March 2022",
    //         gender: "Female",
    //         download: '16 / D',
    //         view: '100 / D',
    //         device: (
    //             <div>
    //                 <span>
    //                     <i className="fa fa-mobile" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    //                 </span>
    //                 <span>
    //                     <i className="fa fa-laptop" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
    //                 </span>
    //             </div>
    //         ),
    //     },
    // ];

    const handleSearch = debounce((value) => {
        setFilterText(value);
    }, 300);

    const getUserList = async () => {
        setLoading(true);
        const res = await getRequestApiByToken(`userdetails?search=${filterText}&pagination=true&orderBy=desc&page=${currentPage}`);
        setUserList(res?.Users?.data);
        setTotalCount(res?.Users?.total);
        // setUserList(res?.Users || []);
        setLoading(false);
    }

    useEffect(() => {
        getUserList()
    }, [filterText, currentPage])

    useEffect(() => {
        const columnList = [...columns];
        // if (ls_userType == 'user') columnList.pop();
        setColumnsLis(columnList);
    }, [ls_userType])

    useEffect(() => {
        if (ls_actionType == "get_user_access_list") {
            getUserList()
            handleSetDataAction()
        }
    }, [ls_actionType])

    useEffect(() => {
      console.log('la_userList--',la_userList);
    }, [la_userList])
    

  return (
      <div>
          <div className="card parent-tblsecuser">
                {/* <div className="card-header card-header-border">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={3} style={{ padding: 5, display: 'flex', justifyContent: 'flex-start' }}>
                            <h5>{"Users"}</h5>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={21} className='user-table-header-main-view'>
                            <Row>
                                <div className='user-table-header-content-view'>
                                    <Badge count={5} color='lime' className='tag-style'>
                                        <Tag color="geekblue" closeIcon onClose={() => { }}>Assesment Report</Tag>
                                    </Badge>
                                    <Badge count={5} color='lime' className='tag-style'>
                                        <Tag color="geekblue" closeIcon onClose={() => { }}>Financial Budget</Tag>
                                    </Badge>
                                    <Badge count={5} color='lime'>
                                        <Tag color="geekblue" closeIcon onClose={() => { }}>Presentation</Tag>
                                    </Badge>
                                </div>
                                <div className="pull-right right-header">
                                    <div className="onhover-dropdown">
                                        <button className="btn btn-primary btn-pill" type="button">
                                            {'2024'}{" "}
                                            <span className="pe-0">
                                                <i className="fa fa-angle-down"></i>
                                            </span>
                                        </button>
                                        <div className="onhover-show-div right-header-dropdown">
                                            <a className="d-block" href="#javascript">
                                                {'2024'}
                                            </a>
                                            <a className="d-block" href="#javascript">
                                                {'2023'}
                                            </a>
                                            <a className="d-block" href="#javascript">
                                                {'2022'}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div> */}
            
                <div className="card-header card-header-border">
                    <div className="row" style={{alignItems:'center'}}>
                        <div className="col-sm-7">
                            <h5>{"User List"}</h5>
                        </div>
                        <div className="col-sm-5">
                            <div className="pull-right right-header">
                            <div className="onhover-dropdown">
                            {/* <Search value={filterText} onChange={handleSearch} placeholder="Serach here..." /> */}
                            <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        {/* <DataTable
                            columns={la_columnsList}
                            data={la_userList}
                            striped={true}
                            center={true}
                            persistTableHead
                            pagination
                        /> */}
                        <DataTable
                            columns={la_columnsList}
                            data={la_userList}
                            striped={true}
                            center={true}
                            persistTableHead
                            pagination
                            paginationServer
                            paginationTotalRows={totalCount}
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            onChangePage={setCurrentPage}
                            progressPending={loading}
                            progressComponent={<div><LoadingOutlined /></div>}
                            customStyles={{
                                headCells: {
                                    style: {
                                        paddingLeft: '8px', // Adjust cell padding as needed
                                        paddingRight: '8px',
                                    },
                                },
                                rows: {
                                    style: {
                                        minHeight: '40px', // Adjust row height as needed

                                    },
                                },
                                cells: {
                                    style: {
                                        paddingLeft: '8px', // Adjust cell padding as needed
                                        paddingRight: '8px', // Adjust cell padding as needed

                                    },
                                },
                            }}
                        />
                    </div>
                </div>
          </div>

      </div>
  )
}

export default UserList