import React, { useState, useEffect } from "react";
// import UserAuthentication from "./UserAuthentication";
// import UserLogin from "./UserLogin";
import { Outlet } from "react-router-dom";

const UserAndAdminLogin = ({header=''}) => {
  const [ls_locationPath, setLocationPath] = useState('');

//   // Default to using the first account if no account is active on page load
// if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   if(pca.getActiveAccount() && pca.getActiveAccount()[0]) pca.setActiveAccount(pca.getActiveAccount()[0]);
// }

// // Listen for sign-in event and set active account
// pca.addEventCallback((event) => {
//   console.log('--event',event);
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//       const account = event.payload.account;
//       console.log('---account--------------------',account);
//       pca.setActiveAccount(account);
//   }
// });

  // useEffect(() => {
  //   if (window?.location?.pathname) {
  //     const la_urlPath = window.location.pathname.split('/');
  //     const ls_currentPath = la_urlPath.pop();
  //     setLocationPath(ls_currentPath)
  //   } else {
  //     setLocationPath('');
  //   }
  // }, [window?.location?.pathname])
  
  return (
    <div>
      
        {/* { ls_locationPath ? ls_locationPath === 'login' ? <UserLogin/> : <UserAuthentication/> } */}
        <Outlet />
      
    </div>
  );
};

export default UserAndAdminLogin;
