import React, { useEffect, useState } from 'react';
import './style.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import localurl from '../../../src/assets/dummy.pdf';

const CustomViewer = ({ls_url}) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [isViewerReady, setIsViewerReady] = useState(false);

    useEffect(() => {
        // Wait for the viewer to be ready before manipulating the toolbar
        if (isViewerReady) {
            const toolbarRight = document.querySelector('.rpv-toolbar__right');
            // if (toolbarRight) {
            //     [3, 4, 5, 6].forEach((index) => {
            //         const child = toolbarRight.children[index - 1];
            //         console.log('--child',child, index - 1);
            //         if (child) {
            //             toolbarRight.removeChild(child);
            //         }
            //     });
            // }

            if (toolbarRight) {
                // Remove the 3rd, 4th, 5th, and 6th children elements
                const indicesToRemove = [0, 1, 2, 3, 4, 5]; // Indices are 0-based; adjust accordingly
                for (let i = indicesToRemove.length - 1; i >= 0; i--) { // Iterate in reverse to avoid indexing issues
                    const index = indicesToRemove[i];
                    if (index == 0) { // for theme
                        const switchThemeElement = toolbarRight?.children[index];
                        if (switchThemeElement) {
                            const themeSwitchButton = switchThemeElement.querySelector('button[aria-label="Switch to the dark theme"]');
                            if (themeSwitchButton) {
                                themeSwitchButton.click();
                            }
                        }
                    } else if (index == 1) { // for full screen
                        // do nothing
                    } else if (toolbarRight.children[index]) {
                        toolbarRight.removeChild(toolbarRight.children[index]);
                    }
                }
            }
        }
    }, [isViewerReady]);

    return (
        <div className='pdfviewer-prntdiv'
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '750px',
            }}
        >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={ls_url}
                    plugins={[defaultLayoutPluginInstance]}
                    onDocumentLoad={() => setIsViewerReady(true)}
                />
            </Worker>
        </div>
    );
};

export default CustomViewer;