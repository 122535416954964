import React from 'react'
import { Progress } from 'reactstrap'

const DownloadAndViewCard = () => {
  return (
    <div className="card">
        <div className="card-body progress-showcase row progress-dwnlimit">
            <div className="col">
                <div className="text-center">
                    <h5>{'Download Limit'}</h5>
                </div>
                <div className="text-center pgress-space">{"30 of 60"}</div>
                <Progress multi className="lg-progress-bar">
                    <Progress bar value="5" max={55}>5</Progress>
                    <Progress bar color="success" value="15" max={55}>{"15"}</Progress>
                    <Progress bar color="warning" value="10" max={55}>{"10"}</Progress>
                    <Progress bar color="danger" value="10" max={55}>{"10"}</Progress>
                </Progress>
                <div className="text-center pgress-space">
                {/* <button className="btn btn-outline-info btn-xs" type="button">{'Request'}</button> */}
                <button disabled className="btn btn-pill btn-outline-light-2x btn-air-light txt-dark" type="button">Request</button>
                </div>
            </div>
        </div>
        <div style={{border:"1px solid #f0f3f6"}}></div>
        <div className="card-body progress-showcase row progress-dwnlimit">
            <div className="col">
                <div className="text-center">
                    <h5>{'View Limit'}</h5>
                </div>
                <div className="text-center pgress-space">{"40 of 55"}</div>
                <Progress multi className="lg-progress-bar">
                    <Progress bar value="5" max={55}>5</Progress>
                    <Progress bar color="success" value="15" max={55}>{"15"}</Progress>
                    <Progress bar color="warning" value="10" max={55}>{"10"}</Progress>
                    <Progress bar color="danger" value="10" max={55}>{"10"}</Progress>
                </Progress>
                <div className="text-center pgress-space">
                {/* <button className="btn btn-outline-info btn-xs" type="button">{'Request'}</button> */}
                <button disabled className="btn btn-pill btn-outline-light-2x btn-air-light txt-dark" type="button">Request</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DownloadAndViewCard