import React, { useEffect, useState } from 'react'
import { getRequestApiByToken } from '../../../api';
import Breadcrumb from '../../../components/common/breadcrumb'
import DocumentListing from '../DocumentListing';

const ShareDocument = () => {
    const [la_documentTypes, setDocumentTypes] = useState([])
    const [lb_loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [changePage, setChangePage] = useState(false);
    const [la_documentList, setDocumentList] = useState([])

    const getSearchData = async () => {
        setLoading(true);
        // const res = await getRequestApiByToken(`document?orderBy=asc&pagination=false&${ls_params}`);
        const res = await getRequestApiByToken(`getsharedocument`);
        console.log('response====>>>',res?.data?.data);
        setDocumentList(res?.data?.data)
        setLoading(false)
    }

   useEffect(() => {
    getSearchData()
   }, [])

   const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
   };

  return (
    <>
    <Breadcrumb parent="Shared With Me" title=""  />
    <div className="col-xl-12">
            <DocumentListing 
            {...{ lb_showDocTypeTag:false, 
            la_documentTypes, 
            lb_loading, 
            handlePaginationChange,
            totalCount, 
            la_documentList, 
            getSearchData, 
            setLoading ,
            ls_headerText:'Shared With Me',
            la_actionAceess:['view']
            }}/>
   </div>
    </>
  )
}

export default ShareDocument