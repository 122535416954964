import { Cascader, Form, Input, Select, Upload,Button, Spin, message, Radio, Space, DatePicker, Tooltip} from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row} from 'reactstrap'
import { CloudUploadOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getRequestApiByToken } from '../../../../api';
import { DateFormat } from '../../../../constant/globalConfig';
import TextArea from 'antd/es/input/TextArea';
import { debounce } from 'lodash';
import { AcceptDocFileTypes, MaxFileSize } from '../../../../constant/basicConstant';
const { Dragger } = Upload;

const AddDocumentForm = ({handleSubmitForm=()=>{},lb_buttonLoader=false,ls_updateId='',form, resetFormData, lb_spining=false, lo_updatRecord={},status=''}) => {
  const [la_sectorData, setSectorData] = useState([])
  const [la_practices, setPractices] = useState([])
  const [la_country, setCountry] = useState([])
  const [la_projectList, setProjectList] = useState([])
  const [la_groupData, setGroupData] = useState([])
  const [la_documentCategory, setDocumentCategory] = useState([])
  const [la_documentSubCategory, setDocumentSubCategory] = useState([])
  // const { getFieldInstance } = form;
  const ls_documentCatId = Form.useWatch('parent_cat_id', form);
  const ls_child_cat_id = Form.useWatch('child_cat_id', form);
  useEffect(() => {
    const selectedOption = la_documentCategory.find(option => option?.id === ls_documentCatId);
    console.log('---ls_documentCatId',ls_documentCatId, selectedOption);
    getDocumentSubCategoryData(selectedOption)
  }, [ls_documentCatId, la_documentCategory])

  // useEffect(() => {
  //   if (ls_child_cat_id && la_documentSubCategory?.length) {
  //     console.log('-ls_child_cat_id',typeof ls_child_cat_id);
  //     form.setFieldsValue({ 'child_cat_id': parseInt(ls_child_cat_id)})
  //   }
  // }, [la_documentSubCategory])
  
  
//   useEffect(() => {
//     const unsubscribe = form.subscribe(({ name, changedFields }) => {
//         console.log('Field changed:', name);
//         console.log('Changed field values:', changedFields);
//     });

//     return () => {
//         unsubscribe();
//     };
// }, [form]);

  const getSectorData = async () => {
    const res = await getRequestApiByToken('sector_master')
    const la_options=[]
    res?.sector_master?.map((v)=>{
      // if(v?.status == 1) la_options.push({label: v?.sector_name,value: v?.id});
      if (v?.status == 1) {
        la_options.push({label: v?.sector_name,value: v?.id});
      } else if (ls_updateId && lo_updatRecord?.sector_id?.includes(v?.id)) {
        la_options.push({label: v?.sector_name,value: v?.id});
      }
    })
    setSectorData(la_options)
 }

 const getPracticeData = async () => {
  const res = await getRequestApiByToken('practice')
  const la_options=[]
  res?.practices?.map((v)=>{
    // if(v?.status == 1) la_options.push({label: v?.practice_name,value: v?.id})
    if (v?.status == 1) {
      la_options.push({label: v?.practice_name,value: v?.id});
    } else if (ls_updateId && lo_updatRecord?.parctices?.includes(v?.id)) {
      la_options.push({label: v?.practice_name,value: v?.id});
    }
  })
  setPractices(la_options)
 }

 const getCountryData = async () => {
  const res = await getRequestApiByToken('country_master')
  const la_options=[]
  res?.country_master?.map((v)=>{
    if(v?.status == 1) la_options.push({label: v?.country_name,value: v?.id})
  })
  setCountry(la_options)
 }
 const getGroupData = async () => {
  const res = await getRequestApiByToken('group_master')
  const la_options=[]
  res?.group_master?.map((v)=>{
    // if(v?.status == 1) la_options.push({label: v?.group_name,value: v?.id})
    if (v?.status == 1) {
      la_options.push({label: v?.group_name,value: v?.id});
    } else if (ls_updateId && lo_updatRecord?.group_id?.includes(v?.id)) {
      la_options.push({label: v?.group_name,value: v?.id});
    }
  })
  setGroupData(la_options)
 }
 
 const [lb_projectLoading, setProjectLoading] = useState(false);
 const getProjectList = async (filterText) => {
  setProjectLoading(true)
  // const res = await getRequestApiByToken('project?pagination=false')
  if (filterText) {
    const res = await getRequestApiByToken(`project?search=${filterText}&pagination=false`);
    const la_newResult = res?.Projects?.data?.filter((rowValue) => (rowValue?.status == 1));
    setProjectList(la_newResult || []);
  }
  // const la_options=[]
  // res?.Projects?.data?.map((v)=>la_options.push({label: v?.project_name,value: v?.id}))
  setProjectLoading(false);
}

const getDocumentCategoryData = async () => {
  const res = await getRequestApiByToken('docuemt_category');
  console.log('res?.document_category',res?.document_category);
  const la_docCatArr = res?.document_category?.filter((rowValue) => {
    if (rowValue?.parent_id == 0 && rowValue?.status == 1) {
      return true;
    } else if (ls_updateId && rowValue?.id == lo_updatRecord?.parent_cat_id) {
      return true;
    }
    return false;
  });
  setDocumentCategory(la_docCatArr || [])
}
const getDocumentSubCategoryData = async (item) => {
  const la_subcatArr = item?.children?.filter((rowValue) => {
    if (rowValue?.status == 1) {
      return true;
    } else if (ls_updateId && rowValue?.id == lo_updatRecord?.child_cat_id) {
      return true;
    }
    return false;
  });
  setDocumentSubCategory(la_subcatArr || [])
}

// const beforeUpload = (file) => {
//   const fileType = file.type;
//   const fileExtension = fileType.split('/')[1];
//   const isValidFileType = AcceptDocFileTypes.includes(fileExtension);

//   if (!isValidFileType) {
//       message.error('You can only upload PDF and DOC files!');
//   }

//   return isValidFileType;
// };

  const beforeUpload = (file) => {
    const fileType = file.type;

    if (!AcceptDocFileTypes.includes(fileType)) {
      form.setFieldsValue({ 'document_file': [] })
      form.setFields([{ name: 'document_file', errors: ['File type not matched!'] }]);
      message.error('File type not matched!');
      return Upload.LIST_IGNORE;
    }

    if (file.size > MaxFileSize) {
      form.setFieldsValue({ 'document_file': [] });
      form.setFields([{ name: 'document_file', errors: ['File size exceeds the 100MB limit!'] }]);
      message.error('File size exceeds the 100MB limit!');
      return Upload.LIST_IGNORE; // Prevent file from being uploaded
    }

    // Clear errors if validation passes
    form.setFields([{ name: 'document_file', errors: [] }]);

    return false;
  };

 useEffect(() => {
  console.log('---lo_updatRecord---',lo_updatRecord);
  getSectorData()
  getPracticeData()
  getCountryData()
  getProjectList()
  getGroupData()
  getDocumentCategoryData()
 }, [JSON.stringify(lo_updatRecord)])

 useEffect(() => {
   if (ls_updateId && lo_updatRecord?.project) {
      setProjectList([lo_updatRecord?.project]);
   }
 }, [JSON.stringify(lo_updatRecord), ls_updateId])
 

  return (
    <>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Spin spinning={lb_spining}>
                  <Form 
                    form={form}
                    className="theme-form" 
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmitForm}
                  >
              
                    <Row>
                      <Col sm="6">
                          <Form.Item
                              label="Document Name"
                              name="title"
                              rules={[{ required: true,  message: "Please input Document Name!"}]}
                          >
                              <Input placeholder="Document name" enterButton />
                          </Form.Item>
                      </Col>
                      <Col sm="6">
                          <Form.Item
                              label="Document created by (name of firm)"
                              name="author"
                              rules={[{ required: true,  message: "Please input Document created by!"}]}
                          >
                              <Input placeholder="Document created by" enterButton />
                          </Form.Item>
                      </Col>
                      <Col sm="6">
                        <Form.Item
                            label="Version"
                            name='version'
                            rules={[ { required: true, message: "Please input Version!" } ]}
                        >
                            <Radio.Group 
                              // optionType="button" buttonStyle="solid"
                            >
                                <Space size={40} align='baseline'>
                                    <Radio value="draft">Draft</Radio>
                                    <Radio value="final">Final</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col sm="6">
                          <Form.Item
                              label="Document created on"
                              name="document_created_date"
                              rules={[{ required: true,  message: "Please input Document created on!"}]}
                          >
                              <DatePicker format={DateFormat} style={{width:'100%'}} needConfirm={false} />
                          </Form.Item>
                      </Col>
                      {/* <Col sm="6">
                          <Form.Item
                              label="Project Name"
                              name="project_id"
                              rules={[{ required: true,  message: "Please input Project Name!"}]}
                          >
                             

                              <Select
                                placeholder="Select Project Name"
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={true}
                                filterOption={false}
                                onSearch={debounce((value) => { getProjectList(value) }, 500)}
                                notFoundContent={lb_projectLoading ? <Spin size="small" /> : null}
                                allowClear
                                style={{ width: '100%' }}
                              >
                                {
                                    la_projectList.map((rowVal, indx) => <Select.Option key={rowVal.id} value={rowVal.id}>{rowVal.project_name}</Select.Option>)
                                }
                              </Select>
                            
                          </Form.Item>
                      </Col> */}
                      
                  
                      <Col sm="6">
                          <Form.Item
                              label="Sectors"
                              name="sector_id"
                              rules={[{ required: true,  message: "Please input Sector!"}]}
                          >
                            <Select placeholder="Select Sector"
                                  mode='multiple'
                                  allowClear showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                      option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                      optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                  }
                              >
                                  {la_sectorData.map(optionValue => (
                                      <Select.Option key={optionValue.value} value={optionValue.value}>{optionValue.label}</Select.Option>
                                  ))}
                              </Select>
                          </Form.Item>
                      </Col>

                      <Col sm="6">
                        <Form.Item
                              label="Practices"
                              name="parctices"
                              rules={[{ required: true,  message: "Please input Practices!"}]}
                          >
                              <Select placeholder="Select Practices"
                                    mode='multiple'
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {la_practices.map(optionValue => (
                                        <Select.Option key={optionValue.value} value={optionValue.value}>{optionValue.label}</Select.Option>
                                    ))}
                                </Select>
                          </Form.Item>
                      </Col>
                      <Col sm="6">
                          <Form.Item
                              label="Document Category"
                              name="parent_cat_id"
                              rules={[{ required: true,  message: "Please input Document Category!"}]}
                          >
                              <Select placeholder="Select Document Category"
                                onChange={(value, event) => {form.setFieldsValue({ 'child_cat_id': null})}}
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {la_documentCategory?.map(rowValue => (
                                    <Select.Option key={rowValue.id} value={rowValue.id} subcategory={rowValue?.children || []}>
                                        {rowValue.category_name}
                                    </Select.Option>
                                ))}
                              </Select>
                            
                          </Form.Item>
                      </Col>
                      <Col sm="6">
                        <Form.Item
                          label="Document Sub Category"
                          name="child_cat_id"
                          rules={[{ required: (la_documentSubCategory?.length > 0), message: "Please input Document Sub Category!" }]}
                        >
                          <Select placeholder="Select Document Category"
                            allowClear showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {la_documentSubCategory?.map(rowValue => (
                              <Select.Option key={rowValue.id} value={rowValue.id}>
                                {rowValue.category_name}
                              </Select.Option>
                            ))}
                          </Select>

                        </Form.Item>
                      </Col>
                      
                   
                      {/* <Col sm="6">
                        <Form.Item
                              label="Country"
                              name="Country"
                              rules={[{ required: true,  message: "Please input Country!"}]}
                          >
                              <Cascader
                                  placeholder="Select Country"
                                  style={{
                                  width: '100%',
                                  }}
                                  options={la_country}
                                  multiple
                                  maxTagCount="responsive"
                              />
                              
                          </Form.Item>
                      </Col> */}
                      <Col sm="6">
                          <Form.Item
                              label="Groups"
                              name="group_id"
                              rules={[{ required: true,  message: "Please input Group!"}]}
                          >
                              <Select placeholder="Select Group"
                                mode='multiple'
                                allowClear showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {la_groupData.map(optionValue => (
                                  <Select.Option key={optionValue.value} value={optionValue.value}>{optionValue.label}</Select.Option>
                                ))}
                              </Select>
                          </Form.Item>
                      </Col>
                      <Col sm="6">
                        <Form.Item
                              label="Tag"
                              name="key_words"
                              rules={[{ required: true,  message: "Please input Tag!"}]}
                          >
                            <Select
                              mode="tags"
                              style={{ width: '100%' }}
                              placeholder="Tags Mode"
                              // onChange={handleChange}
                              tokenSeparators={[',']}
                              options={[]}
                            />
                          </Form.Item>
                      </Col>
                      <Col sm="6">
                        <Form.Item
                          label="Description"
                          name="description"
                          // rules={[{ required: true,  message: "Please input Description!"}]}
                          rules={[
                            { required: true, message: "Please input Description!" },
                            { min: 100, message: "Description must be at least 100 characters long!" }
                          ]}
                        >
                          <TextArea minLength={100} rows={7} placeholder={'Description'}/>
                        </Form.Item>
                      </Col>

                   
                      <Col sm="6">
                        <Form.Item
                          label={
                            <span>
                              File (Max File Size Allowed - 100MB) <Tooltip placement="top" title="Allowed file types: .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpeg, .png, .jpg">
                                <ExclamationCircleOutlined style={{ color: 'red', fontSize: '16px', marginLeft: '5px' }} />
                              </Tooltip>
                            </span>
                          }
                          name='document_file'
                          valuePropName="fileList"
                          getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                              return e;
                            }
                            return e && e.fileList;
                          }}
                          rules={[{ required: true, message: "Please Upload File!" }]}
                        >
                          <Dragger name="file" multiple={false} maxCount={1}
                            beforeUpload={beforeUpload}
                            style={{ backgroundColor: '#8fb7ee3d' }}>
                            <p className="ant-upload-drag-icon">
                              <CloudUploadOutlined />
                            </p>
                            <p className="ant-upload-text">Drag and Drop your file here</p>
                            <p className="ant-upload-hint">Or you can Browse file. </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {(ls_updateId && status !='mydocument')? <button onClick={() => resetFormData()} class="btn btn-outline-info btn-sm" type="button"><PlusOutlined /> Add New</button> : <></>}
                          <Button 
                            style={{float:"right"}} 
                            color="success" 
                            // className="me-3"
                            loading={lb_buttonLoader}
                            type='primary'
                            htmlType='submit'
                          >
                            {ls_updateId =='' ? 'Add' : 'Update'}
                          </Button>
                      </Col>
                    </Row> 
                  </Form>
                </Spin>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </>
  )
}

export default AddDocumentForm