import React, { useEffect, useState } from 'react'
import './style.css'
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../components/common/breadcrumb';
import { Input, message, Modal, Switch, Tag, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import moment from 'moment';
import { getCountryCode, getFileExtension, setApproverDocumentCount } from '../../../utility';
import { FileIconsObj } from '../../../constant/globalConfig';
import { FileDoneOutlined,FormOutlined } from '@ant-design/icons';
import AlertModal from './AlertModal';
import ReviewModal from './ReviewModal';
import { useDispatch } from 'react-redux';
import { ExclamationCircleFilled, StopOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CountryIcon from '../../../components/CountryIcon';
import Avatar from 'antd/lib/avatar/avatar';


const { Search } = Input;

const ApprovedProject = () =>{
  const [la_approverDocumentList, setApproverDocumentList] = useState([])
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [modalVisible, setModalVisible] = useState(false)
  const [ls_documentId, setDocumentId] = useState('')
  const [lb_buttonLoading, setButtonLoading] = useState(false)
  const [lb_reviewModalVisible, setReviewModalVisible] = useState(false)
  const dispatch = useDispatch();

  // const returnCountryIcon = (cntryNm) => {
  //   const cntryCode = getCountryCode(cntryNm);
  //   if (cntryCode) {
  //     return <Tooltip title={cntryNm}>
  //             <i className={`flag-icon flag-icon-${cntryCode.toLowerCase()}`} style={{ width: 45, fontSize: 16, padding: 5}}></i>
  //           </Tooltip>;
  //   } else {
  //     return cntryNm;
  //   }
  //  }

  const returnCountryIcon = (cntryNm) => {
    const cntryCode = getCountryCode(cntryNm);
    if (cntryCode) {
      return <Tooltip title={cntryNm}>
              <i className={`flag-icon flag-icon-${cntryCode.toLowerCase()}`} style={{ width: 45, fontSize: 16, padding: 5}}></i>
            </Tooltip>;
    } else {
      if (cntryNm) {
        return cntryNm
      } else {
        return <Avatar style={{width:45}} shape='square' icon={<StopOutlined />}/>;
      }
    }
  }

  const getCountryComponent = (item) => {
      if (item?.project?.country[0]?.country_name) {
          const ls_cntry = item?.project?.country[0]?.country_name;
          return  returnCountryIcon(ls_cntry) || '-------';
      } else if (item?.documents && item?.documents[0]?.project?.country[0]?.country_name) {
        const ls_cntry = item?.documents[0]?.project?.country[0]?.country_name;
          return  returnCountryIcon(ls_cntry) || '-------';
      } else {
          return  returnCountryIcon('');
      }
  }

  const columns = [
    {
      name: "Country",
      selector: (row) => <div>
        <span>
          { getCountryComponent(row)}
        </span>
      </div>,
      sortable: true,
      center: true,
      width: '120px', 
    },
    {
      selector: (row) => 
      <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
         {row?.title} {row?.project?.mandate_no ? `(${row.project.mandate_no})` : ''}
      </div>,
      name: "Document Name",
      sortable: true,
      // width: '310px', 
      // center: true,
    },
    {
      selector: (row) => <>{row?.modified_on ? moment(row?.modified_on).format('DD MMM YYYY') : ''}</>,
      name: "Updated On",
      sortable: true,
      center: true,
      width:'140px'
    },
    {
      selector: (row) => <>{row?.owners?.name ? row.owners.name : ''}</>,
      name: "Updated By",
      sortable: true,
      center: true,
      width:'160px'
    },

    // {
    //   selector: (row) => 
    //   <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    //   {row?.project?.sector?.map((i,inx)=>i?.sector_name)}
    //   </div>
    //   ,
    //   name: "Sector",
    //   sortable: true,
    //   // center: true,
    //   width: '265px', 
    // },
    // {
    //   selector: (row) => row?.project?.mandate_no,
    //   name: "Mandate Number",
    //   sortable: true,
    //   center: true,
    //   width:'220px'
    // },
    // {
    //   selector: (row) => moment(row?.document_created_date).format("DD MMM YYYY),
    //   name: "Date",
    //   sortable: true,
    //   center: true,
    // },
    // {
    //   selector: (row) => {
    //     const extnsn = getFileExtension(row?.document_file || '');
    //     return <div>
    //   {(row?.document_file && extnsn) ? FileIconsObj[extnsn] : row?.document_file}
    //     </div>
    //   } ,
    //   name: "Type",
    //   center: true,
    //   width: '100px',
    // },
    // {
    //   selector: (row) => 
    //     row.status === 1 ?
    //     <div style={{padding:10}}>
    //       <button onClick={()=>{handleApproveButtonPress(row)}}
    //       // onClick={()=>{setModalVisible(true); setDocumentId(row.id)} }
    //       className="btn btn-pill btn-secondary btn-air-secondary btn-secondary-gradien" type="button" style={{marginInlineEnd:15}}><FileDoneOutlined /> Approve</button>
    //       {/* <button onClick={()=>{setReviewModalVisible(true); setDocumentId(row.id)}} className="btn btn-pill btn-primary btn-air-primary btn-primary-gradien" type="button"><FormOutlined /> Review</button> */}
    //    </div>
    //    : row.status === 0 ?
    //    <div style={{padding:10}}>
    //    <button onClick={()=>{handleApproveButtonPress(row)}
    //      //  ()=>handleApproveButtonPress(row)
    //      }
    //    className="btn btn-pill btn-secondary btn-air-secondary btn-secondary-gradien" 
    //    type="button" style={{marginInlineEnd:15}}><FileDoneOutlined /> Approve</button>
       
    //    {/* <button className="btn btn-pill btn-primary btn-air-primary btn-primary-gradien" type="button"><FormOutlined /> In Review</button> */}
    // </div>
    //    : row.status === 0 ?
    //    <span>
    //      Request for Review.
    //    </span>
    //    :
    //     <></>
    //   // }
    //   ,
    //   name: "Action",
    // //   sortable: true,
    //   center: true,
    //   width: '200px',
    // },

    {
      selector: (row) => 
        
        <div style={{display:"flex", alignItems:"center"}}>
          {row.status == 0 ?
            <div>
              <button onClick={() => { handleApproveButtonPress(row) }}
              className="btn btn-pill btn-secondary btn-air-secondary btn-secondary-gradien" type="button" style={{ marginInlineEnd: 15 }}><FileDoneOutlined /> Approve</button>
            </div>
            : <div></div>}

            <div>
              <Link to={'/document/document-details'} state={{ documentId: row?.document_id || row?.id }} ><i className="fa fa-eye text-secondary" style={{ fontSize: 18, marginInlineEnd: 12 }}></i></Link>
            </div>

            <div>
              <Link to={'/document/document-details'} state={{ documentId: (row?.document_id || row?.id) , viewType:'communication' }}><i className="fa fa-comments text-secondary" style={{ fontSize: 18 }}></i></Link>
            </div>
        </div>
      ,
      name: "Action",
      center: true,
      width: '240px',
    },
  ];

  const handleApproveDocument = async (row, resolve, reject) => {
    let lo_formData = { document_id: row?.id, status: 1 }
    setButtonLoading(true)
    const res = await postRequestApiByToken('approvedocument', lo_formData);
    if (res?.status == "Success") {
      message.success('Approval successful');
      getApproverDocumentList()
      setModalVisible(false)
      resolve()
    } else {
      reject()
    }
    setButtonLoading(false);
    return true;
  };

  const handleApproveButtonPress = (row) => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleFilled />,
      content: 'You want to proceed with this approval?',
      onOk() {
        return new Promise((resolve, reject) => {
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          // const res = handleApproveDocument();
          handleApproveDocument(row, resolve, reject)

        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  };
  
  const handleReviewButtonPress = async (formvalue) => {
    let lo_formData = {
      document_id: ls_documentId,
      status: 1,
      remark: formvalue.review
    }
    setButtonLoading(true)
    const res = await postRequestApiByToken('approvedocument', lo_formData)
    if (res?.status == "Success") {
      message.success('Review successful');
      getApproverDocumentList()
      setReviewModalVisible(false)
    }
    setButtonLoading(false)
  };
  
  const getApproverDocumentList = async () => {
    setLoading(true)
    const res = await getRequestApiByToken(`approvallist?search=${filterText}&page=${currentPage}&orderBy=desc`)
    console.log('res',res);
    // dispatch({ type: 'SET_APPROVE_DOCUMENTS_COUNT', payload: res?.Documents?.total });
    setApproverDocumentCount();
    setApproverDocumentList(res?.Documents?.data)
    setTotalCount(res?.Documents?.total);
    setLoading(false)
  }
  
  useEffect(() => {
    getApproverDocumentList()
  }, [currentPage,filterText])

  // Function to handle search input change
  const handleSearch = debounce((value) => {
    setFilterText(value);
 }, 300);

  return (
    <>
    <Breadcrumb parent="Approver Project List" title="" />
    <div className="col-xl-12 xl-100">
    <div className="card parent-tblsec">
       <div className="card-header card-header-border">
        <div className="row" style={{alignItems:'center'}}>
        <div className="col-sm-7">
            <h5>{"Approver Document List"}</h5>
        </div>
        <div className="col-sm-5">
            <div className="pull-right right-header">
            <div className="onhover-dropdown">
              {/* <Input value={filterText} addonAfter={<SearchOutlined />} onChange={handleSearch} placeholder="Serach here..." /> */}
              <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
            </div>
            </div>
        </div>
        </div>
        </div>
       <div className="card-body">
          <div className="table-responsive">
              <DataTable
                columns={columns}
                data={la_approverDocumentList}
                striped={true}
                center={true}
                persistTableHead
                pagination
                paginationServer
                paginationTotalRows={totalCount} // Assuming 10 rows per page
                paginationPerPage={10} // Number of rows per page
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                onChangePage={setCurrentPage} 
                progressPending={loading}
                progressComponent={<div><LoadingOutlined/></div>}
             />
          </div>
       </div>
    </div>
    <AlertModal
        visible={modalVisible}
        title="Alert"
        content="Are you sure you want to proceed with this approval?"
        onCancel={()=>setModalVisible(false)}
        onOk={handleApproveButtonPress}
        loading={lb_buttonLoading}
      />
      <ReviewModal
        visible={lb_reviewModalVisible}
        onCancel={()=>setReviewModalVisible(false)}
        onOk={handleReviewButtonPress}
        loading={lb_buttonLoading}
      />
    </div>
    </>
  )
}

export default ApprovedProject