import React, { useEffect, useState } from 'react'
import "./style.css";
import Typography from 'antd/lib/typography/Typography';
import Modal from 'antd/lib/modal/Modal';
import { Divider } from 'antd/lib';

const DocDescriptionModal = ({ lb_showDetails, setShowDetails, lo_documentData }) => {
    return (
        <>
            <Modal
                footer={false}
                title={'Document Description'}
                open={lb_showDetails}
                onCancel={() => { setShowDetails(false); }}
                width={700}
            >
                <div>
                    <Divider/>
                    <div style={{ padding: '0px 0px 10px 0px' }}>
                        <Typography.Paragraph>
                            {lo_documentData?.description || <div><span style={{ color: "red" }}>No data available!</span></div>}
                        </Typography.Paragraph>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DocDescriptionModal