import React, { useState } from 'react'
import { LikeOutlined, DislikeOutlined, ShareAltOutlined, DownloadOutlined, LoadingOutlined, ClockCircleOutlined, CheckCircleOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { message } from 'antd/lib';
import moment from 'moment/moment';
import { postRequestApiByToken } from '../../../../api';

const DocDownload = ({ls_documentId, lo_documentData, getData = ()=>{}, getAndSetCounterData=()=>{}}) => {
    const [lb_dwnloadLoading, setDwnloadLoading] = useState(false);

    const handleDownload = async () => {
        let lo_formData = {
            document_id: ls_documentId,
            type: 'download'
        };
        setDwnloadLoading(true);
        const res = await postRequestApiByToken('documentaction', lo_formData);
        if (res?.status == "Success") {
            getAndSetCounterData('download_count');
            setDwnloadLoading(false);
            message.success(res?.message);
            console.log('ressss --download---', res);

            if (!res?.data?.full_file_url) {
                message.error('File not found!');
                return;
            }

            // Fetch the file as a blob
            fetch(res.data.full_file_url)
                .then(response => response.blob())
                .then(blob => {
                    const ls_docFileNm = (lo_documentData?.project?.mandate_no || '') +'-'+ (lo_documentData?.document_parent_category?.category_name || '') +'-'+moment(new Date()).format('-DD-MMMM-YYYY-hh-mm-A');
                    const ls_extensen =res?.data?.full_file_url?.split('.').pop().split('?')[0];
                    console.log('---ls_extensen',ls_extensen);
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `${ls_docFileNm}-.${ls_extensen}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(blobUrl); // Clean up the blob URL
                })
                .catch(error => console.error('Error downloading the file:', error));
        } else {
            setDwnloadLoading(false);
            message.error(res?.message || 'Access denied!');
        }
    };

    return (
        <>
        <button style={{ minWidth: "150px" }} disabled={lb_dwnloadLoading} className="btn btn-pill btn-success btn-air-success btn-success-gradien" type="button" onClick={handleDownload}>{lb_dwnloadLoading ? <LoadingOutlined /> : <DownloadOutlined />} Download</button>
        </>
    )
}

export default DocDownload