import React from 'react';
import './style.css'
import Breadcrumb from '../../../components/common/breadcrumb';
import { Card, Col, Container, Row, CardBody, Form, FormGroup, Label, Input, CardHeader, CardFooter, Media } from 'reactstrap';
import { Chart, ArcElement, DoughnutController, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // import the plugin
import BascicCounter from './BascicCounter';
import SectorOverviews from './SectorOverviews';
import PracticesOverviews from './PracticesOverviews';
import UserActivity from './UserActivity';
import TopUsers from './TopUsers';
import LimitEnhancement from './LimitEnhancement';

Chart.register(ArcElement, DoughnutController, Legend, Tooltip, ChartDataLabels); // register the plugin

const AdminDashboard = () => {

  const la_calrificationData = [
    {
      id: '01',
      documnetname: 'Financial Report',
      type: <i className="fa fa-file-pdf-o" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>,
      date: "11th May 2022 11.36am"
    },
    {
      id: '02',
      documnetname: 'Annual Report',
      type: <i className="fa fa-file-pdf-o" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>,
      date: "15th June 2022 02.45pm"
    },
    {
      id: '03',
      documnetname: 'Budget Proposal',
      type: <i className="fa fa-file-excel-o" style={{ width: 35, fontSize: 16, padding: 11, color: "#3498db" }}></i>,
      date: "20th July 2022 09.15am",
    },
    {
      id: '04',
      documnetname: 'Marketing Plan',
      type: <i className="fa fa-file-text-o" style={{ width: 35, fontSize: 16, padding: 11, color: "#2ecc71" }}></i>,
      date: "05th August 2022 03.30pm",
    },
    {
      id: '05',
      documnetname: 'Quarterly Review',
      type: <i className="fa fa-file-powerpoint-o" style={{ width: 35, fontSize: 16, padding: 11, color: "#f39c12" }}></i>,
      date: "10th September 2022 01.20pm",
    },
  ];

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      center: true,
    },


    {
      selector: (row) => row.documnetname,
      name: "Document",
      sortable: true,
      center: true,
    },
    {
      name: "",
      selector: (row) => <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>,
      sortable: true,
      center: true,
    },
    {
      selector: (row) => row.type,
      name: "Type",
      sortable: true,
      center: true,
    },
    {
      selector: (row) => row.date,
      name: "Date & Time",
      sortable: true,
      center: true,
    },
    {
      selector: (row) => <div>
        <span>
          <i className="fa fa-check-circle" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
        </span>
        <span>
          <i className="fa fa-times-circle" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
        </span>
      </div>,
      name: "",
      //   sortable: true,
      center: true,
    },
  ];
  

  return (
    <>
      <Breadcrumb parent="MIS Dashboard" title="" />
      <div className="container-fluid">
        <div className="row">

          <BascicCounter />

          <div className="col-xl-6 xl-100">
            <SectorOverviews />
          </div>

          <div className="col-xl-6 xl-100">
            <PracticesOverviews />
          </div>
          {/* <div className="col-xl-6 xl-100">
            <CommunicationCard />
          </div> */}
          <Col className="col-xl-12 xl-100">
            <UserActivity />
          </Col>
          <div className="col-xl-6 xl-100">
            <TopUsers />
          </div>

          <div className="col-xl-6 xl-100">
            <LimitEnhancement/>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboard