import React, { Fragment, useEffect, useState } from 'react'
import './style.css';
import Breadcrumb from '../../../components/common/breadcrumb'
import CountUp from 'react-countup'
import { Link2, Download , DownloadCloud, Eye, Send, HelpCircle, Bookmark, File} from 'react-feather'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Progress } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import ScrollBar from 'react-perfect-scrollbar';
import { message, Spin, Tag, Timeline, Typography } from 'antd';
import { Descriptions } from 'antd';
import { Button, Space } from 'antd';
import { LikeOutlined, DislikeOutlined, ShareAltOutlined, DownloadOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { FileIconsObj, FilePath } from '../../../constant/globalConfig';
import moment from 'moment';
import { capitalizeFirstLetter, getFileExtension } from '../../../utility';
import ViewProjectDetails from '../../projects/viewProjectDetails';
import CustomViewer from '../../../components/CustomViewer';
import CustomDocViewer from '../../../components/CustomDocViewer';
import DocActions from './DocActions';
import DocDescription from './DocDescription';
import DocDownload from './DocDownload';
import FileSizeView from '../../document/FileSizeView';

const OtherDocumentDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ls_documentId = location?.state?.documentId || null;
  
  const [lb_spining, setSpining] = useState(false)
  const [lo_documentData, setDocumentData] = useState(false)
  useEffect(() => {
  console.log('ls_documentId ooooo',ls_documentId);
  }, [ls_documentId])
  
  const [lb_showProjectDetails, setShowProjectDetails] = useState(false)
   const [ls_projectId, setProjectId] = useState('')

  const getDocumentData = async ()=>{
    setSpining(true)
    if (ls_documentId) {
      const lo_response = await getRequestApiByToken(`otherdocument/${ls_documentId}`);
      const lo_record = lo_response?.Documents ? lo_response.Documents : {};
      console.log('0---lo_record',lo_record);
      setDocumentData(lo_record)
    }
    setSpining(false)
  }

  useEffect(() => {
    getDocumentData();
  }, [ls_documentId]);
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll:1,
    arrows: false,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
};
const getFileDetails = (row) => {
  const extension = getFileExtension(row?.document_file || '');
  const fileElement = (row?.document_file && extension) ? FileIconsObj[extension] : row?.document_file
  return { extension, fileElement }
}
const getDocUploadDate = () =>{
  let ls_date = '--';
  if (lo_documentData?.document_created_date) {
    ls_date = moment(lo_documentData?.document_created_date).format('DD MMM YYYY');
  } else if (lo_documentData?.created) {
    ls_date = moment(lo_documentData?.created).format('DD MMM YYYY');
  }else if (lo_documentData?.modified_on) {
    ls_date = moment(lo_documentData?.modified_on).format('DD MMM YYYY');
  }
  return ls_date;
}

const items = [
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-file-o docdtl-icn-color"></i> &nbsp;Document Name</Typography.Text>,
    children: lo_documentData?.title,
  },
  // {
  //   key: '2',
  //   label: <Typography.Text  type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Project Name</Typography.Text>,
  //   children: <p onClick={() => { setShowProjectDetails(true); setProjectId(lo_documentData?.project?.id);}} style={{cursor:"pointer"}}>{lo_documentData?.project?.project_name}</p>,
  // },
  {
    key: '3',
    label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Created Date</Typography.Text>,
    children: <>{getDocUploadDate()}</>,
  },
  // {
  //   key: '3',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Last Downloaded</Typography.Text>,
  //   // children: '10/09/2013',
  //   children: '--',
  // },
  // {
  //   key: '4',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;Document Size</Typography.Text>,
  //   children: '8',
  // },
  {
    key: '4',
    label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;Document Type</Typography.Text>,
    children: <>{ lo_documentData?.otherdocument_parent_category?.category_name
      // getFileDetails(lo_documentData)?.extension} {getFileDetails(lo_documentData)?.fileElement
      }</>,
    // children: <>Pdf <i className="fa fa-file-pdf-o"></i></>,
  },
  // {
  //   key: '4',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-download docdtl-icn-color"></i> &nbsp;No of Downloads</Typography.Text>,
  //   children: '0',
  // },
  // {
  //   key: '4',
  //   label: <Typography.Text type="secondary" strong><i className="fa fa-eye docdtl-icn-color"></i> &nbsp;No of Views</Typography.Text>,
  //   children: '0',
  // },
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-user docdtl-icn-color"></i> &nbsp;Created by</Typography.Text>,
    children: lo_documentData?.author,
  },
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Version</Typography.Text>,
    children: lo_documentData?.version ? capitalizeFirstLetter(lo_documentData?.version) : '' ,
  },
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Sectors</Typography.Text>,
    children: lo_documentData?.sectors ? lo_documentData?.sectors?.map((rowvalue, inx) => `${inx > 0 ? ', ':''} ${rowvalue.sector_name}`) : '' ,
  },
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Practices</Typography.Text>,
    children: lo_documentData?.practices ? lo_documentData?.practices?.map((rowvalue, inx) => `${inx > 0 ? ', ':''} ${rowvalue.practice_name}`) : '' ,
  },
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Category</Typography.Text>,
    children: lo_documentData?.otherdocument_parent_category ? lo_documentData?.otherdocument_parent_category?.category_name : '' ,
  },
  {
    key: '1',
    label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Sub Category</Typography.Text>,
    children: lo_documentData?.otherdocument_child_c_ategory ? lo_documentData?.otherdocument_child_c_ategory?.category_name : '' ,
  },
];

 const itemStyle = {
    border: '1px solid #e8e8e8',
    padding: '8px',
    margin: '4px',
  };

  const getTimeLineDetails = (ls_url, ls_name, ls_status, ls_color) => {
    return (
      <div className="align-items-start tmline-col">
        <div className='d-flex' style={{alignItems:"center"}}>
          <img className="img-50 img-fluid m-r-20 rounded-circle" src={ls_url} alt="" />
          <h5 className="tmline-name f-w-700">{ls_name}</h5>
        </div>
        <h5 className='tmline-name2 f-w-600' style={{color:ls_color}}><CheckCircleOutlined style={{color:ls_color}}/> {ls_status}</h5>
      </div>
    )
  }

  // const handleDownload = async (documentData) => {
  //   if (documentData?.full_file_url) {

  //     const link = document.createElement('a');
  //     link.href = documentData?.full_file_url;
  //     link.download = `${documentData.title}.${documentData?.full_file_url?.split('.').pop()}`
  //     link.style.display = 'none';

  //     document.body.appendChild(link);

  //     link.click();

  //     document.body.removeChild(link);
  //     message.success("Successfully Downloaded!")
  //     console.log('ressss', documentData?.full_file_url);
  //   }
  // }
  return (
    <Fragment>
        <Breadcrumb parent="Other Document Details" title="" />
        <Spin spinning={lb_spining}>
        <div className="container-fluid">
          <div className="row">
            <div 
            className="col-xl-12 xl-100" 
            >
             <div className="row">
              <div className="col-xl-12 xl-100"
              // className="col-xl-12 xl-50 col-sm-6"
              >
                <div className='card' style={{padding:30}}>
                  <Descriptions bordered size="small" labelStyle={{minWidth:180}}>
                    {items.map((item) => (
                      <Descriptions.Item span={2} labelStyle={{width:8}} key={item.key} label={item.label}>
                        {item.children}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>
              </div>

              {/* <div className="col-xl-12 xl-100"
              // className="col-xl-12 xl-50 col-sm-6 " 
              // style={{backgroundColor:'white',height:257}}
              >
                <div className='card' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 23 }}>


                  <button className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien" type="button"><LikeOutlined /> Like</button>
                  <button className="btn btn-pill btn-secondary btn-air-secondary btn-secondary-gradien" type="button"><DislikeOutlined /> Dislike</button>
                  <button className="btn btn-pill btn-success btn-air-success btn-success-gradien" type="button" onClick={handleDownload}><DownloadOutlined /> Download</button>

                </div>
              </div> */}
         
         
              </div>
            </div>
            <div className="col-xl-6 xl-100" >
              {/* <div className="row docdetail-counter">
                <div className="col-xl-4 xl-33 col-sm-6">
                  <div className="card server-card-bg">
                    <div className="card-body server-widgets">
                      <div className="d-flex">
                        <DownloadCloud />
                        <div className="top-server">
                          <h6 style={{fontSize:14}} className="mb-0">Download</h6>
                        </div>
                      </div>

                      <div className="bottom-server">
                        <h5 className="mb-0">
                          <span className="second-color counter">
                            <CountUp end={20} />
                          </span>
                        </h5>
                      </div>
                      <div className="progress">
                        <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "90%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 xl-33 col-sm-6">
                  <div className="card server-card-bg">
                    <div className="card-body server-widgets">
                      <div className="d-flex">
                        <Eye />
                        <div className="top-server">
                          <h6 className="mb-0" style={{fontSize:14}}>Views</h6>
                        </div>
                      </div>

                      <div className="bottom-server">
                        <h5 className="mb-0">
                          <span className="second-color counter">
                            <CountUp end={350} />
                          </span>
                        </h5>
                      </div>

                      <div className="progress">
                        <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "90%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 xl-33 col-sm-6">
                  <div className="card server-card-bg">
                    <div className="card-body server-widgets">
                      <div className="d-flex">
                        <Link2 />
                        <div className="top-server">
                          <h6 className="mb-0" style={{fontSize:14}}>Linked User</h6>
                        </div>
                      </div>

                      <div className="bottom-server">
                        <h5 className="mb-0">
                          <span className="second-color counter">
                            <CountUp end={18} />
                          </span>
                        </h5>
                      </div>

                      <div className="progress">
                        <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "90%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-12 xl-100"
              // className="col-xl-12 xl-50 col-sm-6 " 
              // style={{backgroundColor:'white',height:257}}
              >
                <DocActions lo_documentData={lo_documentData} ls_documentId={ls_documentId} getData={getDocumentData} />
              </div> */}
              {/* <div className="row docdetail-downld-limit">
                <div
                  className="col-xl-12 xl-100"
                >
                  <div className="card" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="card-body progress-showcase row progress-dwnlimit">
                      <div className="text-center">
                        <h5>{'Download Limit'}</h5>
                      </div>
                      <div className="text-center pgress-space">{"6 of 10"}</div>
                      <Progress multi className="lg-progress-bar">
                        <Progress bar value="5" max={55}>5</Progress>
                        <Progress bar color="success" value="15" max={55}>{"15"}</Progress>
                        <Progress bar color="warning" value="10" max={55}>{"10"}</Progress>
                        <Progress bar color="danger" value="10" max={55}>{"10"}</Progress>
                      </Progress>
                      <div className="text-center pgress-space">
                        <button disabled className="btn btn-pill btn-outline-light-2x btn-air-light txt-dark" type="button">Request</button>
                      </div>
                    </div>
                    <div className="card-body progress-showcase row progress-dwnlimit">
                      <div className="text-center">
                        <h5>{'View Limit'}</h5>
                      </div>
                      <div className="text-center pgress-space">{"90 of 200"}</div>
                      <Progress multi className="lg-progress-bar">
                        <Progress bar value="5" max={55}>5</Progress>
                        <Progress bar color="success" value="15" max={55}>{"15"}</Progress>
                        <Progress bar color="warning" value="10" max={55}>{"10"}</Progress>
                        <Progress bar color="danger" value="10" max={55}>{"10"}</Progress>
                      </Progress>
                      <div className="text-center pgress-space">
                        <button disabled className="btn btn-pill btn-outline-light-2x btn-air-light txt-dark" type="button">Request</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="col-xl-12 xl-100" >
              {lo_documentData?.description ? 
                <DocDescription lo_documentData={lo_documentData} />
              : <></>}
            </div>
           
          
            <div className="col-xl-12 xl-100"
            // className="col-xl-12 xl-50 col-sm-6" 
              // style={{backgroundColor:'white',height:600}}
              >
                <div className="card card-mb-faq xs-mt-search">
                    <div className="card-header faq-header docviewr-headr">
                      <span style={{display: 'flex', justifyContent:"flex-start", alignItems:"center"}}>
                        <File/>
                        &nbsp;&nbsp;
                        <h5> {'Document Viewer'}</h5>
                      </span>
                        
                      
                      <div style={{display: 'flex', justifyContent:"flex-end", alignItems:"center"}}>
                        <DocDownload lo_documentData={lo_documentData} ls_documentId={ls_documentId} getData={getDocumentData} />
                        <div style={{marginLeft:10}}>
                        {(lo_documentData?.full_file_url?.includes('https') || lo_documentData?.full_file_url?.includes('http')) ?
                          <FileSizeView docFileUrl={lo_documentData?.full_file_url}/> : <></>}
                        </div>
                        <span style={{marginLeft:10}}><Bookmark /></span>
                      </div>
                    </div>
                    <div className="card-body faq-body">
                      {/* <div>
                          <iframe
                            title="Document Viewer"
                            // src={'https://onlinetestcase.com/wp-content/uploads/2023/06/500-KB.pdf'}
                            src={lo_documentData?.full_file_url ? FilePath+lo_documentData?.full_file_url : ''}
                            width="100%"
                            height="600px"
                            style={{ border: '1px solid #ccc' }}
                          ></iframe>
                      </div> */}
                      <div>
                        {(lo_documentData?.full_file_url?.includes('https') || lo_documentData?.full_file_url?.includes('http')) ? 
                          (
                            <>
                            
                            {getFileExtension(lo_documentData?.document_file) == 'pdf' ?
                              <CustomViewer ls_url={lo_documentData?.full_file_url ? lo_documentData.full_file_url : ''}/>
                              :
                              <CustomDocViewer ls_url={lo_documentData?.full_file_url ? lo_documentData.full_file_url : ''}/>
                            }
                            </>
                          )
                          : 
                        <div style={{textAlign:"center"}}><Typography.Text type='danger'>File path temporary unavailable!</Typography.Text></div>}
                      </div>
                    </div>
                </div>
              
            </div>

            {/* <div className="col-xl-6 xl-100">
              <div className="card communication-card-hight"
              // style={{height:761}}
              >
                <div className="card-header card-header-border chat-header-default">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>{'Communication'}</h5>
                    </div>
                    <div className="col-sm-6">

                    </div>
                  </div>
                </div>
                <div className="card-body chat-box dashboard-chat">
                  <div className="chat">
                    <ScrollBar className="scroll chat-section-card-height" options={{ suppressScrollX: true }}
                    //  style={{ maxHeight: "510px" }}
                    >
                      <div className="d-flex left-side-chat align-items-start">
                        <img className="rounded-circle chat-user-img img-60 m-r-20" src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" />
                        <div className="flex-grow-1">
                          <div className="message-main">
                            <p className="mb-0" style={{ backgroundColor: '#f7f5ff' }}>{"Are we meeting today? Project has been already finished and I have results to show you."} </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex right-side-chat align-items-start">
                        <div className="flex-grow-1 text-end">
                          <div className="message-main">
                            <p className="pull-right" style={{ backgroundColor: '#ebfaff' }}>{"Well I am not sure. The rest of the team is not here yet. Maybe in an hour or so?"}</p>
                            <div className="clearfix"></div>
                          </div>
                          <div className="sub-message message-main">
                            <p className="pull-right mb-0" style={{ backgroundColor: '#ebfaff' }}>{"Well I am not sure. The rest of the team"} </p>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <img className="rounded-circle chat-user-img img-60 m-l-20" src={'https://react.pixelstrap.com/endless/static/media/5.53a215ed00aac31d7543.jpg'} alt="" />
                      </div>
                      <div className="d-flex left-side-chat align-items-start">
                        <img className="rounded-circle chat-user-img img-60 m-r-20" src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" />
                        <div className="flex-grow-1">
                          <div className="message-main">
                            <p style={{ backgroundColor: '#f7f5ff' }}>{"Actually everything was fine. I'm very excited to show this to our team."}</p>
                          </div>

                        </div>
                      </div>
                    </ScrollBar>
                  </div>
                </div>
                <div className="d-flex chat-footer bg-primary">
                  <i className="icon-face-smile"></i>
                  <div className="flex-grow-1">
                    <input className="form-control" type="text" placeholder="Type your message" required="" />
                  </div>
                  <Send />
                </div>
              </div>
            </div> */}

                {/* <div className="col-xl-6 xl-100"
                // className="col-xl-6 xl-50 col-sm-6" 
                >
                  <div className="card communication-card-hight"
                  // style={{height:761}}
                  >
                    <div className="card-header card-header-border chat-header-default">
                      <div className="row">
                        <div className="col-sm-6">
                          <h5>{'Activities'}</h5>
                        </div>
                        <div className="col-sm-6">

                        </div>
                      </div>
                    </div>
                    <div className="card-body chat-box dashboard-chat tnline-mainparent">
                      <Timeline mode='left' items={la_timelineItem} />
                    </div>
                  </div>
                </div> */}
            {/* <div className="col-xl-12 xl-50 col-sm-6" style={{backgroundColor:'yellow',height:100}}>
         
            </div> */}
            {/* <Col md="24" lg="24" xl="24" className="box-col-24" >
                    <div className="card">
                        <div className="card-header">
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <h5>{'Related Documents'}</h5>
                                <Link to={"#"}>See All</Link>
                            </div>
                        </div>
                        <div className="card-body progress-showcase row">
                       <Slider {...settings} className='slider-hight' >
                            <div className="item">
                                <div className="card dashbrd-slider">
                                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                                        <div className="file-box">
                                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-word-o txt-info"></i></div>
                                            <div className="file-bottom">
                                            <h6 className='sliderprt-txt'>{'Weekly Report.doc'} </h6>
                                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                                            <p> <b><i className="flag-icon flag-icon-in slider-fontflag"></i></b></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card dashbrd-slider">
                                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                                        <div className="file-box">
                                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-pdf-o txt-info"></i></div>
                                            <div className="file-bottom">
                                            <h6 className='sliderprt-txt'>{'Monthly Report.pdf'} </h6>
                                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                                            <p> <b><i className="flag-icon flag-icon-bd slider-fontflag"></i></b></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item" >
                                <div className="card dashbrd-slider">
                                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                                        <div className="file-box">
                                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-word-o txt-info"></i></div>
                                            <div className="file-bottom">
                                            <h6 className='sliderprt-txt'>{'Project Report.doc'} </h6>
                                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                                            <p> <b><i className="flag-icon flag-icon-af slider-fontflag"></i></b></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card dashbrd-slider">
                                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                                        <div className="file-box">
                                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-word-o txt-info"></i></div>
                                            <div className="file-bottom">
                                            <h6 className='sliderprt-txt'>{'Financial Report.doc'} </h6>
                                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                                            <p> <b><i className="flag-icon flag-icon-gb slider-fontflag"></i></b></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="card dashbrd-slider">
                                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                                        <div className="file-box">
                                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-pdf-o txt-info"></i></div>
                                            <div className="file-bottom">
                                            <h6 className='sliderprt-txt'>{'Weekly Report.pdf'} </h6>
                                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                                            <p> <b><i className="flag-icon flag-icon-es slider-fontflag"></i></b></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </Slider>
                        </div>
                    </div>
            </Col> */}
          </div>
        </div>
        </Spin>

        <ViewProjectDetails {...{lb_showProjectDetails, setShowProjectDetails, ls_projectId, setProjectId}}/>
    </Fragment>
  )
}

export default OtherDocumentDetails
