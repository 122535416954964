// firebase-config.js
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBQ5BH-rJWPlr0eWUsCkjoGHxZpBjhpWW4",
  authDomain: "ipeshare-staging.firebaseapp.com",
  projectId: "ipeshare-staging",
  storageBucket: "ipeshare-staging.appspot.com",
  messagingSenderId: "219300872064",
  appId: "1:219300872064:web:84ac09004285c22e070b65"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export { messaging };