// src/commonApi.js
import axios from 'axios';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../firebase-config';
import { Api_base_url } from '../constant/globalConfig';
import FormData from 'form-data';
import { postRequestApiByToken } from '.';
import { VapIdKey } from '../constant/firebaseConfig';

const saveNotificationTokenForUser = async (fcmToken, authToken) => {
  const data = new FormData();
  data.append('fcm_key', fcmToken);
  const ls_token = 'Bearer '+authToken;
  const apiEndPoint = 'userkey';
  
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${Api_base_url}${apiEndPoint}`,
    headers: {
        'Authorization': ls_token,
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json', 
        'mode': 'no-cors',
    },
    data: data
  };

  try {
    console.log('saveNotificationTokenForUser fcmToken', fcmToken);
    console.log('saveNotificationTokenForUser', authToken);
    const response = await axios.request(config);
    console.log('saveNotificationTokenForUser', response);
  } catch (error) {
    console.log('saveNotificationTokenForUser error', error);
  }
};

export const saveFcmToken = async (authToken) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      throw new Error('Notification permission not granted');
    }
    console.log('Notification permission granted.');
    
    const token = await getToken(messaging, { vapidKey: VapIdKey }); // Replace with your VAPID key
    console.log('FCM token:', token);
    
    await saveNotificationTokenForUser(token, authToken);

    // onMessage(messaging, (payload) => {
    //   console.log('Message received:', payload);
    //   const { title, body } = payload.notification;
    //   new Notification(title, { body });
    // });
  } catch (error) {
    console.error('Error getting token:', error);
  }
};

export const setNotificationSeen = async (id) => {
  let lo_formData = { notification_id: id };
  const res = await postRequestApiByToken('notificationSeen', lo_formData);
  console.log('ressss>>> setNotificationSeen >>>', res);
  return;
}
