import React, { useEffect, useState } from 'react';
import "./style.css";
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { getRequestApiByToken } from '../../../../api';
import { Segmented } from 'antd';
import { Link } from 'react-router-dom';
import defaultUserImg from "../../../../assets/images/user/demouser.png"

const TopUsers = () => {
    const [la_userDataList, setUserDataList] = useState([]);
    const [lb_loading, setLoading] = useState(false);
    const [ls_listType, setListType] = useState('30days');

    const getAndSetDetails = async () => {
        setLoading(true);
        let ls_apipath = `top_user_activity/${ls_listType}`;
        let res = await getRequestApiByToken(ls_apipath);
        setUserDataList(res || []);
        setLoading(false);
    }

    useEffect(() => {
        if (ls_listType) getAndSetDetails()
    }, [ls_listType])

    return (
        <Card className='top-user-card-height'
        style={{height:460}}
        >
            <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h5>{'Top Users'}</h5>
                <div className='activity-prnt-segemnt'>
                    <Segmented
                        value={ls_listType}
                        options={[{ label: 'Last 7 days', value: '7days' }, { label: 'Last 30 days', value: '30days' }, { label: 'Last 90 days', value: '90days' }]}
                        onChange={(value) => { setListType(value) }}
                    />
                </div>
            </CardHeader>
            <div>
                <CardBody className="row">
                    {
                        la_userDataList?.map((rowValue) => (
                            <figure className="col-xl-4 col-sm-6">
                                <div className="align-items-start usercard-col usercard-activity">
                                    <div className='d-flex'>
                                        <div>
                                            <img style={{width:38}} className="img-fluid m-r-0 rounded-circle" src={defaultUserImg} alt="" />
                                        </div>
                                        <div>
                                            <h6 className="usercard-name f-w-600 usercard-name-activity">{rowValue?.user?.name}</h6>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1" style={{width:"100%", marginTop:6}}>
                                        {/* <h6 className="usercard-name f-w-600">{rowValue?.user?.name}</h6> */}
                                        <div className='d-flex' style={{ justifyContent: "space-around" }}>
                                            <span className='usercard-txt'>
                                                <i className="fa fa-eye" style={{ color: '#55a8e1' }}></i><span className="badge rounded-pill badge-light usercard-textpart">{rowValue?.Total_Viewed || 0}</span>
                                            </span>
                                            <span className='usercard-txt'>
                                                <i className="fa fa-download" style={{ color: '#3eb66a' }}></i><span className="badge rounded-pill badge-light usercard-textpart">{rowValue?.Total_Downloaded || 0}</span>
                                            </span>
                                            <span className='usercard-txt'>
                                                <i className="fa fa-share" style={{ color: '#b08bd0' }}></i><span className="badge rounded-pill badge-light usercard-textpart">{rowValue?.Total_Shared || 0}</span>
                                            </span>
                                        </div>

                                    </div>
                                </div>

                                
                            </figure>
                        ))
                    }


                </CardBody>
            </div>
            <CardFooter style={{ textAlign: "center" }}>
                <Link to={`${process.env.PUBLIC_URL}/report/activity-report`}>
                    <button className="btn btn-outline-secondary" type="button">{'View All'}</button>
                </Link>
            </CardFooter>
        </Card>
    )
}

export default TopUsers