import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const QuickAccesCard = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll:1,
        arrows: false,
        autoplay:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 370,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
    };

  return (
    <div className="card">
        <div className="card-header">
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <h5>{'Quick Access'}</h5>
                <Link to={"#"}>See All</Link>
            </div>
        </div>
        <div className="card-body progress-showcase row">
        <Slider {...settings} className='slider-hight' 
        // style={{height:230}}
        >
            <div className="item">
                <div className="card dashbrd-slider">
                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                        <div className="file-box">
                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-word-o txt-info"></i></div>
                            <div className="file-bottom">
                            <h6 className='sliderprt-txt'>{'Weekly Report.doc'} </h6>
                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                            <p> <b><i className="flag-icon flag-icon-in slider-fontflag"></i></b></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="card dashbrd-slider">
                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                        <div className="file-box">
                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-pdf-o txt-info"></i></div>
                            <div className="file-bottom">
                            <h6 className='sliderprt-txt'>{'Monthly Report.pdf'} </h6>
                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                            <p> <b><i className="flag-icon flag-icon-bd slider-fontflag"></i></b></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item" >
                <div className="card dashbrd-slider">
                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                        <div className="file-box">
                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-word-o txt-info"></i></div>
                            <div className="file-bottom">
                            <h6 className='sliderprt-txt'>{'Project Report.doc'} </h6>
                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                            <p> <b><i className="flag-icon flag-icon-af slider-fontflag"></i></b></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="card dashbrd-slider">
                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                        <div className="file-box">
                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-word-o txt-info"></i></div>
                            <div className="file-bottom">
                            <h6 className='sliderprt-txt'>{'Financial Report.doc'} </h6>
                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                            <p> <b><i className="flag-icon flag-icon-gb slider-fontflag"></i></b></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="card dashbrd-slider">
                    <div className="card-body ecommerce-icons text-center" style={{padding:0}}>
                        <div className="file-box">
                        <div className="file-top" >  <i style={{fontSize:60}} className="fa fa-file-pdf-o txt-info"></i></div>
                            <div className="file-bottom">
                            <h6 className='sliderprt-txt'>{'Weekly Report.pdf'} </h6>
                            <p className="mb-2 sliderprt-txtsmall">{'13 MB'}</p>
                            <p> <b><i className="flag-icon flag-icon-es slider-fontflag"></i></b></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </Slider>
        </div>
   </div>
  )
}

export default QuickAccesCard