import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "../redux";

const sagaMiddleware = createSagaMiddleware();

// Load state from local storage
const persistedState = localStorage.getItem('reduxState') 
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

// Create a Redux store that holds the app state.
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsWhitelist: ['SET_USER_TOKEN', 'SET_USER_ID', 'SET_USER_TYPE', 'SET_USER_DETAILS','SET_APPROVE_DOCUMENTS_COUNT', 'SET_HAS_SECTOR', 'SET_Notifications', 'SET_NotificationCount']
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const store = createStore(reducers, persistedState, enhancer);

// Save state to local storage whenever it changes
store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;
