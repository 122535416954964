import React, { Fragment } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'
import Bookmark from './bookmark';
import { useSelector } from 'react-redux';

const Breadcrumb = props => {
    const breadcrumb = props;
    const ls_userType = useSelector(content => content.commonReducer.user_type);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <div className="page-header-left">
                                {/* <h3>{breadcrumb.title}</h3> */}
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <Link 
                                        to={(ls_userType == 'admin') ? `${process.env.PUBLIC_URL}/dashboard` : `${process.env.PUBLIC_URL}/document/search-document`}
                                        >
                                            <Home />
                                        </Link>
                                    </li>
                                    {breadcrumb?.parent ? <li className="breadcrumb-item">{breadcrumb.parent}</li> : <></>}
                                    {breadcrumb?.title ? <li className="breadcrumb-item active">{breadcrumb.title}</li> : <></>}
                                </ol>
                            </div>
                            {/* <div className="page-header-right">
                                {breadcrumb.sideComponent}
                            </div> */}
                        </div>
                        {breadcrumb?.sideComponent ? <div className="col">
                            <div className="bookmark pull-right">
                                {breadcrumb.sideComponent}
                            </div>
                        </div> : <></>}
                        {/* <!-- Bookmark Start--> */}
                        {/* <Bookmark /> */}
                        {/* <!-- Bookmark Ends--> */}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Breadcrumb
