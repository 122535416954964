import React,{Fragment, useState} from 'react';
import { ShoppingBag, Download, AlertCircle, MessageCircle } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { postRequestApiByToken } from '../../../api';
import { setNotificationSeen } from '../../../api/commonApi';
import LimitRequestModal from '../../../pages/document/LimitRequestModal';
import { message } from 'antd/lib';

const Notifications = props => {
    const { la_notifications, lo_notificationCount, getAndSetNotifications, getAndSetNotificationCount } = props;
    const navigate = useNavigate();
    const notificationSeenStyle = {backgroundColor: '#615d6614', borderBottom: "1px solid #dedee259"}
    const notificationUnseenStyle = {borderBottom: "1px solid #dedee259"}

    const [ls_docAction, setForDocAction] = useState('')
    const handleView = async (rowValue) => {
        let lo_formData = { document_id: rowValue?.document_id, type: 'view'}
        const res = await postRequestApiByToken('documentaction', lo_formData);
        console.log('ressss>>> handleView >>>', res);
        if (res?.status === "Success") {
            if (rowValue?.is_seen == 0) {
                await setNotificationSeen(rowValue?.id);
                getAndSetNotifications()
                getAndSetNotificationCount()
            }
            
            navigate('/document/document-details', { state: { documentId: rowValue?.document_id, viewType: 'communication' } });
        } else {
            message.error(res?.message || 'Access denied!');
            setForDocAction('request-for-limit')
        }
    }

    return (
        <Fragment>
            <div>
            <ul className="notification-dropdown onhover-show-div p-0">
                    <li>Notification <span className="badge rounded-pill badge-primary pull-right">{lo_notificationCount?.unSeenCount || 0}</span></li>
                    
                    {la_notifications?.map((notificationrRow, index) => (
                      <li key={index} onClick={()=> handleView(notificationrRow)} style={(notificationrRow?.is_seen == 1) ? {...notificationSeenStyle} : {...notificationUnseenStyle}}>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mt-0"><span><MessageCircle /></span>{notificationrRow?.notification_title}</h6>
                                <p className="mb-0">{notificationrRow?.message}</p>
                            </div>
                        </div>
                      </li>
                    ))}
                    <li className="txt-dark" style={{textAlign:"center"}}><Link to={`${process.env.PUBLIC_URL}/notification-list`}>View All Notification</Link></li>
                </ul>
                {/* <ul className="notification-dropdown onhover-show-div p-0">
                    <li>{Notification} <span className="badge rounded-pill badge-primary pull-right">{"3"}</span></li>
                    <li>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mt-0"><span><ShoppingBag /></span>{"Your order ready for Ship..!"}</h6>
                                <p className="mb-0">{"Lorem ipsum dolor sit amet, consectetuer."}</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mt-0 txt-success"><span><Download className="font-success"/></span>{"Download Complete"}</h6>
                                <p className="mb-0">{"Lorem ipsum dolor sit amet, consectetuer."}</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mt-0 txt-danger"><span><AlertCircle className="font-danger"/></span>{"250 MB trash files"}</h6>
                                <p className="mb-0">{"Lorem ipsum dolor sit amet, consectetuer."}</p>
                            </div>
                        </div>
                    </li>
                    <li className="txt-dark"><a href="#javascript">{All}</a> {Notification}</li>
                </ul> */}
            </div>

            <LimitRequestModal {...{setForDocAction, ls_docAction}} />
        </Fragment>
    );
};

export default Notifications;