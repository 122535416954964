import { Form, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import Breadcrumb from '../../../components/common/breadcrumb';
import { checkIsnumericValue, getCascaderIdsFormArray, getIdsFormArray } from '../../../utility';
import AddDocumentForm from '../addDocument/addDocumentForm';

const UpdateOtherDocument = () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const ls_updateId = location?.state?.updateId || null;
    const ls_userId = useSelector(content => content.commonReducer.user_id);

    const [lb_buttonLoader, setButtonLoader] = useState(false)
    const [lb_spining, setSpining] = useState(false)
    const [form] =Form.useForm()

    const handleSubmitForm = async (formValues) => {
        let lo_formData = {
            ...formValues,
            document_created_date: new Date(formValues.document_created_date).toISOString().slice(0, 10),
            owner:ls_userId,
            status: '0',
            // description: '',
            // parent_cat_id:'10',
            // child_cat_id:'26',
        }
        const la_files = [];
        formValues?.document_file?.forEach((file) => {
            // lo_formData['fileuploadname'] = file.originFileObj;
            if(file?.originFileObj) {
                la_files.push({fieldKey:'document_file', value: file.originFileObj})
            } else {
                la_files.push({fieldKey:'document_file', value: file.name})
            }
        });
      
        setButtonLoader(true)
        if (ls_updateId) {
          const res = await postRequestApiByToken('otherdocument/' + ls_updateId, lo_formData, la_files)
          if (res?.status == 'Success') {
              // setFormData()
            //   getDocumentList()
              form.resetFields()
              form.setFieldsValue({})
            //   setUpdateId('')
              message.success('Successfully Updated!');
              navigate('/other-documents/my-other-document')

          } else {
              message.error(res?.message);
          }
          } else {
            // lo_formData['description'] = '';
            lo_formData['status'] = 0;
            lo_formData['owner'] = ls_userId;
            const res = await postRequestApiByToken('otherdocument', lo_formData, la_files)
            if (res?.status == "Success") {
            //   getDocumentList()
              form.resetFields()
              form.setFieldsValue({})
            //   setUpdateId('')
              message.success(res?.message);
            }else{
              message.error(res?.message)
            }
          }
          setButtonLoader(false)
        }

        const resetFormData = async ()=>{
            form.resetFields()
            // setUpdateId('')
          }
          const [lo_updatRecord, setUpdateRecord] = useState({})

          const setFormData = async ()=>{
            setSpining(true)
            const lo_updateDate = {}
            if (ls_updateId) {
                const lo_response = await getRequestApiByToken(`otherdocument/${ls_updateId}`);
                const lo_record = lo_response?.Documents ? lo_response?.Documents : {};
                lo_updateDate['title'] = lo_record.title;
                lo_updateDate['project_id'] = lo_record?.project?.id;

                // lo_updateDate['parent_cat_id'] = lo_record?.parent_cat_id;
                // if(lo_record?.child_cat_id && lo_record.child_cat_id != 'undefined') lo_updateDate['child_cat_id'] = parseInt(lo_record.child_cat_id);

                if (lo_record?.parent_cat_id && checkIsnumericValue(lo_record?.parent_cat_id)) {
                  lo_updateDate['parent_cat_id'] = parseInt(lo_record.parent_cat_id);
                }
                if (lo_record?.child_cat_id && checkIsnumericValue(lo_record?.child_cat_id)) {
                  lo_updateDate['child_cat_id'] = parseInt(lo_record.child_cat_id);
                }

                lo_updateDate['sector_id'] = getIdsFormArray(lo_record?.sectors);
                lo_updateDate['parctices'] = getIdsFormArray(lo_record?.practices);
                lo_updateDate['group_id'] = getIdsFormArray(lo_record?.groups);

                // lo_updateDate['Country'] = getCascaderIdsFormArray(lo_record?.country);
                lo_updateDate['key_words'] = lo_record?.key_words?.split(",") || [];
                // getCascaderIdsFormArray(lo_record?.groups);
                // lo_updateDate['document_file'] = lo_record.document_file;
                const filePath = lo_record.document_file;
                const filename = filePath.split('/').pop();

                // if(lo_record?.document_file) lo_updateDate['document_file'] = [{ uid: '1', name:  filename}];
                if(lo_record?.document_file) lo_updateDate['document_file'] = [{ uid: '1', name: filename }];
                if(lo_record?.document_created_date) lo_updateDate['document_created_date'] = dayjs(lo_record.document_created_date);
                lo_updateDate['author'] = lo_record?.author;
                lo_updateDate['version'] = lo_record?.version;
                lo_updateDate['description'] = lo_record?.description;
                setUpdateRecord(lo_record);
            }else{
              setUpdateRecord({})
            }
            form.resetFields()
            form.setFieldsValue(lo_updateDate)
            setSpining(false)
          }
          useEffect(() => {
            setFormData();
          }, [ls_updateId]);

  return (

      <>
      <Breadcrumb parent={`${ls_updateId ? 'Update' : 'Create'} Other Document`} title=""  sideComponent={
       <Link to={'/other-documents/my-other-document'}>
          <div style={{ display: 'flex' }}>
            <i className="fa fa-arrow-circle-left" style={{ fontSize: 20, marginInlineEnd: 10 }}></i>
            <strong>Back</strong>
          </div>
       </Link>}/>
         <AddDocumentForm
            handleSubmitForm={handleSubmitForm}
            lb_buttonLoader={lb_buttonLoader}
            ls_updateId={ls_updateId}
            form={form}
            resetFormData={resetFormData}
            lb_spining={lb_spining}
            lo_updatRecord={lo_updatRecord}
            status={'mydocument'}
        />
      </>

  )
}

export default UpdateOtherDocument