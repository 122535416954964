import React, { useEffect, useRef, useState } from 'react'
import './style.css';
import { Send } from 'react-feather'
import ScrollBar from 'react-perfect-scrollbar';
import { getRequestApiByToken, postRequestApiByToken } from '../../../../api';
import { useSelector } from 'react-redux';
import { Button, Empty, message } from 'antd/lib';
import Avatar from 'antd/lib/avatar/avatar';
import { stringAvatar } from '../../../../utility';
import moment from 'moment/moment';
import { SendOutlined } from '@ant-design/icons';
import Typography from 'antd/lib/typography/Typography';

const DocCommunication = (lo_props) => {
    const { ls_documentId } = lo_props;
    const ls_userId = useSelector(content => content.commonReducer.user_id);
    const messagesEndRef = useRef(null);
    const [lb_loading, setLoading] = useState(false);
    const [lb_btnloading, setBtnLoading] = useState(false);
    const [la_communicationList, setCommunicationList] = useState([])
    const [ls_message, setMessage] = useState('');
    const [ls_requestType, setRequestType] = useState('');
    const [la_msgList, setMsgList] = useState([])
    const [lb_loadFirstTime, setLoadFirstTime] = useState(0)

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async (e) => {
        setBtnLoading(true)
        e.preventDefault();
        if (ls_message.trim()) {
            const lo_formData = { document_id: ls_documentId, content: ls_message };
            const res = await postRequestApiByToken('messages/send', lo_formData)
            if (res?.status == 'Success') {
                getCommunicationList();
                setMessage('');
                setRequestType('submit')
            } else {
                message.error(res?.message);
            }
        }
        setBtnLoading(false)
    };

    useEffect(() => {
      if(la_communicationList?.length && lb_loadFirstTime == 1){
        setRequestType('submit')
        setLoadFirstTime(lb_loadFirstTime+1)
      }
    }, [JSON.stringify(la_communicationList), lb_loadFirstTime])
    

    const getCommunicationList = async () => {
        if (!ls_documentId) { setCommunicationList([]); return; }

        setLoading(true);
        const res = await getRequestApiByToken(`messages/${ls_documentId}`);
        if(lb_loadFirstTime <= 1) setLoadFirstTime(lb_loadFirstTime+1)

        // if (subType=='after-submit' && messagesEndRef.current) messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;

        setCommunicationList(res?.data || []);
        setLoading(false);
    }

    const getMessageComponent = () => {
        const messageList = []
        let lastUserId = null;
        la_communicationList.map((rowValue) => {
            let msgStyle = {}
            if (ls_userId != rowValue?.user_id && lastUserId == rowValue?.user_id) {
                msgStyle = { marginTop: "-25px", marginLeft: '76px' };
            } else if (ls_userId == rowValue?.user_id && lastUserId == rowValue?.user_id) {
                msgStyle = { marginTop: "-25px", marginRight: '76px' };
            }
            messageList.push(
                <div key={rowValue?.id} className={`parnt-chatting d-flex ${(ls_userId == rowValue?.user_id) ? 'right' : 'left'}-side-chat align-items-start`}>

                    {(ls_userId != rowValue?.user_id && lastUserId != rowValue?.user_id) ? 
                        (rowValue?.img ? <img className="rounded-circle chat-user-img img-60 m-r-20" src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" /> : <Avatar {...stringAvatar(rowValue?.user?.name)} className="rounded-circle chat-user-img m-r-20" size="large" ></Avatar> )
                    : ''}

                    <div className={`flex-grow-1 ${(ls_userId == rowValue?.user_id) && 'text-end'}`}>
                        <div className="message-main">
                            <p className={`${(ls_userId == rowValue?.user_id) ? 'pull-right' : ''}`} style={(ls_userId == rowValue?.user_id) ? { backgroundColor: '#ebfaff', ...msgStyle } : { backgroundColor: '#f7f5ff', ...msgStyle }} >
                                {(lastUserId != rowValue?.user_id && ls_userId != rowValue?.user_id) ? <><strong className='msg-user-nm'>~{rowValue?.user?.name}</strong><br/></> : ''}
                                {rowValue?.content} 
                                <div class="status chat-datetime-position">
                                    <i class="fa fa-clock-o" style={{fontSize:11, color:'#80808094'}}></i>  
                                    <span style={{fontSize:9, color:'#80808094'}}>  {moment(rowValue?.updated_at || rowValue?.created_at).format('DD MMM, h:mm A')}</span>
                                </div>
                            </p>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    {(ls_userId == rowValue?.user_id && lastUserId != rowValue?.user_id) ? 
                        (rowValue?.img ? <img className="rounded-circle chat-user-img img-60 m-l-20" src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" /> : <Avatar {...stringAvatar(rowValue?.user?.name)} className="rounded-circle chat-user-img m-l-20" size="large" style={{backgroundColor: '#fde3cf', color: '#f56a00'}}></Avatar> )
                    : ''}

                </div>
            )

            lastUserId = rowValue?.user_id;
        })
        return messageList;
    }

    useEffect(() => {
        if (ls_requestType == 'submit') {

            const timeoutId = setTimeout(() => {
                messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
                setRequestType('')
            }, 1000); // Set the interval time here (5000 ms = 5 seconds)
    
            return () => clearTimeout(timeoutId);

            
        }
    }, [ls_requestType])
    

    useEffect(() => {
        getCommunicationList();
    }, [ls_documentId])

    useEffect(() => {
        const intervalId = setInterval(() => {
            getCommunicationList(); // This will be called every 5 seconds
        }, 5000); // Set the interval time here (5000 ms = 5 seconds)

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [ls_documentId, lb_loadFirstTime]); // Add other dependencies if necessary


    return (
        <div>
            <div className="card communication-card-hight" >
                <div className="card-header card-header-border chat-header-default">
                    <div className="row">
                        <div className="col-sm-6">
                            <h5>{'Communication'}</h5>
                        </div>
                        <div className="col-sm-6">
                        </div>
                    </div>
                </div>
                <div className="card-body chat-box dashboard-chat">
                    <div className="chat">
                        {/* <ScrollBar className="scroll chat-section-card-height" options={{ suppressScrollX: true }} > */}
                        <div className='doc-cmuncation-block chat-section-card-height' ref={messagesEndRef}>
                            { la_communicationList?.length ?
                                getMessageComponent()
                            :
                                <div><Empty description={<Typography.Text>Start Your Conversations</Typography.Text>}/></div>
                            }
                        </div>
                        {/* </ScrollBar> */}
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="d-flex chat-footer bg-primary">
                    {/* <i className="icon-face-smile"></i> */}
                    <div className="flex-grow-1">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Type your message"
                            required
                            value={ls_message}
                            onChange={handleMessageChange}
                        />
                    </div>
                    {/* <button type="submit" disabled={!ls_message.trim()} className="send-btn"><Send/></button> */}
                    <div className='chat-btn-prnt'>
                        <Button loading={lb_btnloading} icon={<SendOutlined className='chat-sendbtn-icon'/>} htmlType='submit' type='text' disabled={!ls_message.trim()}></Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DocCommunication