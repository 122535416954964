import React, { useState } from 'react'
import { LikeOutlined, DislikeOutlined, ShareAltOutlined, DownloadOutlined, LoadingOutlined, ClockCircleOutlined, CheckCircleOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { message } from 'antd/lib';
import moment from 'moment/moment';

const DocDownload = ({ ls_documentId, lo_documentData, getData = () => { } }) => {
    const [lb_dwnloadLoading, setDwnloadLoading] = useState(false);

    const handleDownload = async (docData) => {
        console.log('---docData---',docData);
        setDwnloadLoading(true);
        if (docData?.full_file_url && (docData?.full_file_url?.includes('https') || docData?.full_file_url?.includes('http'))) {
            
            const filUrl = docData.full_file_url;
            fetch(filUrl)
                .then(response => response.blob())
                .then(blob => {
                    const ls_docFileNm = ('report') +'-'+ (docData?.otherdocument_parent_category?.category_name || '') +'-'+moment(new Date()).format('-DD-MMMM-YYYY-hh-mm-A');
                    const ls_extensen =filUrl?.split('.').pop().split('?')[0];
                    console.log('---ls_extensen',ls_extensen);
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `${ls_docFileNm}-.${ls_extensen}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(blobUrl);
                    message.success("Successfully Downloaded!");
                    setDwnloadLoading(false);
                })
                .catch(error => {
                    message.error('File path temporary unavailable!');
                    setDwnloadLoading(false);
                    console.error('Error downloading the file:', error)
                });
        } else {
            setDwnloadLoading(false);
            message.error('File path temporary unavailable!');
        }
    };

    return (
        <>
            <button style={{ minWidth: "150px" }} disabled={lb_dwnloadLoading} className="btn btn-pill btn-success btn-air-success btn-success-gradien" type="button" onClick={() => handleDownload(lo_documentData)}>{lb_dwnloadLoading ? <LoadingOutlined /> : <DownloadOutlined />} Download</button>
        </>
    )
}

export default DocDownload