import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component'
import { Button, Input, message, Row, Switch } from 'antd';
import * as XLSX from 'xlsx';
import { getRequestApiByToken } from '../../../../api';
import { getQuerySelectorValueFormArray } from '../../../../utility';
import dayjs from 'dayjs';
const { Search } = Input;

const startLoadTheMessage = (msg) => message.loading({ content: msg || 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

const UserActivity = ({ lo_formData, lb_loading, setLoading, lb_isSearch }) => {
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const [la_reportList, setReportList] = useState([])
    const [la_columnsList, setColumnsLis] = useState([])
    const [filterText, setFilterText] = useState('');

    const getUserDetailsText = (rowValue) => {
        let ls_text = rowValue?.user ? `${rowValue?.user[0]?.name} (${rowValue?.user[0]?.email})` : '';
        return ls_text;
    }

    const getUserNameText = (rowValue) => {
        let ls_text = '';
        if (rowValue?.user?.name) {
            ls_text = rowValue?.user?.name;
        } else if (rowValue?.user && rowValue?.user[0]) {
            ls_text = rowValue?.user[0]?.name;
        }
        return ls_text;
    }
    const getUserEmailText = (rowValue) => {
        let ls_text = '';
        if (rowValue?.user?.email) {
            ls_text = rowValue?.user?.email;
        } else if (rowValue?.user && rowValue?.user[0]) {
            ls_text = rowValue?.user[0]?.email;
        }
        return ls_text;
    }

    const columns = [
        // {
        //     selector: (row) => <><div className='break-tablerow'>{getUserDetailsText(row)}</div></>,
        //     name: "User Name",
        //     sortable: true,
        //     center: true,
        //     width: '250px',
        // },
        {
            selector: (row) => <><div className='break-tablerow'>{getUserNameText(row)}</div></>,
            name: "User Name",
            sortable: true,
            center: true,
            width: '220px',
        },
        {
            selector: (row) => <><div className='break-tablerow'>{getUserEmailText(row)}</div></>,
            name: "User Email",
            sortable: true,
            center: true,
            width: '260px',
        },
        {
            name: "Viewed",
            selector: (row) => <div className='break-tablerow'>{row?.total_view_count}</div>,
            sortable: true,
            center: true,
        },
        {
            name: "Downloaded",
            selector: (row) => <div className='break-tablerow'>{row?.total_download_count}</div>,
            sortable: true,
            center: true,
        },
        {
            name: "Shared",
            selector: (row) => <div className='break-tablerow'>{row?.total_share_count}</div>,
            sortable: true,
            center: true,
        },
        {
            name: "Approved",
            selector: (row) => <div className='break-tablerow'>{row?.total_approve_count}</div>,
            sortable: true,
            center: true,
        },
        {
            name: "Uploaded",
            selector: (row) => <div className='break-tablerow'>{row?.total_uploaded_count}</div>,
            sortable: true,
            center: true,
        },
    ];

    const handleSearch = (e) => {
        setFilterText(e.target.value);
    };
    const filteredData = la_reportList.filter(item => {
        const newItems = {
            'name': getUserNameText(item),
            'email': getUserEmailText(item),
            'total_view_count': `${item?.total_view_count}`,
            'total_download_count': `${item?.total_download_count}`,
            'total_share_count': `${item?.total_share_count}`,
            'total_approve_count': `${item?.total_approve_count}`,
            'total_uploaded_count': `${item?.total_uploaded_count}`,
        }
        return Object.values(newItems).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
        );
    });

    const getReportList = async () => {
        setLoading(true);
        const lo_paramsValue = { ...lo_formData };
        if (lo_paramsValue?.fromdate) lo_paramsValue['fromdate'] = dayjs(new Date(lo_formData.fromdate)).format('YYYY-MM-DD');
        if (lo_paramsValue?.todate) lo_paramsValue['todate'] = dayjs(new Date(lo_formData.todate)).format('YYYY-MM-DD');
        
        console.log('++++++lo_paramsValue++++',lo_paramsValue);

        const ls_params = getQuerySelectorValueFormArray(lo_paramsValue);

        if (lb_isSearch || ls_params) {
            const res = await getRequestApiByToken(`userwise_report?${ls_params}`);
            console.log('---userwise-report--', res);
            setReportList(res || []);
        }

        setLoading(false);
    }

    const exportToExcel = () => {
        startLoadTheMessage('Exporting...');
        const ws = XLSX.utils.json_to_sheet(la_reportList.map(item => ({
            'User Name': getUserNameText(item),
            'User Email': getUserEmailText(item),
            'Viewed': item?.total_view_count,
            'Downloaded': item?.total_download_count,
            'Shared': item?.total_share_count,
            'Approved': item?.total_approve_count,
            'Uploaded': item?.total_uploaded_count,
        })));
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Document Activity");
        XLSX.writeFile(wb, "user_activity_repport.xlsx");
        stopLoadingSuccessMsh('Exported!');
    };

    useEffect(() => {
        getReportList()
    }, [lo_formData, lb_isSearch])

    useEffect(() => {
        const columnList = [...columns];
        setColumnsLis(columnList);
    }, [ls_userType])


    return (
        <>
            <div>
                <div className="col-xl-12 xl-100">
                    <div className="card parent-tblsec">
                        <div className="card-header card-header-border">
                            <div className="row" style={{ alignItems: 'center' }}>
                                <div className="col-sm-7">

                                    <h5>{"User Summary"}</h5>

                                </div>
                                <div className="col-sm-5">
                                    <div className="pull-right right-header">
                                        <div className="onhover-dropdown">
                                            <div style={{ display: "flex", alignItems: "stretch", justifyContent: "space-between" }}>
                                                <div style={{ paddingRight: 8 }}>
                                                    <Button disabled={!la_reportList?.length} onClick={exportToExcel} type="dashed" icon={<ExportOutlined />}>Export</Button>
                                                </div>
                                                <div>
                                                    <Search allowClear value={filterText} onChange={handleSearch} placeholder="Serach here..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={la_columnsList}
                                    // data={la_reportList}
                                    data={filteredData}
                                    progressPending={lb_loading}
                                    striped={true}
                                    center={true}
                                    persistTableHead
                                    pagination

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserActivity