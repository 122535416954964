import React, { Fragment } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { User, Mail, Lock, Settings, LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userimg from "../../../assets/images/user/demouser.png"
import adminimg from "../../../assets/images/user/demouser.png"
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

const UserMenu = () => {
  const dispatch = useDispatch();
  const ls_userType = useSelector(content => content.commonReducer.user_type);
  const ls_loginType = useSelector(content => content.commonReducer.login_type);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // const userimg = 'https://react.pixelstrap.com/endless/static/media/16.e30fc62d991f11de1ef0.jpg';
  // const adminimg = 'https://react.pixelstrap.com/endless/static/media/5.53a215ed00aac31d7543.jpg';
  const dfltimg = 'https://e7.pngegg.com/pngimages/809/39/png-clipart-computer-icons-user-symbol-encapsulated-postscript-info-miscellaneous-cdr-thumbnail.png';

  const navigate = useNavigate();
  const handleLogOut = () => {
    console.log('ls_loginType',ls_loginType);
    
    dispatch({ type: 'SET_USER_TOKEN', payload: null });
    dispatch({ type: 'SET_USER_ID', payload: null });
    dispatch({ type: 'SET_USER_TYPE', payload: null });
    dispatch({ type: 'SET_USER_DETAILS', payload: {} });
    dispatch({ type: 'SET_LOGIN_TYPE', payload: '' });
    dispatch({ type: 'SET_APPROVE_DOCUMENTS_COUNT', payload: 0 });
    dispatch({ type: 'SET_HAS_SECTOR', payload: false });

    if(instance && ls_loginType=='msal') instance?.logout();
    
    navigate(`${process.env.PUBLIC_URL}/login`);
  }
  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="d-flex align-items-center">
          <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={ls_userType == 'user' ? userimg : (ls_userType == 'admin' ?adminimg : dfltimg)} alt="header-user" />
          {/* <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div> */}
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={"/my-profile"}>
              <User />
              Profile
            </Link>
          </li>
          {/* <li>
            <a href="#javascript">
              <Mail />
              Inbox
            </a>
          </li> */}
          {/* <li>
            <a href="#javascript">
              <Lock />
              Lock Screen
            </a>
          </li> */}
          {/* <li>
            <a href="#javascript">
              <Settings />
              Settings
            </a>
          </li> */}
          <li>
            <a href="#javascript" onClick={handleLogOut}>
              <LogOut /> Log out
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
