
import React, { useEffect, useState } from 'react';
import { Container, Row } from "reactstrap";
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../../components/common/breadcrumb';
import AddForm from './AddForm';
import { Form, message , Input, Switch} from 'antd';
import { getRequestApiByToken, postRequestApiByToken } from '../../../../api';
import { LoadingOutlined } from '@ant-design/icons';
const { Search } = Input;

const Practices = () => {
  const [la_list, setList] = useState([])
  const [loading, setLoading] = useState(false);
  const [lb_buttonLoader, setButtonLoader] = useState(false)
  const [ls_updateId, setUpdateId] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [lb_spining, setSpining] = useState(false)
  const [filterText, setFilterText] = useState('');

  const [form] =Form.useForm()

  const getList = async () => {
    setLoading(true)
      const res = await getRequestApiByToken(`practice`)
      setList(res?.practices)
    setLoading(false)
  }

 useEffect(() => {
    getList()
 }, [currentPage])

 const columns = [
  {
    selector: (row,inx) => inx + 1,
    name: "Sl No.",
    sortable: true,
    // width: '100px', 
    center: true,
  },
  {
    selector: (row) => 
    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
    {row?.practice_name}
    </div>,
    name: "Practices Name",
    sortable: true,
    // width: '320px', 
    // center: true,
  },
  {
    selector: (row) => <div>
     <span style={{cursor:"pointer"}}>
     <Switch defaultChecked={row.status}
              size='small'
              onChange={(s)=>onChange(s,row)}
    />
      <i 
      className="fa fa-pencil" 
      style={{ width: 40, fontSize: 18, paddingInlineStart: 11, color: "rgb(40, 167, 69)",marginTop:7 }}
      onClick={() => { setUpdateId(row.id); window.scrollTo({ top: 0, behavior: "smooth" }) ;}}
      ></i>
    </span>
  </div>,
    name: "Action",
  //   sortable: true,
    center: true,
    // width: '150px',
  },
];

const onChange =async (checked,row) => {
  let lo_formData = {
    practice_name: row?.practice_name,
    status: checked == true ? 1 : 0
  }
    const res = await postRequestApiByToken('practice/' + row?.id, lo_formData)
    if (res?.status =="Success") {
      message.success('Status Successfully Updated!');
    }
};

const resetFormData = async ()=>{
  form.resetFields()
  setUpdateId('')
}

const setFormData = async ()=>{
  setSpining(true)
  const lo_updateDate = {}
  if (ls_updateId) {
      const lo_response = await getRequestApiByToken(`practice/${ls_updateId}`);
      const lo_record = lo_response?.Practice ? lo_response?.Practice : {};
      lo_updateDate['practice_name'] = lo_record.practice_name;
  }
  form.resetFields()
  form.setFieldsValue(lo_updateDate)
  setSpining(false)
}
useEffect(() => {
  setFormData();
}, [ls_updateId]);


 const handleSubmitForm = async (formValues) => {
  let lo_formData = {
      ...formValues,
  }
  setButtonLoader(true)
  if (ls_updateId) {
    const res = await postRequestApiByToken('practice/' + ls_updateId, lo_formData)
    if (res?.status == 'Success') {
        // setFormData()
        getList()
        form.resetFields()
        form.setFieldsValue({})
        setUpdateId('')
        message.success('Successfully Updated!');
    } else {
        message.error(res?.message);
    }
    } else {
      const res = await postRequestApiByToken('practice', lo_formData)
      if (res?.status == "Success") {
        getList()
        form.resetFields()
        form.setFieldsValue({})
        setUpdateId('')
        message.success(res?.message);
      }else{
        message.error(res?.message)
      }
    }
    setButtonLoader(false)
  }
 // Define search state

 // Function to handle search input change
 const handleSearch = (e) => {
   setFilterText(e.target.value);
 };

 // Filtered data based on search text
 const filteredData = la_list?.filter((item) =>
   Object.values(item).some(
     (value) => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
   )
 );

    return (
        <>
        <Breadcrumb parent="Add Practices" title="Manage Practices"  />
        <Container fluid={true}>
          <Row>
        <div className="col-xl-5 xl-100">
        <AddForm
          handleSubmitForm={handleSubmitForm}
          lb_buttonLoader={lb_buttonLoader}
          ls_updateId={ls_updateId}
          form={form}
          resetFormData={resetFormData}
          lb_spining={lb_spining}
        />
        </div>
        <div className="col-xl-7 xl-100">
          <div className="card">
             <div className="card-header card-header-border">
                        <div className="row" style={{alignItems:'center'}}>
                        <div className="col-sm-7">
                            <h5>{"Practices Details"}</h5>
                        </div>
                        <div className="col-sm-5">
                       <Search value={filterText} onChange={handleSearch} placeholder="Serach here..." />  
                        </div>
                        </div>
                    </div>
             <div className="card-body">
                <div className="table-responsive">
                     <DataTable
                      columns={columns}
                      // data={la_list}
                      data={filteredData}
                      striped={true}
                      center={true}
                      persistTableHead
                      pagination
                      // paginationServer
                      // paginationTotalRows={totalCount} // Assuming 10 rows per page
                      paginationPerPage={10} // Number of rows per page
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                      paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                      onChangePage={setCurrentPage} 
                      progressPending={loading}
                      progressComponent={<div><LoadingOutlined/></div>}
                      customStyles={{
                      headCells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px',
                          },
                      },
                      rows: { style: { minHeight: '40px'}},
                      cells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px', // Adjust cell padding as needed
                          },
                      },
                      }}
                   /> 
                </div>
             </div>
          </div>
          </div>
          </Row>
      </Container>
        </>
    )
  }
export default Practices 
