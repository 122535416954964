import React, { useEffect, useState } from 'react'
import "./style.css";
import Chart from 'react-apexcharts'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { Segmented } from 'antd';
import { getRequestApiByToken } from '../../../../api';
import { getSpecificDateValueByDate } from '../../../../utility';

let apexColumnChartsone = {
  series: [],
  options: {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#948acb', '#7ebdd8', '#ddc7af'],
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1
    },
  },
};

const UserActivity = () => {
  const [lo_chartDetails, setChartDetails] = useState({ ...apexColumnChartsone });
  const [ls_activityType, setActivityType] = useState('monthly');
  const [li_changeCount, setChangeCount] = useState(1);

  const handleSetChartDetails = async (lo_result) => {
    const lo_newresult = { ...lo_result };
    // const la_result = Object.values(lo_newresult);
    let lo_chartData = { ...apexColumnChartsone };

    const la_dataLabels = [];
    const la_totalViewd = [];
    const la_totalDownloaded = [];
    const la_totalShared = [];

    // for (let j = 0; j < la_result.length; j++) {
    //   const rowValue = la_result[j];
      
    // }

    for (const [rowKey, rowValue] of Object.entries(lo_newresult)) {
      la_totalViewd.push(rowValue?.Total_Viewed || 0);
      la_totalDownloaded.push(rowValue?.Total_Downloaded || 0);
      la_totalShared.push(rowValue?.Total_Shared || 0);
      const ls_dateKey = getSpecificDateValueByDate(rowKey, ls_activityType) || '-';
      la_dataLabels.push(ls_dateKey);
    }

    const la_series = [
      { name: 'Viewed', data: [...la_totalViewd] }, 
      { name: 'Downloaded', data: [...la_totalDownloaded] },
      { name: 'Shared', data: [...la_totalShared] },
    ]

    lo_chartData['series'] = la_series;
    lo_chartData['options']['xaxis']['categories'] = [...la_dataLabels];

    setChartDetails(lo_chartData);
  }

  const getAndSetChartDetails = async () => {
    let ls_apipath = `user_activity/${ls_activityType}`;
    if(ls_activityType == 'daily') ls_apipath = `user_activity/monthly`;
    if(ls_activityType == 'monthly') ls_apipath = `yearly_user_activity`;
    if(ls_activityType == 'yearly') ls_apipath = `year_wise_user_activity`;
    let res = await getRequestApiByToken(ls_apipath);
    handleSetChartDetails(res || {});
  }

  useEffect(() => {
    if(ls_activityType) getAndSetChartDetails()
  }, [ls_activityType])

  useEffect(() => {
    console.log('--lo_chartDetails', lo_chartDetails);
    setChangeCount(li_changeCount+1)
  }, [lo_chartDetails])
  return (
    <><Card style={{ height: 540 }}>
      <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5>{`User's Activity`} </h5>
        <div className='activity-prnt-segemnt'>
          {/* <div className="btn-group btn-group-pill" role="group" aria-label="Basic example">
            <button className="btn btn-info btn-xs active" style={{ padding: 5 }} type="button">{'Daily'}</button>
            <button className="btn btn-info btn-xs" style={{ padding: 5 }} type="button">{'Monthly'}</button>
            <button className="btn btn-info btn-xs" style={{ padding: 5 }} type="button">{'Yearly'}</button>
          </div> */}
          <Segmented
            value={ls_activityType}
            options={[{label:'Daily', value:'daily'}, {label:'Monthly', value:'monthly'}, {label:'Yearly', value:'yearly'}]}
            onChange={(value) => { setActivityType(value) }}
          />
        </div>
      </CardHeader>
      <CardBody style={{ overflowX: 'scroll' }}>
        <div id="column-chart" >
        {li_changeCount ? <Chart key={li_changeCount} width={1150} options={lo_chartDetails.options} series={lo_chartDetails.series} type="bar" height={350} /> : <></>}
        </div>
      </CardBody>

    </Card></>
  )
}

export default UserActivity;