
import React, { useEffect, useState } from 'react';
import { Container } from "reactstrap";
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../../components/common/breadcrumb';
import AddForm from './AddForm';
import { Form, message,Input } from 'antd';
import { getRequestApiByToken, postRequestApiByToken } from '../../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Search } = Input;

const Department = () => {
  const [la_list, setList] = useState([])
  const [loading, setLoading] = useState(false);
  const [lb_buttonLoader, setButtonLoader] = useState(false)
  const [ls_updateId, setUpdateId] = useState('')
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lb_spining, setSpining] = useState(false)

  const [form] =Form.useForm()

  const getList = async () => {
    setLoading(true)
      const res = await getRequestApiByToken(`department_master`)
      setList(res?.department_master)
      // setTotalCount(res?.Documents?.total);
    setLoading(false)
  }

 useEffect(() => {
    getList()
 }, [currentPage])

 const columns = [
  {
    selector: (row) => row?.name,
    name: "Department Name",
    sortable: true,
    // width: '310px', 
    // center: true,
  },
  {
    selector: (row) => moment(row?.created_at).format("DD MMM YYYY"),
    name: "Created Date",
    sortable: true,
    center: true,
    // width: '110px', 
  },
  {
    selector: (row) => moment(row?.updated_at).format("DD MMM YYYY"),
    name: "Updated Date",
    sortable: true,
    center: true,
    // width: '110px', 
  },
  {
    selector: (row) => <div>
     <span style={{cursor:"pointer"}}>
      <i 
      className="fa fa-pencil" 
      style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}
      onClick={() => { setUpdateId(row.id); window.scrollTo({ top: 0, behavior: "smooth" }) ;}}
      ></i>
    </span>
    <span>
      <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    </span>
  </div>,
    name: "",
  //   sortable: true,
    center: true,
    width: '100px',
  },
];

const resetFormData = async ()=>{
  form.resetFields()
  setUpdateId('')
}

const setFormData = async ()=>{
  setSpining(true)
  const lo_updateDate = {}
  if (ls_updateId) {
      const lo_response = await getRequestApiByToken(`department_master/${ls_updateId}`);
      const lo_record = lo_response?.Department ? lo_response?.Department : {};
      lo_updateDate['name'] = lo_record.name;
  }
  form.resetFields()
  form.setFieldsValue(lo_updateDate)
  setSpining(false)
}
useEffect(() => {
  setFormData();
}, [ls_updateId]);


 const handleSubmitForm = async (formValues) => {
  let lo_formData = {
      ...formValues,
    
  }
  setButtonLoader(true)
  if (ls_updateId) {
    const res = await postRequestApiByToken('department_master/' + ls_updateId, lo_formData)
    if (res?.status == 'Success') {
        // setFormData()
        getList()
        form.resetFields()
        form.setFieldsValue({})
        setUpdateId('')
        message.success('Successfully Updated!');
    } else {
        message.error(res?.message);
    }
    } else {
      const res = await postRequestApiByToken('department_master', lo_formData)
      if (res?.status == "Success") {
        getList()
        form.resetFields()
        form.setFieldsValue({})
        setUpdateId('')
        message.success(res?.message);
      }else{
        message.error(res?.message)
      }
    }
    setButtonLoader(false)
  }

    // Define search state
    const [filterText, setFilterText] = useState('');

    // Function to handle search input change
    const handleSearch = (e) => {
      setFilterText(e.target.value);
    };
  
    // Filtered data based on search text
    const filteredData = la_list?.filter((item) =>
      Object.values(item).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
      )
    );
    return (
        <>
        <Breadcrumb parent="Add Department" title="Manage Department"  />
        <Container fluid={true}>
        <AddForm
          handleSubmitForm={handleSubmitForm}
          lb_buttonLoader={lb_buttonLoader}
          ls_updateId={ls_updateId}
          form={form}
          resetFormData={resetFormData}
          lb_spining={lb_spining}
        />
        <div className="col-xl-12 xl-100">
          <div className="card">
             <div className="card-header card-header-border">
                        <div className="row" style={{alignItems:'center'}}>
                        <div className="col-sm-7">
                            <h5>{"Department Details"}</h5>
                        </div>
                        {/* <div className="col-sm-5">
                            <div className="pull-right right-header">
                            <div className="onhover-dropdown">
                                <button className="btn btn-primary btn-pill" type="button">
                                {'2024'}{" "}
                                <span className="pe-0">
                                    <i className="fa fa-angle-down"></i>
                                </span>
                                </button>
                                <div className="onhover-show-div right-header-dropdown">
                                <a className="d-block" href="#javascript">
                                    {'2024'}
                                </a>
                                <a className="d-block" href="#javascript">
                                    {'2023'}
                                </a>
                                <a className="d-block" href="#javascript">
                                    {'2022'}
                                </a>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        <div className="col-sm-5">
                            <div className="pull-right right-header">
                            <div className="onhover-dropdown">
                              {/* <Input value={filterText} addonAfter={<SearchOutlined />} onChange={handleSearch} placeholder="Serach here..." /> */}
                              <Search value={filterText} onChange={handleSearch} placeholder="Serach here..." />
                              {/* <input
                                type="text"
                                placeholder="Search..."
                                value={filterText}
                                onChange={handleSearch}
                              /> */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
             <div className="card-body">
                <div className="table-responsive">
                     <DataTable
                      columns={columns}
                      // data={la_list}
                      data ={filteredData}
                      striped={true}
                      center={true}
                      persistTableHead
                      pagination
                      // paginationServer
                      // paginationTotalRows={totalCount} // Assuming 10 rows per page
                      paginationPerPage={10} // Number of rows per page
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                      paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                      onChangePage={setCurrentPage} 
                      progressPending={loading}
                      progressComponent={<div><LoadingOutlined/></div>}
                      customStyles={{
                      headCells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px',
                          },
                      },
                      rows: { style: { minHeight: '40px'}},
                      cells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px', // Adjust cell padding as needed
                          },
                      },
                      }}
                   /> 
                </div>
             </div>
          </div>
          </div>
      </Container>
        </>
    )
  }
export default Department 
