import React, { useEffect, useState } from 'react'
import { Doughnut } from "react-chartjs-2";
import { getRequestApiByToken } from '../../../api';
import { getAutoRandomColor } from '../../../utility';

const doughnutData = {
    labels: [],
    datasets: [
        {
            legend: "none",
            data: [],
            borderColor: [],
            backgroundColor: [],
            fill: "origin",
        },
    ],
};

const doughnutOptions = {
    maintainAspectRatio: false, // Adjusted to false
    responsive: true, // Ensure the chart is responsive
    elements: {
        point: {
            hoverRadius: 7,
            radius: 5,
        },
    },
    legend: {
        display: false,
    },
    plugins: {
        datalabels: {
            display: true, // show the data labels
            formatter: (value, ctx) => {
                return value; // return the value to display
            },
        },
    },
};
const PracticesOverviews = () => {
    const [lo_chartDetails, setChartDetails] = useState({...doughnutData});

    const handleSetChartDetails = async (lo_result) => {
        const lo_newresult = { ...lo_result };
        const la_result = Object.values(lo_newresult);
        let lo_chartData = { ...doughnutData };

        const la_labels = [];
        const la_datas = [];
        const la_backgroundColor = [];
        const la_borderColor = [];

        for (let j = 0; j < la_result.length; j++) {
            const rowValue = la_result[j];
            if (rowValue?.practice_name !== 'Unknown') {
                la_labels.push(rowValue?.practice_name || '-');
                la_datas.push(rowValue?.documents_count || 0);
                const randomColor = getAutoRandomColor();
                la_backgroundColor.push(randomColor);
                la_borderColor.push(randomColor);
            }
        }

        lo_chartData['labels'] = la_labels;
        lo_chartData['datasets'][0]['data'] = [...la_datas];
        lo_chartData['datasets'][0]['backgroundColor'] = la_backgroundColor;
        lo_chartData['datasets'][0]['borderColor'] = la_borderColor;

        setChartDetails(lo_chartData);
    }

    const getAndSetChartDetails = async () => {
        let res = await getRequestApiByToken(`practice_wise_data`);
        handleSetChartDetails(res || {});
    }

    useEffect(() => {
        getAndSetChartDetails()
    }, []);

    return (
        <div className="card card-height" style={{ maxHeight: 506 }}  >
            <div className="card-header">
                <h5>{'Practices Overview'}</h5>
            </div>
            <div className="card-body chart-block">
                <div className="flot-chart-placeholder" id="bitcoin-morris">
                    {(lo_chartDetails?.labels?.length && lo_chartDetails?.datasets?.[0]?.data?.length) ? 
                    <Doughnut data={lo_chartDetails} options={doughnutOptions} height={210} />
                    : <></>}
                </div>
            </div>
        </div>
    )
}

export default PracticesOverviews