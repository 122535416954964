import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import commonReducer from "./commonAction/reducer";

const reducers = combineReducers({
  Customizer,
  commonReducer,
});

export default reducers;
