//CUSTOMIZER
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";

// login
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_TYPE = "SET_USER_TYPE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_LOGIN_TYPE = "SET_LOGIN_TYPE";
export const SET_HAS_SECTOR = "SET_HAS_SECTOR";

//approve document count
export const SET_APPROVE_DOCUMENTS_COUNT = "SET_APPROVE_DOCUMENTS_COUNT";

export const SET_Notifications = "SET_Notifications";
export const SET_NotificationCount = "SET_NotificationCount";
