import { Button, Cascader, Col, Divider, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import './style.css'
import { SearchOutlined } from '@ant-design/icons';
import Typography from 'antd/lib/typography/Typography';
import { debounce } from 'lodash';
import { DatePicker, InputNumber, Slider, Spin } from 'antd/lib';
import { getRequestApiByToken } from '../../../../api';
import { DateFormat } from '../../../../constant/globalConfig';
const { Search } = Input;

const ProjectSearchForm = ({ advanceForm, handleSearchData = () => { }, lb_loading }) => {

    const [lb_projectFormLoading, setProjectFormLoading] = useState(true);
    const [la_consortiumList, setConsortiumList] = useState([]);
    const [la_currencyList, setCurrencyList] = useState([]);
    const [lo_rangeValues, setRangeValues] = useState({
        "tot_value_min": 0,
        "tot_value_max": 0,
        "firm_value_min": 0,
        "firm_value_max": 0
    });

    const [la_sectorData, setSectorData] = useState([])
    const [la_practices, setPractices] = useState([])
    const [la_country, setCountry] = useState([])
    const [la_donorName, setDonorName] = useState([])

    const ls_tot_value_currency_id = Form.useWatch('tot_value_currency_id', advanceForm);
    const ls_firm_value_currency_id = Form.useWatch('firm_value_currency_id', advanceForm);

    const la_tot_value_range = Form.useWatch('tot_value_range', advanceForm);
    const la_firm_value_range = Form.useWatch('firm_value_range', advanceForm);

    const getConsortiumData = async () => {
        setProjectFormLoading(true)
        const res = await getRequestApiByToken('consortium_master');
        console.log('---consortium_master---', res);
        const la_newArr = res?.data || [];
        la_newArr.unshift({ id: 0, name: "No Data" });
        setConsortiumList(la_newArr)
        setProjectFormLoading(false)
    }
    const getCurrencyData = async () => {
        setProjectFormLoading(true)
        const res = await getRequestApiByToken('currency_master');
        setCurrencyList(res?.data || [])
        setProjectFormLoading(false)
    }

    const getSectorData = async () => {
        const res = await getRequestApiByToken('get_sectors')
        setSectorData(res?.sector_master || []);
    }
    const getPracticeData = async () => {
        const res = await getRequestApiByToken('practice')
        setPractices(res?.practices || []);
    }
    const getCountryData = async () => {
        const res = await getRequestApiByToken('country_master')
        setCountry(res?.country_master || [])
    }
    const getDonorData = async () => {
        const res = await getRequestApiByToken('client_master')
        setDonorName(res?.client_master || [])
    }

    const getAndSetRangeValues = async () => {
        setProjectFormLoading(true)
        const res = await getRequestApiByToken('project_values');
        console.log('--res--', res);
        const tot_value_min = res?.data?.tot_value_min ? parseFloat(res?.data?.tot_value_min) : 0;
        const tot_value_max = res?.data?.tot_value_max ? parseFloat(res?.data?.tot_value_max) : 0;
        const firm_value_min = res?.data?.firm_value_min ? parseFloat(res?.data?.firm_value_min) : 0;
        const firm_value_max = res?.data?.firm_value_max ? parseFloat(res?.data?.firm_value_max) : 0;
        const lo_rangeVal = { tot_value_min, tot_value_max, firm_value_min, firm_value_max }
        setRangeValues(lo_rangeVal)
        setProjectFormLoading(false)
        // setRangeTotValues([tot_value_min, tot_value_max])
    }

    // Add the handle functions for updating values
    const [la_rangeTotValues, setRangeTotValues] = useState([]);
    const [la_rangeFirmValues, setRangeFirmValues] = useState([]);

    const handleTotValueChange = (values) => {
        console.log('--values-',values);
        const sanitizedValues = values.map(val => Number(val)); // Convert values to numbers
        console.log('--sanitizedValues-',sanitizedValues);
        advanceForm.setFieldsValue({ 'tot_value_range': sanitizedValues });
        setRangeTotValues(sanitizedValues)
    };
    const handleTotValueBlur = () => {
        console.log('-handleTotValueBlur-');
        advanceForm.setFieldsValue({ 'tot_value_range': la_rangeTotValues || [] }); // Update the form field
        setRangeTotValues(la_rangeTotValues || [])
    };
    const handleFirmValueChange = (values) => {
        console.log('-handleFirmValueChange-');
        const sanitizedValues = values.map(val => Number(val)); // Convert values to numbers
        advanceForm.setFieldsValue({ 'firm_value_range': sanitizedValues });
        setRangeFirmValues(sanitizedValues)
    };
    const handleFirmValueBlur = () => {
        console.log('-handleFirmValueBlur-');
        advanceForm.setFieldsValue({ 'firm_value_range': la_rangeFirmValues || [] }); // Update the form field
        setRangeFirmValues(la_rangeFirmValues)
    };

    useEffect(() => {
        getConsortiumData();
        getCurrencyData();
        getAndSetRangeValues();
        getSectorData();
        getPracticeData();
        getCountryData();
        getDonorData();
    }, [])

    useEffect(() => {
        let la_valueRange = '';
        if (ls_tot_value_currency_id) la_valueRange = [lo_rangeValues?.tot_value_min, lo_rangeValues?.tot_value_max];
        advanceForm.setFieldsValue({ 'tot_value_range': la_valueRange })
        setRangeTotValues([...la_valueRange])
    }, [ls_tot_value_currency_id])

    useEffect(() => {
        let la_valueRange = '';
        if (ls_firm_value_currency_id) la_valueRange = [lo_rangeValues?.firm_value_min, lo_rangeValues?.firm_value_max];
        advanceForm.setFieldsValue({ 'firm_value_range': la_valueRange })
        setRangeFirmValues([...la_valueRange])
    }, [ls_firm_value_currency_id])


    return (
        <>
            <Card>
                <CardBody>
                    <Form
                        className="theme-form"
                        labelCol={{ span: 24 }}
                        onFinish={handleSearchData}
                        form={advanceForm}
                    >
                        <Spin spinning={lb_projectFormLoading}>
                            <Row gutter={[6]}>
                                <Col xs={24} sm={8} md={8} lg={8}>
                                    <Form.Item
                                        label="From Date"
                                        name="start_date"
                                        style={{ width: '100%' }}
                                    >
                                        <DatePicker format={DateFormat} style={{ width: '100%' }} needConfirm={false} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={8} lg={8}>
                                    <Form.Item
                                        label="To Date"
                                        name="end_date"
                                        style={{ width: '100%' }}
                                        size={12}
                                    >
                                        <DatePicker format={DateFormat} style={{ width: '100%' }} needConfirm={false} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={8} md={8} lg={8}>
                                    <Form.Item
                                        label="Consortium"
                                        name="consortium_id"
                                    >
                                        <Select placeholder="Role in the Consortium" allowClear>
                                            {la_consortiumList.map(rowValue => (
                                                <Select.Option key={rowValue.id} value={rowValue.id}>{rowValue.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* sector practice country donor name */}
                                
                                <Col xs={24} sm={6} md={6} lg={6}>
                                    <Form.Item
                                        label="Sectors"
                                        name="sector_id"
                                    >
                                        <Select
                                            placeholder="Select Sectors"
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {la_sectorData.map(sector => (
                                                <Select.Option key={sector.id} value={sector.id}>
                                                    {sector.sector_name}
                                                </Select.Option>
                                            ))}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={6} md={6} lg={6}>
                                    <Form.Item
                                        label="Practices"
                                        name="parctices"
                                    >
                                        <Select placeholder="Select Practices"
                                            // options={la_practices}
                                            mode='multiple'
                                            allowClear showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {la_practices?.map(practices => (
                                                <Select.Option key={practices.id} value={practices.id}>
                                                    {practices.practice_name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={6} md={6} lg={6}>
                                    <Form.Item label="Country" name="country_id">
                                        <Select placeholder="Select Country"
                                            mode='multiple'
                                            allowClear showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {la_country?.map(country => (
                                                <Select.Option key={country.id} value={country.id}>
                                                    {country.country_name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={6} md={6} lg={6}>
                                    <Form.Item
                                        label="Donor Name"
                                        name="donor"
                                    >
                                        <Select placeholder="Select Donor"
                                            mode='multiple'
                                            allowClear showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {la_donorName?.map(rowValue => (
                                                <Select.Option key={rowValue.id} value={rowValue.id}>
                                                    {rowValue.client_name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "16%", paddingRight: 10 }}>
                                            <Form.Item label="Select Currency" name="tot_value_currency_id" >
                                                <Select placeholder="Select Currency" showSearch allowClear
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {la_currencyList && la_currencyList.map(rowValue => <Select.Option value={rowValue.id} key={rowValue.id}>{rowValue.currency}</Select.Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div style={{ width: "84%", display: 'flex', alignItems: 'center' }}>
                                            <Form.Item
                                                label={`Consultancy Fees Range ${(Array.isArray(la_tot_value_range) && la_tot_value_range.length > 0) ? '(' + la_tot_value_range.join(' - ') + ')' : ''}`}
                                                name='tot_value_range'
                                                style={{ width: '100%' }}
                                            >
                                                <Row gutter={16}>
                                                    <Col span={6}>
                                                        <InputNumber
                                                            min={lo_rangeValues?.tot_value_min}
                                                            max={lo_rangeValues?.tot_value_max}
                                                            value={la_rangeTotValues?.[0]}
                                                            onChange={(value) => {
                                                                console.log('____value___???',value);
                                                                handleTotValueChange([value, la_rangeTotValues?.[1]])
                                                            }}
                                                            onBlur={handleTotValueBlur}
                                                            disabled={!ls_tot_value_currency_id}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Slider
                                                            disabled={!ls_tot_value_currency_id}
                                                            range
                                                            min={lo_rangeValues?.tot_value_min}
                                                            max={lo_rangeValues?.tot_value_max}
                                                            value={ la_tot_value_range || [lo_rangeValues?.tot_value_min, lo_rangeValues?.tot_value_max]}
                                                            onChange={handleTotValueChange}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <InputNumber
                                                            min={lo_rangeValues?.tot_value_min}
                                                            max={lo_rangeValues?.tot_value_max}
                                                            value={la_rangeTotValues?.[1]}
                                                            onChange={(value) => handleTotValueChange([la_rangeTotValues?.[0], value])}
                                                            onBlur={handleTotValueBlur}
                                                            disabled={!ls_tot_value_currency_id}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: "16%", paddingRight: 10 }}>
                                            <Form.Item label="Select Currency" name="firm_value_currency_id" >
                                                <Select placeholder="Select Currency" showSearch allowClear
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {la_currencyList && la_currencyList.map(rowValue => <Select.Option value={rowValue.id} key={rowValue.id}>{rowValue.currency}</Select.Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div style={{ width: "84%", display: 'flex', alignItems: 'center' }}>
                                            <Form.Item
                                                label={`Value Of Services ${(Array.isArray(la_firm_value_range) && la_firm_value_range.length > 0) ? '(' + la_firm_value_range.join(' - ') + ')' : ''}`}
                                                name='firm_value_range'
                                                style={{ width: '100%' }}
                                            >
                                                <Row gutter={16}>
                                                    <Col span={6}>
                                                        <InputNumber
                                                            min={lo_rangeValues?.firm_value_min}
                                                            max={lo_rangeValues?.firm_value_max}
                                                            value={la_rangeFirmValues?.[0]}
                                                            onChange={(value) => handleFirmValueChange([value, la_rangeFirmValues?.[1]])}
                                                            onBlur={handleFirmValueBlur}
                                                            disabled={!ls_firm_value_currency_id}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Slider
                                                            disabled={!ls_firm_value_currency_id}
                                                            range
                                                            min={lo_rangeValues?.firm_value_min}
                                                            max={lo_rangeValues?.firm_value_max}
                                                            value={la_firm_value_range || [lo_rangeValues?.firm_value_min, lo_rangeValues?.firm_value_max]}
                                                            onChange={handleFirmValueChange}
                                                        />
                                                    </Col>
                                                    <Col span={6}>
                                                        <InputNumber
                                                            min={lo_rangeValues?.firm_value_min}
                                                            max={lo_rangeValues?.firm_value_max}
                                                            value={la_rangeFirmValues?.[1]}
                                                            onChange={(value) => handleFirmValueChange([la_rangeFirmValues?.[0], value])}
                                                            onBlur={handleFirmValueBlur}
                                                            disabled={!ls_firm_value_currency_id}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Col>



                                <Col xs={24} sm={24} md={24} lg={24} style={{ marginTop: -20 }}>
                                    <div className="serchdoc-btn" style={{ float: "right", minWidth: 240 }}>
                                        <Button
                                            loading={lb_loading}
                                            icon={<i className="fa fa-search" style={{ color: 'white' }} />}
                                            className="btn btn-primary button-style"
                                            type="button"
                                            htmlType='submit'
                                            style={{ marginInlineStart: 3 }}
                                        >Search</Button>
                                    </div>
                                </Col>

                            </Row>
                        </Spin>

                    </Form>
                </CardBody>
            </Card>
        </>
    )
}

export default ProjectSearchForm