export const MicrosoftClientId = "5040bcd6-459f-4d6a-a289-af03707e9a1d";

export const MicrosoftRedirectUri = process.env.PUBLIC_URL + "/auth_login";

export const MsalConfig = {
    auth: {
        clientId: MicrosoftClientId,
        // authority: BaseUrl,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: MicrosoftRedirectUri,
        postLogoutRedirectUri: `${process.env.PUBLIC_URL}/login`,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                // switch (level) {
                //   case LogLevel.Error:
                //     console.error(message);
                //     return;
                //   case LogLevel.Info:
                //     console.info(message);
                //     return;
                //   case LogLevel.Verbose:
                //     console.debug(message);
                //     return;
                //   case LogLevel.Warning:
                //     console.warn(message);
                //     return;
                //   default:
                //     return;
                // }
            },
        },
    },
};