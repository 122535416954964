import React, { Fragment, useEffect, useState } from 'react'
import "./style.css";
import CountUp from 'react-countup'
import { Link2, Download , DownloadCloud, Eye, Send, HelpCircle, Bookmark, File} from 'react-feather'


const DocumentBasicCounter = ({lo_counterData}) => {
    return (
        <div>
            <div className="row docdetail-counter doc-countr-prnt">
                <div className="col-xl-4 xl-33 col-sm-6">
                    <div className="card server-card-bg">
                        <div className="card-body server-widgets">
                            <div className="d-flex">
                                <DownloadCloud />
                                <div className="top-server">
                                    <h6 style={{ fontSize: 14 }} className="mb-0">Download</h6>
                                </div>
                            </div>

                            <div className="bottom-server">
                                <h4 className="mb-0" style={{textAlign:"center"}}>
                                    <span className="second-color counter">
                                        <CountUp end={lo_counterData?.download_count || 0} />
                                    </span>
                                </h4>
                            </div>
                            {/* <div className="progress">
                                <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "90%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 xl-33 col-sm-6">
                    <div className="card server-card-bg">
                        <div className="card-body server-widgets">
                            <div className="d-flex">
                                <Eye />
                                <div className="top-server">
                                    <h6 className="mb-0" style={{ fontSize: 14 }}>Views</h6>
                                </div>
                            </div>

                            <div className="bottom-server">
                                <h4 className="mb-0" style={{textAlign:"center"}}>
                                    <span className="second-color counter">
                                        <CountUp end={lo_counterData?.view_count || 0} />
                                    </span>
                                </h4>
                            </div>

                            {/* <div className="progress">
                                <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "90%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 xl-33 col-sm-6">
                    <div className="card server-card-bg">
                        <div className="card-body server-widgets">
                            <div className="d-flex">
                                <Link2 />
                                <div className="top-server">
                                    <h6 className="mb-0" style={{ fontSize: 14 }}>Linked User</h6>
                                </div>
                            </div>

                            <div className="bottom-server">
                                <h4 className="mb-0" style={{textAlign:"center"}}>
                                    <span className="second-color counter" >
                                        <CountUp end={lo_counterData?.linked_user || 0} />
                                    </span>
                                </h4>
                            </div>

                            {/* <div className="progress">
                                <div className="progress-bar-animated bg-primary progress-bar-striped" role="progressbar" style={{ width: "90%" }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentBasicCounter