
import React, { useEffect, useState } from 'react';
import { Container } from "reactstrap";
import DataTable from 'react-data-table-component'
import Breadcrumb from '../../../../components/common/breadcrumb';
import AddForm from './AddForm';
import { Form, message,Input, Switch, Image } from 'antd';
import { getRequestApiByToken, postRequestApiByToken } from '../../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AwsFilePath } from '../../../../constant/globalConfig';
const { Search } = Input;

const DonorAgencies = () => {
  const [la_list, setList] = useState([])
  const [loading, setLoading] = useState(false);
  const [lb_buttonLoader, setButtonLoader] = useState(false)
  const [ls_updateId, setUpdateId] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [lb_spining, setSpining] = useState(false)

  const [form] =Form.useForm()

  const getList = async () => {
    setLoading(true)
      const res = await getRequestApiByToken(`client_master`)
      setList(res?.client_master)
    setLoading(false)
  }

 useEffect(() => {
    getList()
 }, [currentPage])

 const columns = [
  {
    selector: (row,inx) => inx + 1,
    name: "Sl No.",
    sortable: true,
    width: '100px', 
    center: true,
  },
  {
    selector: (row) => 
     <Image
    width={45}
    height={45}
    style={{borderRadius:10}}
    src={`${AwsFilePath}${row.client_logo}`}
  />,
    name: "Logo",
    // sortable: true,
    width: '110px', 
    // center: true,
  },
  {
    selector: (row) => 
    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row?.client_name}
    </div>,
    name: "Donor Agencies Name",
    sortable: true,
    // width: '310px', 
    // center: true,
  },
  {
    selector: (row) =>
    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row?.short_name}
    </div>
    ,
    name: "Donor Agencies Short Name",
    sortable: true,
    // width: '310px', 
    // center: true,
  },
  
  {
    selector: (row) => <div>
     <span style={{cursor:"pointer"}}>
     <Switch defaultChecked={row.status}
              size='small'
              onChange={(s)=>onChange(s,row)}
    />
      <i 
      className="fa fa-pencil" 
      style={{ width: 40, fontSize: 18, paddingInlineStart: 11, color: "rgb(40, 167, 69)",marginTop:7 }}
      onClick={() => { setUpdateId(row.id); window.scrollTo({ top: 0, behavior: "smooth" }) ;}}
      ></i>
    </span>
  </div>,
    name: "Action",
  //   sortable: true,
    center: true,
    // width: '200px',
  },
];

const onChange =async (checked,row) => {
  console.log();
  let lo_formData = {
    client_name: row?.client_name,
    client_logo: row?.client_logo,
    short_name: row.short_name,
    priority: row.priority,
    status: checked == true ? 1 : 0
}
    const res = await postRequestApiByToken('client_master/' + row?.id, lo_formData)
    if (res?.status =="Success") {
      message.success('Status Successfully Updated!');
    }
};

const resetFormData = async ()=>{
  form.resetFields()
  setUpdateId('')
}
const setFormData = async ()=>{
  setSpining(true)
  const lo_updateDate = {}
  if (ls_updateId) {
      const lo_response = await getRequestApiByToken(`client_master/${ls_updateId}`);
      const lo_record = lo_response?.Client ? lo_response?.Client : {};
      lo_updateDate['client_name'] = lo_record.client_name;
      lo_updateDate['short_name'] = lo_record.short_name;
      lo_updateDate['priority'] = lo_record.priority;
      const path = lo_record?.client_logo
      const filename = path.substring(path.lastIndexOf('/') + 1);
      if(lo_record?.client_logo) lo_updateDate['client_logo'] = [{ uid: '1', name: filename }];

  }
  form.resetFields()
  form.setFieldsValue(lo_updateDate)
  setSpining(false)
}
useEffect(() => {
  console.log('ls_updateId:', ls_updateId);
  setFormData();
}, [ls_updateId]);


 const handleSubmitForm = async (formValues) => {
  console.log('formValues....>>>>>',formValues);
  let lo_formData = {
      ...formValues,
      // priority:'Yes',
      // client_logo: '68b24010-9ee9-49ce-8460-8e6c8fa2c06f.jpeg'
  }
  const la_files = [];
  formValues?.client_logo?.forEach((file) => {
      // console.log('2-----------------------file.originFileObj',file);
      // lo_formData['fileuploadname'] = file.originFileObj;
      if(file?.originFileObj) {
          la_files.push({fieldKey:'client_logo', value: file.originFileObj})
      } else {
          la_files.push({fieldKey:'client_logo', value: file.name})
      }
  });
  console.log('lo_formData=====>>>>>',la_files);
  setButtonLoader(true)
  if (ls_updateId) {
    const res = await postRequestApiByToken('client_master/' + ls_updateId, lo_formData,la_files)
    console.log('res in update api calll------>>>>',res);
    if (res?.status == 'Success') {
        // setFormData()
        getList()
        form.resetFields()
        form.setFieldsValue({})
        setUpdateId('')
        message.success('Successfully Updated!');
    } else {
        message.error(res?.message);
    }
    } else {
      const res = await postRequestApiByToken('client_master', lo_formData,la_files)
      if (res?.status == "Success") {
        getList()
        form.resetFields()
        form.setFieldsValue({})
        setUpdateId('')
        message.success(res?.message);
      }else{
        message.error(res?.message)
      }
    }
    setButtonLoader(false)
  }
    // Define search state
    const [filterText, setFilterText] = useState('');

    // Function to handle search input change
    const handleSearch = (e) => {
      setFilterText(e.target.value);
    };
  
    // Filtered data based on search text
    const filteredData = la_list?.filter((item) =>
      Object.values(item).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
      )
    );
    return (
        <>
        <Breadcrumb parent="Add Donor Agencies" title="Manage Donor Agencies"  />
        <Container fluid={true}>
        <AddForm
          handleSubmitForm={handleSubmitForm}
          lb_buttonLoader={lb_buttonLoader}
          ls_updateId={ls_updateId}
          form={form}
          resetFormData={resetFormData}
          lb_spining={lb_spining}
        />
        <div className="col-xl-12 xl-100">
          <div className="card">
             <div className="card-header card-header-border">
                        <div className="row" style={{alignItems:'center'}}>
                        <div className="col-sm-7">
                            <h5>{"Donor Agencies Details"}</h5>
                        </div>
                       
                        <div className="col-sm-5">
                           <Search value={filterText} onChange={handleSearch} placeholder="Serach here..." />
                        </div>
                        </div>
                    </div>
             <div className="card-body">
                <div className="table-responsive">
                     <DataTable
                      columns={columns}
                      // data={la_list}
                      data={filteredData}
                      striped={true}
                      center={true}
                      persistTableHead
                      pagination
                      // paginationServer
                      // paginationTotalRows={totalCount} // Assuming 10 rows per page
                      paginationPerPage={10} // Number of rows per page
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                      paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                      onChangePage={setCurrentPage} 
                      progressPending={loading}
                      progressComponent={<div><LoadingOutlined/></div>}
                      customStyles={{
                      headCells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px',
                          },
                      },
                      rows: { style: { minHeight: '40px'}},
                      cells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px', // Adjust cell padding as needed
                          },
                      },
                      }}
                   /> 
                </div>
             </div>
          </div>
          </div>
      </Container>
        </>
    )
  }
export default DonorAgencies 
