import { SET_USER_TOKEN, SET_USER_ID, SET_USER_TYPE, SET_USER_DETAILS,SET_APPROVE_DOCUMENTS_COUNT, SET_LOGIN_TYPE, SET_HAS_SECTOR, SET_Notifications, SET_NotificationCount } from '../actionTypes';

const initial_state = {
    token:null,
    user_id: null,
    user_type:null,
    user_details:{},
    login_type:'',
    approve_document_count:'',
    sector: false,
    la_notifications: [],
    lo_notificationCount: {"unSeenCount": 0, "seenCount": 0},
}

const commonReducer = (state = initial_state, action) => {

    switch (action.type) {


        case SET_USER_TOKEN:
            state.token = action.payload;
            return { ...state, token: state.token };

        case SET_USER_ID:
            state.user_id = action.payload;
            return { ...state, user_id: state.user_id };

        case SET_USER_TYPE:
            state.user_type = action.payload;
            return { ...state, user_type: state.user_type };

        case SET_USER_DETAILS:
        state.user_details = action.payload;
        return { ...state, user_details: state.user_details };

        case SET_LOGIN_TYPE:
        state.login_type = action.payload;
        return { ...state, login_type: state.login_type };

        case SET_APPROVE_DOCUMENTS_COUNT:
        state.approve_document_count = action.payload;
        console.log('action.payload=======>>>>>>>',state.approve_document_count);
        return { ...state, approve_document_count: state.approve_document_count };

        case SET_Notifications:
        state.la_notifications = action.payload;
        return { ...state, la_notifications: state.la_notifications };

        case SET_NotificationCount:
        state.lo_notificationCount = action.payload;
        return { ...state, lo_notificationCount: state.lo_notificationCount };

        case SET_HAS_SECTOR:
        state.sector = action.payload;
        return { ...state, sector: state.sector };
        
        default:
            return state;
    }

}
export default commonReducer