import React from 'react'
import { FileIconsObj } from '../../../constant/globalConfig';

const RecentActivities = () => {
  return (
    <div className="card height-equal" style={{height:761}}>
    <div className="card-header card-header-border">
        <div className="row">
        <div className="col-sm-7">
            <h5>{'Recent Activities'}</h5>
        </div>
        <div className="col-sm-5">
            <div className="pull-right right-header">
            <div className="onhover-dropdown">
                <button className="btn btn-primary btn-pill" type="button">
                {'2024'}{" "}
                <span className="pe-0">
                    <i className="fa fa-angle-down"></i>
                </span>
                </button>
                <div className="onhover-show-div right-header-dropdown">
                <a className="d-block" href="#javascript">
                    {'2024'}
                </a>
                <a className="d-block" href="#javascript">
                    {'2023'}
                </a>
                <a className="d-block" href="#javascript">
                    {'2022'}
                </a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    <div className="card-body recent-notification">
        <div className="d-flex">
            <h6>{"01"}</h6>
            <div className="flex-grow-1">
                <span>{"Financial Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                    {FileIconsObj.pdf}
                    {/* <button className="btn btn-outline- btn-xs" type="button">{'Downloaded'} <Download /></button> */}
                    {/* <span className="badge badge-success">Downloaded <Download/></span> */}
                  {/* <span> <Download  style={{color:'#22af47'}}/> </span> */}
                  <i className="fa fa-download"  style={{color:'#22af47',fontSize:16,marginInlineEnd:8}}></i>
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
                {/* <div className="">
                    <span className="badge rounded-pill badge-light"><Download /></span>
                </div> */}
            </div>
        </div>
        <div className="d-flex">
            <h6>{"02"}</h6>
            <div className="flex-grow-1">
                <span>{"Review Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                    {FileIconsObj.ppt}
                    <i className="fa fa-eye" style={{fontSize:16,marginInlineEnd:8,color:'#1ea6ec'}}></i>
                    {/* <span className="badge badge-secondary">Viewed <Eye/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <h6>{"03"}</h6>
            <div className="flex-grow-1">
                <span>{"Physical Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                    {FileIconsObj.word}
                    <i className="fa fa-share-alt" style={{fontSize:16,marginInlineEnd:8,color:'#4466f2'}}></i>
                    {/* <span className="badge badge-primary">Shared <Share2/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <h6>{"04"}</h6>
            <div className="flex-grow-1">
                <span>{"Health Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                    {FileIconsObj.ppt}
                    
                    <i className="fa fa-send" style={{fontSize:16,marginInlineEnd:8,color:'#ff9f40'}}></i>
                    {/* <span className="badge badge-warning">Requested <Send/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <h6>{"05"}</h6>
            <div className="flex-grow-1">
                <span>{"Financial Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                    {FileIconsObj.ppt}
                  <i className="fa fa-download"  style={{color:'#22af47',fontSize:16,marginInlineEnd:8}}></i>
                    {/* <span className="badge badge-success">Downloaded <Download/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
                {/* <div className="">
                    <span className="badge rounded-pill badge-light"><Download /></span>
                </div> */}
            </div>
        </div>
        <div className="d-flex">
            <h6>{"06"}</h6>
            <div className="flex-grow-1">
                <span>{"Review Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                    {FileIconsObj.ppt}
                    <i className="fa fa-eye" style={{fontSize:16,marginInlineEnd:8,color:'#1ea6ec'}}></i>
                    {/* <span className="badge badge-secondary">Viewed <Eye/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <h6>{"07"}</h6>
            <div className="flex-grow-1">
                <span>{"Physical Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                    {FileIconsObj.ppt}
                    <i className="fa fa-share-alt" style={{fontSize:16,marginInlineEnd:8,color:'#4466f2'}}></i>
                    {/* <span className="badge badge-primary">Shared <Share2/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <h6>{"08"}</h6>
            <div className="flex-grow-1">
                <span>{"Health Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                <i className="fa fa-send" style={{fontSize:16,marginInlineEnd:8,color:'#ff9f40'}}></i>

                    {/* <span className="badge badge-warning">Requested <Send/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <h6>{"09"}</h6>
            <div className="flex-grow-1">
                <span>{"Physical Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                <i className="fa fa-share-alt" style={{fontSize:16,marginInlineEnd:8,color:'#4466f2'}}></i>
                    {/* <span className="badge badge-primary">Shared <Share2/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                </div>
            </div>
        </div>
        <div className="d-flex">
            <h6>{"10"}</h6>
            <div className="flex-grow-1">
                <span>{"Health Report"}</span>
                <p className="f-12">{'10 May 2022, 11:00 am'}</p>
            </div>
            <div className="d-flex" style={{flexDirection:"column"}}>
                <div className="mb-1">
                <i className="fa fa-send" style={{fontSize:16,marginInlineEnd:8,color:'#ff9f40'}}></i>

                    {/* <span className="badge badge-warning">Requested <Send/></span> */}
                    <a className="badge rounded-pill badge-secondary" href="#javascript"><i className="icofont icofont-info"></i></a>
                    
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default RecentActivities