import Typography from 'antd/lib/typography/Typography'
import React, { useState } from 'react'
import DocDescriptionModal from '../../../document/documentDetails/DocDescriptionModal';

const DocDescription = ({ lo_documentData }) => {
    const [lb_showDetails, setShowDetails] = useState(false);
    return (
        <>
            <div className="card">
                <div className="card-header faq-header docviewr-headr">
                    <span style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center" }}>
                        <i style={{ fontSize: 21 }} className="icofont icofont-clip-board"></i>
                        &nbsp;&nbsp;
                        <h5>{'Document Description'}</h5>
                    </span>
                </div>
                <div className="card-body faq-body">
                    <Typography.Paragraph
                        // ellipsis={{
                        //     rows: 2,
                        //     // expandable: 'collapsible',
                        //     expanded: false,
                        //     expandable: { text: 'View more' },
                        //     //   onExpand: (_, info) => setExpanded(info.expanded),
                        // }}
                        ellipsis={{
                            rows: 2,
                            expandable: true,
                            expanded: false,
                            symbol: 'View more',
                            onExpand: (_, info) => setShowDetails(true),
                        }}
                        //   copyable
                    >
                        {lo_documentData?.description || <div><span style={{ color: "red" }}>No data available!</span></div>}
                    </Typography.Paragraph>
                </div>
            </div>
            
            <DocDescriptionModal {...{ lb_showDetails, setShowDetails, lo_documentData }}/>
        </>
    )
}

export default DocDescription