import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { getRequestApiByToken } from '../../../api';

let apexColumnChartsone = {

    series: [
        // {
        //     name: 'View',
        //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57, 56, 61, 58, 63, 60, 66, 87, 61, 58,]
        // }, {
        //     name: 'Download',
        //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101, 98, 87, 105, 91, 114, 94, 54, 94, 76,]
        // }, {
        //     name: 'Share',
        //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 35, 41, 36, 26, 45, 48, 52, 53, 41, 35, 41, 36, 26, 45, 48, 52, 53, 41, 45, 36, 26,]
        // }
    ],
    options: {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '70%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#948acb', '#7ebdd8', '#ddc7af'],
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: 
            [
                // '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
                // '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
                // '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
            ],
            // axisTicks: {
            //   show: false,
            // },
            // labels: {
            //   show: false,
            // },
        },
        // yaxis: {
        //   title: {
        //     text: '$ (thousands)'
        //   }
        // },
        fill: {
            opacity: 1
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return "$ " + val + " thousands"
        //     }
        //   }
        // }
    },
};

const SectorOverviews = () => {

    const [lo_sectorOverviewsDetails, setSectorOverviewsDetails] = useState({...apexColumnChartsone});

    const getAndSetSectorOverviews = async () => {
        let res = await getRequestApiByToken(`sector_wise_data`);
        handleSetSectorOverviews(res || {});
    }

    const handleSetSectorOverviews = async (lo_result) => {
        const lo_newresult = {...lo_result};
        const la_result = Object.values(lo_newresult);
        console.log('___la_result',la_result);
        let lo_chartData = {...apexColumnChartsone};

        const la_activeDocsCount = [];
        const la_pendingCount = [];
        const la_sectors = [];

        for (let j = 0; j < la_result.length; j++) {
            const rowValue = la_result[j];
            la_activeDocsCount.push(rowValue?.active_documents_count || 0);
            la_pendingCount.push(rowValue?.inactive_documents_count || 0);
            la_sectors.push(rowValue?.sector_name || '-');
        }
        const la_series = [{
            name: 'Active Documents',
            data: [...la_activeDocsCount]
        }, {
            name: 'Pending for Approval',
            data: [...la_pendingCount]
        }]

        lo_chartData['series'] = la_series;
        lo_chartData['options']['xaxis']['categories'] = [...la_sectors];

        setSectorOverviewsDetails(lo_chartData);
    }

    useEffect(() => {
        getAndSetSectorOverviews()
    }, [])
    
    return (
        <><Card style={{ height: 506 }}>
            <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h5>{'Sector Overview'} </h5>
            </CardHeader>
            <CardBody style={{ overflowX: 'scroll' }}>
                <div id="column-chart" >
                    {lo_sectorOverviewsDetails?.options?.xaxis?.categories?.length && lo_sectorOverviewsDetails.series && <Chart width={900} options={lo_sectorOverviewsDetails.options} series={lo_sectorOverviewsDetails.series} type="bar" height={350} />}
                </div>
            </CardBody>

        </Card></>
    )
}

export default SectorOverviews;