import { Divider, Modal } from 'antd/lib';
import React, { useEffect, useState } from 'react'
import DocumentRequest from '../documentDetails/DocumentRequest';

const LimitRequestModal = ({setForDocAction, ls_docAction}) => {
    const [lb_showModal, setShowModal] = useState(false);

    const handleCancel = () => {
        setShowModal(false);
        setForDocAction('');
    }

    useEffect(() => {
      if(ls_docAction == 'request-for-limit') setShowModal(true);
    }, [ls_docAction])
    
    return (
        <>
            <Modal
                title={"Request for increase your limit"}
                visible={lb_showModal}
                onCancel={handleCancel}
                footer={null}
            >
                <Divider />
                <DocumentRequest lo_documentData={{}} handleGetdata={handleCancel} />
            </Modal>
        </>
    )
}

export default LimitRequestModal