import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, FormGroup, Row } from 'reactstrap'
import { Cascader, Divider,Space, Form, Input, Select, Upload, DatePicker, Button, Spin} from 'antd'
import TextArea from 'antd/es/input/TextArea';
import { CloudUploadOutlined } from '@ant-design/icons';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { message } from "antd";
import { getCascaderIdsFormArray, getCommaSeperateIdsFormArray, getIdsFormArray, romeveUndefinedValueFromObject } from '../../../utility';
import { DateFormat } from '../../../constant/globalConfig';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { MaxFileSize } from '../../../constant/basicConstant';
import { InputNumber } from 'antd/lib';
import MultiItemInput from './multiItemInput';
const { Dragger } = Upload;

const CreateProject = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const ls_updateId = location?.state?.updateId || null;
    const [form] =Form.useForm()
    const ls_userId = useSelector(content => content.commonReducer.user_id);

    const ls_in_consortium = Form.useWatch('in_consortium', form);

    const [lb_loading, setLoading] = useState(false)
    const [lb_spining, setSpining] = useState(false)
    const [la_sectorData, setSectorData] = useState([])
    const [la_practices, setPractices] = useState([])
    const [la_country, setCountry] = useState([])
    const [la_donorName, setDonorName] = useState([])
    const [la_consortiumList, setConsortiumList] = useState([])
    const [la_currencyList, setCurrencyList] = useState([])
    const [ls_startDate, setStartDate] = useState(new Date())
    const [ls_endDate, setEndDate] = useState(new Date())

    const [lo_updatRecord, setUpdateRecord] = useState({})
    
    

    const [fileUpload, setFileUpload] = useState(null);
    const [fileUploadTor, setFileUploadTor] = useState(null);

    const handleFileUpload = (info) => {
        // Assuming you want to keep the latest selected file
        const file = info.fileList.slice(-1)[0];
        console.log(file);
        setFileUpload(file);
    };

    const handleFileUploadTor = (info) => {
        // Assuming you want to keep the latest selected file
        const file = info.fileList.slice(-1)[0];
        console.log(file);
        setFileUploadTor(file);
    };
      


    const getSectorData = async () => {
        const res = await getRequestApiByToken('sector_master')
        const la_options=[]
        const selectedids = lo_updatRecord?.sector?.map((rowvalue)=> rowvalue?.id) || [];
        res?.sector_master?.map((v)=>{
            // if(v?.status == 1) la_options.push({label: v?.sector_name,value: v?.id});
            if (v?.status == 1) {
                la_options.push({label: v?.sector_name,value: v?.id});
            } else if (ls_updateId && selectedids?.includes(v?.id)) {
                la_options.push({label: v?.sector_name,value: v?.id});
            }
        })
        setSectorData(la_options)
    }

    const getPracticeData = async () => {
        const res = await getRequestApiByToken('practice')
        const la_options=[]
        const selectedids = lo_updatRecord?.practice?.map((rowvalue)=> rowvalue?.id) || [];
        res?.practices?.map((v)=>{
            // if(v?.status == 1) la_options.push({label: v?.practice_name,value: v?.id})
            if (v?.status == 1) {
                la_options.push({label: v?.practice_name,value: v?.id});
            } else if (ls_updateId && selectedids?.includes(v?.id)) {
                la_options.push({label: v?.practice_name,value: v?.id});
            }
        })
        setPractices(la_options)
    }

    const getCountryData = async () => {
        const res = await getRequestApiByToken('country_master')
        const la_options=[]
        const selectedids = lo_updatRecord?.country?.map((rowvalue)=> rowvalue?.id) || [];
        res?.country_master?.map((v)=>{
            // if(v?.status == 1) la_options.push({label: v?.country_name,value: v?.id});

            if (v?.status == 1) {
                la_options.push({label: v?.country_name,value: v?.id});
            } else if (ls_updateId && selectedids?.includes(v?.id)) {
                la_options.push({label: v?.country_name,value: v?.id});
            }
        })
        setCountry(la_options)
    }

    const getDonorData = async () => {
        const res = await getRequestApiByToken('client_master')
        const la_options=[]
        const selectedids = lo_updatRecord?.donor?.map((rowvalue)=> rowvalue?.id) || [];
        res?.client_master?.map((v)=>{
            // if(v?.status == 1) la_options.push({label: v?.client_name,value: v?.id})

            if (v?.status == 1) {
                la_options.push({label: v?.client_name,value: v?.id})
            } else if (ls_updateId && selectedids?.includes(v?.id)) {
                la_options.push({label: v?.client_name,value: v?.id})
            }
        })
        setDonorName(la_options)
    }

    const getConsortiumData = async () => {
        const res = await getRequestApiByToken('consortium_master');
        console.log('---res---', res);
        const la_arr = res?.data?.filter((rowValue) => {
            if (rowValue?.status == 1) {
                return true;
            } else if (ls_updateId && rowValue?.id == lo_updatRecord?.consortium_id) {
                return true;
            }
            return false;
        });
        setConsortiumList(la_arr || [])
    }

    const getCurrencyData = async () => {
        const res = await getRequestApiByToken('currency_master');
        console.log('---res---', res);
        const la_arr = res?.data?.filter((rowValue) => {
            if (rowValue?.status == 1) {
                return true;
            } else if (ls_updateId && (rowValue?.id == lo_updatRecord?.firm_value_currency_id || rowValue?.id == lo_updatRecord?.tot_value_currency_id)) {
                return true;
            }
            return false;
        });
        setCurrencyList(la_arr || [])
    }

    const onChange = (date, dateString) => {
        console.log(date, dateString);
        setStartDate(dateString)
    };
    const onChangeEndDate = (date, dateString) => {
        console.log(date, dateString);
        setEndDate(dateString)
    };
    const handleSubmitForm = async (formValues) => {
        console.log('1-----formValues',formValues);
     
        let lo_formValues = await romeveUndefinedValueFromObject(formValues)
        let lo_formData = { ...lo_formValues, "client_id": ls_userId }

        if(lo_formValues?.start_date) lo_formData['start_date'] = dayjs(lo_formData.start_date).format('YYYY-MM-DD');
        if(lo_formValues?.end_date) lo_formData['end_date'] = dayjs(lo_formData.end_date).format('YYYY-MM-DD');
        
        lo_formData['in_consortium'] = formValues?.in_consortium ? 1 : 0;
        if (formValues?.in_consortium) {
            lo_formData['consortium_id'] = formValues?.consortium_id ? formValues.consortium_id : '';
        } else {
            lo_formData['consortium_id'] = '';
        }

        // Handle nested total_values and firm_values
        if (lo_formValues.total_values) {
            lo_formValues.total_values.forEach((item, index) => {
                lo_formData[`total_values[${index}][currency_id]`] = item.currency_id;
                lo_formData[`total_values[${index}][total_value]`] = item.total_value;
            });
        }

        if (lo_formValues.firm_values) {
            lo_formValues.firm_values.forEach((item, index) => {
                lo_formData[`firm_values[${index}][currency_id]`] = item.currency_id;
                lo_formData[`firm_values[${index}][firm_value]`] = item.firm_value;
            });
        }

        const la_files = [];
        formValues?.fileuploadname?.forEach((file) => {
            if(file?.originFileObj) {
                la_files.push({fieldKey:'fileuploadname', value: file.originFileObj})
            } else {
                la_files.push({fieldKey:'fileuploadname', value: file.name})
            }
        });

        formValues?.fileuploadname_tor?.forEach((file) => {
            if(file?.originFileObj) {
                la_files.push({fieldKey:'fileuploadname_tor', value: file.originFileObj})
            } else {
                la_files.push({fieldKey:'fileuploadname_tor', value: file.name})
            }
        });
    
        setLoading(true)

        console.log('-----2 lo_formData---',lo_formData);
        

        if (ls_updateId) {
            if (lo_formData?.fileuploadname?.length > 0) {
                lo_formData['old_fileuploadname'] = 'file';
            }else{
                lo_formData['old_fileuploadname'] = '';
            }

            if (lo_formData?.fileuploadname_tor?.length > 0) {
                lo_formData['old_fileuploadname_tor'] = 'file';
            }else{
                lo_formData['old_fileuploadname_tor'] = '';
            }
            const res = await postRequestApiByToken('project/' + ls_updateId, lo_formData, la_files)
            if (res?.status == 'Success') {
                setFormData()
                message.success('Successfully Updated!');
            } else {
                message.error(res?.message);
            }
        } else {
            const res = await postRequestApiByToken('project', lo_formData, la_files)
            if (res?.status == 'Success') {
                message.success('Successfully Created!');
                navigate('/projects/project-list')
            } else {
                message.error(res?.message);
            }
        }

        setLoading(false)
    }

    const setFormData = async ()=>{
        setSpining(true)
        const lo_updateDate = {}
        if (ls_updateId) {
            const lo_response = await getRequestApiByToken(`project/${ls_updateId}`);
            const lo_record = lo_response?.Project ? lo_response.Project : {};
            setUpdateRecord(lo_record);
            console.log('lo_record <<<<<<<',lo_record);
            lo_updateDate['project_name'] = lo_record.project_name;
            lo_updateDate['mandate_no'] = lo_record.mandate_no;
            lo_updateDate['sector'] = getIdsFormArray(lo_record?.sector);
            lo_updateDate['practice'] = getIdsFormArray(lo_record?.practice);
            // lo_updateDate['country'] = getCascaderIdsFormArray(lo_record?.country);
            lo_updateDate['country'] = getIdsFormArray(lo_record?.country);
            lo_updateDate['donor'] = getIdsFormArray(lo_record?.donor);
            lo_updateDate['contracted_by'] = lo_record.contracted_by;
            lo_updateDate['prj_for'] = lo_record.prj_for;
            lo_updateDate['start_date'] = dayjs(lo_record.start_date);
            lo_updateDate['end_date'] = dayjs(lo_record.end_date);
            // lo_updateDate['tot_value'] = lo_record?.tot_value;
            // lo_updateDate['tot_value_currency_id'] = lo_record?.tot_value_currency_id;
            // lo_updateDate['firm_value'] = lo_record?.firm_value;
            // lo_updateDate['firm_value_currency_id'] = lo_record?.firm_value_currency_id;
            lo_updateDate['total_values'] = lo_record?.total_values?.length ? lo_record?.total_values : [{currency_id:null, total_value:0}];
            lo_updateDate['firm_values'] = lo_record?.firm_values?.length ? lo_record?.firm_values : [{currency_id:null, firm_value:0}];
            lo_updateDate['for'] = lo_record.for;
            // lo_updateDate['fileuploadname'] = lo_record.fileuploadname;
            // lo_updateDate['fileuploadname_tor'] = lo_record.fileuploadname_tor;
            const path = lo_record?.fileuploadname
            const filename = path?.substring(path.lastIndexOf('/') + 1);
            if(lo_record?.fileuploadname) lo_updateDate['fileuploadname'] = [{ uid: '1', name: filename }];
            const tor_path = lo_record?.fileuploadname_tor
            const tor_filename = tor_path?.substring(tor_path.lastIndexOf('/') + 1);

            if(lo_record?.fileuploadname_tor) lo_updateDate['fileuploadname_tor'] = [{ uid: '2', name: tor_filename }];
            lo_updateDate['description'] = lo_record.description;

            lo_updateDate['in_consortium'] = (lo_record?.in_consortium == 1) ? 1 : 0;
            lo_updateDate['consortium_id'] = lo_record?.consortium_id || '';
        } else {
            lo_updateDate['total_values'] = [{currency_id:null, total_value:0}];
            lo_updateDate['firm_values'] = [{currency_id:null, firm_value:0}];
            // lo_updateDate['tot_value'] = 0;
            // lo_updateDate['firm_value'] = 0;
            setUpdateRecord({});
        }
        form.resetFields()
        form.setFieldsValue(lo_updateDate)
        setSpining(false)
    }

    const beforeUploadPDS = (file) => {
        const fileType = file.type;

        // if (!AcceptDocFileTypes.includes(fileType)) {
        //   form.setFieldsValue({'fileuploadname': []})
        //   form.setFields([{  name: 'fileuploadname', errors: ['File type not matched!'] }]);
        //   message.error('File type not matched!');
        //   return Upload.LIST_IGNORE;
        // }

        if (file.size > MaxFileSize) {
            form.setFieldsValue({ 'fileuploadname': [] });
            form.setFields([{ name: 'fileuploadname', errors: ['File size exceeds the 100MB limit!'] }]);
            message.error('File size exceeds the 100MB limit!');
            return Upload.LIST_IGNORE; // Prevent file from being uploaded
        }

        // Clear errors if validation passes
        form.setFields([{ name: 'fileuploadname', errors: [] }]);

        return false;
    };

    const beforeUploadTor = (file) => {
        const fileType = file.type;

        // if (!AcceptDocFileTypes.includes(fileType)) {
        //   form.setFieldsValue({'fileuploadname_tor': []})
        //   form.setFields([{  name: 'fileuploadname_tor', errors: ['File type not matched!'] }]);
        //   message.error('File type not matched!');
        //   return Upload.LIST_IGNORE;
        // }

        if (file.size > MaxFileSize) {
            form.setFieldsValue({ 'fileuploadname_tor': [] });
            form.setFields([{ name: 'fileuploadname_tor', errors: ['File size exceeds the 100MB limit!'] }]);
            message.error('File size exceeds the 100MB limit!');
            return Upload.LIST_IGNORE; // Prevent file from being uploaded
        }

        // Clear errors if validation passes
        form.setFields([{ name: 'fileuploadname_tor', errors: [] }]);

        return false;
    };

    const formatNumber = (value) => {
        if (value === null || value === undefined) {
          return '';
        }
        const numberValue = parseFloat(value);
        return numberValue % 1 === 0 ? numberValue.toFixed(0) : numberValue.toFixed(2);
      };
    
      const parseNumber = (value) => {
        if (value === null || value === undefined) {
          return '';
        }
        return parseFloat(value);
      };

    useEffect(() => {
        getSectorData()
        getPracticeData()
        getCountryData()
        getDonorData()
        getConsortiumData()
        getCurrencyData()
    }, [JSON.stringify(lo_updatRecord)])

    useEffect(() => {
        console.log('ls_updateId:', ls_updateId);
        setFormData();
    }, [ls_updateId]);

    useEffect(() => {
      console.log('---ls_in_consortium---',ls_in_consortium);
    }, [ls_in_consortium])
    
    
  return (
    <>
      <Breadcrumb parent={`${ls_updateId ? 'Update' : 'Create'} Project`} title=""  sideComponent={
       <Link to={'/projects/project-list'}>
          <div style={{ display: 'flex' }}>
            <i className="fa fa-arrow-circle-left" style={{ fontSize: 20, marginInlineEnd: 10 }}></i>
            <strong>Back</strong>
          </div>
       </Link>}/>
      <Row>
        <Col sm="12">
            <Card>
            <CardBody>
                <Spin spinning={lb_spining}>
                    <Form 
                        form={form}
                        className="theme-form" 
                        labelCol={{ span: 24 }}
                        onFinish={handleSubmitForm} // Utilize onFinish here
                    >
                    <Row>
                        <Col sm="6">
                            <Form.Item
                                label="Project Name"
                                name="project_name"
                                rules={[{ required: true,  message: "Please input Project Name!"}]}
                            >
                                {/* <Input type='te' placeholder="Project name *" enterButton /> */}
                                <TextArea rows={1} placeholder={'Project name'}/>
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item
                                label="Project Code"
                                name="mandate_no"
                                rules={[{ required: true,  message: "Please input Project Code!"}]}
                            >
                                <Input placeholder="Project Code *" enterButton />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Form.Item
                                label="Sectors"
                                name="sector"
                                rules={[{ required: true,  message: "Please input Sectors!"}]}
                            >
                                <Select placeholder="Select Sector"
                                    mode='multiple'
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {la_sectorData.map(optionValue => (
                                        <Select.Option key={optionValue.value} value={optionValue.value}>{optionValue.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col sm="6">
                        <Form.Item
                                label="Practices"
                                name="practice"
                                rules={[{ required: true,  message: "Please input Practices!"}]}
                            >
                                <Select placeholder="Select Practices"
                                    mode='multiple'
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {la_practices.map(optionValue => (
                                        <Select.Option key={optionValue.value} value={optionValue.value}>{optionValue.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Form.Item label="Country Name" name="country" rules={[{ required: true, message: "Please input Country!" }]}>
                                {/* <Cascader
                                    placeholder="Select Country"
                                    style={{
                                        width: '100%',
                                    }}
                                    options={la_country}
                                    multiple
                                    maxTagCount="responsive"
                                /> */}
                                <Select placeholder="Select Country"
                                    mode='multiple'
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {la_country.map(optionValue => (
                                        <Select.Option key={optionValue.value} value={optionValue.value}>{optionValue.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                        <Form.Item
                                label="Donor Name"
                                name="donor"
                                rules={[{ required: true,  message: "Please input Donor!"}]}
                            >
                                <Select placeholder="Select Donor"
                                    mode='multiple'
                                    allowClear showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA?.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {la_donorName.map(optionValue => (
                                        <Select.Option key={optionValue.value} value={optionValue.value}>{optionValue.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item
                                label="Client Name"
                                name="contracted_by"
                                rules={[{ required: true,  message: "Please input Client Name!"}]}
                            >
                                {/* <Input type='te' placeholder="Project name *" enterButton /> */}
                                <TextArea rows={4} placeholder={'Client name'}/>
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item
                                label="Implementing Agency"
                                name="prj_for"
                                rules={[{ required: true,  message: "Please input Implementing Agency!"}]}
                            >
                                {/* <Input type='te' placeholder="Project name *" enterButton /> */}
                                <TextArea rows={4} placeholder={'Implementing Agency'}/>
                            </Form.Item>
                        </Col>
                        <Col sm="6" style={{width:'50%'}}>
                            <Form.Item
                                label="Start Date"
                                name="start_date"
                                style={{ width: '100%'}}
                                rules={[{ required: true,  message: "Please input Start Date!"}]}
                            >
                                <DatePicker format={DateFormat} style={{width:'100%'}} onChange={onChange} needConfirm={false} />
                            {/* <Space
                                    direction="vertical"
                                    style={{
                                    width: '100%',
                                    }}
                                    size={12}
                                >
                                
                            </Space> */}
                            </Form.Item>
                        </Col>
                        <Col sm="6" style={{width:'50%'}}>
                            <Form.Item
                                label="End  Date"
                                name="end_date"
                                style={{  width: '100%'}}
                                size={12}
                                rules={[{ required: true,  message: "Please input End Date!"}]}
                            >
                            <DatePicker format={DateFormat} style={{width:'100%'}} onChange={onChangeEndDate} needConfirm={false} />
                            </Form.Item>
                        </Col>

                        <Col sm="6">
                            <Form.Item
                                label="Total Consultancy Fees"
                                // noStyle
                            >
                                <MultiItemInput la_currencyList={la_currencyList} fieldName={'total_values'} valueFieldName={'total_value'} valueFieldLabel={'Total Consultancy Fees'} />
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item
                                label="Value Of Services"
                                // noStyle
                            >
                                <MultiItemInput la_currencyList={la_currencyList} fieldName={'firm_values'} valueFieldName={'firm_value'} valueFieldLabel={'Value Of Services'} />
                            </Form.Item>
                        </Col>
                        
                        <Col sm="12">
                            <Row>
                                <Col sm="6">
                                    <Form.Item label="Whether in Consortium?" name="in_consortium" valuePropName="checked">
                                        <Checkbox>Yes</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col sm="6">
                                    {ls_in_consortium ? <Form.Item
                                        label="Role in the Consortium"
                                        name="consortium_id"
                                        rules={[{ required: true, message: "Please input Consortium!" }]}
                                    >
                                        <Select
                                            placeholder="Select"
                                            allowClear showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {la_consortiumList && la_consortiumList.map(rowValue => <Select.Option value={rowValue.id} key={rowValue.id}>{rowValue.name}</Select.Option>)}
                                        </Select>
                                    </Form.Item> : <></>}
                                </Col>
                            </Row>
                        </Col>

                        <Col sm="6">
                            <Form.Item
                                label="Upload PDS (Max File Size Allowed - 100MB)"
                                name="fileuploadname"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                            >
                                {/* <Dragger {...{name: 'file', multiple: true,}} style={{backgroundColor:'#8fb7ee3d'}}> */}
                                <Dragger beforeUpload={beforeUploadPDS} name="file" multiple={false} maxCount={1}
                                    // beforeUpload={() => false}
                                    onChange={handleFileUpload}
                                    style={{ backgroundColor: '#8fb7ee3d' }}>
                                    <p className="ant-upload-drag-icon">
                                        <CloudUploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Drag and Drop your file here</p>
                                    <p className="ant-upload-hint">Or you can Browse file. </p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col sm="6">
                            <Form.Item
                                label="Upload TOR (Max File Size Allowed - 100MB)"
                                name='fileuploadname_tor'
                                valuePropName="fileList"
                                getValueFromEvent={(e) => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e && e.fileList;
                                }}
                            >
                                {/* <Dragger {...{name: 'file', multiple: true,}} style={{backgroundColor:'#8fb7ee3d'}}> */}
                                <Dragger name="file" multiple={false} maxCount={1} beforeUpload={beforeUploadTor}
                                    onChange={handleFileUploadTor}
                                    style={{ backgroundColor: '#8fb7ee3d' }}>
                                    <p className="ant-upload-drag-icon">
                                        <CloudUploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">Drag and Drop your file here</p>
                                    <p className="ant-upload-hint">Or you can Browse file. </p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col sm="12">
                            <Form.Item
                                label="Description"
                                name="description"
                                // rules={[{ required: true,  message: "Please input Description!"}]}
                                rules={[
                                    { required: true, message: "Please input Description!" },
                                    { min: 100, message: "Description must be at least 100 characters long!" }
                                ]}
                            >
                                {/* <Input type='te' placeholder="Project name *" enterButton /> */}
                                <TextArea minLength={100} rows={4} placeholder={'Description'}/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Col  sm="12" className="mb-10" style={{display:'flex',justifyContent:'center'}}>
                        {/* <FormGroup className="mb-10" style={{display:'flex',justifyContent:'center'}}> */}
                            <Button 
                                loading={lb_loading}
                                color="success" 
                                type='primary'
                                htmlType='submit'
                            >
                            {'Submit'}
                            </Button>
                        {/* </FormGroup> */}
                        </Col>
                    </Row> 
                    </Form>
                </Spin>
            </CardBody>
            </Card>
        </Col>
      </Row>
    </>
  )
}

export default CreateProject
