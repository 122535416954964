import React from 'react'
import { Send } from 'react-feather';
import ScrollBar from 'react-perfect-scrollbar';

const Communication = () => {
  return (
    <div className="card communication-card-hight" 
    // style={{height:761}}
    >
        <div className="card-header card-header-border chat-header-default">
            <div className="row">
            <div className="col-sm-6">
                <h5>{'Communication'}</h5>
            </div>
            <div className="col-sm-6">
                {/* <div className="right-header pull-right m-t-5">
                <div className="onhover-dropdown">
                    <MoreHorizontal />
                    <div className="onhover-show-div right-header-dropdown more-dropdown">
                    <a className="d-block" href="#javascript">
                        {View}
                    </a>
                    <a className="d-block" href="#javascript">
                        {Media}
                    </a>
                    <a className="d-block" href="#javascript">
                        {'Search'}
                    </a>
                    </div>
                </div>
                </div> */}
            </div>
            </div>
        </div>
        <div className="card-body chat-box dashboard-chat">
            <div className="chat">
            <ScrollBar className="scroll chat-section-card-height" options={{ suppressScrollX: true }}
            //  style={{ maxHeight: "510px" }}
             >
                <div className="d-flex left-side-chat align-items-start">
                    <img className="rounded-circle chat-user-img img-60 m-r-20" src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" />
                    <div className="flex-grow-1">
                    <div className="message-main">
                        <p className="mb-0" style={{backgroundColor:'#f7f5ff'}}>{"Are we meeting today? Project has been already finished and I have results to show you."} </p>
                    </div>
                    </div>
                </div>
                <div className="d-flex right-side-chat align-items-start">
                    <div className="flex-grow-1 text-end">
                    <div className="message-main">
                        <p className="pull-right" style={{backgroundColor:'#ebfaff'}}>{"Well I am not sure. The rest of the team is not here yet. Maybe in an hour or so?"}</p>
                        <div className="clearfix"></div>
                    </div>
                    <div className="sub-message message-main">
                        <p className="pull-right mb-0" style={{backgroundColor:'#ebfaff'}}>{"Well I am not sure. The rest of the team"} </p>
                        <div className="clearfix"></div>
                    </div>
                    </div>
                    <img className="rounded-circle chat-user-img img-60 m-l-20" src={'https://react.pixelstrap.com/endless/static/media/5.53a215ed00aac31d7543.jpg'} alt="" />
                </div>
                <div className="d-flex left-side-chat align-items-start">
                    <img className="rounded-circle chat-user-img img-60 m-r-20" src={'https://react.pixelstrap.com/endless/static/media/4.af13b24b764747328fb2.jpg'} alt="" />
                    <div className="flex-grow-1">
                    <div className="message-main">
                        <p style={{backgroundColor:'#f7f5ff'}}>{"Actually everything was fine. I'm very excited to show this to our team."}</p>
                    </div>
                    {/* <div className="sub-message message-main smiley-bg" style={{backgroundColor:'#f7f5ff'}}>
                        <img src={require("../../../assets/images/dashboard/smily.png")} alt="" />
                    </div> */}
                    </div>
                </div>
            </ScrollBar>
            </div>
        </div>
        <div className="d-flex chat-footer bg-primary">
            <i className="icon-face-smile"></i>
            <div className="flex-grow-1">
            <input className="form-control" type="text" placeholder="Type your message" required="" />
            </div>
            <Send />
        </div>
    </div>
  )
}

export default Communication