import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { actionForUserLogout } from '../../utility';

const CommonLogout = () => {
    const { instance } = useMsal();
    const ls_loginType = useSelector(content => content.commonReducer.login_type);

    const handleLogOutUser = async () => {
        await actionForUserLogout(instance);
        window.location.href = `${process.env.PUBLIC_URL}/login`;
    }

    useEffect(() => {
    
      if(ls_loginType) handleLogOutUser();
    }, [ls_loginType, instance])
    

    return (
        <div>Authenticating user......</div>
    )
}

export default CommonLogout