import React from 'react'

const CountCrad = ({icon=<></>,title='',count=0}) => {
  return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex feather-main">
                <div className="feather-icon-block flex-shrink-0">
                    {icon}
                </div>
                <div className="flex-grow-1 align-self-center">
                    <h6>{title}</h6>
                    <p>{count}</p>
                </div>
                </div>
            </div>
        </div>
  )
}

export default CountCrad