import { Form, InputNumber, Typography } from 'antd'
import CaretRightFilled from "@ant-design/icons/CaretRightFilled";
import React from 'react'
import { Col, Row } from 'reactstrap'

const RequestLimitForm = ({limitType, view_number, download_number}) => {
  return (
    <>
        <Row className="align-items-center">
            <Col className='col-sm-4 col-xl-4 col-lg-4'>
                <Form.Item>
                <Typography.Text type='secondary' strong style={{fontSize:13}}><CaretRightFilled style={{color:"#728aec"}} /> {limitType}</Typography.Text>
                </Form.Item>
            </Col>
            <Col className='col-sm-4 col-xl-4 col-lg-4'>
                <Form.Item
                labelAlign="right"
                name={[view_number]}
                rules={[
                    {
                    required: true, message: 'Missing rate',
                    type: 'number',
                    },
                ]}
                >
                <InputNumber
                    min={0}
                    style={{width:'100%'}}
                    placeholder="Input Number"
                />
                </Form.Item>
            </Col>
            <Col className='col-sm-4 col-xl-4 col-lg-4'>
                <Form.Item
                labelAlign="right"
                name={[download_number]}
                rules={[
                    {
                    required: true, message: 'Missing rate',
                    type: 'number',
                    },
                ]}
                >
                <InputNumber min={0} style={{width:'100%'}} placeholder="Input Number" />
                </Form.Item>
            </Col>
        </Row>
    </>
  )
}

export default RequestLimitForm