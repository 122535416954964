import { Badge } from "antd";
import { Airplay, Anchor, Bookmark, BookOpen, Check, CheckCircle, FilePlus, FileText, Headphones, Home } from "react-feather";
// import { useSelector } from "react-redux";
// const ls_approverDocumentCount = useSelector(content => content.commonReducer.approve_document_count);

const getDocumentChildrenMenu = (ls_approverDocumentCount, lo_permissionCheck) => {
  const la_menu = [
    { path: "/document/search-document", title: "Search Documents", type: "link", icon:<i className="fa fa-search"></i> },
    { path: "document/my-document", title: "My Uploads", type: "link", icon:<i className="fa fa-file"></i> },
    { path: "/bookmark-document", title: "Favourites", type: "link", icon:<i className="fa fa-bookmark-o"></i> },
  ];

  if (lo_permissionCheck?.approve_document) {
    la_menu.push({ path: "/projects/approver-project", title: `Approve`, contents: <>&nbsp;&nbsp;<Badge color="#f13737b8" count={ls_approverDocumentCount} overflowCount={100000000000000} /></>, type: "link", icon:<i className="fa fa-check-square-o"></i> })
  }

  la_menu.push( { path: "/document/share-document", title: "Shared with me", type: "link", icon:<i className="fa fa-share-alt"></i> },
  { path: "/document/share-document-by-me", title: "Shared by me", type: "link", icon:<i className="fa fa-share-alt"></i> } );

  if (lo_permissionCheck?.add_document) {
    la_menu.push({ path: "/document/add-document", title: "Add Documents", type: "link", icon:<i className="fa fa-plus-square-o"></i> });
  }

  return la_menu;
}

const getOtherDocumentChildrenMenu = (ls_approverDocumentCount, lo_permissionCheck) => {
  const la_menu = [
    { path: "/other-documents/search-document", title: "Search Documents", type: "link", icon:<i className="fa fa-search"></i> },
    { path: "/other-documents/my-other-document", title: "My Uploads", type: "link", icon:<i className="fa fa-file"></i> },
  ];

  if (lo_permissionCheck?.add_document) {
    la_menu.push({ path: "/other-documents/add-document", title: "Add Documents", type: "link", icon:<i className="fa fa-plus-square-o"></i> });
  }

  return la_menu;
}

//----
const getDocumentChildrenMenuForUser = (ls_approverDocumentCount, lo_permissionCheck) => {
  const la_menu = [
    { path: "/document/search-document", title: "Search Documents", type: "link", icon:<i className="fa fa-search"></i> },
    { path: "document/my-document", title: "My Uploads", type: "link", icon:<i className="fa fa-file"></i> },
    { path: "/bookmark-document", title: "Favourites", type: "link", icon:<i className="fa fa-bookmark-o"></i> }
  ];

  if (lo_permissionCheck?.approve_document) {
    la_menu.push({ path: "/projects/approver-project", title: `Approve`, contents: <>&nbsp;&nbsp;<Badge color="#f13737b8" count={ls_approverDocumentCount} overflowCount={100000000000000} /></>, type: "link", icon:<i className="fa fa-check-square-o"></i> });
  }

  la_menu.push( { path: "/document/share-document", title: "Shared with me", type: "link", icon:<i className="fa fa-share-alt"></i> },
  { path: "/document/share-document-by-me", title: "Shared by me", type: "link", icon:<i className="fa fa-share-alt"></i> } );

  if (lo_permissionCheck?.add_document) {
    la_menu.push({ path: "/document/add-document", title: "Add Documents", type: "link", icon:<i className="fa fa-plus-square-o"></i> });
  }

  return la_menu;
}

const getOtherDocumentChildrenMenuForUser = (ls_approverDocumentCount, lo_permissionCheck) => {
  const la_menu = [
    { path: "/other-documents/search-document", title: "Search Documents", type: "link", icon:<i className="fa fa-search"></i> },
    { path: "/other-documents/my-other-document", title: "My Uploads", type: "link", icon:<i className="fa fa-file"></i> },
  ];

  if (lo_permissionCheck?.add_document) {
    la_menu.push({ path: "/other-documents/add-document", title: "Add Documents", type: "link", icon:<i className="fa fa-plus-square-o"></i> });
  }

  return la_menu;
}

export const getAdminMenuItems = (ls_approverDocumentCount, lo_permissionCheck) => {
  return [
    // {
    //   title: "Dashboard",
    //   icon: Home,
    //   type: "sub",
    //   badgeType: "primary",
    //   active: false,
    //   children: [
    //     { path: "/dashboard/user", title: "User", type: "link" },
    //     { path: "/dashboard/admin", title: "MIS Dashboard", type: "link" },
    //     // { path: "/dashboard/ecommerce", title: "E-Commerce", type: "link" },
    //     // { path: "/dashboard/university", title: "University", type: "link" },
    //     // { path: "/dashboard/server", title: "Server", type: "link" },
    //     // { path: "/dashboard/project", title: "Project", type: "link" },
    //   ],
    // },
    {
      title: "Dashboard",
      icon: Home,
      type: "link",
      path: "/dashboard",
      active: false,
      badgeType: "primary",
    },
    {
      title: "Project Document",
      icon: FileText,
      type: "sub",
      // path: "/search-document",
      active: false,
      children: getDocumentChildrenMenu(ls_approverDocumentCount, lo_permissionCheck),
    },
    {
      title: "Other Documents",
      icon: FilePlus,
      type: "sub",
      // path: "/search-document",
      active: false,
      children: getOtherDocumentChildrenMenu(ls_approverDocumentCount, lo_permissionCheck),
    },
    
    {
      title: "Management",
      icon: Airplay,
      type: "sub",
      active: false,
      children: [
        { path: "/management/user", title: "User", icon: <i className="fa fa-user"></i>, type: "link" },
        { path: "/management/master/sector", title: "Sector", icon: <i className="fa fa-briefcase"></i>, type: "link" },
        { path: "/management/master/practices", title: "Practices", icon: <i className="fa fa-check-square"></i>, type: "link" },
        { path: "/management/master/country", title: "Country", icon: <i className="fa fa-location-arrow"></i>, type: "link" },
        { path: "/management/master/document-category", title: "Document Category", icon: <i className="fa fa-list-alt"></i>, type: "link" },
        { path: "/management/master/doonor-agencies", title: "Donor Agencies", icon: <i className="fa fa-group"></i>, type: "link" },
        // { path: "/management/master/department", title: "Department", icon: <i className="fa fa-bank"></i>, type: "link" },
        { path: "/management/master/group", title: "Group", icon: <i className="fa fa-paw"></i>, type: "link" },
        { path: "/management/master/currency", title: "Currency", icon: <i className="fa fa-paw"></i>, type: "link" },
      ],
    },
    // {
    //   title: "Projects",
    //   icon: BookOpen,
    //   type: "link",
    //   path: "/projects/project-list",
    //   active: false,
    //   badgeType: "primary",
    // },
    {
      title: "Projects",
      icon: BookOpen,
      type: "sub",
      active: false,
      children: [
        { path: "/projects/project-list", title: "Project List", type: "link", icon:<i className="fa fa-list"></i> },
        { path: "/projects/create", title: "Add Project", type: "link", icon:<i className="fa fa-plus-square-o"></i> },
      ],
    },
    // {
    //   title: `Approve Documents(${ls_approverDocumentCount})`,
    //   icon: CheckCircle,
    //   type: "link",
    //   path: "/projects/approver-project",
    //   active: false,
    //   badgeType: "primary",
    // },
    // ${ls_approverDocumentCount}
    // {
    //   title: "Favourite Documents",
    //   icon: Bookmark,
    //   type: "link",
    //   path: "/bookmark-document",
    //   active: false,
    //   badgeType: "primary",
    // },
    {
      title: "Reports",
      icon: FilePlus,
      type: "sub",
      // path: "/search-document",
      active: false,
      children: [
        { path: "/report/activity-report", title: "Activity Report", type: "link", icon:<i className="fa fa-search"></i> },
      ],
    },
    {
      path: "",
      title: "Raise Support",
      icon: Headphones,
      type: "exteral_link",
      active: false,
    },
  ]
};

export const getUserMenuItems = (ls_approverDocumentCount, lo_permissionCheck) => {
  return [
    // {
    //   title: "Dashboard",
    //   icon: Home,
    //   type: "link",
    //   path: "/dashboard",
    //   active: false,
    //   badgeType: "primary",
    // },
    {
      title: "Project Documents",
      icon: FileText,
      type: "sub",
      active: false,
      children: getDocumentChildrenMenuForUser(ls_approverDocumentCount, lo_permissionCheck),
    },
    {
      title: "Other Documents",
      icon: FilePlus,
      type: "sub",
      // path: "/search-document",
      active: false,
      children:  getOtherDocumentChildrenMenuForUser(ls_approverDocumentCount, lo_permissionCheck),
    },
    {
      title: "Projects",
      icon: BookOpen,
      type: "sub",
      active: false,
      children: [
        { path: "/projects/project-list", title: "Project List", type: "link", icon:<i className="fa fa-list"></i> },
      ],
    },
    // {
    //   title: `Approve Documents(${ls_approverDocumentCount})`,
    //   icon: CheckCircle,
    //   type: "link",
    //   path: "/projects/approver-project",
    //   active: false,
    //   badgeType: "primary",
    // },
    // {
    //   title: "Favourite Documents",
    //   icon: Bookmark,
    //   type: "link",
    //   path: "/bookmark-document",
    //   active: false,
    //   badgeType: "primary",
    // },
  ]
};