import React from 'react'
import { Checkbox, Form, Input, Select, Space } from 'antd'
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import MinusCircleOutlined from "@ant-design/icons/MinusCircleOutlined";
// import './style.css'
import { InputNumber } from 'antd/lib';

const MultiItemInput = ({la_currencyList, fieldName, valueFieldName, valueFieldLabel}) => {
    const formatNumber = (value) => {
        if (!value || value === null || value === undefined) {
          return 0;
        }
        const numberValue = parseFloat(value);
        return numberValue % 1 === 0 ? numberValue.toFixed(0) : numberValue.toFixed(2);
      };
      
      const parseNumber = (value) => {
        if (!value || value === null || value === undefined) {
          return 0;
        }
        return parseFloat(value);
      };

    return (
        <div className='ipformitem-space'>
                <Form.List name={[fieldName]} initialValue={[{currency_id:null, [valueFieldName]:0}]}>
                    {(fields, { add, remove }) => (
                        <>

                            {fields.map((field, index) => (
                                <div
                                    key={field.key}
                                    className='deviceformitem-list'
                                    // align="baseline"
                                >
                                    <Form.Item
                                        style={{ marginBottom: 10 , width:'30%', marginRight:8}}
                                        name={[field.name, 'currency_id']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select Currency" showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {la_currencyList && la_currencyList.map(rowValue => <Select.Option value={rowValue.id} key={rowValue.id}>{rowValue.currency}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name={[field.name, valueFieldName]}
                                        style={{ marginBottom: 10, width:'70%', marginRight:8 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'required!',
                                            },
                                        ]}

                                    >
                                        <InputNumber
                                            placeholder={valueFieldLabel}
                                            min={0}
                                            step={0.01}
                                            formatter={formatNumber}
                                            parser={parseNumber}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                    
                                    {
                                        <>
                                            {field.name > 0 ? <MinusCircleOutlined style={{color:"red"}} onClick={() => remove(index)} /> : <PlusCircleOutlined style={{color:"blue"}} onClick={() => add()} />}
                                        </>
                                    }


                                </div>
                            ))}
                        </>
                    )}
                </Form.List>
        </div>
    )
}

export default MultiItemInput