import React from 'react'

const HeaderSidebarComponent = ({onPress=()=>{},lb_default=false}) => {
  return (
    <div className="col-xl-4 col-md-6 col-sm-12">
        <div className="btn-group btn-group-pill" role="group" aria-label="Basic example">
            <button className={`btn btn-info ${lb_default && 'active'}`} type="button" onClick={()=>onPress('Daily')}>{'Daily'}</button>
            <button className="btn btn-info" type="button" onClick={()=>onPress('Weakly')}>{'Weakly'}</button>
            <button className="btn btn-info" type="button" onClick={()=>onPress('Monthly')}>{'Monthly'}</button>
        </div>
   </div>
  )
}

export default HeaderSidebarComponent