import React from 'react'
import Chart from "react-apexcharts";
// const allmonths = Array.from({length: 30}, (e, i) => i + 1);
const turnoverData = {
  series: [
    {
      name: [['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
    ],
      data: [10, 20, 30, 40, 60, 70, 40, 60, 70, 48 , 30, 40, 50, 60, 10, 20, 30, 40, 50, 60, 70 , 30, 40, 50, 40, 70, 70, 90, 80, 70],
    },
    {
      name: [['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
],
      data: [10, 20, 70, 10, 20, 30, 70, 20 , 31, 38, 40 , 31, 38, 20, 10, 20, 38, 10, 20, 30, 40, 40 , 31, 38, 20, 10, 30, 20, 25, 15],
    },
  ],
  options: {
    chart: {
      type: "bar",
      stacked: true,
      height: 320,
      toolbar: {
        show: false,
      },
    },
    colors: ["#495f68", "#6fb5e7"],
    stroke: {
      colors: ["transparent"],
      width: 0,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
        horizontal: false,
        borderRadius: 1,
        dataLabels: {
          total: {
            enabled: true,
          },
        },
      },
    },
    grid: {
      show: true,
      borderColor: "#d9d4d4",
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
      ,
      show: true,
    },
    yaxis: {
      min: 0,
      max: 125,
      tickAmount: 5, // Adjust the number of ticks as needed
      labels: {
        formatter: function (val) {
          return val === 125 ? "125" : val; // Format the labels as needed
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};
const CustomChart = () => {
  return (
    <>
    {/* <div className="xl-50 col-md-6"> */}
                        <div className="small-chart-widget chart-widgets-small">
                        <div className="card">
                            <div className="card-header">
                              <div style={{display:"flex", justifyContent:"space-between"}}>
                                <h5>{'Activity'}</h5>
                                <span style={{fontWeight: 600, color:"#426a7ccc"}}>{'Last 30 Days'}</span>
                              </div>
                            </div>
                            <div className="card-body" style={{background:'#fff !important'}}>
                            <div className="chart-container">
                                <div className="turnover">
                                <Chart series={turnoverData.series} options={turnoverData.options} type={"bar"} height="320" />
                                </div>
                                
                            </div>
                            </div>
                            <div className="card-body chart-block">
                            <div className="show-value-top d-flex mb-0 bottom-morris-chart" style={{marginTop:-10, justifyContent:"center"}}>
                                  <div className="value-third d-inline-block">
                                      <div className="circle-graph d-inline-block m-r-5" style={{backgroundColor:"#495f68"}}></div>
                                      <span>{'Download'}</span>
                                  </div> 
                                  <div className="value-third d-inline-block">
                                      <div className="circle-graph d-inline-block m-r-5" style={{backgroundColor:"#6fb5e7"}}></div>
                                      <span>{'View'}</span>
                                  </div> 
                              </div>
                            </div>
                        </div>
                        </div>
                    {/* </div> */}
    </>
  )
}

export default CustomChart