import React from "react";
import Default from "../components/dashboard/defaultCompo/default";
import Ecommerce from "../components/dashboard/ecommerce";
import University from "../components/dashboard/university";
import ServerComponent from "../components/dashboard/server/serverComponent";
import Project from "../components/dashboard/project/project";

// sample page
import SupportTicket from "../components/support-ticket/supportTicket";
import SearchDocument from "../pages/document/searchDocument";
import AddDocument from "../pages/document/addDocument";
import UserAndAdminLogin from "../pages/auth/userandadminlogin";
import DocumentDetails from "../pages/document/documentDetails";
import User from "../pages/management/user";
import ProjectList from "../pages/projects/projectList";
import CreateProject from "../pages/projects/createProject";
import ApprovedProject from "../pages/projects/approvedProject";
import Sector from "../pages/management/master/sector";
import Practices from "../pages/management/master/practices";
import Country from "../pages/management/master/country";
import DocumnetCategory from "../pages/management/master/document_categoy";
import DonorAgencies from "../pages/management/master/donor";
import Department from "../pages/management/master/department";
import Group from "../pages/management/master/group"
import Currency from "../pages/management/master/currency"
import Dashboard from "../pages/dashboard";
import MyDocument from "../pages/document/myDocumnet";
import UpdateDocument from "../pages/document/myDocumnet/UpdateDocument";
import BookmaredDocument from "../pages/bookmaredDocument";
import SearchOtherDocument from "../pages/otherDocuments/searchDocument";
import AddOtherDocument from "../pages/otherDocuments/addDocument";
import ShareDocument from "../pages/document/shareDocument";
import OtherDocumentDetails from "../pages/otherDocuments/documentDetails";
import MyOtherDocument from "../pages/otherDocuments/myOtherDocument";
import UpdateOtherDocument from "../pages/otherDocuments/myOtherDocument/UpdateOtherDocument";
import ProfileDetails from "../pages/userAccount/profile/ProfileDetails";
import ShareDocumentByMe from "../pages/document/ShareDocumentByMe";
import ActivityReports from "../pages/reports/ActivityReports";
import CommonLogout from "../components/CommonLogout";
import NotificationList from "../pages/NotificationSection/NotificationList";
// import SearchDocument from "../pages/otherDocument/searchDocument";
// import AddDocument from "../pages/otherDocument/addDocument";

// const ls_userType = localStorage.getItem('user_type');

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
  { path: `${process.env.PUBLIC_URL}/document/search-document`, Component: <SearchDocument /> },
  { path: `${process.env.PUBLIC_URL}/document/add-document`, Component: <AddDocument />, permission_key: 'add_document' },
  { path: `${process.env.PUBLIC_URL}/document/document-details`, Component: <DocumentDetails /> },
  { path: `${process.env.PUBLIC_URL}/management/user`, Component: <User />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/projects/project-list`, Component: <ProjectList /> },
  { path: `${process.env.PUBLIC_URL}/projects/create`, Component: <CreateProject />, permission_key: 'admin'  },
  { path: `${process.env.PUBLIC_URL}/projects/update`, Component: <CreateProject />, permission_key: 'admin'  },
  { path: `${process.env.PUBLIC_URL}/projects/approver-project`, Component: <ApprovedProject />, permission_key: 'approve_document' },
  { path: `${process.env.PUBLIC_URL}/management/master/sector`, Component: <Sector />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/management/master/practices`, Component: <Practices />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/management/master/country`, Component: <Country />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/management/master/document-category`, Component: <DocumnetCategory />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/management/master/doonor-agencies`, Component: <DonorAgencies />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/management/master/department`, Component: <Department />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/management/master/group`, Component: <Group />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/management/master/currency`, Component: <Currency />, permission_key: 'admin' },
  { path: `${process.env.PUBLIC_URL}/document/my-document`, Component: <MyDocument /> },
  { path: `${process.env.PUBLIC_URL}/document/update-document`, Component: <UpdateDocument /> },
  { path: `${process.env.PUBLIC_URL}/bookmark-document`, Component: <BookmaredDocument /> },
  { path: `${process.env.PUBLIC_URL}/other-documents/search-document`, Component: <SearchOtherDocument /> },
  { path: `${process.env.PUBLIC_URL}/other-documents/add-document`, Component: <AddOtherDocument />, permission_key: 'add_document' },
  { path: `${process.env.PUBLIC_URL}/document/share-document`, Component: <ShareDocument /> },
  { path: `${process.env.PUBLIC_URL}/document/share-document-by-me`, Component: <ShareDocumentByMe /> },
  { path: `${process.env.PUBLIC_URL}/other-documents/document-details`, Component: <OtherDocumentDetails /> },
  { path: `${process.env.PUBLIC_URL}/other-documents/my-other-document`, Component: <MyOtherDocument /> },
  { path: `${process.env.PUBLIC_URL}/other-documents/update-other-document`, Component: <UpdateOtherDocument /> },
  // my-profile
  { path: `${process.env.PUBLIC_URL}/my-profile`, Component: <ProfileDetails /> },

  // Report section
  { path: `${process.env.PUBLIC_URL}/report/activity-report`, Component: <ActivityReports />, permission_key: 'admin' },

  { path: `${process.env.PUBLIC_URL}/logging-out-user`, Component: <CommonLogout /> },

  { path: `${process.env.PUBLIC_URL}/notification-list`, Component: <NotificationList />},
  
  // { path: `${process.env.PUBLIC_URL}/auth/user-login`, Component: <UserAndAdminLogin header={'User'}/> },
  // { path: `${process.env.PUBLIC_URL}/auth/admin-login`, Component: <UserAndAdminLogin header={'Admin'}/> },
 
  // { path: `${process.env.PUBLIC_URL}/auth/addmi-login`, Component: <Login /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/university`, Component: <University /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/server`, Component: <ServerComponent /> },
  // { path: `${process.env.PUBLIC_URL}/dashboard/project`, Component: <Project /> },

  // { path: `${process.env.PUBLIC_URL}/support-ticket/supportTicket`, Component: <SupportTicket /> },
];
