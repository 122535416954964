import React, { Fragment, useEffect, useState } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import {UserName,GeneralManager} from '../../../constant'
import { useSelector } from 'react-redux';
import { getRequestApiByToken } from '../../../api';
import userimg from "../../../assets/images/user/demouser.png"
import adminimg from "../../../assets/images/user/demouser.png"

const UserPanel = () => {
    const ls_userId = useSelector(content => content.commonReducer.user_id);
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const lo_userDetails = useSelector(content => content.commonReducer.user_details);
    // const userimg = 'https://react.pixelstrap.com/endless/static/media/16.e30fc62d991f11de1ef0.jpg';
    // const adminimg = 'https://react.pixelstrap.com/endless/static/media/5.53a215ed00aac31d7543.jpg';
    const dfltimg = 'https://e7.pngegg.com/pngimages/809/39/png-clipart-computer-icons-user-symbol-encapsulated-postscript-info-miscellaneous-cdr-thumbnail.png';
    const url = '';
    const [lo_userInfo, setUserInfo] = useState({})

    const getuserinfo = async () => {
        let res = await getRequestApiByToken(`userdetails/${ls_userId}`)
        setUserInfo(res?.User)
    }
    useEffect(() => {
        getuserinfo()
    }, [ls_userId])

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={ls_userType == 'user' ? userimg : (ls_userType == 'admin' ?adminimg : dfltimg)} alt="#" />
                    <div className="profile-edit">
                        <Link 
                            // to={`${process.env.PUBLIC_URL}/users/userEdit`}
                        >
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{lo_userInfo?.name }</h6>
                <p>{lo_userInfo?.info?.designation}.</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;