import React, { useEffect, useState } from 'react'
import { Input, Checkbox, Row, Col, Badge, Tag, Card, Button } from 'antd';
import './style.css'
import Breadcrumb from '../../../../components/common/breadcrumb';
import ProfileBasicDetails from './ProfileBasicDetails';
import UserWithAccessDetails from './userWithAccessDetails';
import { useSelector } from 'react-redux';

const { Search } = Input;

const ProfileDetails = () => {
    const ls_userId = useSelector(content => content.commonReducer.user_id);
    const [userId, handleSetUserId] = useState('');
    const [lb_showUserAccess, setShowUserAccess] = useState(false);

    useEffect(() => {
        handleSetUserId(ls_userId);
    }, [ls_userId])
    
    return (
        <>
            <Breadcrumb parent="My Profile" />
            <div className='profile-dtls-prnt'>
                <div className="col-xl-12 xl-100">
                    <div className="card">
                        <div className="card-header card-header-border">
                            <div className="col-sm-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h5>{"Profile Details"}</h5>
                            </div>
                        </div>
                        <div className="card-body">
                            <ProfileBasicDetails userId={userId} setShowUserAccess={setShowUserAccess} />
                        </div>
                    </div>
                </div>
                {(userId && lb_showUserAccess) ?
                <div className="col-xl-12 xl-100">
                    <div className="card">
                        <div className="card-header card-header-border">
                            <div className="col-sm-7">
                                <h5>{"User With Access"}</h5>
                            </div>
                        </div>
                        <div className="card-body">
                            <UserWithAccessDetails userId={userId} />
                        </div>
                    </div>
                </div>
                : <></>}
            </div>
        </>
    )
}

export default ProfileDetails
