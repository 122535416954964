import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd/lib';
import Badge from 'antd/lib/badge';
import Tag from 'antd/lib/tag';

const TagSelection = ({ value = [], onChange = () => { } , la_documentTypes = [], isInput=false}) => {
    const [la_tagIds, setTagIds] = useState([...value]);
    const handleSetTagIds = (ls_id) => {
        const la_tags = [...la_tagIds];
        if (la_tagIds.includes(ls_id)) {
            const index = la_tags.indexOf(ls_id);
            if (index > -1) la_tags.splice(index, 1);

        } else {
            la_tags.push(ls_id);
        }

        setTagIds(la_tags)
        onChange(la_tags)
    }

    // useEffect(() => {
    //   console.log('----la_tagIds',la_tagIds);
    // }, [la_tagIds])
    
    return (
        <>
            {/* <Row justify={'start'}>
                {
                    la_documentTypes?.map((rowValue) =>
                        <Col key={rowValue?.id} xs={24} sm={24} md={8} lg={8} style={{ padding: 5, display: 'flex', justifyContent: 'flex-start' }}>

                            <Badge count={rowValue?.category_name ? parseInt(rowValue.documents_count) : 0} color={'#2fc3e5'} overflowCount={100000000000000}
                            // color='lime'
                            >
                                <Tag onClick={() => isInput ? handleSetTagIds(rowValue?.id) : ()=> {}} style={{ cursor: "pointer" }} color={la_tagIds.includes(rowValue?.id) ? "lime" : "geekblue"}>{rowValue?.category_name}</Tag>
                            </Badge>
                        </Col>)
                }

            </Row> */}

            <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between"}}>
                {
                    la_documentTypes?.map((rowValue) =>
                        <div key={rowValue?.id} style={{ padding: `9px  ${rowValue?.documents_count?.toString()?.length * 5}px 5px 5px`, display: 'flex', justifyContent: 'flex-start' }}>

                            <Badge count={rowValue?.category_name ? parseInt(rowValue.documents_count) : 0} color={'#2fc3e5'} overflowCount={100000000000000}
                            // color='lime'
                            >
                                <Tag onClick={() => isInput ? handleSetTagIds(rowValue?.id) : () => { }} style={{ cursor: "pointer" }} color={la_tagIds.includes(rowValue?.id) ? "lime" : "geekblue"}>{rowValue?.category_name}</Tag>
                            </Badge>
                        </div>)
                }

            </div>
        </>
    )
}

export default TagSelection