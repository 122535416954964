import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import store from "./store";
import MainRoutes from "./routes";
import { MsalConfig } from "./constant/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as serviceWorkerRegistration from './';

// setup fake backend

const Root = () => {
  // MSAL configuration remains the same
  let msalInstance;
  if (!msalInstance) msalInstance = new PublicClientApplication(MsalConfig);

  return (
    <div className="App" >
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <MainRoutes />
        </MsalProvider>
      </Provider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

serviceWorker.unregister();

// Register the service worker
ServiceWorkerRegistration?.register({
  onUpdate: registration => {
    alert('New version available!  Ready to update?');
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  }
});
