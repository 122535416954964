import React, { useState } from 'react'
import { LikeOutlined, DislikeOutlined, ShareAltOutlined, DownloadOutlined, LoadingOutlined, ClockCircleOutlined, CheckCircleOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { postRequestApiByToken } from '../../../../api';
import { message } from 'antd/lib';
import ShareModal from '../../DocumentListing/ShareModal';
import moment from 'moment/moment';

const DocActions = ({ls_documentId, lo_documentData, getData = ()=>{}, getAndSetCounterData=()=>{}}) => {
    const [lb_dwnloadLoading, setDwnloadLoading] = useState(false);
    const [lb_favLoading, setFavLoading] = useState(false);
    const [lb_shareModal, setShareModal] = useState(false);

    const handleDownload = async () => {
        let lo_formData = {
            document_id: ls_documentId,
            type: 'download'
        };
        setDwnloadLoading(true);
        const res = await postRequestApiByToken('documentaction', lo_formData);
        if (res?.status == "Success") {
            getAndSetCounterData('download_count');
            setDwnloadLoading(false);
            message.success(res?.message);
            console.log('ressss --download---', res);

            if (!res?.data?.full_file_url) {
                message.error('File not found!');
                return;
            }

            // Fetch the file as a blob
            fetch(res.data.full_file_url)
                .then(response => response.blob())
                .then(blob => {
                    const ls_docFileNm = (lo_documentData?.project?.mandate_no || '') +'-'+ (lo_documentData?.document_parent_category?.category_name || '') +'-'+moment(new Date()).format('-DD-MMMM-YYYY-hh-mm-A');
                    const ls_extensen =res?.data?.full_file_url?.split('.').pop().split('?')[0];
                    console.log('---ls_extensen',ls_extensen);
                    const blobUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = `${ls_docFileNm}-.${ls_extensen}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(blobUrl); // Clean up the blob URL
                })
                .catch(error => console.error('Error downloading the file:', error));
        } else {
            setDwnloadLoading(false);
            message.error(res?.message || 'Access denied!');
        }
    };

    const handleBookedMarkButtonPress =async (item) => {
        setFavLoading(true);
        let lo_formData = {
          document_id: item?.id ,
          status: item.bookmarked ? 0 : 1 
        }
        const res = await postRequestApiByToken('addbookmark', lo_formData)
        setFavLoading(false);
        if (res?.status =="Success") {
            const ls_msg = item.bookmarked ? 'Successfully removed from bookmark!' : res?.message;
            message.success(ls_msg);
            getData()
        }
    }

    return (
        <>
        <div>
            <div className='card' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 23 }}>

                <button onClick={() => handleBookedMarkButtonPress(lo_documentData)} style={{ minWidth: "150px" }} className="btn btn-pill btn-warning btn-air-warning btn-warning-gradien" type="button">
                    {lb_favLoading ? <LoadingOutlined /> : <>{lo_documentData?.bookmarked ? <StarFilled /> : <StarOutlined />}</>}
                    {lo_documentData?.bookmarked ? " Remove" : " Add to Favourites"}</button>

                <button onClick={()=>{setShareModal(true);}} style={{ minWidth: "150px" }} className="btn btn-pill btn-primary btn-air-primary btn-primary-gradien" type="button"><ShareAltOutlined /> Share</button>

                {/* <button style={{ minWidth: "150px" }} disabled={lb_dwnloadLoading} className="btn btn-pill btn-success btn-air-success btn-success-gradien" type="button" onClick={handleDownload}>{lb_dwnloadLoading ? <LoadingOutlined /> : <DownloadOutlined />} Download</button> */}

            </div>
        </div>

        <ShareModal visible={lb_shareModal} onCancel={()=>setShareModal(false)} ls_documentId={ls_documentId} />
        </>
    )
}

export default DocActions