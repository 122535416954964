import React from 'react'
import { Doughnut } from "react-chartjs-2";

const ManageDocument = () =>{

    const doughnutData = {
        labels: ["Assesment Report", "Financial Budget", "Presentation"],
        datasets: [
            {
                legend: "none",
                data: [40, 8, 10],
                borderColor: ["#23b03f", "#1ea6ec", "#f05338"],
                backgroundColor: ["#23b03f", "#1ea6ec", "#f05338"],
                fill: "origin",
            },
        ],
    };

    const doughnutOptions = {
        maintainAspectRatio: true,
        elements: {
            point: {
                hoverRadius: 7,
                radius: 5,
            },
        },
        legend: {
            display: false,
        },
        plugins: {
            datalabels: {
                display: true, // show the data labels
                formatter: (value, ctx) => {
                    return value; // return the value to display
                },
            },
        },
    };
    
  return (
    <div className="card manage-card-height" 
    // style={{minHeight:'535px !important'}}
    >
    <div className="card-header">
        <h5>{'Managed Documents'}</h5>
    </div>
    <div className="card-body chart-block">
        <div className="flot-chart-placeholder" id="bitcoin-morris">
        <Doughnut data={doughnutData} options={doughnutOptions} height={305} />
        </div>
        <div className="value-right" style={{textAlign:'center',marginTop:-28}}>
                <h6 style={{textAlign:'center'}}>Total: <strong>58</strong> Files</h6>
        </div>
        {/* <div className="show-value-top d-flex mb-0 bottom-morris-chart" style={{marginTop:-42}}>
             <div className="value-left d-inline-block">
                <div className="circle-graph bg-primary d-inline-block m-r-5"></div>
                <span>{'Assesment Report'}</span>
            </div>
            <div className="value-right d-inline-block">
                <div className="circle-graph d-inline-block bg-secondary m-r-5"></div>
                <span>{'Financial Budget'}</span>
            </div>
            <div className="value-third d-inline-block">
                <div className="circle-graph d-inline-block m-r-5" style={{backgroundColor:"#FF5370"}}></div>
                <span>{'Presentation'}</span>
            </div> 
        </div> */}
    </div>
    </div>
  )
}

export default ManageDocument