import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd/lib';
import { postRequestApiWithDataType } from '../../../api';
import { EventType } from '@azure/msal-browser';
import { MicrosoftClientId, MicrosoftRedirectUri } from '../../../constant/authConfig';
import { saveFcmToken } from '../../../api/commonApi';

const UserAuthentication = () => {
  const { instance } = useMsal();

  // user login and set login details
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setUserDEtails = (userType, userId, authToken, userDetails, sector) => {
    dispatch({ type: 'SET_USER_TOKEN', payload: authToken });
    dispatch({ type: 'SET_USER_ID', payload: userId });
    dispatch({ type: 'SET_USER_TYPE', payload: userType });
    dispatch({ type: 'SET_USER_DETAILS', payload: {...userDetails} });
    dispatch({ type: 'SET_HAS_SECTOR', payload: sector });
  }

  const loginAuth = async (lo_data) => {
    if (!lo_data?.username || !lo_data?.idToken) {
        message.error('Invalid Email Id!');
        return;
    }

    const lo_postData = {...lo_data}
    const lo_response = await postRequestApiWithDataType('json', 'auth-login', lo_postData);
    console.log('---login---',lo_response);
    if (lo_response?.success) {
      const usrTyp = (lo_response?.user?.user_type_id == 1) ? 'admin' : 'user';
      setUserDEtails(usrTyp, lo_response?.user?.id, lo_response?.token?.token, lo_response?.user, lo_response?.sector);
      saveFcmToken(lo_response?.token?.token);
      message.success("Logged in successfully!");
      if (usrTyp == 'user') {
        navigate(`${process.env.PUBLIC_URL}/document/search-document`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      }
      
    }else{
      // message.error(lo_response?.message)
    }
  }

  // const navigateToPage = (userType) => {
  //   const page = userType === 'user' ? '/document/search-document' : '/dashboard';
  //   navigate(`${process.env.PUBLIC_URL}${page}`, { replace: true });
  //   };

  useEffect(() => {
    const handleAuthResponse = async () => {
    // Parse the hash to extract the code and other information
    const hash = window.location.hash.substr(1);
    const result = hash.split('&').reduce((result, item) => {
    const parts = item.split('=');
    result[parts[0]] = parts[1];
    return result;
    }, {});
    
    if (result.code) {
    try {
    // Acquire a token using the code received from Azure AD
    const response = await instance.acquireTokenByCode({
    ...{
      auth: {
        clientId: MicrosoftClientId,
        // authority: BaseUrl,
        redirectUri: MicrosoftRedirectUri,
        // postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    code: result.code,
    });
    
    console.log('--response',response);
    // Use the acquired token to authenticate the user
    // You can dispatch actions to your store or navigate to a different page
    // For example:
    // navigate('/home'); // Navigate to the home page after successful login
    } catch (error) {
    console.error('Error during authentication:', error);
    // Handle or log error as appropriate
    }
    }
    };
    
    handleAuthResponse();
  }, [instance, navigate]);



  return (
    <div>
      <h2>Authenticating...</h2>
      {/* You can add additional UI elements here */}
    </div>
  );
}

export default UserAuthentication