import React, { useEffect, useState, useRef } from 'react';
import { Download, Eye, File, FilePlus, } from 'react-feather';
import CountUp from 'react-countup';
import { Form, Popover, Typography, Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DataTable from 'react-data-table-component'
import { LoadingOutlined } from '@ant-design/icons';
import { getFileExtension, getQuerySelectorValueFormArray } from '../../../utility';
import { getRequestApiByToken } from '../../../api';
import { FileIconsObj } from '../../../constant/globalConfig';
import DocumentListing from '../DocumentListing';



const RelatedDocumentList = ({ selectedProject }) => {
  const popoverRef = useRef();
  const [la_documentList, setDocumentList] = useState([])
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changePage, setChangePage] = useState(false);
  const [ls_searchText, setsSearchText] = useState('')
  const [la_keyWords, setKeyWords] = useState([]);
  const [lb_loading, setLoading] = useState(false);
  const [la_documentTypes, setDocumentTypes] = useState([])
  const [la_selectedDocumentType, setSelectedDocumentType] = useState([])
  
  const [la_country_id, setCountryids] = useState([]);
  const [la_sector_id, setSectorids] = useState([]);
  const [la_parctices, setParcticesids] = useState([]);
  const [la_donor, setDonorids] = useState([]);

  const columns = [
    {
      selector: (row) =>
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {row?.title}
        </div>
      ,
      name: "Document Name",
      sortable: true,
      width: '310px',
      // center: true,
    },
    {
      selector: (row) => row?.project?.mandate_no,
      name: "Mandate No.",
      sortable: true,
      center: true,
      width: '220px'
    },
    {
      selector: (row) => row?.sectors?.map((i, inx) => i?.sector_name),
      name: "Sector",
      sortable: true,
      center: true,
      width: '265px',
    },

    {
      selector: (row) => moment(row?.document_created_date).format("DD MMM YYYY"),
      name: "Date",
      sortable: true,
      center: true,
    },
    {
      selector: (row) => {
        const extnsn = getFileExtension(row?.document_file || '');
        return <div>
          {(row?.document_file && extnsn) ? FileIconsObj[extnsn] : row?.document_file}
        </div>
      },
      name: "Type",
      center: true,
    },
    {
      selector: (row) => <div>
        <span style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }}>
          <Link to={'/document/document-details'} state={{ documentId: row.id }} style={{ marginInlineEnd: 10 }}>
            <i className="fa fa-eye text-secondary" style={{ fontSize: 18 }} />
          </Link>
          <a href='#javascript'><i className="fa fa-share-alt text-secondary" style={{ fontSize: 18 }}></i></a>
          <Popover ref={popoverRef} placement="left" content={getDocsDetailsContent(row)} trigger="click" overlayClassName='col-xl-4 xl-100'>
            <a className="badge rounded-pill badge-secondary"
              href="#javascript" style={{ marginInlineStart: 10 }}>
              <i className="icofont icofont-info"></i></a>
          </Popover>
        </span>
      </div>,
      name: "Action",
      //   sortable: true,
      center: true,
      // width: '100px',
    },
  ];

  const handleDocTypeChange = (docTypeValues) => {
    console.log('---docTypeValues---',docTypeValues);
    setSelectedDocumentType(docTypeValues || [])
  }


  const getSearchData = async (selectedProject, value = '', searchType) => {

    if (!selectedProject) { setDocumentList([]); return; }

    const lo_paramsValue = value;
    // if(ls_search && !value?.search) lo_paramsValue['search'] = ls_search;
    const ls_params = getQuerySelectorValueFormArray(lo_paramsValue);

    setLoading(true);
    console.log('ls_paramsls_paramsls_paramsls_params', selectedProject);

    let res;
    if (searchType == 'advance') {
      res = await getRequestApiByToken(`document?orderBy=dsc&pagination=true&page=${currentPage}&project_id=${selectedProject}`);
    } else {
      res = await getRequestApiByToken(`document?orderBy=desc&pagination=true&page=${currentPage}&${ls_params}`);
    }

    setTotalCount(res?.Documents?.total || 0)
    setDocumentList(res?.Documents?.data || []);
    
    if (searchType == 'advance') {
      const sectorsData = res?.sectors ? Object.values(res?.sectors) : [];
      const parcticesData = res?.parctices ? Object.values(res?.parctices) : [];
      const countriesData = res?.countries ? Object.values(res?.countries) : [];
      const clientsData = res?.clients ? Object.values(res?.clients) : [];
      const doctypes = res?.categories ? Object.values(res?.categories) : [];
      
      
      const country_ids = countriesData?.map((rowValue) => rowValue?.id);
      const sector_ids = sectorsData?.map((rowValue) => rowValue?.id);
      const parctices_ids = parcticesData?.map((rowValue) => rowValue?.id);
      const donor_ids = clientsData?.map((rowValue) => rowValue?.id);
      
      setCountryids(country_ids)
      setSectorids(sector_ids)
      setParcticesids(parctices_ids)
      setDonorids(donor_ids)
      setDocumentTypes(doctypes);
    }

    setLoading(false);
  }

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if(currentPage > 1) setCurrentPage(1); else setChangePage(!changePage);
  }, [selectedProject, la_selectedDocumentType]);

  useEffect(() => {
    let la_typIds = [];
    if (la_selectedDocumentType?.length) {
      la_typIds = [...la_selectedDocumentType];
    } else {
      la_typIds = la_documentTypes?.map((rowValue) => rowValue?.id);
    }

    if (la_selectedDocumentType?.length) {
      getSearchData(selectedProject, { country_id: la_country_id, sector_id: la_sector_id, parctices: la_parctices, donor: la_donor, category_id: la_typIds }, 'filter-with-type');
    } else {
      getSearchData(selectedProject, null, 'advance');
    }

  }, [currentPage, changePage])

  // should make common
  const getFileDetails = (row) => {
    const extension = getFileExtension(row?.document_file || '');
    const fileElement = (row?.document_file && extension) ? FileIconsObj[extension] : row?.document_file
    return { extension, fileElement }
  }
  const getDocsDetailsContent = (lo_documentData = {}) => {
    const items = [
      {
        key: '1',
        label: <Typography.Text type="secondary" strong><i className="fa fa-file-o docdtl-icn-color"></i> &nbsp;Document Name</Typography.Text>,
        children: lo_documentData?.title,
      },
      // {
      //   key: '2',
      //   label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Project Name</Typography.Text>,
      //   children: lo_documentData?.project?.project_name,
      // },
      {
        key: '3',
        label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Created Date</Typography.Text>,
        children: moment(lo_documentData?.document_created_date).format("DD MMM YYYY"),
      },
      {
        key: '3',
        label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Last Downloaded</Typography.Text>,
        // children: '10/09/2013',
        children: '--',
      },
      {
        key: '4',
        label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;No of Pages</Typography.Text>,
        children: '8',
      },
      {
        key: '4',
        label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;Type</Typography.Text>,
        children: <>{getFileDetails(lo_documentData)?.extension} {getFileDetails(lo_documentData)?.fileElement}</>,
        // children: <>Pdf <i className="fa fa-file-pdf-o"></i></>,
      },
      {
        key: '4',
        label: <Typography.Text type="secondary" strong><i className="fa fa-download docdtl-icn-color"></i> &nbsp;No of Downloads</Typography.Text>,
        children: '0',
      },
      {
        key: '4',
        label: <Typography.Text type="secondary" strong><i className="fa fa-eye docdtl-icn-color"></i> &nbsp;No of Views</Typography.Text>,
        children: '0',
      },
    ];
    return (
      // <div className='col-xl-6 xl-100'>
      <Descriptions bordered column={1} size="small" labelStyle={{ minWidth: 180 }}>
        {items.map((item) => (
          <Descriptions.Item key={item.key} label={item.label}>
            {item.children}
          </Descriptions.Item>
        ))}
      </Descriptions>
      // </div>
    )
  }
  return (
    <div>
      <DocumentListing {...{ lb_showDocTypeTag: true, ls_headerText: 'Other Project Related Document', la_documentTypes, isInput:true, handleDocTypeChange, lb_loading, handlePaginationChange, totalCount, la_documentList, getSearchData, setLoading, la_actionAceess: ['view', '', 'share', '', ''] }} />

      {/* <div className="table-responsive">
        <DataTable
            columns={columns}
            data={la_documentList}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalCount} // Assuming 10 rows per page
            paginationPerPage={10} // Number of rows per page
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
            paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
            onChangePage={setCurrentPage} 
            progressPending={lb_loading}
            progressComponent={<div><LoadingOutlined/></div>}
            customStyles={{
            headCells: {
                style: {
                paddingLeft: '8px', // Adjust cell padding as needed
                paddingRight: '8px',
                },
            },
            rows: { style: { minHeight: '40px'}},
            cells: {
                style: {
                paddingLeft: '8px', // Adjust cell padding as needed
                paddingRight: '8px', // Adjust cell padding as needed
                },
            },
            }}
        />
      </div> */}
    </div>

  )
}

export default RelatedDocumentList