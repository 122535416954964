import React from 'react'
import { Col, Card, CardHeader, CardFooter, Media } from 'reactstrap'

const UserCard = ({lo_userDetails={}}) => {
  return (
    <Card className="custom-card manage-profile"  style={{minHeight:535, paddingBottom:0}} >
        <CardHeader>
            <Media body className="img-fluid" src={'https://react.pixelstrap.com/endless/static/media/7.7b45885578f37fc46324.jpg'} alt="" />
        </CardHeader>
        <div className="card-profile">
            <Media body className="rounded-circle" src={'https://react.pixelstrap.com/endless/static/media/16.e30fc62d991f11de1ef0.jpg'} alt="" />
        </div>
        {/* <ul className="card-social">
            <li><a href="https://www.facebook.com/"><i className="fa fa-facebook"></i></a></li>
            <li><a href="https://accounts.google.com/"><i className="fa fa-google-plus"></i></a></li>
            <li><a href="https://twitter.com/"><i className="fa fa-twitter"></i></a></li>
            <li><a href="https://www.instagram.com/"><i className="fa fa-instagram"></i></a></li>
            <li><a href="https://dashboard.rss.com/auth/sign-in/"><i className="fa fa-rss"></i></a></li>
        </ul> */}
        <div className="text-center profile-details">
            <h4>{lo_userDetails?.name}</h4>
            <h6>{lo_userDetails?.info?.designation}</h6>
        </div>
        <CardFooter className="row">
            <Col sm="6 col-6">
            <h6>{"Mobile No"}</h6>
            <small className="counter">{lo_userDetails?.info?.mobile_no}</small>
            </Col>
            <Col sm="6 col-6">
            <h6>{"Email Id"}</h6>
            <small className="counter">{lo_userDetails?.email}</small>
            </Col>
            {/* <Col sm="4 col-4">
            <h6>{"Sector"}</h6>
            <small className="counter">{lo_userDetails?.info?.sector}</small>
            </Col> */}
        </CardFooter>
    </Card>
  )
}

export default UserCard