
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container } from "reactstrap";
import DataTable from 'react-data-table-component'
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Tooltip, message ,Input} from 'antd';
import { FileIconsObj } from '../../../constant/globalConfig';
import { getCascaderIdsFormArray, getCountryCode, getFileExtension } from '../../../utility';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

const { Search } = Input;

const MyOtherDocument = () => {
  const ls_userId = useSelector(content => content.commonReducer.user_id);
  const ls_userType = useSelector(content => content.commonReducer.user_type);
  const [la_documentList, setDocumentList] = useState([])
  const [loading, setLoading] = useState(false);
  const [lb_buttonLoader, setButtonLoader] = useState(false)
  const [ls_updateId, setUpdateId] = useState('')
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lb_spining, setSpining] = useState(false);
  const [la_columnsList, setColumnsLis] = useState([]);
  const [filterText, setFilterText] = useState('');

  const [form] =Form.useForm()

  const getDocumentList = async () => {
    setLoading(true)
      const res = await getRequestApiByToken(`myotherdocument?search=${filterText}&orderBy=desc&page=${currentPage}`)
      setDocumentList(res?.Documents?.data)
      setTotalCount(res?.Documents?.total);
    setLoading(false)
  }
  
 useEffect(() => {
    getDocumentList()
 }, [currentPage,filterText])

 const returnCountryIcon = (cntryNm) => {
  const cntryCode = getCountryCode(cntryNm);
  if (cntryCode) {
    return <Tooltip title={cntryNm}>
            <i className={`flag-icon flag-icon-${cntryCode.toLowerCase()}`} style={{ width: 45, fontSize: 16, padding: 5}}></i>
          </Tooltip>;
  } else {
    return cntryNm;
  }
 }
 
 const columns = [
  // {
  //   name: "Country",
  //   selector: (row) => <div>
  //     <span>
  //       {row?.project?.country[0]?.country_name ? returnCountryIcon(row.project.country[0].country_name) : ''}
  //     </span>
  //   </div>,
  //   // row?.project?.country[0]?.country_name,
  //   sortable: true,
  //   center: true,
  //   width: '100px', 
  // },
  {
    selector: (row) =>
    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {row?.title}
    </div>
   ,
    name: "Document Name",
    sortable: true,
    width: '400px', 
    // center: true,
  },
  {
    selector: (row) =>
    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
     {row?.sectors?.map((i,inx)=>i?.sector_name)}
     </div>
     ,
    name: "Sector",
    sortable: true,
    // center: true,
    width: '265px', 
  },
  {
    selector: (row) => moment(row?.document_created_date).format("DD MMM YYYY"),
    name: "Date",
    sortable: true,
    center: true,
  },
  {
    selector: (row) => {
      const extnsn = getFileExtension(row?.document_file || '');
      return <div>
    {(row?.document_file && extnsn) ? FileIconsObj[extnsn] : row?.document_file}

          {/* <span style={{cursor:"pointer"}}>
      <Link to="/document/update-document" 
       state={{ updateId: row.id }}
      >
        <i 
        className="fa fa-pencil" 
        style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}
        onClick={() => { setUpdateId(row.id); window.scrollTo({ top: 0, behavior: "smooth" }) ;}}
        ></i>
      </Link>
    </span> */}
      </div>
    } ,
    name: "Type",
    center: true,
  },
  {
    selector: (row) => <div>
      <span style={{cursor:"pointer"}}>
      <Link to="/other-documents/update-other-document" 
       state={{ updateId: row.id }}
      >
        <i 
        className="fa fa-pencil" 
        style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}
        onClick={() => { setUpdateId(row.id); window.scrollTo({ top: 0, behavior: "smooth" }) ;}}
        ></i>
      </Link>
    </span>
    {/* <span>
      <i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i>
    </span> */}
  </div>,
    name: "Action",
  //   sortable: true,
    center: true,
    // width: '100px',
  },
];

useEffect(() => {
  const columnList = [...columns];
  // if (ls_userType == 'user') {
  //    columnList.pop();
  // }
  setColumnsLis(columnList);
}, [ls_userType])

const resetFormData = async ()=>{
  form.resetFields()
  setUpdateId('')
}

const [lo_updatRecord, setUpdateRecord] = useState({})
const setFormData = async ()=>{
  setSpining(true)
  const lo_updateDate = {}
  if (ls_updateId) {
      const lo_response = await getRequestApiByToken(`document/${ls_updateId}`);
      const lo_record = lo_response?.Documents ? lo_response?.Documents : {};
      lo_updateDate['title'] = lo_record.title;
      lo_updateDate['project_id'] = lo_record?.project?.id;
      lo_updateDate['parent_cat_id'] = lo_record?.parent_cat_id;
      if(lo_record?.child_cat_id && lo_record.child_cat_id != 'undefined') lo_updateDate['child_cat_id'] = parseInt(lo_record.child_cat_id);
      lo_updateDate['sector_id'] = getCascaderIdsFormArray(lo_record?.sectors);
      lo_updateDate['parctices'] = getCascaderIdsFormArray(lo_record?.practices);
      lo_updateDate['group_id'] = getCascaderIdsFormArray(lo_record?.groups);
      lo_updateDate['Country'] = getCascaderIdsFormArray(lo_record?.country);
      lo_updateDate['key_words'] = lo_record?.key_words?.split(",") || [];
      // getCascaderIdsFormArray(lo_record?.groups);
      // lo_updateDate['document_file'] = lo_record.document_file;
      if(lo_record?.document_file) lo_updateDate['document_file'] = [{ uid: '1', name: lo_record.document_file }];
      if(lo_record?.document_created_date) lo_updateDate['document_created_date'] = dayjs(lo_record.document_created_date);
      lo_updateDate['author'] = lo_record?.author;
      lo_updateDate['version'] = lo_record?.version;
      lo_updateDate['description'] = lo_record?.description;
      setUpdateRecord(lo_record);
  }else{
    setUpdateRecord({})
  }
  form.resetFields()
  form.setFieldsValue(lo_updateDate)
  setSpining(false)
}
useEffect(() => {
  setFormData();
}, [ls_updateId]);

  const handleSearch = debounce((value) => {
    setFilterText(value);
 }, 300);
    return (
        <>
        <Breadcrumb parent="My Other Document"  />
        <Container fluid={true}>

          <div className="col-xl-12 xl-100">
          <div className="card">
                        <div className="card-header card-header-border">
                            <div className="row" style={{alignItems:'center'}}>
                              <div className="col-sm-8">
                                  <h5>{"My Other Documents"}</h5>
                              </div>
                              <div className="col-sm-4">
                                  <div >
                                  <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
                                  </div>
                              </div>
                            </div>
                        </div>
                        {/* <div className="col-sm-3">
                            <div className="pull-right right-header">
                            <div className="onhover-dropdown">
                                <button className="btn btn-primary btn-pill" type="button">
                                {'2024'}{" "}
                                <span className="pe-0">
                                    <i className="fa fa-angle-down"></i>
                                </span>
                                </button>
                                <div className="onhover-show-div right-header-dropdown">
                                <a className="d-block" href="#javascript">
                                    {'2024'}
                                </a>
                                <a className="d-block" href="#javascript">
                                    {'2023'}
                                </a>
                                <a className="d-block" href="#javascript">
                                    {'2022'}
                                </a>
                                </div>
                            </div>
                            </div>
                        </div> */}
                        {/* </div> */}
                    {/* </div> */}
             <div className="card-body">
                <div className="table-responsive">
                    <DataTable
                      columns={la_columnsList}
                      data={la_documentList}
                      striped={true}
                      center={true}
                      persistTableHead
                      pagination
                      paginationServer
                      paginationTotalRows={totalCount} // Assuming 10 rows per page
                      paginationPerPage={10} // Number of rows per page
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                      paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                      onChangePage={setCurrentPage} 
                      progressPending={loading}
                      progressComponent={<div><LoadingOutlined/></div>}
                      customStyles={{
                      headCells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px',
                          },
                      },
                      rows: { style: { minHeight: '40px'}},
                      cells: {
                          style: {
                            paddingLeft: '8px', // Adjust cell padding as needed
                            paddingRight: '8px', // Adjust cell padding as needed
                          },
                      },
                      }}
                   />
                </div>
             </div>
          </div>
          </div>
      </Container>
        </>
    )
  }
export default MyOtherDocument 
