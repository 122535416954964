import React, { useEffect, useState,useRef } from 'react';
import './style.css'
import { Download, Eye, File, FilePlus, } from 'react-feather';
import Breadcrumb from '../../../components/common/breadcrumb';
import {Container } from 'reactstrap';
import CountUp from 'react-countup';
import { FileIconsObj } from '../../../constant/globalConfig';
import AdvanceSerchForm from './advanceSerchForm';
import { Form, Popover, Typography, Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { getRequestApiByToken } from '../../../api';
import { getFileExtension, getQuerySelectorValueFormArray } from '../../../utility';
import moment from 'moment';
import DataTable from 'react-data-table-component'
import { LoadingOutlined } from '@ant-design/icons';
import DocumentListing from './DocumentListing';
import { useSelector } from 'react-redux';

const SearchOtherDocument = () => {
  const [la_documentList, setDocumentList] = useState([])
  const [la_sectorData, setSectorData] = useState([])
  const [la_practices, setPractices] = useState([])
  const [la_country, setCountry] = useState([])
  const [la_clientName, setClientName] = useState([])
  const [lb_isAdvanceSearch, setIsAdvanceSearch] = useState(false)
  const [la_documentCategory, setDocumentCategory] = useState([])
  const [la_documentSubCategory, setDocumentSubCategory] = useState([])
  const ls_userType = useSelector(content => content.commonReducer.user_type);

  const [filterForm] =Form.useForm()
  const la_country_id = Form.useWatch('country_id', filterForm);
  // const la_sector_id = Form.useWatch('sector_id', filterForm);
  // const la_parctices = Form.useWatch('parctices', filterForm);
  // const la_donor = Form.useWatch('donor', filterForm);
  const [la_donor, setDonor] = useState([])
  const [la_parctices, setParctices] = useState([])
  const [la_sector_id, setSector_id] = useState([])
  const [ls_searchText, setsSearchText] = useState('')
  const [la_keyWords, setKeyWords] = useState([]);
  const [ls_selectedDocumentType, setSelectedDocumentType] = useState([])
  const [clickedTags, setClickedTags] = useState({});
  const [lb_loading, setLoading] = useState(false);
  const [clickedTagIds, setClickedTagIds] = useState([]);

  const popoverRef = useRef();

  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [changePage, setChangePage] = useState(false);
  const [la_documentTypes, setDocumentTypes] = useState([])

  const getSectorData = async () => {
    // const res = await getRequestApiByToken('sector_master')
    const res = await getRequestApiByToken('get_sectors')
    const la_options=[]
    res?.sector_master?.map((v)=>la_options.push({label: v?.sector_name,value: v?.id}))
    setSectorData(la_options)
  }

  const getPracticeData = async () => {
  const res = await getRequestApiByToken('practice')
  const la_options=[]
  res?.practices?.map((v)=>la_options.push({label: v?.practice_name,value: v?.id}))
  setPractices(la_options)
  }

  // const getCountryData = async () => {
  // const res = await getRequestApiByToken('country_master')
  // const la_options=[]
  // res?.country_master?.map((v)=>la_options.push({label: v?.country_name,value: v?.id}))
  // setCountry(la_options)
  // }
  const getDonorData = async () => {
    const res = await getRequestApiByToken('client_master')
    const la_options=[]
    res?.client_master?.map((v)=>la_options.push({label: v?.client_name,value: v?.id}))
    setClientName(la_options)
}
const getDocumentCategoryData = async () => {
    const res = await getRequestApiByToken('docuemt_category');
    const la_docCatArr = res?.document_category?.filter((rowValue) => (rowValue?.parent_id == 0));
    setDocumentCategory(la_docCatArr || [])
}
  useEffect(() => {
    getSectorData()
    getPracticeData()
    // getCountryData()
    getDonorData()
    getDocumentCategoryData()
  }, [])
  useEffect(() => {
    // getSearchData({search:ls_searchText, country_id: la_country_id, sector_id:la_sector_id, parctices:la_parctices, donor:la_donor, key_words:la_keyWords, category_id:ls_selectedDocumentType})
    if(currentPage > 1) setCurrentPage(1);
    else setChangePage(!changePage);
  }, [ls_searchText, la_country_id, la_sector_id, la_parctices, la_donor, la_keyWords, ls_selectedDocumentType])

  useEffect(() => {
  console.log('la_sector_id',la_sector_id);
}, [la_sector_id])

  useEffect(() => {
    // if (clickedTagIds ==[]) {
    getSearchData({search:ls_searchText, country_id: la_country_id, sector_id:la_sector_id, parctices:la_parctices, donor:la_donor, key_words:la_keyWords, category_id:ls_selectedDocumentType})
    // }
  }, [currentPage, changePage])
 

    const getSearchData = async (value='') => {
      setLoading(true);
      const ls_params = getQuerySelectorValueFormArray(value);
      console.log('ls_paramsls_paramsls_paramsls_params',ls_params);
      // const res = await getRequestApiByToken(`document?orderBy=asc&pagination=false&${ls_params}`);
      const res = await getRequestApiByToken(`otherdocument?orderBy=desc&pagination=true&page=${currentPage}&${ls_params}`);
      setTotalCount(res?.Documents?.total || 0)
      setDocumentList(res?.Documents?.data || []);
      if (clickedTagIds.length === 0) {
        // Condition for when clickedTagIds is empty
      setDocumentTypes(res?.categories ? Object.values(res?.categories) : [])

    } else {
        // Condition for when clickedTagIds has a value
    }
      // setDocumentTypes(res?.categories ? Object.values(res?.categories) : [])
      setLoading(false);
    }

    const handleMultiSearch = async (value) => {
      console.log('value......',value);
      const ls_params = getQuerySelectorValueFormArray(value);
      if (value) {
      // filterForm.setFieldsValue({ country_id: value.country_id, sector_id: value.sector_id, "parctices": value.parctices, donor: value.donor});
      setsSearchText(value?.search || '')
      setSector_id(value.sector_id || [])
      setParctices(value.parctices || [])
      setDonor(value.donor || [])
      setKeyWords(value?.key_words || [])
      setSelectedDocumentType(value?.category_id || '')
      setClickedTagIds([])
      const res = await getRequestApiByToken(`otherdocument?orderBy=desc&pagination=true&page=${currentPage}&${ls_params}`);
      setTotalCount(res?.Documents?.total || 0)
      setDocumentList(res?.Documents?.data || []);
      setDocumentTypes(res?.categories ? Object.values(res?.categories) : [])

    }else{
      filterForm.setFieldsValue({ country_id: [], sector_id: [], "parctices": [], donor: []});
      setsSearchText('')
      setKeyWords([])
      setDocumentList([])
    }
    }

    const handlePaginationChange = (page, pageSize) => {
      setCurrentPage(page);
    };

   const  handleTagPress = (tagId) =>{
      if (clickedTagIds.includes(tagId)) {
        setClickedTagIds(clickedTagIds.filter(id => id !== tagId));
        setSelectedDocumentType(clickedTagIds.filter(id => id !== tagId))
      } else {
        // If tagId is not in the array, add it
        setClickedTagIds([...clickedTagIds, tagId]);
        setSelectedDocumentType([...clickedTagIds, tagId])

      }
   }
   const getFilterTagData =  async (value='') => {
    setLoading(true);
    const ls_params = getQuerySelectorValueFormArray(value);
    console.log('ls_paramsls_paramsls_paramsls_params',ls_params);
    // const res = await getRequestApiByToken(`document?orderBy=asc&pagination=false&${ls_params}`);
    const res = await getRequestApiByToken(`otherdocument?orderBy=desc&pagination=true&page=${currentPage}&${ls_params}`);
    setTotalCount(res?.Documents?.total || 0)
    setDocumentList(res?.Documents?.data || []);
    // setDocumentTypes(res?.categories ? Object.values(res?.categories) : [])
    setLoading(false);
  }

   useEffect(() => {
    // setSelectedDocumentType(clickedTagIds)
    // getFilterTagData({search:ls_searchText, country_id: la_country_id, sector_id:la_sector_id, parctices:la_parctices, donor:la_donor, key_words:la_keyWords, category_id:clickedTagIds})
   }, [clickedTagIds])
   
    const columns = [
      {
        selector: (row) => 
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
        {row?.title}
        </div>
        ,
        name: "Document Name",
        sortable: true,
        width: '310px', 
        // center: true,
      },
      {
        selector: (row) => row?.project?.mandate_no,
        name: "Mandate No.",
        sortable: true,
        center: true,
        width:'220px'
      },
      {
        selector: (row) => row?.sectors?.map((i,inx)=>i?.sector_name),
        name: "Sector",
        sortable: true,
        center: true,
        width: '265px', 
      },
      
      {
        selector: (row) => moment(row?.document_created_date).format("DD MMM YYYY"),
        name: "Date",
        sortable: true,
        center: true,
      },
      {
        selector: (row) => {
          const extnsn = getFileExtension(row?.document_file || '');
          return <div>
              {(row?.document_file && extnsn) ? FileIconsObj[extnsn] : row?.document_file}
          </div>
        } ,
        name: "Type",
        center: true,
      },
      {
        selector: (row) => <div>
          <span style={{cursor:"pointer",display:'flex',alignItems:'center'}}>
          <Link to={'/document/document-details'} state={{ documentId: row.id }} style={{marginInlineEnd:10}}>
              <i className="fa fa-eye text-secondary" style={{ fontSize: 18 }}/>
          </Link>
          <a href='#javascript'><i className="fa fa-share-alt text-secondary" style={{ fontSize: 18 }}></i></a>
          <Popover ref={popoverRef} placement="left" content={getDocsDetailsContent(row)} trigger="click" overlayClassName='col-xl-4 xl-100'>
                  <a className="badge rounded-pill badge-secondary"
                    href="#javascript" style={{marginInlineStart:10}}>
                    <i className="icofont icofont-info"></i></a>
          </Popover>
        </span>
      </div>,
        name: "Action",
      //   sortable: true,
        center: true,
        // width: '100px',
      },
    ];
    return (
        <>
        <Breadcrumb parent="Search Other Document" title="" 
        sideComponent={
          <div style={{ display: 'flex',cursor:'pointer' }} onClick={()=>{
            if(lb_isAdvanceSearch) {setIsAdvanceSearch(false)} else {setIsAdvanceSearch(true)} }}>
            <i className={`fa fa-${lb_isAdvanceSearch ? 'minus' : 'plus'}-circle`} style={{ fontSize: 20, marginInlineEnd: 10 }}></i>
            <strong>Advance Search</strong>
          </div>} 
        />
        <Container fluid={true}>
        <AdvanceSerchForm 
          //  form={filterForm}
           la_sectorData={la_sectorData}
           la_practices={la_practices}
          //  la_country={la_country}
           la_clientName={la_clientName}
           lb_loading={lb_loading}
           handleMultiSearch={handleMultiSearch}
           lb_isAdvanceSearch={lb_isAdvanceSearch}
           la_documentCategory={la_documentCategory} 
           la_documentSubCategory={la_documentSubCategory}
           setDocumentSubCategory={setDocumentSubCategory}
          //  handleTagSelect={handleTagSelect} handleSingleSearch={handleSingleSearch}

        />
        
        {/* <div className="row">
          <div className="col-sm-3 col-xl-3 col-lg-6">
            <div className="card o-hidden" style={{height:110}}>
              <div className="bg-primary b-r-4 card-body">
                <div className="d-flex static-top-widget">
                  <div className="align-self-center text-center">
                  <FilePlus /> 
                  </div>
                  <div className="flex-grow-1">
                    <span className="m-0">{'TOTAL DOCUMENTS'}</span>
                    <h4 className="mb-0 counter">
                      <CountUp className="counter" end={totalCount || 0} />
                    </h4>
                    <FilePlus className="icon-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xl-3 col-lg-6">
            <div className="card o-hidden" style={{height:110}}>
              <div className="bg-secondary b-r-4 card-body">
                <div className="d-flex static-top-widget">
                  <div className="align-self-center text-center">
                    <File />
                  </div>
                  <div className="flex-grow-1"><span className="m-0">{'NEW DOCUMENTS'}</span>
                    <h4 className="mb-0 counter">
                      <CountUp className="counter" end={33} />
                    </h4>
                    <File className="icon-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xl-3 col-lg-6">
            <div className="card o-hidden" style={{height:110}}>
              <div className="bg-primary b-r-4 card-body">
                <div className="d-flex static-top-widget">
                  <div className="align-self-center text-center">
                    <Download />
                  </div>
                  <div className="flex-grow-1"><span className="m-0">{'DOWNLOAD'}</span>
                    <h4 className="mb-0 counter">
                      <CountUp className="counter" end={3} /> / <CountUp className="counter" end={10} />
                    </h4>
                    <Download className="icon-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xl-3 col-lg-6">
            <div className="card o-hidden" style={{height:110}}>
              <div className="bg-primary b-r-4 card-body">
                <div className="d-flex static-top-widget">
                  <div className="align-self-center text-center">
                    <Eye />
                  </div>
                  <div className="flex-grow-1"><span className="m-0">{'VIEW'}</span>
                    <h4 className="mb-0 counter">
                    <CountUp className="counter" end={33} /> / <CountUp className="counter" end={100} />
                    </h4>
                    <Eye className="icon-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  */}
          <div className="row">
            <div className="col-xl-12">
            <DocumentListing {...{ lb_showDocTypeTag:true, 
              ls_headerText:'Other Documents', 
              la_documentTypes, lb_loading, 
              handlePaginationChange, 
              totalCount, la_documentList, 
              getSearchData, setLoading, 
              la_actionAceess:['view',`${ls_userType == 'admin' && 'edit'}`, 'delete', '', `${ls_userType == 'admin' && 'transfer'}`],
              handleTagPress,clickedTagIds
              }}/>

                {/* <div className="card">
                    <div className="card-header card-header-border">
                        <div className="row" style={{alignItems:'center'}}>
                          <div className="col-sm-8">
                              <h5>{"Other Documents"}</h5>
                          </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <DataTable
                              columns={columns}
                              data={la_documentList}
                              striped={true}
                              center={true}
                              persistTableHead
                              pagination
                              paginationServer
                              paginationTotalRows={totalCount} // Assuming 10 rows per page
                              paginationPerPage={10} // Number of rows per page
                              paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                              paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                              onChangePage={setCurrentPage} 
                              progressPending={lb_loading}
                              progressComponent={<div><LoadingOutlined/></div>}
                              customStyles={{
                              headCells: {
                                  style: {
                                    paddingLeft: '8px', // Adjust cell padding as needed
                                    paddingRight: '8px',
                                  },
                              },
                              rows: { style: { minHeight: '40px'}},
                              cells: {
                                  style: {
                                    paddingLeft: '8px', // Adjust cell padding as needed
                                    paddingRight: '8px', // Adjust cell padding as needed
                                  },
                              },
                              }}
                          />
                        </div>
                    </div>
                </div> */}
            </div>
          </div>
      </Container>
        </>
    );
};
const getFileDetails = (row) => {
  const extension = getFileExtension(row?.document_file || '');
  const fileElement = (row?.document_file && extension) ? FileIconsObj[extension] : row?.document_file
  return { extension, fileElement }
}

const getDocsDetailsContent = (lo_documentData={}) => {
  const items = [
    {
      key: '1',
      label: <Typography.Text type="secondary" strong><i className="fa fa-file-o docdtl-icn-color"></i> &nbsp;Document Name</Typography.Text>,
      children: lo_documentData?.title,
    },
    // {
    //   key: '2',
    //   label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Project Name</Typography.Text>,
    //   children: lo_documentData?.project?.project_name,
    // },
    {
      key: '3',
      label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Created Date</Typography.Text>,
      children: moment(lo_documentData?.document_created_date).format("DD MMM YYYY"),
    },
    {
      key: '3',
      label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Last Downloaded</Typography.Text>,
      // children: '10/09/2013',
      children: '--',
    },
    {
      key: '4',
      label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;No of Pages</Typography.Text>,
      children: '8',
    },
    {
      key: '4',
      label: <Typography.Text type="secondary" strong><i className="fa fa-file-text-o docdtl-icn-color"></i> &nbsp;Type</Typography.Text>,
      children: <>{getFileDetails(lo_documentData)?.extension} {getFileDetails(lo_documentData)?.fileElement}</>,
      // children: <>Pdf <i className="fa fa-file-pdf-o"></i></>,
    },
    {
      key: '4',
      label: <Typography.Text type="secondary" strong><i className="fa fa-download docdtl-icn-color"></i> &nbsp;No of Downloads</Typography.Text>,
      children: '0',
    },
    {
      key: '4',
      label: <Typography.Text type="secondary" strong><i className="fa fa-eye docdtl-icn-color"></i> &nbsp;No of Views</Typography.Text>,
      children: '0',
    },
  ];
        return (
          // <div className='col-xl-6 xl-100'>
            <Descriptions bordered column={1} size="small" labelStyle={{minWidth:180}}>
              {items.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                  {item.children}
                </Descriptions.Item>
              ))}
            </Descriptions>
          // </div>
       )
    }

export default SearchOtherDocument;