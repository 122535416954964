import React from 'react';
import { Col, Modal, Row } from 'antd';
import Form from "antd/lib/form";
import TextArea from 'antd/es/input/TextArea';

const ReviewModal = ({ visible=false, onCancel=()=>{},onOk=()=>{} ,loading=false}) => {
    const [form] = Form.useForm();
  
    return (
    <Modal
      title={"Review"}
      visible={visible}
      onCancel={onCancel}
        // footer={null}
        onOk={() => {
            form.validateFields().then((values) => {
                // form.resetFields();
                onOk(values);
            })
                .catch((info) => {
                    // console.log('Validate Failed:', info);
                });
        }
        }
        confirmLoading={loading}
    >
      <Form form={form}
                className='mt8-xs'
                labelCol={{
                    span: 8,
                }}
            >
        <Row>
            <Col span={24}>
                <Form.Item
                    labelAlign="right"
                    label="Review"
                    name='review'
                    rules={[{ required: true, message: "Please input Review!" }]}
                >
                  <TextArea rows={4} placeholder={'Review'}/>

                </Form.Item>
            </Col>
       </Row> 
       </Form>            
    </Modal>
  );
};

export default ReviewModal;
