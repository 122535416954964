import { Button, Checkbox, Divider, Form, Select } from 'antd'
import Typography from 'antd/es/typography/Typography'
import React, { useEffect, useState } from 'react'
import { Card, CardFooter, CardHeader, Col, Media,Row } from 'reactstrap'
import DocumentLimit from './documentLimit'
import IpAccessForm from '../ipAccessForm'
import DeviceId from '../deviceId'
import { getRequestApiByToken, postRequestApiByTokenWithDataType } from '../../../api'
import { message } from 'antd/lib'
import defaultUserImg from "../../../assets/images/user/demouser.png"

const Createusercard = ({userId, la_userDetails=[], handleSetUserId, handleSetDataAction, setShowUserAccess}) => {
  const [lb_spining, setSpining] = useState(false);
  const [lo_userDetails, setUserDetails] = useState({})
  const [lb_loading, setLoading] = useState(false);
  const [la_groupData, setGroupData] = useState([]);
  const [formUser] =Form.useForm()
  const ls_is_admin = Form.useWatch('is_admin', formUser);
  useEffect(() => {
    console.log('----ls_is_admin----',ls_is_admin);
    if (ls_is_admin == 1) {
      setShowUserAccess(false)
    } else {
      setShowUserAccess(true)
    }
  }, [ls_is_admin, userId])
  

  useEffect(() => {
    getuserinfo()
  }, [userId])
  useEffect(() => {
    getGroupData();
  }, [])
  
  
  const getuserinfo = async () => {
    setSpining(true)
    let res = {};
    let lo_updateDate = {};

    if(userId){
      res = await getRequestApiByToken(`userdetails/${userId}`)
      // console.log('update',res);
      const lo_record = res?.User ? res.User : {};
      setUserDetails(lo_record);
  
      const lo_download_limit = lo_record?.download_limit || {};
      const lo_info = lo_record?.info || {};
      lo_updateDate = {...lo_download_limit, ...lo_info, user_id: userId};
      lo_updateDate['group_id'] = lo_record?.groups?.map((rowValue) => rowValue.id) || [];
      lo_updateDate['device_id'] = lo_record?.device_id?.map((rowValue) => { return {type:rowValue.type, id:rowValue.device_id}}) || [{type:'1', id:''},{type:'2', id:''}];
      lo_updateDate['ip_address'] = lo_record?.ip_address?.map((rowValue) => { return {name:rowValue.name, ip:rowValue.ip_address}}) || [{name:'Office', ip:''},{name:'Project Office', ip:''}];
      lo_updateDate['is_admin'] = (lo_record?.user_type_id == 1) ? 1 : 0;
      if (!lo_updateDate['device_id']?.length) {
        lo_updateDate['device_id'] = [{type:'1', id:''},{type:'2', id:''}];
      }
      if (!lo_updateDate['ip_address']?.length) {
        lo_updateDate['ip_address'] = [{name:'Office', ip:''},{name:'Project Office', ip:''}];
      }
    } 

    formUser.resetFields();
    formUser.setFieldsValue(lo_updateDate);
    setSpining(false);
  };

  const getGroupData = async () => {
    const res = await getRequestApiByToken('group_master');
    setGroupData(res?.group_master || []);
   }

  const handleSubmitForm = async (formValues) => {
    
    let lo_formData = { ...formValues }
    lo_formData['is_admin'] = formValues?.is_admin ? 1 : 0;
    lo_formData['group_id'] = formValues?.group_id ? formValues.group_id.join(",") : '';
    
    // console.log('formValues....>>>>>',lo_formData);
    setLoading(true)
    const res = await postRequestApiByTokenWithDataType('json', 'userdetails', lo_formData);
    if (res?.status == 'Success') {
        handleSetDataAction('get_user_access_list');
        message.success('Successfully Updated!');
    } else {
        message.error(res?.message);
    }
    setLoading(false)
  }

  return (
    <div className="container-fluid">
      <Form   form={formUser} onFinish={handleSubmitForm} >
     
      <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>

        <div className="col-xl-6  " >
          <div className="row">
          
            <div className="col-xl-12 xl-100 " >
              <div className="col-xl-12 theme-form">
              
                <Form.Item
                    // label="Document Category"
                    name="user_id"
                    rules={[{ required: true,  message: "Please input User!"}]}
                >
                    <Select placeholder="Select User"
                      onChange={(value, event) => {handleSetUserId(value)}}
                      allowClear showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {la_userDetails?.map(rowValue => (
                          <Select.Option key={rowValue.id} value={rowValue.id} subcategory={rowValue?.children || []}>
                              {`${rowValue.name} (${rowValue.email})`}
                          </Select.Option>
                      ))}
                    </Select>
                  
                </Form.Item>
              </div>
            </div>

            { userId ?
            <div className="col-xl-12 xl-100 createuser-crd-parent">
              <Card className="custom-card manage-profile" style={{minHeight:336}} >
                <CardHeader>
                  <Media body className="img-fluid" src={'https://react.pixelstrap.com/endless/static/media/7.7b45885578f37fc46324.jpg'} alt="" />
                </CardHeader>
                <div className="card-profile">
                  <Media body className="rounded-circle" src={defaultUserImg} alt="" />
                </div>
                <div className="text-center profile-details">
                  <h4>{lo_userDetails?.name || 'No User Selected'}</h4>
                  <h6>{lo_userDetails?.info?.designation || ''}</h6>
                </div>
                <CardFooter className="row">
                  <Col sm="4 col-4">
                    <h6>{"Mobile No"}</h6>
                    <small className="counter">{lo_userDetails?.info?.mobile_no}</small>
                  </Col>
                  <Col sm="4 col-4">
                    <h6>{"Email Id"}</h6>
                    <small className="counter">{lo_userDetails?.email}</small>
                  </Col>
                  <Col sm="4 col-4">
                    <h6>{"Office Location"}</h6>
                    <small className="counter">{lo_userDetails?.info?.office_location || '--'}</small>
                  </Col>
                </CardFooter>
              </Card>
            </div>
            : <></>}
          </div>
        </div>

        { userId ?
        <>
          <div className="col-xl-6 ">
            <div className="row">
              <div className="col-xl-12 xl-100 " >
                <div className='theme-form'>
                <Divider orientation='left'><h6>{"Document Limit"}</h6></Divider>
                </div>
              </div>

              <div className="col-xl-12 xl-100" >
                <div className="card" style={{minHeight:336}}>
                  <div className="card-body" style={{display:"flex", paddingBottom:6}}>
                    <Form.Item name="is_admin" valuePropName="checked" style={{width:'25%'}}>
                      <Checkbox>Is Admin</Checkbox>
                    </Form.Item>
                
                    <Form.Item style={{width:'75%'}}
                        // label="Groups"
                        name="group_id"
                        rules={[{ required: true,  message: "Please input Group!"}]}
                    >
                        <Select 
                          placeholder="Groups"
                          allowClear showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          mode='multiple'
                          maxTagCount={2}
                        >
                          {
                              la_groupData && la_groupData.map(rowValue => <Select.Option value={rowValue.id} key={rowValue.id}>
                                  {rowValue.group_name}
                              </Select.Option>)

                          }
                        </Select>
                    </Form.Item>
                  </div>
                  <div className="card-body" style={{paddingTop:6}}>
                    <Row className="align-items-center">
                      <Col className='col-sm-4 col-xl-4 col-lg-4 mb-3'>
                      </Col>
                      <Col className='col-sm-4 col-xl-4 col-lg-4 mb-3' style={{textAlign:"center"}}>
                        <Typography.Text style={{color:"#707273"}} strong>{'View'}</Typography.Text>
                      </Col>
                      <Col className='col-sm-4 col-xl-4 col-lg-4 mb-3' style={{textAlign:"center"}}>
                        <Typography.Text style={{color:"#707273"}} strong>{'Download'}</Typography.Text>
                      </Col>
                    </Row>
                    <DocumentLimit limitType={'Daily'} view_number={'daily_view'} download_number={'daily_download'}/>
                    <DocumentLimit limitType={'Weekly'} view_number={'weekly_view'} download_number={'weekly_download'}/>
                    <DocumentLimit limitType={'Monthly'} view_number={'monthly_view'} download_number={'monthly_download'}/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 xl-100">
            <div className="card" style={{minHeight:200}}>
                <div className="card-body" style={{paddingBottom:0, marginBottom:-38}}>
                  {/* <Form.Item
                    // name="remember"
                    // valuePropName="checked"
                  >
                    <Checkbox checked={true}>I.P Access</Checkbox>
                  </Form.Item> */}
                  <Form.Item>I.P Access</Form.Item>
                </div>
              <div className="card-body">
                <IpAccessForm/>
              </div>
            </div>
          </div>

          <div className="col-xl-6 xl-100">
            <div className="card" style={{minHeight:200}}>
              <div className="card-body" style={{paddingBottom:0, marginBottom:-38, display:"flex", justifyContent:"space-between"}}>
                <Form.Item
                  // name="remember"
                  label='Device Id'
                  // valuePropName="checked"
                ></Form.Item>
                <div style={{display:"flex"}}>
                  <Form.Item>Desktop</Form.Item>&nbsp;
                  <Form.Item> / Mobile</Form.Item>
                  {/* <Form.Item><Checkbox checked={true}>Desktop</Checkbox></Form.Item>
                  <Form.Item><Checkbox checked={true}>Mobile</Checkbox></Form.Item> */}
                </div>
                
              </div>
              <div className="card-body">
                <DeviceId/>
              </div>
            </div>
          </div>

          <div className="col-xl-12 xl-100">
            <Button
                loading={lb_loading}
                // icon={<i className="fa fa-search" style={{ color: 'white' }} />}
                className="btn btn-primary button-style"
                type="button"
                htmlType='submit'
                style={{float:"right", maxWidth:100}}
                //   onClick={handleMultiSearch}
            >Save</Button>
          </div>
        </>
        : <></>}

      </div>
      </Form>
    </div>
  )
}

export default Createusercard
